export const MAX_MAIN_STEP_NUMBER = 3;
export var InstallationStepType;
(function (InstallationStepType) {
    InstallationStepType[InstallationStepType["SiteDetail"] = 1] = "SiteDetail";
    InstallationStepType[InstallationStepType["CustomerAgreement"] = 2] = "CustomerAgreement";
    InstallationStepType[InstallationStepType["SystemSetting"] = 3] = "SystemSetting";
    InstallationStepType[InstallationStepType["ArrayMapping"] = 4] = "ArrayMapping";
})(InstallationStepType || (InstallationStepType = {}));
export var INSTALL_DONE;
(function (INSTALL_DONE) {
    INSTALL_DONE[INSTALL_DONE["DONE"] = 1] = "DONE";
    INSTALL_DONE[INSTALL_DONE["NOT"] = 0] = "NOT";
})(INSTALL_DONE || (INSTALL_DONE = {}));
export var OFFLINE_FLAG;
(function (OFFLINE_FLAG) {
    OFFLINE_FLAG[OFFLINE_FLAG["OFFLINE"] = 1] = "OFFLINE";
    OFFLINE_FLAG[OFFLINE_FLAG["ONLINE"] = 0] = "ONLINE";
})(OFFLINE_FLAG || (OFFLINE_FLAG = {}));
export const INSTALLATION_STATUS = {
    /** 완료 */
    COMPLETE: 'complete',
    /** 진행중 */
    IN_PROGRESS: 'inProgress',
    /** 미완료 */
    IN_COMPLETE: 'inComplete',
};
export const INSTALLATION_SUB_STEP = {
    SITE_CREATION: 'siteCreation',
    EMS_FIRST_SETTING: 'emsFirstSetting',
    EMS_SECOND_SETTING: 'emsSecondSetting',
    ARRAY_MAPPING: 'arrayMapping',
};
export const SITE_TYPE = {
    RESIDENTIAL: '1',
    COMMERCIAL: '2',
    OTHER_PURPOSE: '3',
    TEST: '4',
};
export const RETAILER_CODE = {
    ARCSTREAM: '1',
    SYNERGY: '2',
    SYMPHONY: '3',
    NECTR: '4',
};
export const INSTALLATION_POPUP_NAME = {
    REPLACE_DEVICE: 'replaceDevice',
    REPLACE_NMI_DEVICE: 'replaceNMIDevice',
    SAPN_LOADING: 'sapnLoading',
};
