/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import { computed } from 'vue';
import { useStore } from 'vuex';
export const useStormCover = () => {
    const store = useStore();
    const isStormCoverEnable = computed(() => store.state.storm.stormCoverStatus);
    const severeWeatherEventList = computed(() => store.state.storm.stormCoverEvent);
    return {
        isStormCoverEnable,
        severeWeatherEventList,
    };
};
