import '@hems/component/resources/scss/main.scss';
import { defineComponent, ref, computed, watch } from 'vue';
import Image from '@hems/component/src/image/Image2.vue';
import { MAPPER_POPUP_ICON_SIZE } from '@hems/util/src/constant/mapper';
// FIXME: Class 제거 및 컴포지블화 작업 필요
class MobilePopup {
    // FIXME: 변수명 '_' 제거 필요
    _type = 'alert';
    _imageName = 'img_check_72';
    _imageExtension = 'svg';
    _imageSize = MAPPER_POPUP_ICON_SIZE.CHECK;
    _title = '';
    _message = '';
    _okName = 'Ok';
    _cancelName = 'Cancel';
    constructor() {
        this.init();
    }
    init(type = 'alert') {
        this._type = type;
        this._imageName = this.setPopupImageName(type);
        this._imageExtension = 'svg';
        this._imageSize = this.setPopupImageSize(type);
        this._title = 'Ok';
        this._message = 'Cancel';
        return this;
    }
    setPopupImageName(type) {
        return ImageNameByPopupType[type] ?? '';
    }
    // FIXME: 화살표 함수로 변경 필요
    setPopupImageSize(type) {
        return ImageSizeByPopupType[type] ?? MAPPER_POPUP_ICON_SIZE.CHECK;
    }
    getInstance(resolve, reject) {
        return {
            on: true,
            type: this._type,
            imageName: this._imageName,
            imageExtension: this._imageExtension,
            imageSize: this._imageSize,
            title: this._title,
            message: this._message,
            okName: this._okName,
            cancelName: this._cancelName,
            resolve,
            reject,
        };
    }
    /**
     * 팝업 설정
     * @param type
     * @param title
     * @param message
     * @param okName
     * @param cancelName
     */
    setPopup(type, title, message, okName = 'OK', cancelName = 'Cancel') {
        this.init(type);
        this._title = title;
        this._message = message;
        this._okName = okName;
        this._cancelName = cancelName;
        return this.chainSetMessage();
    }
    chainSetMessage() {
        const self = this;
        return {
            open() {
                return new Promise((resolve, reject) => {
                    _mobilePopup.value.on = false;
                    setTimeout(() => {
                        _mobilePopup.value = self.getInstance(resolve, reject);
                    }, 250);
                });
            },
        };
    }
}
const ImageNameByPopupType = {
    alert: 'img_check_72',
    warn: 'img_error',
};
const ImageSizeByPopupType = {
    alert: MAPPER_POPUP_ICON_SIZE.CHECK,
    warn: MAPPER_POPUP_ICON_SIZE.ERROR,
};
const _mobilePopup = ref({});
const btnCancelEl = ref(null);
const close = () => {
    _mobilePopup.value.on = false;
    _mobilePopup.value.imageName = '';
};
export const useMobilePopup = () => new MobilePopup();
export default defineComponent({
    name: 'MobileMessagePopup',
    components: {
        Image,
    },
    setup() {
        const mobilePopup = computed(() => _mobilePopup.value);
        const onOk = () => {
            const { resolve } = mobilePopup.value;
            if (resolve)
                resolve(true);
            close();
        };
        const onCancel = () => {
            const { resolve } = mobilePopup.value;
            if (resolve)
                resolve(false);
            close();
        };
        watch(() => _mobilePopup.value.on, (on) => {
            if (on) {
                _mobilePopup.value.imageName = ImageNameByPopupType[_mobilePopup.value.type] ?? '';
                _mobilePopup.value.imageSize = ImageSizeByPopupType[_mobilePopup.value.type] ?? MAPPER_POPUP_ICON_SIZE.CHECK;
                setTimeout(() => {
                    btnCancelEl.value?.focus();
                }, 250);
                if (_mobilePopup.value.type === 'alert') {
                    setTimeout(() => {
                        close();
                    }, 1000);
                }
            }
        });
        return {
            mobilePopup,
            onOk,
            onCancel,
            btnCancelEl,
        };
    },
});
