class GroupRunResultRenderer {
    el;
    $container;
    constructor(props) {
        const { options } = props.columnInfo.renderer;
        this.$container = $('<div class="run-result-container"></div>');
        this.el = this.$container.get(0);
        const success = props.grid.getValue(props.rowKey, options?.successColumnName);
        const total = props.grid.getValue(props.rowKey, options?.totalColumnName);
        const $success = $(`<span class="run-result-item success">${success || 0}</span>`);
        const $blindValue = $(`<span class="run-result-item total">${total || 0}</span>`);
        this.$container.append($success, $('<span class="result-divider">/</span>'), $blindValue);
        this.render(props);
    }
    getElement() {
        return this.el;
    }
    async render(props) {
        const groupIdColumnName = props.columnInfo.renderer.options?.groupIdColumnName || 'grp_id';
        const cntrlIdColumnName = props.columnInfo.renderer.options?.ctrlIdColumnName || 'ctrl_id';
        const onClick = props.columnInfo.renderer.options?.click;
        const grpId = props.grid.getValue(props.rowKey, groupIdColumnName);
        const ctrlId = props.grid.getValue(props.rowKey, cntrlIdColumnName);
        if (!onClick)
            return;
        this.$container.off('click').on('click', () => onClick(grpId, ctrlId));
    }
}
export default GroupRunResultRenderer;
