import moment from 'moment';
import EventService from './EventService';
import { apiWrapper } from '../../util/helper';
export default class EventServiceInstaller extends EventService {
    constructor(axios) {
        super(axios);
    }
    async getEventList(params) {
        let _params = { ...params };
        if (params.date) {
            const { start, end } = params.date;
            if (start && end) {
                _params = {
                    ..._params,
                    fromDate: moment(start).format('YYYYMMDD'),
                    toDate: moment(end).format('YYYYMMDD'),
                };
                delete _params.date;
            }
        }
        const { data } = await this.get(apiWrapper.managerApi('/devices/alarms'), _params);
        return data;
    }
}
