import { defineComponent } from 'vue';
import { CHIP_TYPE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'BasicChip',
    props: {
        contents: {
            type: String,
            required: true,
        },
        chipType: {
            type: String,
            default: CHIP_TYPE.BASIC,
        },
    },
});
