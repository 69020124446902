import { computed, defineComponent, reactive } from 'vue';
import { BUTTON_SIZE, BUTTON_TYPE } from '@hems/util/src/constant';
const buttonSize = {
    [BUTTON_SIZE.SMALL]: 'btn_s',
    [BUTTON_SIZE.MEDIUM]: 'btn_m',
    [BUTTON_SIZE.LARGE]: 'btn_l',
    [BUTTON_SIZE.FIT_TO_PARENT]: '',
};
const buttonType = {
    primary: 'btn_c_3',
    secondary: 'btn_c_2',
    tertiary: 'btn_c',
    white: 'btn_c_4',
};
export default defineComponent({
    name: 'Button',
    props: {
        size: {
            type: String,
            default: BUTTON_SIZE.MEDIUM,
        },
        type: {
            type: String,
            default: BUTTON_TYPE.SECONDARY,
        },
        additionalClass: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const state = reactive({
            size: computed(() => buttonSize[props.size]),
            type: computed(() => buttonType[props.type]),
        });
        return {
            state,
        };
    },
});
