import { apiWrapper } from '../../util/helper';
import { Service } from '../Service';
export default class TermsService extends Service {
    constructor(axios) {
        super(axios);
    }
    async getTerms(termsType) {
        const { data } = await this.get(apiWrapper.managerApi('/managements/mobiles/pro-apps/terms'), { termsType });
        return data;
    }
    async registerDeviceTerms(params) {
        const { data } = await this.post(apiWrapper.managerApi('/managements/mobiles/pro-apps/site-terms'), params);
        return data;
    }
    async getTermsList(category) {
        const { data } = await this.get(apiWrapper.managerApi('/managements/terms/list'), {
            categoryNm: category,
        });
        return data;
    }
    async getTermsDetail(termsId) {
        const { data } = await this.get(apiWrapper.managerApi('/managements/terms'), {
            termsId,
        });
        return data;
    }
    async registerTerms(termsInfo) {
        const { data } = await this.post(apiWrapper.managerApi('/managements/terms'), termsInfo);
        return data;
    }
    async updateTerms(termsId, termsInfo) {
        const { data } = await this.post(apiWrapper.managerApi('/managements/terms/update'), {
            termsId,
            ...termsInfo,
        });
        return data;
    }
    async deleteTerms(termsId) {
        const { data } = await this.delete(apiWrapper.managerApi('/managements/terms/delete'), undefined, {
            data: {
                termsId,
            },
        });
        return data;
    }
}
