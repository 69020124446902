import { computed, defineComponent, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMessageBox } from '@hems/component';
import { Helper } from '@hems/util';
import { RoleWebMap } from '@/config/ConstantPortal';
import NavContainer from './NavContainer.vue';
import NavItem from './NavItem.vue';
import VisualText from './VisualText.vue';
export default defineComponent({
    name: 'NavRoot',
    components: {
        NavItem,
        VisualText,
        NavContainer,
    },
    props: {
        roleNm: {
            type: String,
            default: null,
        },
    },
    emits: ['login'],
    setup(props) {
        const { t } = useI18n();
        const messageBox = useMessageBox();
        const state = reactive({
            roleNm: computed(() => props.roleNm),
            link: computed(() => {
                if (Helper.isNull(props.roleNm) || Helper.isNull(RoleWebMap[props.roleNm])) {
                    return;
                }
                return RoleWebMap[props.roleNm];
            }),
        });
        function goToSite() {
            if (!state.link) {
                messageBox.alert(t('message.no_access_privilege'));
                return;
            }
            window.location.href = state.link;
        }
        return {
            state,
            goToSite,
        };
    },
});
