import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import * as Highcharts from 'highcharts';
import { Panel, BasicCheckbox } from '@hems/component';
import { getLabelText } from '@hems/container/src/forms/device/statistics/_shared';
import { GRAPH_SERIES_TYPE, GRAPH_TERM_UNIT, GRAPH_Y_AXIS_UNIT } from '@hems/util/src/constant';
import { graphTooltipFormatter } from '@hems/util/src/helper/graphHelper';
import { capitalize } from '@hems/util/src/helper/helper';
import { getStringValue } from '@hems/util/src/helper/tsguardHelper';
export default defineComponent({
    name: 'DeviceLineGraph',
    components: {
        Panel,
        BasicCheckbox,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        checkAll: {
            type: Boolean,
            default: () => false,
        },
        filterList: {
            type: Array,
            default: () => [],
        },
        xAxisCategories: {
            type: Array,
            default: () => [],
        },
        termUnit: {
            type: String,
            default: GRAPH_TERM_UNIT.MINUTE,
        },
        data: {
            type: Array,
            default: () => [],
        },
    },
    setup(props) {
        const { t } = useI18n();
        const chart = ref(null);
        const filters = props.filterList.map((filterItem) => ({
            text: getLabelText(filterItem),
            value: filterItem.code,
            isChecked: filterItem.defaultChecked ?? false,
        }));
        const isAllChecked = ref(props.checkAll);
        const chartInstance = ref(null);
        const checkedFilters = ref(filters.map((filter) => ({
            name: filter.value,
            isChecked: props.checkAll ? true : filter.isChecked,
        })));
        const seriesRedrawState = {};
        onMounted(() => {
            createChart();
        });
        onBeforeUnmount(() => {
            destroyChart();
        });
        watch([() => props.xAxisCategories, () => props.data], () => {
            if (!chartInstance.value)
                return;
            const options = getOptionForUpdate(props.xAxisCategories, props.data);
            chartInstance.value?.update(options, true, true, false);
        });
        function onCheckAll() {
            isAllChecked.value = !isAllChecked.value;
            if (isAllChecked.value) {
                checkedFilters.value = checkedFilters.value.map((item) => {
                    return { name: item.name, isChecked: true };
                });
                chartInstance.value?.series?.forEach((item, idx) => {
                    item.setVisible(true, seriesRedrawState[idx] ?? true);
                    seriesRedrawState[idx] = false;
                });
            }
            else {
                checkedFilters.value = checkedFilters.value.map((item) => {
                    return { name: item.name, isChecked: false };
                });
                chartInstance.value?.series?.forEach((item) => item.setVisible(false, false));
            }
            chartInstance.value?.redraw();
        }
        function onClickCheckbox(idx) {
            if (!chartInstance.value)
                return;
            checkedFilters.value[idx].isChecked = !checkedFilters.value[idx].isChecked;
            isAllChecked.value = checkedFilters.value.every((item) => item.isChecked);
            if (checkedFilters.value[idx].isChecked) {
                chartInstance.value.series?.[idx]?.setVisible(true, seriesRedrawState[idx] ?? true);
                seriesRedrawState[idx] = false;
            }
            else {
                chartInstance.value.series?.[idx]?.setVisible(false, false);
            }
            chartInstance.value.redraw();
        }
        function getOptions(renderTo) {
            return {
                credits: { enabled: false },
                title: { text: '' },
                chart: {
                    type: GRAPH_SERIES_TYPE.SPLINE,
                    zoomType: 'x',
                    renderTo,
                },
                boost: {
                    useGPUTranslations: true,
                },
                yAxis: [
                    {
                        id: GRAPH_Y_AXIS_UNIT.WATT,
                        title: {
                            text: `${t('device.power')} (${GRAPH_Y_AXIS_UNIT.WATT})`,
                        },
                        showEmpty: false,
                    },
                    {
                        id: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
                        title: {
                            text: `${t('common.energy')} (${GRAPH_Y_AXIS_UNIT.WATT_HOUR})`,
                        },
                        min: 0,
                        showEmpty: false,
                    },
                    {
                        id: GRAPH_Y_AXIS_UNIT.KILOWATT_HOUR,
                        title: {
                            text: `${t('common.energy')} (${GRAPH_Y_AXIS_UNIT.KILOWATT_HOUR})`,
                        },
                        min: 0,
                        showEmpty: false,
                    },
                    {
                        id: GRAPH_Y_AXIS_UNIT.VOLTAGE,
                        title: {
                            text: `${t('device.voltage')} (${GRAPH_Y_AXIS_UNIT.VOLTAGE})`,
                        },
                        showEmpty: false,
                    },
                    {
                        id: GRAPH_Y_AXIS_UNIT.VOLTAGE_AMPERE,
                        title: {
                            text: `Apparent Power (${GRAPH_Y_AXIS_UNIT.VOLTAGE_AMPERE})`,
                        },
                        showEmpty: false,
                    },
                    {
                        id: GRAPH_Y_AXIS_UNIT.VOLTAGE_AMPERE_REACTIVE,
                        title: {
                            text: `${t('device.voltage')} (${GRAPH_Y_AXIS_UNIT.VOLTAGE_AMPERE_REACTIVE})`,
                        },
                        showEmpty: false,
                    },
                    {
                        id: GRAPH_Y_AXIS_UNIT.AMPERE,
                        title: {
                            text: `${t('device.current')} (${GRAPH_Y_AXIS_UNIT.AMPERE})`,
                        },
                        showEmpty: false,
                    },
                    {
                        id: GRAPH_Y_AXIS_UNIT.HERTZ,
                        title: {
                            text: `${t('device.frequency')} (${GRAPH_Y_AXIS_UNIT.HERTZ})`,
                        },
                        opposite: true,
                        showEmpty: false,
                    },
                    {
                        id: GRAPH_Y_AXIS_UNIT.CELSIUS,
                        title: {
                            text: `${t('device.temp')} (${GRAPH_Y_AXIS_UNIT.CELSIUS})`,
                        },
                        opposite: true,
                        showEmpty: false,
                    },
                    {
                        id: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
                        title: {
                            text: `${t('common.percentage')} (${GRAPH_Y_AXIS_UNIT.PERCENTAGE})`,
                        },
                        opposite: true,
                        showEmpty: false,
                    },
                    {
                        id: GRAPH_Y_AXIS_UNIT.FACTOR,
                        title: {
                            text: capitalize(GRAPH_Y_AXIS_UNIT.FACTOR),
                        },
                        showEmpty: false,
                    },
                ],
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom',
                },
                plotOptions: {
                    series: {
                        lineWidth: 1,
                        events: {
                            legendItemClick(evt) {
                                const id = getStringValue(evt.target.userOptions.id);
                                const visible = evt.target.visible;
                                checkedFilters.value.forEach((item, i) => {
                                    if (id && item.name === id) {
                                        checkedFilters.value[i].isChecked = !visible;
                                    }
                                });
                                return true;
                            },
                        },
                    },
                },
                series: [],
                tooltip: {
                    shared: true,
                    outside: true,
                    formatter() {
                        return graphTooltipFormatter(this);
                    },
                },
            };
        }
        function getOptionForUpdate(xAxisCategories, data) {
            const series = data.map((item, idx) => {
                seriesRedrawState[idx] = checkedFilters.value.map((item) => !item.isChecked)[idx];
                return {
                    ...item,
                    boostThreshold: 1,
                    turboThreshold: 1,
                    visible: checkedFilters.value.map((item) => item.isChecked)[idx],
                    type: GRAPH_SERIES_TYPE.SPLINE,
                };
            });
            return {
                xAxis: {
                    categories: xAxisCategories,
                    tickPositioner: () => {
                        const tickX = new Set([0]);
                        const regexp2hours = /(00|02|04|06|08|10|12|14|16|18|20|22|24):00$/g;
                        if (props.termUnit === GRAPH_TERM_UNIT.MINUTE) {
                            xAxisCategories.forEach((item, index) => {
                                if (regexp2hours.test(item))
                                    tickX.add(index);
                            });
                        }
                        else if (props.termUnit === GRAPH_TERM_UNIT.HOUR) {
                            const regexp4hours = /(00|04|08|12|16|20|24):00$/g;
                            const temp = [];
                            const maxCount = 14;
                            // 일단 2시간 단위로 필터링
                            xAxisCategories.forEach((item, index) => {
                                if (regexp2hours.test(item))
                                    temp.push({ index, item });
                            });
                            // 2시간 단위로 필터링 했을 때 maxCount보다 크면 4시간 단위로 다시 필터링
                            const temp2 = temp.length > maxCount ? temp.filter((item) => regexp4hours.test(item.item)) : temp;
                            // 4시간 단위로 필터링 했을 때에도 maxCount보다 크면 일단위로.,..
                            const temp3 = temp2.length > maxCount ? temp.filter((item) => item.item.endsWith('00:00')) : temp2;
                            temp3.map((item) => item.index).forEach(tickX.add, tickX);
                        }
                        tickX.add(xAxisCategories.length - 1);
                        return Array.from(tickX);
                    },
                },
                series,
            };
        }
        function createChart() {
            if (!chart.value)
                return;
            const options = getOptions(chart.value);
            chartInstance.value = Highcharts.chart(options);
        }
        function destroyChart() {
            if (chartInstance.value) {
                chartInstance.value.destroy();
                chartInstance.value = null;
            }
        }
        return {
            isAllChecked,
            chart,
            filters,
            checkedFilters,
            onClickCheckbox,
            onCheckAll,
        };
    },
});
