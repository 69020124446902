import { defineComponent, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { BasicInput, CommonPopup, SettingTable, SettingTableRow } from '@hems/component';
import { DeviceService } from '@hems/service/src/api/device/DeviceService';
export default defineComponent({
    name: 'ReplaceDevicePopup',
    components: {
        CommonPopup,
        Form,
        BasicInput,
        SettingTable,
        SettingTableRow,
    },
    props: {
        title: String,
        nmiDeviceId: {
            type: String,
            required: true,
        },
    },
    emits: ['confirm'],
    async setup(props, { emit }) {
        const { t } = useI18n();
        const deviceService = new DeviceService(window.axiosInstance.axios);
        const form = ref(null);
        const state = reactive({
            nmiDeviceId: props.nmiDeviceId,
            newNmiDeviceId: null,
        });
        const schema = yup.object().shape({
            newNmiDeviceId: yup
                .string()
                .nullable()
                .required()
                .isValidByFn('nmiDeviceId', async (curr) => {
                try {
                    if (!/^[0-9A-Z]{11,11}$/g.test(curr)) {
                        return { isValid: false };
                    }
                    const { result, fail_type } = await deviceService.checkNMIDeviceId(curr);
                    return {
                        isValid: result,
                        errMsg: fail_type === '1'
                            ? t('message.duplicate_serial')
                            : fail_type === '0'
                                ? t('account.invalid_special_number')
                                : undefined,
                    };
                }
                catch (error) {
                    return {
                        isValid: false,
                    };
                }
            }),
        });
        async function onConfirm() {
            const { valid } = await form.value?.validate();
            if (valid) {
                emit('confirm', state.newNmiDeviceId);
            }
        }
        watch(() => state.newNmiDeviceId, () => {
            state.newNmiDeviceId = state.newNmiDeviceId?.toUpperCase() ?? null;
        });
        return {
            state,
            onConfirm,
            schema,
            form,
        };
    },
});
