import { defineComponent, reactive, ref, watch } from 'vue';
import { gsap } from 'gsap';
import NavSubItem from '@hems/component/src/navigation/NavSubItem.vue';
export default defineComponent({
    name: 'NavMainItem',
    components: {
        NavSubItem,
    },
    props: {
        navItem: {
            type: Object,
            required: true,
        },
    },
    emits: ['click'],
    setup(props, { emit }) {
        const subMenuBg = ref();
        const subMenuContainer = ref();
        const state = reactive({
            show: false,
        });
        watch(() => state.show, (show) => {
            if (show) {
                gsap.to([subMenuBg.value, subMenuContainer.value], {
                    height: '4.375rem',
                    duration: 0.4,
                });
                return;
            }
            gsap.to([subMenuBg.value, subMenuContainer.value], {
                height: '0',
                duration: 0.4,
            });
        });
        const onClick = (e) => {
            emit('click', e, props.navItem.path);
        };
        const onSubClick = (e, path) => {
            emit('click', e, path);
        };
        return {
            state,
            subMenuBg,
            subMenuContainer,
            onClick,
            onSubClick,
        };
    },
});
