import { defineComponent, computed } from 'vue';
import { ModalsContainer } from 'vue-final-modal';
import { useStore } from 'vuex';
import { Loading, MessageBox, ErrorNotification, MessageNotification, MobilePopup } from '@hems/component';
import ToastMessage from '@hems/component/src/toastMessage/ToastMessage.vue';
export default defineComponent({
    name: 'MainTemplate',
    components: {
        Loading,
        MessageBox,
        ErrorNotification,
        MessageNotification,
        MobilePopup,
        ModalsContainer,
        ToastMessage,
    },
    setup() {
        const store = useStore();
        const toastMessageInfo = computed(() => ({
            isShow: store.state.toast.isShow,
            message: store.state.toast.message,
            type: store.state.toast.type,
        }));
        return { toastMessageInfo };
    },
});
