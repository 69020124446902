import { computed, defineComponent, reactive } from 'vue';
import { useModelWrapper } from '@hems/util';
export default defineComponent({
    name: 'BasicCheckbox',
    inheritAttrs: false,
    props: {
        name: {
            type: String,
            required: false,
            default: null,
        },
        value: {
            type: [String, Number, Boolean, null],
            required: false,
            default: null,
        },
        modelValue: {
            type: [Array, Boolean],
            required: false,
            default: false,
        },
        readonly: {
            type: [String, Boolean],
            required: false,
            default: false,
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            isReadonly: computed(() => !!(props.readonly === '' || props.readonly)),
            value: useModelWrapper(props, emit),
        });
        const onClick = (e) => {
            if (state.isReadonly) {
                e.preventDefault();
            }
        };
        return {
            state,
            onClick,
        };
    },
});
