import { computed, defineComponent, ref, watch, onMounted, onBeforeUnmount } from 'vue';
import * as Highcharts from 'highcharts';
import { GRAPH_SERIES_TYPE } from '@hems/util/src/constant';
import { AC_MODULE_CHART_LABEL, AC_MODULE_KEY, ACModuleColorArr, ACModuleKeyArr } from '@hems/util/src/constant/mapper';
import { graphTooltipFormatter } from '@hems/util/src/helper/graphHelper';
export default defineComponent({
    name: 'SmartModuleWebACModuleChartContainer',
    props: {
        title: {
            type: String,
            default: '',
        },
        xAxisCategories: {
            type: Array,
            default: () => [],
        },
        yAxis: {
            type: Array,
            default: () => [],
        },
        data: {
            type: Array,
            default: () => [],
        },
        deviceIdList: {
            type: Array,
            default: () => [],
        },
        tickXCount: {
            type: Number,
            default: 12,
        },
    },
    setup(props) {
        const chart = ref(null);
        const instance = ref(null);
        const isMultiCase = computed(() => props.deviceIdList.length > 1);
        const selectedDeviceCount = computed(() => props.deviceIdList.length);
        let visibleLegendArr = [AC_MODULE_CHART_LABEL.AC_POWER_PRODUCED];
        let legendName = '';
        const setIntegratedLegend = (data) => {
            const integratedData = [];
            // FIXME: push 하지 않고 data.map 으로 대입하는 방식으로 변경
            data.map((item) => {
                if (legendName !== String(item.yAxis)) {
                    legendName = item.yAxis;
                    integratedData.push({
                        name: item.name,
                        altName: item.altName,
                        data: item.data,
                        type: item.type,
                        yAxis: item.yAxis,
                        visible: !(isMultiCase.value && item.yAxis !== AC_MODULE_KEY.WATT),
                        color: ACModuleColorArr[ACModuleKeyArr.indexOf(item.yAxis)],
                        connectNulls: item.type === GRAPH_SERIES_TYPE.SPLINE,
                        pointWidth: item.type === GRAPH_SERIES_TYPE.COLUMN ? 5 : 0,
                    });
                }
                else {
                    integratedData.push({
                        name: `${item.name} ${item.id}`,
                        altName: item.altName,
                        data: item.data,
                        type: item.type,
                        visible: !(isMultiCase.value && item.yAxis !== AC_MODULE_KEY.WATT),
                        yAxis: item.yAxis,
                        linkedTo: ':previous',
                        color: ACModuleColorArr[ACModuleKeyArr.indexOf(item.yAxis)],
                        connectNulls: item.type === GRAPH_SERIES_TYPE.SPLINE,
                        pointWidth: item.type === GRAPH_SERIES_TYPE.COLUMN ? 5 : 0,
                    });
                }
            });
            return integratedData;
        };
        const visibleLegendChange = (chart, legendName) => {
            const oldestLegendName = visibleLegendArr[0];
            visibleLegendArr.splice(0, 2, visibleLegendArr[1], legendName);
            chart.series.forEach((item) => {
                if (oldestLegendName.includes(item.name)) {
                    item.setVisible(false, false);
                }
            });
        };
        const updateVisibleLegendArr = (isVisible, legendName) => {
            if (isVisible) {
                visibleLegendArr = visibleLegendArr.filter((item) => !item.includes(legendName));
            }
            else {
                visibleLegendArr.push(legendName);
            }
        };
        const getOptions = (xAxisCategories, data) => {
            const graphData = setIntegratedLegend(data);
            return {
                yAxis: props.yAxis,
                xAxis: {
                    categories: xAxisCategories,
                    tickPositioner: () => {
                        const dataLength = xAxisCategories.length;
                        const maxCountTickX = props.tickXCount;
                        const distance = Math.round(dataLength / maxCountTickX);
                        if (maxCountTickX > dataLength)
                            return Array.from(Array(dataLength), (_, idx) => idx);
                        const tickX = Array.from(Array(maxCountTickX), (_, idx) => distance * idx);
                        return tickX;
                    },
                },
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom',
                },
                plotOptions: {
                    series: {
                        lineWidth: 1,
                        events: {
                            legendItemClick: (event) => {
                                if (!isMultiCase.value)
                                    return true;
                                const chart = event.target.chart;
                                const selectedLegendName = event.target.userOptions.name;
                                if (!selectedLegendName)
                                    return false;
                                const visibleDeviceCount = chart.series.filter((item) => item.visible).length;
                                const visibleLegendCount = visibleDeviceCount / selectedDeviceCount.value;
                                if (!event.target.visible && visibleLegendCount >= 2) {
                                    visibleLegendChange(chart, selectedLegendName);
                                }
                                else {
                                    updateVisibleLegendArr(event.target.visible, selectedLegendName);
                                }
                            },
                        },
                    },
                    column: {
                        pointWidth: 5,
                    },
                },
                // FIXME: 타입 개선 필요
                series: graphData,
                responsive: {
                    rules: [
                        {
                            condition: {
                                maxWidth: 1000,
                                minHeight: 400,
                            },
                            chartOptions: {
                                legend: {
                                    layout: 'horizontal',
                                    align: 'center',
                                    verticalAlign: 'bottom',
                                },
                            },
                        },
                    ],
                },
                tooltip: {
                    shared: true,
                    formatter() {
                        return graphTooltipFormatter(this);
                    },
                },
            };
        };
        const createChart = (xAxisCategories, data) => {
            if (!chart.value)
                return;
            const options = getOptions(xAxisCategories, data);
            instance.value = Highcharts.chart(Object.assign({
                credits: { enabled: false },
                chart: {
                    type: GRAPH_SERIES_TYPE.SPLINE,
                    zoomType: 'x',
                    renderTo: chart.value,
                },
                title: {
                    text: props.title,
                    align: 'left',
                    style: {
                        fontWeight: 'bolder',
                        fontSize: '1.2em',
                    },
                },
                options,
            }));
            instance.value.update(options, true, true, true);
        };
        const destroyChart = () => {
            if (instance.value) {
                instance.value.destroy();
                instance.value = null;
            }
        };
        watch([() => props.data], () => {
            if (!instance.value)
                return;
            visibleLegendArr = [AC_MODULE_CHART_LABEL.AC_POWER_PRODUCED];
            /*
             FIXME: 데이터가 순서대로 들어가지 않아, legend visible/invisible 처리가 안돼서 신규 차트 생성 방식으로 우선 적용
             추후 legend visible/invisible 처리 및 차트 업데이트 방식으로 변경
            */
            destroyChart();
            createChart(props.xAxisCategories, props.data);
            // const options = getOptions(props.xAxisCategories, props.data);
            // instance.value.update(options, true, true, true);
        });
        onMounted(() => {
            createChart(props.xAxisCategories, props.data);
        });
        onBeforeUnmount(() => {
            destroyChart();
        });
        return {
            chart,
        };
    },
});
