import { defineComponent, reactive } from 'vue';
import NavMainItem from '@hems/component/src/navigation/NavMainItem.vue';
export default defineComponent({
    name: 'Navigation',
    components: {
        NavMainItem,
    },
    props: {
        navItems: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['click'],
    setup(props, { emit }) {
        const state = reactive({});
        const onClick = (e, path) => {
            emit('click', e, path);
        };
        return {
            state,
            onClick,
        };
    },
});
