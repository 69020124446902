import { defineComponent } from 'vue';
import ToggleButton from '@hems/component/src/buttons/ToggleButton.vue';
import { defaultInputErrorInfo } from '@hems/util/src/helper/helper';
export default defineComponent({
    name: 'ToggleButtonGroup',
    components: { ToggleButton },
    props: {
        selectedItemValues: {
            type: Array,
            required: true,
        },
        toggleButtonItems: {
            type: Array,
            default: () => [],
        },
        errorInfo: {
            type: Object,
            default: () => defaultInputErrorInfo,
        },
        toggleButtonItemIsDisabledList: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['change'],
    setup(props, { emit }) {
        const isSelectedItem = (value) => props.selectedItemValues.includes(value);
        const isDisabledItem = (value) => props.toggleButtonItemIsDisabledList.find((item) => item.key === value)?.isDisabled ?? false;
        const toggleItem = (selectedValue) => {
            const isSelected = isSelectedItem(selectedValue);
            emit('change', selectedValue, !isSelected);
        };
        return { toggleItem, isSelectedItem, isDisabledItem };
    },
});
