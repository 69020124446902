class LocalStorageService {
    get(key) {
        const items = window.localStorage.getItem(key);
        if (!items || items === 'null') {
            return null;
        }
        try {
            return JSON.parse(items);
        }
        catch (e) {
            console.log(e);
        }
        return null;
    }
    set(key, value) {
        if (value === undefined) {
            value = null;
        }
        else {
            value = JSON.stringify(value);
        }
        try {
            window.localStorage.setItem(key, value);
        }
        catch (e) {
            console.log(e);
        }
        return false;
    }
    remove(key) {
        window.localStorage.removeItem(key);
    }
    /* 아래 메서드들은 항목을 배열로 저장할 때 사용 */
    getOneItem(key, id) {
        const items = window.localStorage.getItem(key);
        if (!items || items === 'null') {
            return null;
        }
        try {
            const parsedItems = JSON.parse(items);
            return parsedItems.filter((item) => item.id === id)[0] ?? null;
        }
        catch (e) {
            console.log(e);
        }
        return null;
    }
    insertOneItem(key, newItem) {
        const items = this.get(key);
        if (items) {
            this.set(key, [...items, newItem]);
        }
        else {
            this.set(key, [newItem]);
        }
        return true;
    }
    updateOneItem(key, id, updatedItem) {
        const items = this.get(key);
        if (items) {
            const updateditems = items.map((item) => (item.id === id ? { ...item, ...updatedItem } : item));
            this.set(key, updateditems);
        }
        return true;
    }
    removeOneItem(key, id) {
        const items = this.get(key);
        if (items) {
            const updatedItems = items.filter((item) => item.id !== id);
            this.set(key, updatedItems);
        }
    }
}
export default new LocalStorageService();
