export const ROLE_TYPE = {
    ENGINEER: 'Engineer Page',
    SERVICE: 'Service Page',
};
export const DEVICE_STATUS = {
    INVALID_SERIAL: '0',
    REGISTERED_SERIAL: '1',
    NOT_REGISTERED_SERIAL: '2',
};
export const UNIQUE_PASSWORD_EMS_VERSION = 'R300001';
