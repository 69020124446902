import { useModal } from 'vue-final-modal';
import { MessageModal } from '@hems/component';
import { MESSAGE_MODAL_TYPE } from '@hems/util/src/constant';
export const useDangerConfirmationModal = ({ title, content, confirmButtonText, cancelButtonText, confirmCallback, cancelCallback, }) => {
    const messageModal = useModal({
        component: MessageModal,
        attrs: {
            type: MESSAGE_MODAL_TYPE.DANGER_CONFIRMATION,
            title,
            content,
            confirmButtonText,
            cancelButtonText,
            onConfirm: confirmCallback,
            onCancel: cancelCallback,
        },
    });
    return messageModal;
};
export const useConfirmationModal = ({ title, content, confirmButtonText, cancelButtonText, confirmCallback, cancelCallback, }) => {
    const messageModal = useModal({
        component: MessageModal,
        attrs: {
            type: MESSAGE_MODAL_TYPE.CONFIRMATION,
            title,
            content,
            confirmButtonText,
            cancelButtonText,
            onConfirm: confirmCallback,
            onCancel: cancelCallback,
        },
    });
    return messageModal;
};
export const useWarningModal = ({ title, content, confirmButtonText, confirmCallback, }) => {
    const messageModal = useModal({
        component: MessageModal,
        attrs: {
            type: MESSAGE_MODAL_TYPE.WARNING,
            title,
            content,
            confirmButtonText,
            onConfirm: confirmCallback,
        },
    });
    return messageModal;
};
