import { defineComponent, ref } from 'vue';
import { useLanguage } from '@hems/util';
export default defineComponent({
    name: 'CalendarNew',
    props: {
        modelValue: {
            type: [Date, null],
            required: true,
        },
        open: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: String,
            default: 'date',
        },
        minDate: Date,
        maxDate: Date,
        disabledDates: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    emits: ['update:modelValue', 'close'],
    setup(props, { emit }) {
        const selectedDate = ref(props.modelValue);
        const { languageCode } = useLanguage();
        const attributes = [
            {
                highlight: {
                    start: {
                        fillMode: 'solid',
                    },
                    base: {
                        fillMode: 'solid',
                    },
                    end: {
                        fillMode: 'solid',
                    },
                },
            },
        ];
        const close = () => {
            emit('close');
        };
        const apply = () => {
            emit('update:modelValue', selectedDate.value);
            close();
        };
        return {
            selectedDate,
            attributes,
            apply,
            close,
            languageCode,
        };
    },
});
