/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import { computed } from 'vue';
import { useStore } from 'vuex';
import { GEN_TYPE } from '@hems/util/src/constant';
import { isEnergyPolicyType } from '@hems/util/src/helper/tsguardHelper';
export const useUserSiteInfo = () => {
    const store = useStore();
    const deviceList = computed(() => store.state.site.deviceList);
    const masterDeviceInfo = computed(() => store.state.site.masterDeviceInfo);
    const siteId = computed(() => masterDeviceInfo.value?.deviceInfo.site_id ?? null);
    const energyControlValue = computed(() => masterDeviceInfo.value?.genType === GEN_TYPE.AC_SYS ? masterDeviceInfo.value?.deviceInfo.energy_control : '');
    const currentOperationMode = computed(() => isEnergyPolicyType(energyControlValue.value) ? energyControlValue.value : null);
    return {
        siteId,
        masterDeviceInfo,
        currentOperationMode,
    };
};
