import { defineComponent, reactive } from 'vue';
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue';
export default defineComponent({
    name: 'Flow',
    components: {
        LottieAnimation,
    },
    props: {
        name: String,
    },
    setup(props) {
        const state = reactive({});
        const baseUrl = process.env.BASE_URL?.substring(1) || '';
        return {
            state,
            baseUrl,
            path: `${baseUrl}resources/lottie/${props.name}.json`,
        };
    },
});
