import { defineComponent, computed } from 'vue';
import { VueFinalModal } from 'vue-final-modal';
import ButtonNew from '@hems/component/src/buttons/ButtonNew.vue';
import Image2 from '@hems/component/src/image/Image2.vue';
import { BUTTON_NEW_TYPE } from '@hems/util/src/constant';
const MESSAGE_MODAL_TYPE = {
    WARNING: 'WARNING',
    DANGER_CONFIRMATION: 'DANGER_CONFIRMATION',
    CONFIRMATION: 'CONFIRMATION',
};
export default defineComponent({
    name: 'MessageModal',
    components: { VueFinalModal, Image2, ButtonNew },
    props: {
        type: {
            type: String,
            default: MESSAGE_MODAL_TYPE.CONFIRMATION,
        },
        title: {
            type: String,
            required: true,
        },
        content: {
            type: String,
            required: true,
        },
        confirmButtonText: {
            type: String,
            default: '',
        },
        cancelButtonText: {
            type: String,
            default: '',
        },
        clickToClose: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['confirm', 'cancel'],
    setup(props, { emit }) {
        const iconName = computed(() => (props.type === MESSAGE_MODAL_TYPE.CONFIRMATION ? 'img_check_72' : 'img_error'));
        const isDangerAction = computed(() => props.type === MESSAGE_MODAL_TYPE.DANGER_CONFIRMATION);
        const confirmButtonType = isDangerAction.value ? BUTTON_NEW_TYPE.OTL : BUTTON_NEW_TYPE.SOLID;
        const cancelButtonType = props.type === MESSAGE_MODAL_TYPE.DANGER_CONFIRMATION ? BUTTON_NEW_TYPE.SOLID : BUTTON_NEW_TYPE.OTL;
        const onClose = (close) => {
            emit('cancel');
            close();
        };
        return {
            iconName,
            isDangerAction,
            confirmButtonType,
            cancelButtonType,
            MESSAGE_MODAL_TYPE,
            onClose,
        };
    },
});
