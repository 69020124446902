import { defineComponent } from 'vue';
import ListItem from '@hems/component/src/list/ListItem.vue';
export default defineComponent({
    name: 'List',
    components: {
        ListItem,
    },
    props: {
        itemList: {
            type: Array,
            required: true,
        },
        isBorder: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['updateCheckedStatus', 'onDetail', 'onEdit'],
    setup(_props, { emit }) {
        const updateCheckedStatus = (itemId, isChecked) => {
            emit('updateCheckedStatus', itemId, isChecked);
        };
        const onDetail = (event, itemId) => {
            if (event.target instanceof HTMLElement) {
                const element = event.target;
                if (element.tagName === 'DIV') {
                    emit('onDetail', itemId);
                }
            }
        };
        const onEdit = (itemId) => {
            emit('onEdit', itemId);
        };
        return {
            updateCheckedStatus,
            onDetail,
            onEdit,
        };
    },
});
