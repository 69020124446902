class ConnectionStatusRendererV2 {
    el;
    connection;
    blindValue;
    constructor(props) {
        this.connection = $('<span class="state_connect"></span>');
        this.blindValue = $('<span class="blind"></span>');
        this.connection.append(this.blindValue);
        this.el = this.connection.get(0);
        this.render(props);
    }
    getElement() {
        return this.el;
    }
    async render(props) {
        const value = props.grid.getValue(props.rowKey, 'connect_status');
        if (value === 'online') {
            this.connection.addClass('online');
            this.blindValue.text('connect');
        }
        else if (value === 'online-disconnected') {
            this.connection.addClass('online-disconnected');
            this.blindValue.text('disconnect');
        }
        else if (value === 'offline') {
            this.connection.addClass('offline');
            this.blindValue.text('disconnect');
        }
    }
}
export default ConnectionStatusRendererV2;
