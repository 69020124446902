import { defineComponent } from 'vue';
import { useLocale, useLanguage } from '@hems/util';
import { LANGUAGE } from '@hems/util/src/constant';
import { Image } from '@/components';
import { APP_STORE_TYPE } from '@/config/ConstantPortal';
export default defineComponent({
    name: 'Footer',
    components: { Image },
    emits: ['openAppDownloadPopup', 'openPrivacyPopup', 'openCyberSecurityPopup'],
    setup() {
        const { languageCode } = useLanguage();
        const { isEU } = useLocale();
        return {
            APP_STORE_TYPE,
            languageCode,
            isEU,
            LANGUAGE,
        };
    },
});
