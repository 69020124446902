import { DAYS_OF_WEEK } from '@hems/util/src/constant/date';
export const CALENDAR_ID = 'schedule';
export const SCHEDULE_EVENT_TYPE = {
    DEFAULT: 'default',
    HIGHLIGHT: 'highlight',
    DUPLICATED: 'duplicated',
    TEMPORARY: 'temporary',
};
export const ENERGY_MANAGEMENT_PAGE_TYPE = {
    SCHEDULE_SETTING: 'scheduleSetting',
    SCHEDULE_DELETE: 'scheduleDelete',
    SCHEDULE_OPERATION_DELETE: 'scheduleOperationDelete',
    PRESET_SETTING: 'presetSetting',
    PRESET_OPERATION_DELETE: 'presetOperationDelete',
    SCHEDULE_PRESET_OPERATION: 'schedulePresetOperation',
};
/** Time of Use Operation API Schema ENUM 값 */
export const TOU_OPERATION = {
    CHARGE_FROM_SOLAR: 'charge_from_solar',
    CHARGE_FROM_SOLAR_AND_GRID: 'charge_from_solar_and_grid',
    MAXIMIZE_EXPORT: 'maximize_export',
    MAXIMIZE_SELF_CONSUMPTION: 'maximize_self_consumption',
    LIMIT_EXPORT: 'limit_export',
};
/** Time of Use Operation 다국어 code 값 */
export const TOU_OPERATION_MULTILINGUAL_CODE = {
    [TOU_OPERATION.CHARGE_FROM_SOLAR]: 'control.charge_from_solar',
    [TOU_OPERATION.CHARGE_FROM_SOLAR_AND_GRID]: 'control.charge_from_solar_grid',
    [TOU_OPERATION.MAXIMIZE_EXPORT]: 'control.maximize_export',
    [TOU_OPERATION.MAXIMIZE_SELF_CONSUMPTION]: 'control.maximize_self_consumption',
    [TOU_OPERATION.LIMIT_EXPORT]: 'control.limit_export',
};
/** Time of Use Operation Description 다국어 code 값 */
export const TOU_OPERATION_MULTILINGUAL_DESCRIPTION_CODE = {
    [TOU_OPERATION.CHARGE_FROM_SOLAR]: 'message.charge_from_solar_description',
    [TOU_OPERATION.CHARGE_FROM_SOLAR_AND_GRID]: 'message.charge_from_solar_grid_description',
    [TOU_OPERATION.MAXIMIZE_EXPORT]: 'message.maximize_export_description',
    [TOU_OPERATION.MAXIMIZE_SELF_CONSUMPTION]: 'message.maximize_self_consumption_description',
    [TOU_OPERATION.LIMIT_EXPORT]: 'message.limit_export_description',
};
/** operation 요일 다국어 code 값 */
export const OPERATION_DAY_OF_WEEK_MULTILINGUAL_CODE = {
    [DAYS_OF_WEEK.SUNDAY]: 'common.sun',
    [DAYS_OF_WEEK.MONDAY]: 'common.mon',
    [DAYS_OF_WEEK.TUESDAY]: 'common.tue',
    [DAYS_OF_WEEK.WEDNESDAY]: 'common.wed',
    [DAYS_OF_WEEK.THURSDAY]: 'common.thu',
    [DAYS_OF_WEEK.FRIDAY]: 'common.fri',
    [DAYS_OF_WEEK.SATURDAY]: 'common.sat',
};
export const VALIDATE_SCHEDULE_PRESET_NAME = {
    SUCCESS: 'SUCCESS',
    DUPLICATE: 'DUPLICATE',
};
export const ENERGY_MANAGEMENT_SETTING_TYPE = {
    PRESET: 'preset',
    SCHEDULE: 'schedule',
};
export const SCHEDULER_INTERACTIVE_STATUS = {
    INTERACTIVE: 'interactive',
    NON_INTERACTIVE: 'non-interactive',
};
export const NAME_INPUT_DEBOUNCE_WAIT_TIME = 500;
export const START_HOUR = 0;
export const START_TIME_END_HOUR = 23;
export const END_TIME_END_HOUR = 24;
export const START_MINUTE = 0;
export const END_MINUTE = 59;
export const MINUTE_LIST_STEP = 15;
export const MAX_COUNT_SCHEDULE = 12;
export const MAX_COUNT_OPERATION_PER_DAY = 10;
export const MAX_COUNT_OPERATION_PER_WEEK = 70;
