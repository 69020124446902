export const phase = {
    G0: '#8B8B8B',
    G1: '#C5EEED',
    G2: '#A9E6E4',
    G3: '#8EDFDC',
    G4: '#72D7D3',
    G5: '#55D3CF',
    G6: '#38CFCA',
    G7: '#00C6C1',
    G8: '#00BBB6',
    G9: '#00B0AC',
    G10: '#00A5A1',
    G11: '#009A97',
    G12: '#008F8C',
    G13: '#008481',
    G14: '#007A77',
    G15: '#006F6C',
    G16: '#006461',
    G17: '#005957',
    G18: '#004E4C',
    G19: '#004342',
    G20: '#003D3C',
};
export const error = {
    E1: '#FD2F40',
};
export const SCALE_FACTOR = 0.999;
export const MAX_ZOOM = 15;
export const MIN_ZOOM = 1;
export const DEFAULT_ZOOM_DELTA = 30;
export const PANNEL_WIDTH = 36;
export const PANNEL_HEIGHT = 60;
export const PANNEL_MARGIN = 8;
export const ARRAY_TEXT_PADDING = 30;
export const arrayDefaultOptions = {
    subTargetCheck: true,
    perPixelTargetFind: true,
    centeredRotation: true,
    originX: 'center',
    originY: 'center',
    scaleX: 0.5,
    scaleY: 0.5,
    lockMovementX: false,
    lockMovementY: false,
    hasControls: true,
};
export const pannelDefaultOptions = {
    type: 'group',
    originX: 'left',
    originY: 'top',
    borderColor: 'transparent',
    padding: 0,
    width: PANNEL_WIDTH,
    height: PANNEL_HEIGHT,
    fill: '#00aa9e',
    subTargetCheck: false,
    lockMovementX: true,
    lockMovementY: true,
    hasControls: false,
};
export const textDefaultOptions = {
    type: 'textbox',
    originX: 'center',
    originY: 'top',
    textAlign: 'center',
    fill: '#fafafa',
    fontSize: 8,
    fontFamily: 'Pretendard',
    fontWeight: 'bold',
    lineHeight: 1,
    charSpacing: 100,
    lockScalingX: true,
    lockScalingY: true,
    hasControls: false,
    left: 0,
    top: 10,
    width: 34,
    splitByGrapheme: true,
};
export const unitTextDefaultOptions = {
    type: 'text',
    originX: 'center',
    originY: 'top',
    textAlign: 'center',
    fill: '#fafafa',
    fontSize: 5,
    fontFamily: 'Pretendard',
    fontWeight: 'bold',
    lineHeight: 1.3,
    lockScalingX: true,
    lockScalingY: true,
    hasControls: false,
    left: 0,
    top: 20,
    visible: true,
};
export const textShadowDefaultOptions = {
    left: -18,
    top: 6,
    width: 36,
    height: 24,
    strokeWidth: 0,
    fill: 'black',
    opacity: 0.4,
};
export const rectDefaultOptions = {
    type: 'rect',
    left: -18,
    top: -30,
    width: 36,
    height: 60,
    strokeWidth: 0,
    rx: 3,
    ry: 3,
    fill: phase.G0,
    lockScalingX: true,
    lockScalingY: true,
    hasControls: false,
};
export const commentDefaultOptions = {
    qtype: 'i-text',
    fontFamily: 'Pretendard',
    strokeWidth: 1,
    scaleX: 0.5,
    scaleY: 0.5,
    hasRotatingPoint: true,
    centerTransform: true,
};
export const stringTextDetaultOptions = {
    qtype: 'string-text',
    fontFamily: 'Pretendard',
    strokeWidth: 1,
    scaleX: 0.5,
    scaleY: 0.5,
    hasRotatingPoint: true,
    centerTransform: true,
};
export const extraAttribute = [
    'qStringId',
    'qUid',
    'qGroupId',
    'mapperVersion',
    'qid',
    'qtype',
    'qvtype',
    'qtilt',
    'qrotate',
    'qserial',
    'qcol',
    'qrow',
    'subTargetCheck',
    'borderColor',
    'padding',
    'textboxBorderColor',
    'lineHeight',
    'perPixelTargetFind',
    'textboxBorderColor',
    'padding',
];
