import { defineComponent, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import axios from 'axios';
import { PopupWrapper, useMessageBox } from '@hems/component';
import { JoinService } from '@hems/service';
import { getRoleCdByRoleName } from '@hems/util/src/helper/authHelper';
import Step1TermAndCondition from './Step1TermAndCondition.vue';
import Step2RequiredData from './Step2RequiredData.vue';
import Step3OptionalData from './Step3OptionalData.vue';
const API_BASE_URL = process.env.VUE_APP_API_URL;
const GENERAL_JOIN_STEP = {
    REQUIRED: 2,
    OPTIONAL: 3,
};
export default defineComponent({
    name: 'GeneralJoinPopup',
    components: {
        PopupWrapper,
        Step1TermAndCondition,
        Step2RequiredData,
        Step3OptionalData,
    },
    emits: ['close'],
    props: {
        social: Object,
        locale: String,
        termsList: {
            type: Array,
            required: true,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const messageBox = useMessageBox();
        const store = useStore();
        const appCtx = store.state.appCtx;
        const maxStep = ['AU'].includes(appCtx.envLocale) ? GENERAL_JOIN_STEP.OPTIONAL : GENERAL_JOIN_STEP.REQUIRED;
        const axiosInstance = axios.create({
            baseURL: API_BASE_URL,
        });
        const joinService = new JoinService(axiosInstance);
        const state = reactive({
            step: 1,
            termAndCondition: {
                termAndUse: false,
                crossBorders: false,
                privacyPolicy: false,
            },
            essentialData: {
                site_id: null,
                user_id: null,
                user_nm: null,
                password: null,
                mpn_no: null,
                email: null,
                pin_code: null,
                local_mpn_no: null,
                auth_type_cd: getRoleCdByRoleName('user'),
            },
            optionalData: {
                bld_area_val: null,
                usage_report: true,
                direct_marketing: true,
            },
        });
        const isUS = appCtx.envLocale === 'US';
        function closePopup() {
            emit('close');
        }
        function onBack() {
            if (state.step <= 1)
                return;
            state.step -= 1;
        }
        function onNext(dataType, data) {
            state[dataType] = data;
            const NZTermsVer = `${process.env.VUE_APP_TERMS_VER_NZ}-NZ`;
            if (state.step >= maxStep) {
                join({
                    essential_info: { ...state.essentialData },
                    ...state.optionalData,
                    terms_ver: props.locale === 'NZ' ? NZTermsVer : `${appCtx.termsVer}-${appCtx.envLocale}`,
                });
                return;
            }
            state.step += 1;
        }
        function join(params) {
            if (!validate(params))
                return;
            joinService
                .joinGeneralMember(params, props.social)
                .then(() => {
                emit('close');
                messageBox.alert(t('message.success_sign_up')).open();
            })
                .catch(() => {
                messageBox.alert(t('message.unknown_error')).open();
            });
        }
        function validate(params) {
            const { termAndUse, crossBorders, privacyPolicy } = state.termAndCondition;
            if (isUS) {
                if (!termAndUse || !privacyPolicy) {
                    messageBox.alert(t('message.agree_terms')).open();
                    return false;
                }
            }
            else {
                if (!termAndUse || !crossBorders || !privacyPolicy) {
                    messageBox.alert(t('message.agree_terms')).open();
                    return false;
                }
            }
            const { site_id, user_id, password, email, pin_code, user_nm } = params.essential_info;
            if (!site_id || !user_id || (!props.social && !password) || !email || !pin_code || !user_nm) {
                messageBox.alert(t('message.req_value_missing')).open();
                return false;
            }
            return true;
        }
        return {
            state,
            maxStep,
            closePopup,
            onNext,
            onBack,
        };
    },
});
