import { defineComponent } from 'vue';
import Image2 from '../../image/Image2.vue';
export default defineComponent({
    name: 'NotificationItem',
    components: {
        Image2,
    },
    props: {
        iconInfo: {
            type: Object,
            default: () => ({ isIcon: false, iconPath: '', extension: '' }),
        },
        notificationInfo: {
            type: Object,
            default: () => ({ read: true, title: '', contents: '', date: '' }),
        },
    },
});
