import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import AdvancedSettingsGEN2 from '@hems/container/src/forms/device/settings/_shared/advanced/AdvancedSettingsGEN2.vue';
import { getAdvancedSettingGen2Config } from '@hems/container/src/forms/device/settings/pvmodule/gen2/advanced/config';
export default defineComponent({
    name: 'AdvancedSettingsPvModuleGen2Container',
    components: {
        AdvancedSettingsGEN2,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        productModelNm: {
            type: String,
            required: true,
        },
        cntryCd: {
            type: String,
            required: true,
        },
        editable: {
            type: Boolean,
            required: true,
        },
        showEditableColumnsOnly: {
            type: Boolean,
            default: true,
        },
        isConnection: Boolean,
        roleNm: {
            type: String,
            required: true,
        },
        hideEdit: {
            type: Boolean,
            default: false,
        },
        isGrp: {
            type: Boolean,
            default: false,
        },
        changedValueSet: {
            type: Object,
            default: () => new Set(),
        },
    },
    emits: ['goToList', 'save', 'cancel', 'edit'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const advancedSettingGen2Config = getAdvancedSettingGen2Config(t);
        function goToList() {
            emit('goToList');
        }
        function onSave(data, changedValueSet, callback) {
            if (props.isGrp) {
                emit('save', data, changedValueSet);
            }
            else {
                emit('save', data, callback);
            }
        }
        function onCancel() {
            emit('cancel');
        }
        function onEdit() {
            emit('edit');
        }
        return {
            advancedSettingGen2Config,
            goToList,
            onSave,
            onCancel,
            onEdit,
        };
    },
});
