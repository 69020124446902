/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useSiteDeviceGenType } from '@hems/util/src/composable/site';
import { AC_DEVICE_TYPE } from '@hems/util/src/constant';
import { isACSystemType, isDeviceACType } from '@hems/util/src/helper/tsguardHelper';
export const useSiteInfo = () => {
    const store = useStore();
    const { t } = useI18n();
    const inventoryInfo = store.state.siteNew.inventoryInfo;
    const siteInfoFOX = store.state.siteNew.siteInfoFOX;
    const siteInfoAC = store.state.siteNew.siteInfoAC;
    const siteInfoAU = store.state.siteNew.siteInfoAU;
    const hubInfo = store.state.siteNew.hubInfo;
    const deviceList = store.state.siteNew.deviceList;
    const getACDeviceProfileList = () => {
        const deviceProfile = deviceList.map((device) => device.deviceInfo);
        /** @TODO map & filter 사용으로 개선 예정 */
        return deviceProfile.reduce((ACTypeGardProfileList, profile) => {
            if (isACSystemType(profile)) {
                ACTypeGardProfileList.push(profile);
            }
            return ACTypeGardProfileList;
        }, []);
    };
    const ACDeviceList = computed(() => deviceList.filter((device) => isDeviceACType(device)));
    const { isAC } = useSiteDeviceGenType();
    const [masterDeviceInfo] = deviceList.map((device) => device);
    const batteryInfoOfMasterDevice = {
        batteryPackInfoList: masterDeviceInfo.batteryInfo,
    };
    const masterDeviceProfile = isAC
        ? store.state.siteNew.siteInfoAC?.masterACDeviceProfile
        : masterDeviceInfo.deviceInfo;
    const ACCombinerProfile = getACDeviceProfileList().find((profile) => profile.device_type_cd === AC_DEVICE_TYPE.AC_COMBINER);
    const getGridInfo = () => {
        if (masterDeviceProfile) {
            const { grid_error_cd_type, grid_st_cd, pv_feed_in_limit_w, meter_v, meter_i, meter_active_pw, meter_reactive_pw, meter_pwfactor, meter_freq, grid_error_cd, error_dt_map, } = masterDeviceProfile;
            return {
                grid_error_cd_type,
                grid_st_cd,
                pv_feed_in_limit_w,
                meter_v,
                meter_i,
                meter_active_pw,
                meter_reactive_pw,
                meter_pwfactor,
                meter_freq,
                grid_error_cd,
                error_dt_map,
            };
        }
        return null;
    };
    const gridInfo = getGridInfo();
    const ESSCount = getACDeviceProfileList().filter((device) => device.device_type_cd === AC_DEVICE_TYPE.AC_ESS).length;
    return {
        masterDeviceProfile,
        inventoryInfo,
        gridInfo,
        hubInfo,
        siteInfoFOX,
        siteInfoAC,
        siteInfoAU,
        getACDeviceProfileList,
        ACCombinerProfile,
        batteryInfoOfMasterDevice,
        ACDeviceList,
        ESSCount,
    };
};
