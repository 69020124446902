import { Service } from '@hems/service/src/api/Service';
import { apiWrapper } from '@hems/service/src/util/helper';
export default class SupportService extends Service {
    constructor(axios) {
        super(axios);
    }
    getGuideList = async (params) => {
        const { data } = await this.get(apiWrapper.managerApi('/managements/mobiles/pro-apps/support-guides'), params);
        return data;
    };
    addGuide = async (params) => {
        const { data } = await this.post(apiWrapper.managerApi('/managements/portals/supports/guides'), params);
        return data;
    };
    updateGuide = async (supportId, params) => {
        const { data } = await this.put(apiWrapper.managerApi(`/managements/portals/supports/guides/${supportId}`), params);
        return data;
    };
    deleteGuide = async (supportId) => {
        const { data } = await this.delete(apiWrapper.managerApi(`/managements/portals/supports/guides/${supportId}`));
        return data;
    };
    getEventcodeList = async (params) => {
        const { data } = await this.get(apiWrapper.managerApi('/managements/mobiles/pro-apps/supports/event-codes'), params);
        return data;
    };
    addEventcode = async (params) => {
        const { data } = await this.post(apiWrapper.managerApi('/managements/portals/supports/event-codes'), params);
        return data;
    };
    updateEventcode = async (alarmCd, params) => {
        const { data } = await this.put(apiWrapper.managerApi(`/managements/portals/supports/event-codes/${alarmCd}`), params);
        return data;
    };
    deleteEventcode = async (alarmCd) => {
        const { data } = await this.delete(apiWrapper.managerApi(`/managements/portals/supports/event-codes/${alarmCd}`));
        return data;
    };
}
export { SupportService };
