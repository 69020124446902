import { defineComponent, computed, ref, watch } from 'vue';
import MultiSelect from 'primevue/multiselect';
import Image2 from '@hems/component/src/image/Image2.vue';
import { SELECT_OPTION_LIST_EXPANDED_STATUS } from '@hems/util/src/constant';
export default defineComponent({
    name: 'MultiCheckSelector',
    components: {
        MultiSelect,
        Image2,
    },
    props: {
        options: {
            type: Array,
            default: () => [],
        },
        maxSelectedLabels: {
            type: Number,
            default: 7,
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const selectedOptions = ref([]);
        const selectOptions = computed(() => props.options);
        const isSelectAll = ref(false);
        const multiSelectRef = ref();
        const multiSelectExpandedStatus = ref(SELECT_OPTION_LIST_EXPANDED_STATUS.HIDE);
        const pcOptionCheckBoxClassOption = {
            root: 'multi-select-display-none',
            box: 'multi-select-check-box',
            icon: 'multi-select-display-none',
        };
        const backgroundColorClassOption = computed(() => selectedOptions.value.length > 0 ? 'multi-select-background-color-skyblue' : 'multi-select-background-color-gray');
        const colorClassOption = computed(() => selectedOptions.value.length > 0 ? 'multi-select-color-skyblue' : 'multi-select-color-gray');
        const placeholderClassOption = computed(() => selectedOptions.value.length > 7 ? 'multi-select-placeholder-with-ellipsis' : 'multi-select-placeholder');
        const dropIconClassOption = computed(() => (selectedOptions.value.length > 7 ? 'selected-count' : ''));
        const isSelectedOption = computed(() => selectedOptions.value.length === 0);
        const changeMultiSelectExpandedStatus = (expandedStatus) => {
            multiSelectExpandedStatus.value = expandedStatus;
        };
        const checkAll = () => {
            selectedOptions.value = selectOptions.value;
        };
        const clearAll = () => {
            selectedOptions.value = [];
            isSelectAll.value = false;
        };
        const onClickApply = () => {
            emit('update:modelValue', selectedOptions.value.map((item) => item.value));
            multiSelectRef.value?.hide();
        };
        const onClickRemove = (removeItem) => (selectedOptions.value = selectedOptions.value.filter((option) => option.value !== removeItem));
        watch(() => isSelectAll.value, () => {
            isSelectAll.value ? checkAll() : clearAll();
        });
        const onClickChipXButton = (event, label) => {
            event.stopPropagation();
            onClickRemove(label);
        };
        return {
            selectedOptions,
            selectOptions,
            pcOptionCheckBoxClassOption,
            placeholderClassOption,
            isSelectedOption,
            dropIconClassOption,
            isSelectAll,
            onClickApply,
            onClickRemove,
            clearAll,
            multiSelectRef,
            changeMultiSelectExpandedStatus,
            multiSelectExpandedStatus,
            onClickChipXButton,
            backgroundColorClassOption,
            colorClassOption,
            SELECT_OPTION_LIST_EXPANDED_STATUS,
        };
    },
});
