/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import '@hems/component/resources/vendor/css_new/common.css';
import '@hems/component/resources/vendor/css_new/common_style.css';
import '@hems/component/resources/vendor/css_new/main.css';
import '@hems/component/resources/vendor/css_new/contents.css';
import '@hems/component/resources/vendor/css_new2021/common_new2021.css';
import '@hems/component/resources/vendor/script/selecter/jquery.fs.selecter.css';
import '@hems/component/resources/styles/main.scss';
import '@/resources/styles/main.scss';
import 'vue-final-modal/dist/style.css';

import { createApp } from 'vue';
import { createVfm } from 'vue-final-modal';

import PrimeVue from 'primevue/config';

import App from '@hems/portal/src/App.vue';
import router from '@hems/portal/src/router';
import store from '@hems/portal/src/store';
import { AxiosPlugin, i18n, initYup, Vue3FacebookOAuth2, Vue3GoogleOAuth2, Vue3AppleOAuth2 } from '@hems/util';

initYup();

const vueFinalModal = createVfm();

createApp(App)
  .use(AxiosPlugin, {
    baseURL: process.env.VUE_APP_API_URL,
  })
  .use(Vue3FacebookOAuth2, { appId: process.env.VUE_APP_FACEBOOK_APP_ID })
  .use(Vue3GoogleOAuth2, { clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID })
  .use(Vue3AppleOAuth2, {
    clientId: process.env.VUE_APP_APPLE_CLIENT_ID,
    redirectURI: process.env.VUE_APP_APPLE_REDIRECT_URI,
    usePopup: true,
    nonce: `${Math.round(Math.random() * 1000000)}`,
  })
  .use(store)
  .use(i18n)
  .use(router)
  .use(vueFinalModal)
  .use(PrimeVue, { unstyled: true })
  .mount('#app');
