import { getFormattedNumberValue } from '@hems/container/src/energyflow/value/EnergyValueFunctions';
import { GRAPH_SERIES_TYPE, HOME_2_COLOR } from '@hems/util/src/constant';
const xLabels = ['6AM', '9AM', '12PM', '3PM', '6PM', '9PM'];
const DEFAULT_CHART_HEIGHT = 130;
export const getTodayProductionChartOptions = ({ locale, data, name = '', height = DEFAULT_CHART_HEIGHT, }) => {
    return {
        chart: {
            type: GRAPH_SERIES_TYPE.LINE,
            height,
            zoomType: 'x',
        },
        rangeSelector: {
            enabled: false,
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        yAxis: {
            visible: false,
        },
        xAxis: {
            categories: xLabels,
        },
        tooltip: {
            formatter() {
                return getTooltipFormatter(this, locale);
            },
        },
        series: [
            {
                type: GRAPH_SERIES_TYPE.LINE,
                name,
                color: HOME_2_COLOR.PRIMARY_MINT,
                marker: {
                    radius: 2,
                },
                data: data.map((item) => item.y),
                lineWidth: 1,
            },
        ],
    };
};
export const getTodayConsumptionChartOptions = ({ locale, data, name = '', height = DEFAULT_CHART_HEIGHT, }) => {
    return {
        chart: {
            type: GRAPH_SERIES_TYPE.LINE,
            height,
            zoomType: 'x',
        },
        rangeSelector: {
            enabled: false,
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        yAxis: {
            visible: false,
        },
        xAxis: {
            categories: xLabels,
        },
        tooltip: {
            formatter() {
                return getTooltipFormatter(this, locale);
            },
        },
        series: [
            {
                type: GRAPH_SERIES_TYPE.LINE,
                name,
                color: HOME_2_COLOR.PRIMARY_MINT,
                marker: {
                    radius: 2,
                },
                data: data.map((item) => item.y),
                lineWidth: 1,
            },
        ],
    };
};
export const checkDateRange = (date) => {
    const time = date.substring(8, 10);
    const regexp3hours = /(06|09|12|15|18|21)$/g;
    return regexp3hours.test(time);
};
const getTooltipFormatter = (tooltipObject, locale) => {
    return `${tooltipObject.x}</br><span style="color:${tooltipObject.point.color}>\u25CF</span> ${getFormattedNumberValue(tooltipObject.y, locale, 2)} kWh`;
};
