import { useI18n } from 'vue-i18n';
import { DateHelper } from '@hems/util';
import { GRAPH_GROUP_CODE, GRAPH_TERM_UNIT } from '@hems/util/src/constant';
import { calculatePowerFactor } from '@hems/util/src/helper/dashboardHelper';
import { getConvertedGraphDateString } from '@hems/util/src/helper/dateHelper';
import { getGraphGroupCodeTypeValue, getGraphGroupCodeListTypeValue } from '@hems/util/src/helper/tsguardHelper';
// FIXME useI18n 을 사용하고 있어, 컴포저블로 변경 필요
export function getLabelText(filter) {
    const { t } = useI18n();
    return `${filter.prefix ?? ''}${t(filter.name, { defaultValue: filter.altName })}${filter.postfix ?? ''}${filter.unit ? ` [${filter.unit}]` : ''}`;
}
export function clearChartData(chartData) {
    for (const key in chartData) {
        const chartDataKey = getGraphGroupCodeTypeValue(key);
        if (chartDataKey) {
            chartData[chartDataKey]?.forEach((item) => (item.data = []));
        }
    }
}
export function getChartTemplateAndData(chartConfig) {
    const chartDataTemplate = {};
    const chartData = chartConfig.reduce((prev, curr) => {
        chartDataTemplate[curr.filterGroupCode] = curr;
        return {
            ...prev,
            [curr.filterGroupCode]: curr.filterList.map((item) => {
                return {
                    id: item.code,
                    name: getLabelText(item),
                    yAxis: item.yAxis,
                    data: [],
                };
            }),
        };
    }, {});
    return {
        chartDataTemplate,
        chartData,
    };
}
export function getXAxisCategories(data, termUnit = GRAPH_TERM_UNIT.MINUTE) {
    return data.map((item) => DateHelper.getConvertedGraphDateString(item.std_dt, termUnit));
}
export function updateChartData(chartData, data, graphKeys) {
    clearChartData(chartData);
    const groupKeys = getGraphGroupCodeListTypeValue(Object.keys(graphKeys));
    for (let i = 0; i < data.length; i++) {
        groupKeys?.forEach((groupKey) => {
            graphKeys[groupKey]?.forEach((key) => {
                const graphData = data[i];
                const graphDataByKey = graphData[key] ?? null;
                chartData[groupKey]?.find((item) => item.id === key)?.data.push(graphDataByKey);
            });
        });
    }
}
export const getColumns = (config, t) => {
    const columnArr = [{ header: '', name: 'std_dt' }];
    config.forEach((item) => {
        item.filterList.forEach((item) => {
            columnArr.push({
                header: `${t(`${item.name}`, { defaultValue: item.name })}${item.postfix ?? ''}`,
                name: item.code,
            });
        });
    });
    return columnArr;
};
export const convertFormatDate = (data) => {
    return data.map((item) => ({
        ...item,
        std_dt: getConvertedGraphDateString(item.std_dt, GRAPH_TERM_UNIT.HOUR),
    }));
};
export const getGraphDataByGroupCodes = (groupCodes, chartData) => {
    return groupCodes.reduce((accumulator, groupCode) => [...accumulator, ...chartData[groupCode]], []);
};
export const getGraphDataByGroupCodesACSystem = (groupCodes, chartData) => {
    if (groupCodes.includes(GRAPH_GROUP_CODE.METER)) {
        chartData[GRAPH_GROUP_CODE.METER] = getACMeterData(chartData[GRAPH_GROUP_CODE.METER]);
    }
    return getGraphDataByGroupCodes(groupCodes, chartData);
};
const calculateGraphPowerFactor = (meterActivePowerList, meterReactivePowerList) => {
    return meterActivePowerList.map((meterActivePower, index) => {
        const meterReactivePower = meterReactivePowerList[index];
        return calculatePowerFactor(meterActivePower ?? 0, meterReactivePower ?? 0);
    });
};
export const getACMeterData = (meterDataList) => {
    const meterActivePowerList = meterDataList.find((meterData) => meterData.id === 'meter_active_pw')?.data ?? [];
    const meterReactivePowerList = meterDataList.find((meterData) => meterData.id === 'meter_reactive_pw')?.data ?? [];
    return meterDataList.map((meterData) => {
        if (meterData.id === 'meter_pwfactor') {
            return { ...meterData, data: calculateGraphPowerFactor(meterActivePowerList, meterReactivePowerList) };
        }
        return meterData;
    });
};
