import { defineComponent, reactive, computed, watch } from 'vue';
import { OnClickOutside } from '@vueuse/components';
import _ from 'lodash';
import { DateHelper } from '@hems/util';
import NotificationItem from './NotificationItem.vue';
import Image2 from '../../image/Image2.vue';
export default defineComponent({
    name: 'NotificationListDropdown',
    components: {
        Image2,
        NotificationItem,
        OnClickOutside,
    },
    props: {
        isExistUnrecognitionNotification: {
            type: Boolean,
            default: false,
        },
        tabList: {
            type: Array,
            default: () => ['Notice'],
        },
        notificationList: {
            type: Array,
            default: () => [],
        },
        langCd: {
            type: String,
            default: 'en',
        },
    },
    emits: ['open', 'updateNotificationRead'],
    setup(props, { emit }) {
        const state = reactive({
            isExistUnrecognitionNotification: computed(() => props.isExistUnrecognitionNotification),
            showNotificationList: false,
            currentTab: props.tabList[0].value,
            notificationList: getFormattedNotificationList(props.notificationList ?? [], props.tabList[0].value),
        });
        function getFormattedDateString(date, langCd = 'en') {
            return DateHelper.getDateFormatter(langCd, {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
            })
                .format(new Date(date))
                .toString();
        }
        function getFormattedNotificationList(data, category) {
            if (_.isEmpty(data))
                return [];
            const notificationListForCurrentTab = data
                .filter((item) => item.category === category)
                .map((item) => {
                if (item.category === 'MKT') {
                    return {
                        notiId: item.notiId,
                        category: item.category,
                        iconInfo: {
                            isIcon: true,
                            iconPath: 'arcstream/ic_re_symbol_arc',
                            extension: 'svg',
                        },
                        notificationInfo: {
                            read: item.isRead === 'Y',
                            title: item.title,
                            contents: item.message,
                            date: getFormattedDateString(item.createDt, props.langCd),
                        },
                    };
                }
                return {
                    notiId: item.notiId,
                    category: item.category,
                    notificationInfo: {
                        read: item.isRead === 'Y',
                        title: item.title,
                        contents: item.message,
                        date: getFormattedDateString(item.createDt, props.langCd),
                    },
                };
            });
            return notificationListForCurrentTab;
        }
        const onClickNotificationIcon = () => {
            state.showNotificationList = !state.showNotificationList;
            if (state.showNotificationList) {
                emit('open');
            }
        };
        const onMoveTab = (tabValue) => {
            state.currentTab = tabValue;
            state.notificationList = getFormattedNotificationList(props.notificationList ?? [], tabValue);
        };
        const onClickOutside = () => {
            state.showNotificationList = false;
        };
        watch([() => props.notificationList, () => props.langCd], () => {
            state.notificationList = getFormattedNotificationList(props.notificationList ?? [], state.currentTab);
        });
        return {
            state,
            onClickNotificationIcon,
            onMoveTab,
            _,
            onClickOutside,
        };
    },
});
