import { Service } from '@hems/service/src/api/Service';
import { apiWrapper } from '@hems/service/src/util/helper';
export default class EnergyManagementService extends Service {
    constructor(axios) {
        super(axios);
        this.setContentTypeHeaderInterceptor();
    }
    getScheduleList = async (siteId, scheduleListParam) => {
        const { data } = await this.get(apiWrapper.managerApiV1(`/sites/${siteId}/tou`), scheduleListParam);
        return data;
    };
    createScheduleList = async (siteId, siteTOUInfo) => {
        const { data } = await this.post(apiWrapper.managerApiV1(`/sites/${siteId}/tou`), siteTOUInfo);
        return data;
    };
    getPresetList = async (siteId, presetListParam) => {
        const { data } = await this.get(apiWrapper.managerApiV1(`/sites/${siteId}/tou/presets`), presetListParam);
        return data;
    };
    getPresetOperationList = async (siteId, presetId, presetOperationListParam) => {
        const { data } = await this.get(apiWrapper.managerApiV1(`/sites/${siteId}/tou/presets/${presetId}/operations`), presetOperationListParam);
        return data;
    };
    validatePresetName = async (siteId, presetNameRequestBody) => {
        const { data } = await this.post(apiWrapper.managerApiV1(`/sites/${siteId}/tou/presets/name/validate`), presetNameRequestBody);
        return data.body.result;
    };
    createPreset = async (siteId, presetNameRequestBody) => {
        const { data } = await this.post(apiWrapper.managerApiV1(`/sites/${siteId}/tou/presets`), presetNameRequestBody);
        return data;
    };
    deletePreset = async (siteId, presetId) => {
        const { data } = await this.delete(apiWrapper.managerApiV1(`/sites/${siteId}/tou/presets/${presetId}`));
        return data;
    };
    patchPresetName = async (siteId, presetId, presetNameRequestBody) => {
        const { data } = await this.patch(apiWrapper.managerApiV1(`/sites/${siteId}/tou/presets/${presetId}`), presetNameRequestBody);
        return data;
    };
    postPresetOperation = async (siteId, presetId, operation) => {
        const { data } = await this.post(apiWrapper.managerApiV1(`/sites/${siteId}/tou/presets/${presetId}/operations`), operation);
        return data;
    };
    putPresetOperation = async (siteId, presetId, operationId, operation) => {
        const { data } = await this.put(apiWrapper.managerApiV1(`/sites/${siteId}/tou/presets/${presetId}/operations/${operationId}`), operation);
        return data;
    };
    deletePresetOperationList = async (siteId, presetId, operationIdList) => {
        const { data } = await this.post(apiWrapper.managerApiV1(`/sites/${siteId}/tou/presets/${presetId}/operations/delete`), operationIdList);
        return data;
    };
}
