import { isNull } from '@hems/util/src/helper/helper';
class CommentsRenderer {
    el;
    tooltipContainer;
    img;
    text;
    tooltipbox;
    constructor(props) {
        const el = $('<div class="comments-container"></div>');
        this.tooltipContainer = $('<div class="utc_tooltip"></div>');
        this.img = $('<span class="state_comments"></span>');
        this.text = $('<div class="state_text"></div>');
        this.tooltipbox = $('<span class="tooltip-comments"></span>');
        el.append(this.text);
        el.append(this.tooltipContainer);
        this.el = el.get(0);
        this.render(props);
    }
    getElement() {
        return this.el;
    }
    async render(props) {
        const options = props.columnInfo.renderer.options;
        const position = options ? options.position : [];
        const tooltip_text = props.grid.getValue(props.rowKey, 'memo');
        // tooltip
        if (!isNull(tooltip_text)) {
            this.tooltipContainer.append(this.img).append(this.tooltipbox);
            position.forEach((pos) => {
                this.tooltipbox.addClass(pos);
            });
            this.tooltipbox.text(`${tooltip_text}`);
        }
    }
}
export default CommentsRenderer;
