import { defineComponent, ref } from 'vue';
import { formatNumber } from '@hems/util/src/helper/helper';
// @FIXME 절대경로로 설정시, node_modules로 경로가 잡혀 상대경로로 설정
import Pagenation from '../../src/pagenation/Pagenation.vue';
import ListSize from '../../src/selectors/ListSize/ListSize.vue';
export default defineComponent({
    name: 'ListNavigator',
    components: {
        ListSize,
        Pagenation,
    },
    props: {
        listTotalCount: {
            type: Number,
            required: true,
        },
    },
    emits: ['changeListSize', 'changePage'],
    setup(props, { emit }) {
        const pageSize = ref(10);
        const pageNumber = ref(1);
        const onChangeListSize = () => {
            pageNumber.value = 1;
            emit('changeListSize', pageSize.value, pageNumber.value);
        };
        const onChangePage = () => {
            emit('changePage', pageNumber.value);
        };
        return {
            formatNumber,
            pageSize,
            pageNumber,
            onChangeListSize,
            onChangePage,
        };
    },
});
