export const BUTTON_SIZE = {
    SMALL: 'sm',
    MEDIUM: 'md',
    LARGE: 'lg',
    FIT_TO_PARENT: 'fit-to-parent',
};
export const BUTTON_TYPE = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    TERTIARY: 'tertiary',
    WHITE: 'white',
};
export const BUTTON_CLASS = {
    BUTTON: 'btn_t',
    PRIMARY: 'btn_c_3',
    SECONDARY: 'btn_c_2',
    TERTIARY: 'btn_c',
    WHITE: 'btn_c_4',
};
export const BUTTON_NEW_TYPE = {
    GRAY: 'gray',
    SOLID: 'solid',
    /** out line */
    OTL: 'otl',
    OTL_GRAY: 'otl-gray',
};
export const BUTTON_NEW_SIZE = {
    SMALL: 'small',
    LARGE: 'large',
};
