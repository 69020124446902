import { DeviceService } from './DeviceService';
import { apiWrapper } from '../../util/helper';
export default class DeviceServiceAdmin extends DeviceService {
    constructor(axios) {
        super(axios);
    }
    // TODO: 사용하지 않음 -> 삭제
    async getRecentInstalledDeviceList(pageNum) {
        return await this.get(apiWrapper.adminAPI('/main/prod/list'), { pageNum });
    }
    // TODO: 사용하지 않음 -> 삭제
    async getDeviceSimpleInfo(deviceId) {
        return await this.get(apiWrapper.adminAPI(`/mon/type/${deviceId}`));
    }
    async changeInstaller(params) {
        const { data } = await this.put(apiWrapper.managerApi(`/devices/profiles/${params.siteId}/installers`), {}, { params });
        return data;
    }
    /** @TODO AC System TEST를 위한 임시 API로 추후 삭제 요망 */
    deleteDeviceForACSystem(siteId) {
        return this.delete(apiWrapper.managerApi(`/devices/installations/sqa/delete/${siteId}`));
    }
}
