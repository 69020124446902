import { DateHelper } from '@hems/util';
import { UTC_TIME_ZONE } from '@hems/util/src/constant';
import { isNull } from '@hems/util/src/helper/helper';
class UTCTooltipRenderer {
    el;
    text;
    tooltipText;
    constructor(props) {
        const el = $('<div></div>');
        const tooltipContainer = $('<div class="utc_tooltip"></div>');
        this.text = $('<div></div>');
        this.tooltipText = $('<span class="tooltip-text"></span>');
        tooltipContainer.append(this.text).append(this.tooltipText);
        el.append(tooltipContainer);
        this.el = el.get(0);
        this.render(props);
    }
    getElement() {
        return this.el;
    }
    async render(props) {
        if (isNull(props.value))
            return;
        const options = props.columnInfo.renderer.options;
        const tooltipColumnName = options ? options.tooltipColumnName : '';
        const position = options ? options.position : [];
        const isSecond = options?.isSecond ?? true;
        const tooltip_text = props.grid.getValue(props.rowKey, tooltipColumnName);
        this.text.text(`${props.value}`);
        position.forEach((pos) => {
            this.tooltipText.addClass(pos);
        });
        this.tooltipText.text(DateHelper.getTimezoneDate(tooltip_text, { isTime: true, isSecond, timezone: UTC_TIME_ZONE }));
    }
}
export default UTCTooltipRenderer;
