export function getWeatherIcon() {
    return {
        0: 'ic_tornado_weather',
        1: 'ic_storm_weather',
        2: 'ic_storm_weather',
        3: 'ic_thunderstorms_weather',
        4: 'ic_thunderstorms_weather',
        5: 'ic_rain_snow_weather',
        6: 'ic_sleet_weather',
        7: 'ic_rain_snow_weather',
        8: 'ic_sleet_weather',
        9: 'ic_rain_light_weather',
        10: 'ic_sleet_weather',
        11: 'ic_rain_weather',
        12: 'ic_rain_weather',
        13: 'ic_snow_weather',
        14: 'ic_snow_weather',
        15: 'ic_snow_wind_weather',
        16: 'ic_snow_weather',
        17: 'ic_hail_weather',
        18: 'ic_sleet_weather',
        19: 'ic_sandstorm_weather',
        20: 'ic_fog_weather',
        21: 'ic_fog_weather',
        22: 'ic_fog_weather',
        23: 'ic_wind_weather',
        24: 'ic_wind_weather',
        25: 'ic_snow_wind_weather',
        26: 'ic_cloudy_weather',
        27: 'ic_mostly_cloudy_weather',
        28: 'ic_cloudy_day_weather',
        29: 'ic_cloudy_night_weather',
        30: 'ic_cloudy_day_weather',
        31: 'ic_night_weather',
        32: 'ic_day_weather',
        33: 'ic_night_weather',
        34: 'ic_day_weather',
        35: 'ic_rain_hail_weather',
        36: 'ic_day_weather',
        37: 'ic_isolated_thunderstorms_weather',
        38: 'ic_isolated_thunderstorms_weather',
        39: 'ic_rain_light_weather',
        40: 'ic_rain_heavy_weather',
        41: 'ic_snow_weather',
        42: 'ic_snow_heavy_weather',
        43: 'ic_snow_heavy_weather',
        44: 'ic_error_weather',
        45: 'ic_rain_light_weather',
        46: 'ic_snow_heavy_weather',
        47: 'ic_thunderstorms_weather', // 'Scattered Thunderstorms',
    };
}
