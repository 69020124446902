/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import { useI18n } from 'vue-i18n';
import { NETWORK_TYPE } from '@hems/util/src/constant';
export const useNetworkStatus = () => {
    const { t } = useI18n();
    const getNetworkStatusText = (networkStatus) => {
        switch (networkStatus) {
            case NETWORK_TYPE.ETHERNET:
                return t('device.network_ethernet');
            case NETWORK_TYPE.WIFI:
                return t('device.network_wifi');
            case NETWORK_TYPE.LTE:
                return t('device.network_cellular');
            default:
                return '';
        }
    };
    return { getNetworkStatusText };
};
