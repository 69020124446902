import { defineComponent, reactive } from 'vue';
import { Searchbox, LabelInput } from '@hems/component';
export default defineComponent({
    name: 'SiteSearchBox',
    components: {
        Searchbox,
        LabelInput,
    },
    props: {
        condition: {
            type: Object,
            default: () => ({
                searchFor: null,
            }),
        },
    },
    emits: ['search'],
    setup(props, { emit }) {
        const state = reactive({
            condition: { ...props.condition, searchFor: props.condition.searchFor ?? '' },
        });
        const onSearch = () => {
            state.condition.searchFor = state.condition.searchFor?.trim();
            emit('search', { ...state.condition });
        };
        return {
            state,
            onSearch,
        };
    },
});
