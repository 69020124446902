import { APP_STORE_TYPE, MOBILE_APP_TYPE } from '@/config/ConstantPortal';
const MOBILE_APP_IMAGE_PATH_BASE = 'store/qommand';
export const getMobileAppLinkConfig = (isHideGoApp) => {
    return {
        [MOBILE_APP_TYPE.GO]: {
            name: 'QCELLS CORE GO',
            icon: `${MOBILE_APP_IMAGE_PATH_BASE}/${MOBILE_APP_TYPE.GO}`,
            link: {
                [APP_STORE_TYPE.GOOGLE]: 'https://play.google.com/store/apps/details?id=com.qcells.qonnect_setting',
                [APP_STORE_TYPE.APPLE]: 'https://apps.apple.com/us/app/qcells-core-go/id1571614935',
            },
            qr: {
                [APP_STORE_TYPE.GOOGLE]: `${MOBILE_APP_IMAGE_PATH_BASE}/qr-android-${MOBILE_APP_TYPE.GO}`,
                [APP_STORE_TYPE.APPLE]: `${MOBILE_APP_IMAGE_PATH_BASE}/qr-ios-${MOBILE_APP_TYPE.GO}`,
            },
            hidden: isHideGoApp,
        },
        [MOBILE_APP_TYPE.PRO]: {
            name: 'Q.OMMAND PRO',
            icon: `${MOBILE_APP_IMAGE_PATH_BASE}/${MOBILE_APP_TYPE.PRO}`,
            link: {
                [APP_STORE_TYPE.GOOGLE]: 'https://play.google.com/store/apps/details?id=com.qcells.q_installer',
                [APP_STORE_TYPE.APPLE]: 'https://apps.apple.com/us/app/q-ommand-pro/id6478453023',
            },
            qr: {
                [APP_STORE_TYPE.GOOGLE]: `${MOBILE_APP_IMAGE_PATH_BASE}/qr-android-${MOBILE_APP_TYPE.PRO}`,
                [APP_STORE_TYPE.APPLE]: `${MOBILE_APP_IMAGE_PATH_BASE}/qr-ios-${MOBILE_APP_TYPE.PRO}`,
            },
            hidden: false,
        },
        [MOBILE_APP_TYPE.HOME]: {
            name: 'Q.OMMAND Home',
            icon: `${MOBILE_APP_IMAGE_PATH_BASE}/${MOBILE_APP_TYPE.HOME}`,
            link: {
                [APP_STORE_TYPE.GOOGLE]: 'https://play.google.com/store/apps/details?id=com.qcells.qonnect',
                [APP_STORE_TYPE.APPLE]: 'https://apps.apple.com/us/app/q-ommand-home/id1548240496',
            },
            qr: {
                [APP_STORE_TYPE.GOOGLE]: `${MOBILE_APP_IMAGE_PATH_BASE}/qr-android-${MOBILE_APP_TYPE.HOME}`,
                [APP_STORE_TYPE.APPLE]: `${MOBILE_APP_IMAGE_PATH_BASE}/qr-ios-${MOBILE_APP_TYPE.HOME}`,
            },
            hidden: false,
        },
    };
};
