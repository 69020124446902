import { computed, defineComponent, reactive, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { Accordion, BasicInput, ToggleInput, Selector, SettingTable, SettingTableRow, useMessageBox, BaseSlider, RadioGroup, ChangedMark, } from '@hems/component';
import { getBasicSettingPvModuleGen3SmartModuleGen2Config } from '@hems/container/src/forms/device/settings/_shared/basic/config';
import SettingConfirmPopup from '@hems/container/src/forms/device/settings/_shared/SettingConfirmPopup.vue';
import { CommonService } from '@hems/service';
import { DateHelper, useLanguage, useRole } from '@hems/util';
import { ENERGY_POLICY, NUMBER_UNIT } from '@hems/util/src/constant';
import { ROLE_TYPE } from '@hems/util/src/constant/passwordManagement';
import { isNull, checkValidEmsVersion, codeNamesToSelectorOptions, getTemporaryPassword, } from '@hems/util/src/helper/helper';
import { isPvStringPowerValue } from '@hems/util/src/helper/tsguardHelper';
const initValue = {
    inverter_multiple_earthed_neutral_system: 0,
    external_control_modbus_rtu_dev: '/dev/ttymxc3',
    meter_modbus_rtu_dev: '/dev/ttymxc3',
    pv_meter_modbus_rtu_dev: '/dev/ttymxc0',
    pv_capacity_calc_option: 0,
    third_party_inverter_exist: 0,
    ul1741_flag: 0,
};
export default defineComponent({
    name: 'BasicSettingsPvModuleGen3SmartModuleGen2',
    components: {
        Accordion,
        BasicInput,
        ToggleInput,
        Form,
        Selector,
        SettingTable,
        SettingTableRow,
        BaseSlider,
        RadioGroup,
        ChangedMark,
        SettingConfirmPopup,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        hideEdit: {
            type: Boolean,
            default: false,
        },
        isConnection: {
            type: Boolean,
            required: true,
        },
        isHcore: {
            type: Boolean,
            default: false,
        },
        isAcCoupled: {
            type: Boolean,
            default: false,
        },
        isHybrid: {
            type: Boolean,
            default: false,
        },
        isGrp: {
            type: Boolean,
            default: false,
        },
        timeZone: {
            type: String,
            default: '',
        },
        emsVersion: {
            type: String,
            default: '',
        },
        genType: {
            type: String,
            default: 'GEN3',
        },
        changedValueSet: {
            type: Object,
            default: () => new Set(),
        },
    },
    emits: ['goToList', 'save', 'generate', 'cancel', 'edit', 'changeWebHMIPassword'],
    // eslint-disable-next-line complexity
    async setup(props, { emit }) {
        const messageBox = useMessageBox();
        const { t } = useI18n();
        const commonService = new CommonService(window.axiosInstance.axios);
        let copyData = _.cloneDeep(props.data);
        const copyChangedValueSet = _.cloneDeep(props.changedValueSet);
        const { languageCode } = useLanguage();
        const { roleName } = useRole();
        const DEFAULT_RTU_BAUDRATE = 9600;
        const DEFAULT_RTU_BAUDRATE_EM530OR540 = 115200;
        const state = reactive({
            editable: props.editable,
            data: { ...getInitialValue(), ...props.data },
            enableEditFeedInLimit: props.data.energy_policy !== 2,
            prevFeedInLimit: props.data.pv_feed_in_limit,
            prevFeedInLimitWatt: props.data.pv_feed_in_limit_w,
            isAcCoupled: props.isAcCoupled,
            isHybrid: props.isHybrid,
            isGrp: props.isGrp,
            isShowFeedInWatt: checkValidEmsVersion(props.genType, props.emsVersion, 20) === true,
            isEmsVer20OrHigher: checkValidEmsVersion(props.genType, props.emsVersion, 20) === true,
            isEmsVer31OrHigher: checkValidEmsVersion(props.genType, props.emsVersion, 31) === true,
            changedValueSet: _.cloneDeep(props.changedValueSet),
            confirmPopup: { on: false, data: [] },
            validClass: {
                web_page_password_engineer: false,
                web_page_password_service: false,
            },
        });
        const selectorOptionsState = reactive({
            energyPolicy: [],
            smtrModlCd: [],
            smtrModlPvCd: [],
            modbusConnectionType: [],
            modbusRtuDev: [],
            modbusRtuBaudrate: [],
            modbusRtuParity: [],
            extctlGwConnection: [],
        });
        const getPvStringPowerValue = (value) => state.data[getPvStringPowerKey(value)];
        const getPvStringPowerKey = (value) => {
            // 발생하지 않는 케이스이나, type 방어로직으로 pv_string_power1를 return하게 처리
            if (!isPvStringPowerValue(value))
                return 'pv_string_power1';
            return `pv_string_power${value}`;
        };
        const afterChange = (stringCount) => {
            for (let i = Number(stringCount) + 1; i <= 3; i++) {
                state.data[getPvStringPowerKey(i - 1)] = 0;
            }
        };
        function getInitialValue() {
            // EMS Version R20 미만 + 3rd party inverter exist 값이 null + ac coupled
            if (!props.isGrp) {
                if (!checkValidEmsVersion(props.genType, props.emsVersion, 20) &&
                    isNull(props.data.third_party_inverter_exist) &&
                    props.isAcCoupled) {
                    return {
                        ...initValue,
                        third_party_inverter_exist: 1,
                    };
                }
                return {
                    ...initValue,
                };
            }
            return {
                ul1741_flag: 0,
            };
        }
        const totalModulePower = computed(() => {
            const pvModuleCount = state.data?.pv_module_count;
            const pvModulePower = state.data?.pv_module_power;
            if (!pvModuleCount || !pvModulePower)
                return '';
            if (typeof pvModuleCount === 'number' && typeof pvModulePower === 'number') {
                return pvModuleCount * pvModulePower;
            }
            return '';
        });
        const computedVal = {
            valueRange: {
                pv_feed_in_limit: { min: 0, max: 100 },
                pv_max_pwr2: { min: 0, max: 3300 },
                feed_in_limit: { min: 0, max: 100 },
                battery_backup_soc: { min: 0, max: 100 },
                battery_installed_rack_count: { min: 0, max: 3 },
                pv_per: { min: 0, max: 65535 },
                pv_module_count: { min: 0, max: 30 },
                pv_module_power: { min: 0, max: 1000 },
            },
        };
        const defaultValidate = yup
            .number()
            .transform((v) => (isNull(v) || isNaN(v) ? null : v))
            .nullable();
        const schema = yup.object().shape({
            pv_feed_in_limit: !state.isAcCoupled && state.enableEditFeedInLimit && (state.isGrp || !state.isShowFeedInWatt)
                ? defaultValidate.integer().min(0).max(100)
                : yup.number().nullable().notRequired(),
            pv_feed_in_limit_w: defaultValidate.integer().min(0).max(32000),
            inverter_multiple_earthed_neutral_system: defaultValidate,
            external_control_connection: defaultValidate,
            external_control_meter_load: yup.number().when('external_control_connection', {
                is: (external_control_connection) => external_control_connection === 1 || external_control_connection === 2 || external_control_connection === 3,
                then: defaultValidate,
            }),
            external_control_pv_meter_load: yup.number().when('external_control_connection', {
                is: (external_control_connection) => external_control_connection === 1 || external_control_connection === 2 || external_control_connection === 3,
                then: defaultValidate,
            }),
            external_control_modbus_tcp_port: yup.number().when('external_control_connection', {
                is: (external_control_connection) => external_control_connection === 1,
                then: defaultValidate.integer().min(0).max(65535),
            }),
            external_control_modbus_rtu_parity: yup
                .string()
                .nullable()
                .when('external_control_connection', {
                is: (external_control_connection) => external_control_connection === 2,
                then: yup.string().nullable(),
            }),
            external_control_modbus_rtu_baudrate: defaultValidate.when('external_control_connection', {
                is: (external_control_connection) => external_control_connection === 2,
                then: defaultValidate.integer(),
            }),
            external_control_modbus_rtu_data: yup.number().when('external_control_connection', {
                is: (external_control_connection) => external_control_connection === 2,
                then: defaultValidate.integer().min(5).max(8),
            }),
            external_control_modbus_rtu_stop: yup.number().when('external_control_connection', {
                is: (external_control_connection) => external_control_connection === 2,
                then: defaultValidate.integer().min(1).max(2),
            }),
            energy_policy: defaultValidate.when('external_control_connection', {
                is: (external_control_connection) => external_control_connection === 0,
                then: defaultValidate,
            }),
            pv_type: !state.isAcCoupled ? defaultValidate : yup.number().nullable().notRequired(),
            pv_capacity_calc_option: !state.isAcCoupled ? defaultValidate : yup.number().nullable().notRequired(),
            pv_string_count: !state.isAcCoupled ? defaultValidate.integer().min(1) : yup.number().nullable().notRequired(),
            pv_string_power1: yup.mixed().when(['pv_capacity_calc_option', 'pv_string_count'], {
                is: (pv_cap_calc_opt, pv_string_count) => pv_cap_calc_opt === 0 && pv_string_count >= 1,
                then: defaultValidate.integer().min(0).max(65535),
            }),
            pv_string_power2: yup.mixed().when(['pv_capacity_calc_option', 'pv_string_count'], {
                is: (pv_cap_calc_opt, pv_string_count) => pv_cap_calc_opt === 0 && pv_string_count >= 2,
                then: defaultValidate.integer().min(0).max(65535),
            }),
            pv_string_power3: yup.mixed().when(['pv_capacity_calc_option', 'pv_string_count'], {
                is: (pv_cap_calc_opt, pv_string_count) => pv_cap_calc_opt === 0 && pv_string_count >= 3,
                then: defaultValidate.integer().min(0).max(65535),
            }),
            pv_module_count: yup.mixed().when('pv_capacity_calc_option', {
                is: (pv_cap_calc_opt) => pv_cap_calc_opt === 1,
                then: defaultValidate
                    .integer()
                    .min(computedVal.valueRange.pv_module_count.min)
                    .max(computedVal.valueRange.pv_module_count.max),
            }),
            pv_module_power: yup.mixed().when('pv_capacity_calc_option', {
                is: (pv_cap_calc_opt) => pv_cap_calc_opt === 1,
                then: defaultValidate
                    .integer()
                    .min(computedVal.valueRange.pv_module_power.min)
                    .max(computedVal.valueRange.pv_module_power.max),
            }),
            battery_installed_rack_count: defaultValidate.min(0).max(3),
            meter_model: yup.mixed().when(['external_control_connection', 'external_control_meter_load'], {
                is: (egc, ml) => egc === 0 || ml === 0,
                then: defaultValidate,
            }),
            meter_connection: yup.mixed().when(['external_control_connection', 'external_control_meter_load'], {
                is: (egc, ml) => egc === 0 || ml === 0,
                then: defaultValidate,
            }),
            meter_modbus_tcp_ip: yup
                .mixed()
                .when(['external_control_connection', 'external_control_meter_load', 'meter_connection'], {
                is: (egc, ml, mc) => (egc === 0 || ml === 0) && mc === 1,
                then: yup
                    .string()
                    .nullable()
                    .matches(/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/, t('message.format_ipv4')),
            }),
            meter_modbus_tcp_port: yup
                .mixed()
                .when(['external_control_connection', 'external_control_meter_load', 'meter_connection'], {
                is: (egc, ml, mc) => (egc === 0 || ml === 0) && mc === 1,
                then: defaultValidate.integer().min(0).max(65535),
            }),
            meter_modbus_rtu_parity: yup
                .mixed()
                .when(['external_control_connection', 'external_control_meter_load', 'meter_connection'], {
                is: (egc, ml, mc) => (egc === 0 || ml === 0) && mc === 2,
                then: yup.string().nullable(),
            }),
            meter_modbus_rtu_baudrate: yup
                .mixed()
                .when(['external_control_connection', 'external_control_meter_load', 'meter_connection'], {
                is: (egc, ml, mc) => (egc === 0 || ml === 0) && mc === 2,
                then: defaultValidate.integer(),
            }),
            meter_modbus_rtu_data: yup
                .mixed()
                .when(['external_control_connection', 'external_control_meter_load', 'meter_connection'], {
                is: (egc, ml, mc) => (egc === 0 || ml === 0) && mc === 2,
                then: defaultValidate.integer().min(5).max(8),
            }),
            meter_modbus_rtu_stop: yup
                .mixed()
                .when(['external_control_connection', 'external_control_meter_load', 'meter_connection'], {
                is: (egc, ml, mc) => (egc === 0 || ml === 0) && mc === 2,
                then: defaultValidate.integer().min(1).max(2),
            }),
            pv_connection_type: state.isGrp || state.isAcCoupled || state.isEmsVer20OrHigher
                ? yup.mixed().when('third_party_inverter_exist', {
                    is: (third_party_inverter_exist) => third_party_inverter_exist === 0,
                    then: defaultValidate
                        .integer()
                        .test('pv_connection_type', { key: 'message.invalid' }, function (value) {
                        const { createError } = this;
                        if (value === 0 || value === 1)
                            return createError({
                                message: {
                                    key: 'message.validate_pv_connection_type',
                                },
                            });
                        return true;
                    }),
                })
                : yup.number().nullable().notRequired(),
            pv_meter_model: yup.mixed().when(['pv_connection_type'], {
                is: (mt) => mt === 1,
                then: defaultValidate,
            }),
            pv_meter_connection: yup.mixed().when(['pv_connection_type'], {
                is: (mt) => mt === 1,
                then: defaultValidate,
            }),
            pv_meter_modbus_tcp_ip: yup.mixed().when(['pv_connection_type', 'pv_meter_connection'], {
                is: (mt, mc) => mt === 1 && mc === 1,
                then: yup
                    .string()
                    .nullable()
                    .matches(/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/, t('message.format_ipv4')),
            }),
            pv_meter_modbus_tcp_port: yup.mixed().when(['pv_connection_type', 'pv_meter_connection'], {
                is: (mt, mc) => mt === 1 && mc === 1,
                then: defaultValidate.integer().min(0).max(65535),
            }),
            pv_meter_modbus_rtu_baudrate: yup.mixed().when(['pv_connection_type', 'pv_meter_connection'], {
                is: (mt, mc) => mt === 1 && mc === 2,
                then: defaultValidate.integer(),
            }),
            pv_meter_modbus_rtu_parity: yup.mixed().when(['pv_connection_type', 'pv_meter_connection'], {
                is: (mt, mc) => mt === 1 && mc === 2,
                then: yup.string().nullable(),
            }),
            pv_meter_modbus_rtu_data: yup.mixed().when(['pv_connection_type', 'pv_meter_connection'], {
                is: (mt, mc) => mt === 1 && mc === 2,
                then: defaultValidate.integer().min(5).max(8),
            }),
            pv_meter_modbus_rtu_stop: yup.mixed().when(['pv_connection_type', 'pv_meter_connection'], {
                is: (mt, mc) => mt === 1 && mc === 2,
                then: defaultValidate.integer().min(1).max(2),
            }),
            web_page_password_engineer: yup
                .string()
                .nullable()
                .isValidPassword({ min: 16, max: 16 }, (isValid) => {
                state.validClass.web_page_password_engineer = isValid;
            }, t),
            web_page_password_service: yup
                .string()
                .nullable()
                .isValidPassword({ min: 16, max: 16 }, (isValid) => {
                state.validClass.web_page_password_service = isValid;
            }, t),
        });
        function getFormatDate(dt) {
            let date = '-';
            if (dt === undefined || dt === 0 || dt === '0')
                return date;
            if (typeof dt === 'number') {
                if (dt.toString().length === 10)
                    dt *= NUMBER_UNIT.THOUSAND;
                if (!isExpireDate(dt))
                    date = moment(new Date(dt)).tz(props.timeZone).format(DateHelper.getGridDateFormat('TD', languageCode.value));
            }
            else if (typeof dt === 'string') {
                let timestamp = Number(dt);
                if (dt.length === 10)
                    timestamp *= NUMBER_UNIT.THOUSAND;
                if (!isExpireDate(timestamp)) {
                    const tmpDate = new Date(timestamp);
                    const utcDate = moment.utc(tmpDate, 'YYYYMMDDHHmmss');
                    const tzDate = utcDate.clone().tz(props.timeZone);
                    date = tzDate.format(DateHelper.getGridDateFormat('TD', languageCode.value));
                }
            }
            return date;
        }
        function isExpireDate(time) {
            let isExpire = false;
            if (time === undefined || time === 0 || time === '0')
                return isExpire;
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            if (Number(time) < yesterday.getTime()) {
                isExpire = true;
            }
            return isExpire;
        }
        async function loadSelectorOptions() {
            const { ENERGY_POLICY_GEN3_CD, SMTR_MODL_CD, MODBUS_CONNECTION_TYPE_GEN3_CD, MODBUS_RTU_BAUDRATE_GEN3_CD, MODBUS_RTU_PARITY_GEN3_CD, EXTCTL_GW_CONNECTION_GEN3_CD, } = await commonService.getCodesByGroupCode([
                { grpCd: 'ENERGY_POLICY_GEN3_CD', exclude: ['4'] },
                { grpCd: 'SMTR_MODL_CD', exclude: ['1', '2', '3', '6', '7', '8', '9', '10'] },
                { grpCd: 'MODBUS_CONNECTION_TYPE_GEN3_CD' },
                { grpCd: 'MODBUS_RTU_BAUDRATE_GEN3_CD' },
                { grpCd: 'MODBUS_RTU_PARITY_GEN3_CD' },
                { grpCd: 'EXTCTL_GW_CONNECTION_GEN3_CD' },
            ]);
            selectorOptionsState.energyPolicy = codeNamesToSelectorOptions(ENERGY_POLICY_GEN3_CD, t, {
                text: t('common.select'),
                value: null,
            });
            selectorOptionsState.smtrModlCd = codeNamesToSelectorOptions(SMTR_MODL_CD, t, {
                text: t('common.select'),
                value: null,
            });
            selectorOptionsState.smtrModlPvCd = codeNamesToSelectorOptions(SMTR_MODL_CD, t, {
                text: t('common.select'),
                value: null,
            }).filter((item) => item.value === null ||
                !['1', '2', '3', '4', '6', '7', '8', '9', '10', '11', '14', '16', '17', '18', '20'].includes(item.value.toString()));
            selectorOptionsState.modbusConnectionType = codeNamesToSelectorOptions(MODBUS_CONNECTION_TYPE_GEN3_CD, t, {
                text: t('common.select'),
                value: null,
            });
            selectorOptionsState.modbusRtuBaudrate = codeNamesToSelectorOptions(MODBUS_RTU_BAUDRATE_GEN3_CD, t, {
                text: t('common.select'),
                value: null,
            });
            selectorOptionsState.modbusRtuParity = codeNamesToSelectorOptions(MODBUS_RTU_PARITY_GEN3_CD, t, {
                text: t('common.select'),
                value: null,
            });
            selectorOptionsState.modbusRtuDev = [
                { text: 'Serial1 (/dev/ttymxc3)', value: '/dev/ttymxc3' },
                { text: 'Serial2 (/dev/ttymxc0)', value: '/dev/ttymxc0' },
                // { text: 'Serial3 (/dev/ttymxc1)', value: '/dev/ttymxc1' }, // 추후 변경될 가능성이 있어 주석 처리 조치
            ];
            // 추후 변경될 가능성이 있어 주석 처리 조치
            // if (props.isHcore) {
            //   selectorOptionsState.modbusRtuDev.splice(2);
            // }
            selectorOptionsState.extctlGwConnection = codeNamesToSelectorOptions(EXTCTL_GW_CONNECTION_GEN3_CD, t, {
                text: t('common.select'),
                value: null,
            });
        }
        // eslint-disable-next-line complexity
        function getParam() {
            const { pv_feed_in_limit, pv_feed_in_limit_w, inverter_multiple_earthed_neutral_system, external_control_connection, external_control_meter_load, external_control_pv_meter_load, external_control_modbus_tcp_port, external_control_modbus_rtu_dev, external_control_modbus_rtu_baudrate, external_control_modbus_rtu_parity, external_control_modbus_rtu_data, external_control_modbus_rtu_stop, energy_policy, pv_type, pv_capacity_calc_option, pv_string_count, pv_string_power1, pv_string_power2, pv_string_power3, pv_module_count, pv_module_power, battery_backup_soc, battery_installed_rack_count, meter_model, meter_connection, meter_modbus_tcp_ip, meter_modbus_tcp_port, meter_modbus_rtu_dev, meter_modbus_rtu_baudrate, meter_modbus_rtu_parity, meter_modbus_rtu_data, meter_modbus_rtu_stop, third_party_inverter_exist, pv_connection_type, pv_meter_model, pv_meter_connection, pv_meter_modbus_tcp_ip, pv_meter_modbus_tcp_port, pv_meter_modbus_rtu_dev, pv_meter_modbus_rtu_baudrate, pv_meter_modbus_rtu_parity, pv_meter_modbus_rtu_data, pv_meter_modbus_rtu_stop, ul1741_flag, } = state.data;
            const param = {
                pv_feed_in_limit: !state.isAcCoupled && state.enableEditFeedInLimit && (state.isGrp || !state.isShowFeedInWatt)
                    ? energy_policy !== 2
                        ? pv_feed_in_limit
                        : undefined
                    : undefined,
                pv_feed_in_limit_w: !state.isAcCoupled && state.enableEditFeedInLimit && (state.isGrp || state.isShowFeedInWatt)
                    ? energy_policy !== 2
                        ? pv_feed_in_limit_w
                        : undefined
                    : undefined,
                inverter_multiple_earthed_neutral_system,
                external_control_connection,
                external_control_meter_load: external_control_connection === 1 || external_control_connection === 2 || external_control_connection === 3
                    ? external_control_meter_load
                    : undefined,
                external_control_pv_meter_load: external_control_connection === 1 || external_control_connection === 2 || external_control_connection === 3
                    ? external_control_pv_meter_load
                    : undefined,
                external_control_modbus_tcp_port: external_control_connection === 1 ? external_control_modbus_tcp_port : undefined,
                external_control_modbus_rtu_dev: external_control_connection === 2 ? external_control_modbus_rtu_dev || '/dev/ttymxc3' : undefined,
                external_control_modbus_rtu_baudrate: external_control_connection === 2 ? external_control_modbus_rtu_baudrate : undefined,
                external_control_modbus_rtu_parity: external_control_connection === 2 ? external_control_modbus_rtu_parity : undefined,
                external_control_modbus_rtu_data: external_control_connection === 2 ? external_control_modbus_rtu_data : undefined,
                external_control_modbus_rtu_stop: external_control_connection === 2 ? external_control_modbus_rtu_stop : undefined,
                energy_policy: external_control_connection === 0 ? energy_policy : undefined,
                pv_type: !state.isAcCoupled ? pv_type : undefined,
                pv_capacity_calc_option: !state.isAcCoupled ? pv_capacity_calc_option : undefined,
                pv_string_count: !state.isAcCoupled ? pv_string_count : undefined,
                pv_string_power1: !state.isAcCoupled && pv_capacity_calc_option === 0 && pv_string_count && pv_string_count > 0
                    ? pv_string_power1
                    : undefined,
                pv_string_power2: !state.isAcCoupled && pv_capacity_calc_option === 0 && pv_string_count && pv_string_count > 1
                    ? pv_string_power2
                    : undefined,
                pv_string_power3: !state.isAcCoupled && pv_capacity_calc_option === 0 && pv_string_count && pv_string_count > 2
                    ? pv_string_power3
                    : undefined,
                pv_module_count: !state.isAcCoupled && pv_capacity_calc_option === 1 ? pv_module_count : undefined,
                pv_module_power: !state.isAcCoupled && pv_capacity_calc_option === 1 ? pv_module_power : undefined,
                battery_backup_soc,
                battery_installed_rack_count,
                meter_model: external_control_connection === 0 || external_control_meter_load === 0 ? meter_model : undefined,
                meter_connection: external_control_connection === 0 || external_control_meter_load === 0 ? meter_connection : undefined,
                meter_modbus_tcp_ip: (external_control_connection === 0 || external_control_meter_load === 0) && meter_connection === 1
                    ? meter_modbus_tcp_ip
                    : undefined,
                meter_modbus_tcp_port: (external_control_connection === 0 || external_control_meter_load === 0) && meter_connection === 1
                    ? meter_modbus_tcp_port
                    : undefined,
                meter_modbus_rtu_dev: (external_control_connection === 0 || external_control_meter_load === 0) && meter_connection === 2
                    ? meter_modbus_rtu_dev || '/dev/ttymxc3'
                    : undefined,
                meter_modbus_rtu_baudrate: (external_control_connection === 0 || external_control_meter_load === 0) && meter_connection === 2
                    ? meter_modbus_rtu_baudrate
                    : undefined,
                meter_modbus_rtu_parity: (external_control_connection === 0 || external_control_meter_load === 0) && meter_connection === 2
                    ? meter_modbus_rtu_parity
                    : undefined,
                meter_modbus_rtu_data: (external_control_connection === 0 || external_control_meter_load === 0) && meter_connection === 2
                    ? meter_modbus_rtu_data
                    : undefined,
                meter_modbus_rtu_stop: (external_control_connection === 0 || external_control_meter_load === 0) && meter_connection === 2
                    ? meter_modbus_rtu_stop
                    : undefined,
                third_party_inverter_exist: state.isGrp || state.isEmsVer20OrHigher ? third_party_inverter_exist : undefined,
                pv_connection_type: state.isGrp || props.isAcCoupled || state.isEmsVer20OrHigher ? pv_connection_type : undefined,
                pv_meter_model: pv_connection_type === 1 ? pv_meter_model : undefined,
                pv_meter_connection: pv_connection_type === 1 ? pv_meter_connection : undefined,
                pv_meter_modbus_tcp_ip: pv_connection_type === 1 && pv_meter_connection === 1 ? pv_meter_modbus_tcp_ip : undefined,
                pv_meter_modbus_tcp_port: pv_connection_type === 1 && pv_meter_connection === 1 ? pv_meter_modbus_tcp_port : undefined,
                pv_meter_modbus_rtu_dev: pv_connection_type === 1 && pv_meter_connection === 2 ? pv_meter_modbus_rtu_dev || '/dev/ttymxc0' : undefined,
                pv_meter_modbus_rtu_baudrate: pv_connection_type === 1 && pv_meter_connection === 2 ? pv_meter_modbus_rtu_baudrate : undefined,
                pv_meter_modbus_rtu_parity: pv_connection_type === 1 && pv_meter_connection === 2 ? pv_meter_modbus_rtu_parity : undefined,
                pv_meter_modbus_rtu_data: pv_connection_type === 1 && pv_meter_connection === 2 ? pv_meter_modbus_rtu_data : undefined,
                pv_meter_modbus_rtu_stop: pv_connection_type === 1 && pv_meter_connection === 2 ? pv_meter_modbus_rtu_stop : undefined,
                // external_control_connection 과의 dependency가 있어 화면에는 없으나 Setting 내려가도록 처리
                ul1741_flag: external_control_connection !== 0 ? 0 : ul1741_flag,
            };
            const cleanedParam = _.pickBy(param, (v) => v !== undefined && v !== null);
            return cleanedParam;
        }
        function getTempPasswordParam() {
            const nowUTCDate = moment.utc().format();
            const nowUTCTimestamp = new Date(nowUTCDate).getTime() / 1000;
            const param = {
                installer_page_temp_password_password: getTemporaryPassword(),
                installer_page_temp_password_setting_time: nowUTCTimestamp,
            };
            const cleanedParam = _.pickBy(param, (v) => v !== undefined && v !== null);
            return cleanedParam;
        }
        function onSave() {
            const confirmItems = getParam();
            const params = getBasicSettingPvModuleGen3SmartModuleGen2Config(t, selectorOptionsState)
                .map((config) => {
                return {
                    ...config,
                    children: config.children
                        .map((item) => {
                        const itemValue = confirmItems[item.code];
                        const value = item.type === 'number' || item.type === 'text'
                            ? itemValue
                            : !isNull(itemValue)
                                ? item.selector?.filter((option) => option.value == itemValue)[0]?.text
                                : undefined;
                        return {
                            ...item,
                            value,
                        };
                    })
                        .filter((item) => !isNull(item.value)),
                };
            })
                .filter((config) => config.children.length > 0);
            state.confirmPopup = { on: true, data: params };
        }
        function onConfirm() {
            state.confirmPopup.on = false;
            emit('save', getParam(), state.changedValueSet, (isOk) => {
                if (isOk) {
                    state.editable = false;
                    if (state.data.energy_policy !== 2) {
                        state.prevFeedInLimit = state.data.pv_feed_in_limit;
                        state.prevFeedInLimitWatt = state.data.pv_feed_in_limit_w;
                    }
                }
            });
        }
        function onGenerate() {
            if (state.data.install_done != 1) {
                // 장비 초기 설정 x
                messageBox.alert(t('message.device_init_set_not_complete')).open();
                return;
            }
            if (!props.isConnection) {
                // 장비 미연결 상태일 경우
                const messageCode = 'code.cmdgen3configreqstatuscd.230';
                messageBox.alert(t(messageCode)).open();
                return;
            }
            const isValidVersion = checkValidEmsVersion(props.genType, props.emsVersion, 18);
            if (isValidVersion === null) {
                // 버전 정보가 없거나 다른 형식일 경우
                messageBox.alert([t('message.error_data_info'), `[${t('device.ems_ver')}]`]).open();
                return;
            }
            if (!isValidVersion) {
                // 버전이 낮을 경우
                messageBox.alert([t('message.create_fail'), t('message.retry_or_update_ems_ver')]).open();
                return;
            }
            emit('generate', getTempPasswordParam());
        }
        function onEdit() {
            if (!props.isConnection) {
                messageBox.alert(t('message.modify_when_disconnected')).open();
                return;
            }
            copyData = _.cloneDeep(state.data);
            state.editable = true;
            emit('edit', copyData);
        }
        function onCancel(handleReset) {
            state.data = copyData;
            state.editable = false;
            if (handleReset)
                handleReset();
            emit('cancel');
        }
        function goToList() {
            emit('goToList');
        }
        function onChange(valueKey, targetValue) {
            if (state.editable) {
                if (!isNull(targetValue)) {
                    // 변경 항목 key set에 저장
                    state.changedValueSet.add(valueKey);
                }
                else {
                    // 변경 항목 key set에서 제거
                    state.changedValueSet.delete(valueKey);
                }
            }
            else {
                // cancel 버튼 클릭 시
                state.changedValueSet = copyChangedValueSet;
            }
        }
        function checkEnergyPolicy(value, beforeValue) {
            if (value === Number(ENERGY_POLICY.ZERO_EXPORT)) {
                state.prevFeedInLimit = state.data.pv_feed_in_limit;
                state.enableEditFeedInLimit = false;
                state.data.pv_feed_in_limit = 0;
                state.data.pv_feed_in_limit_w = 0;
            }
            else {
                state.enableEditFeedInLimit = true;
                if (beforeValue === Number(ENERGY_POLICY.ZERO_EXPORT)) {
                    state.data.pv_feed_in_limit = state.prevFeedInLimit;
                }
                else if (state.prevFeedInLimit && beforeValue === Number(ENERGY_POLICY.ZERO_EXPORT)) {
                    state.data.pv_feed_in_limit = state.prevFeedInLimit;
                }
                if (beforeValue === Number(ENERGY_POLICY.ZERO_EXPORT)) {
                    state.data.pv_feed_in_limit_w = state.prevFeedInLimitWatt;
                }
                else if (state.prevFeedInLimitWatt && beforeValue === Number(ENERGY_POLICY.ZERO_EXPORT)) {
                    state.data.pv_feed_in_limit_w = state.prevFeedInLimitWatt;
                }
            }
            if (value === Number(ENERGY_POLICY.TIME_BASED)) {
                messageBox.alert(t('message.pricingsetting_when_timebasemode')).open();
            }
            return true;
        }
        function onChangeMeterModel(value) {
            if (isNull(value) || value == 0) {
                state.data.meter_connection = 0;
                return;
            }
            if (!value)
                return;
            state.data.meter_modbus_rtu_baudrate = DEFAULT_RTU_BAUDRATE;
            if (value == 12 || value == 13) {
                state.data.meter_modbus_rtu_baudrate = DEFAULT_RTU_BAUDRATE_EM530OR540;
            }
        }
        function onChangePvMeterModel(value) {
            if (isNull(value) || value == 0) {
                state.data.pv_meter_connection = 0;
                return;
            }
            if (!value)
                return;
            state.data.pv_meter_modbus_rtu_baudrate = DEFAULT_RTU_BAUDRATE;
            if (value == 12 || value == 13) {
                state.data.pv_meter_modbus_rtu_baudrate = DEFAULT_RTU_BAUDRATE_EM530OR540;
            }
        }
        function getWebHMIPasswordParams(type) {
            if (type === ROLE_TYPE.ENGINEER) {
                const params = {
                    web_page_password_engineer: state.data.web_page_password_engineer,
                };
                return params;
            }
            const params = {
                web_page_password_service: state.data.web_page_password_service,
            };
            return params;
        }
        function onChangeWebHMIPassword(type) {
            emit('changeWebHMIPassword', getWebHMIPasswordParams(type));
        }
        onMounted(() => {
            if (state.data.energy_policy === 2) {
                state.enableEditFeedInLimit = false;
                state.data.pv_feed_in_limit = 0;
                state.data.pv_feed_in_limit_w = 0;
            }
            if (props.isAcCoupled) {
                state.enableEditFeedInLimit = false;
                state.data.pv_feed_in_limit = 100;
                state.data.pv_string_count = 1;
                state.data.pv_string_power1 = 0;
                state.data.pv_string_power2 = 0;
                state.data.pv_string_power3 = 0;
            }
            if (props.data.installer_page_temp_password_setting_time != undefined) {
                state.data.installer_page_temp_password_setting_time = getFormatDate(props.data.installer_page_temp_password_setting_time);
            }
        });
        watch(() => props.data, () => {
            state.data.installer_page_temp_password_setting_time = getFormatDate(props.data.installer_page_temp_password_setting_time);
        });
        watch(() => languageCode.value, () => {
            state.data.installer_page_temp_password_setting_time = getFormatDate(props.data.installer_page_temp_password_setting_time);
        });
        watch(() => state.data.third_party_inverter_exist, (third_party_inverter_exist) => {
            if (third_party_inverter_exist === 0) {
                state.data.pv_connection_type = 2;
            }
        });
        await loadSelectorOptions();
        return {
            state,
            schema,
            computedVal,
            selectorOptionsState,
            onSave,
            onGenerate,
            onEdit,
            onCancel,
            onChangeMeterModel,
            onChangePvMeterModel,
            goToList,
            checkEnergyPolicy,
            onChange,
            onConfirm,
            titleWidth: '40%',
            totalModulePower,
            onChangeWebHMIPassword,
            isNull,
            roleName,
            afterChange,
            getPvStringPowerValue,
            getPvStringPowerKey,
        };
    },
});
