import { defineComponent, reactive, watch } from 'vue';
import { useModelWrapper } from '@hems/util';
import { CONNECTION_STATUS } from '@hems/util/src/constant';
export default defineComponent({
    name: 'OperationTypeFilter',
    props: {
        modelValue: String,
    },
    setup(props, { emit }) {
        const state = reactive({
            value: useModelWrapper(props, emit),
            selected: props.modelValue?.split(',') || [],
            filterConfig: [
                {
                    imgUrl: '',
                    code: CONNECTION_STATUS.online,
                    title: 'device.online',
                    class: {
                        filter_col: 'w_ty',
                        state_connect: 'ty_1',
                    },
                    children: [
                        { class: { state_prod: 'ty_1' }, code: '01', text: 'device.run' },
                        { class: { state_prod: 'ty_2' }, code: '11', text: 'device.warning' },
                        { class: { state_prod: 'ty_3' }, code: '21', text: 'common.error' },
                    ],
                },
                {
                    imgUrl: '',
                    code: CONNECTION_STATUS.offline,
                    title: 'device.offline',
                    class: {
                        state_connect: 'ty_2',
                    },
                    children: [
                        { class: { state_prod: 'ty_1' }, code: '00', text: 'device.run' },
                        { class: { state_prod: 'ty_2' }, code: '10', text: 'device.warning' },
                        { class: { state_prod: 'ty_3' }, code: '20', text: 'common.error' },
                        { class: { state_prod: 'ty_4' }, code: '40', text: 'common.incomplete' },
                    ],
                },
            ],
        });
        watch(() => state.selected, () => {
            state.value = state.selected.join(',');
        });
        function onClickGroup(onOff) {
            const onOffList = onOff === CONNECTION_STATUS.online ? ['01', '11', '21'] : ['00', '10', '20', '40'];
            const diff = state.selected.filter((item) => onOffList.includes(item));
            if (diff.length > 0) {
                state.selected = state.selected.filter((item) => !diff.includes(item));
            }
            else {
                state.selected = state.selected.concat(onOffList);
            }
        }
        return {
            state,
            onClickGroup,
        };
    },
});
