import { defineComponent, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { withLoading, LabelInput } from '@hems/component';
import PopupWrapper from '@hems/component/src/popup/PopupWrapper.vue';
import Selector from '@hems/component/src/selectors/Selector/Selector.vue';
import { CommonService, SupportService } from '@hems/service';
import { useMessageBox } from '@hems/util';
import { isNull } from '@hems/util/src/helper/helper';
export default defineComponent({
    name: 'SupportDetailPopup',
    components: {
        PopupWrapper,
        Selector,
        Form,
        LabelInput,
    },
    props: {
        params: {
            type: Number,
            required: true,
        },
    },
    emits: ['confirm', 'close', 'load'],
    async setup(props, { emit }) {
        const { t } = useI18n();
        const commonService = new CommonService(window.axiosInstance.axios);
        const supportService = new SupportService(window.axiosInstance.axios);
        const { alertMessageBox } = useMessageBox();
        const defaultSupportDetail = {
            categoryCode: null,
            categoryName: '',
            filterName: '',
            pdfFileName: '',
            pdfFileUrl: '',
        };
        const supportDetail = ref(defaultSupportDetail);
        const defaultValidate = yup
            .string()
            .transform((v, o) => (o === 'null' || o === '' ? null : v))
            .nullable()
            .required();
        const schema = yup.object().shape({
            category: defaultValidate,
            filterName: defaultValidate,
            pdfFileName: defaultValidate,
            pdfFileUrl: defaultValidate,
        });
        const getGuideCategoryList = await commonService.getGuideCategory();
        const selectAll = { text: t('common.all'), value: '' };
        const guideCategoryList = computed(() => [
            selectAll,
            ...getGuideCategoryList.map((data) => ({
                value: data.categoryCode,
                text: t(`${data.categoryName}`, { defaultValue: data.categoryName }),
            })),
        ]);
        const getParam = () => {
            const { categoryCode, filterName, pdfFileName, pdfFileUrl } = supportDetail.value;
            return {
                categoryCode,
                filterName,
                pdfFileName: `${pdfFileName}.pdf`,
                pdfFileUrl,
            };
        };
        const onConfirm = async () => {
            const params = getParam();
            try {
                withLoading(async () => {
                    if (props.params) {
                        // 수정할 경우
                        await supportService
                            .updateGuide(props.params, params)
                            .then(() => emit('confirm'))
                            .then(() => emit('load'));
                        return;
                    }
                    // 새로 등록할 경우
                    await supportService.addGuide(params).then(() => emit('confirm'));
                })();
            }
            catch (e) {
                alertMessageBox(t('message.error_apply_setting'));
            }
        };
        const onClose = () => {
            emit('close');
        };
        if (!isNull(props.params)) {
            await supportService.getGuideList({ supportId: props.params }).then((result) => {
                const detailData = result.find((data) => data.supportId == props.params);
                supportDetail.value = detailData ?? defaultSupportDetail;
            });
        }
        return {
            schema,
            getGuideCategoryList,
            guideCategoryList,
            supportDetail,
            onClose,
            onConfirm,
        };
    },
});
