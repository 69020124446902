import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Image',
    props: {
        src: {
            type: String,
            required: true,
        },
        extension: {
            type: String,
            default: 'png',
            validator: (value) => ['png', 'gif', 'svg'].indexOf(value) !== -1,
        },
    },
});
