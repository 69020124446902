import { defineComponent } from 'vue';
import { Image2 } from '@hems/component';
import { ENERGY_FLOW_IMAGE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'EnergyFlowPipeLayer',
    components: {
        Image2,
    },
    props: {
        isOnGrid: {
            type: Boolean,
            default: true,
        },
    },
    setup() {
        return {
            ENERGY_FLOW_IMAGE,
        };
    },
});
