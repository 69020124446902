import { defineComponent } from 'vue';
import BatteryPackItem from '@hems/component/src/battery/batteryPack/BatteryPackItem.vue';
export default defineComponent({
    name: 'BatteryPack',
    components: {
        BatteryPackItem,
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    setup() {
        const padBatteryInfoWithDefaultData = (batteryInfo) => {
            const paddedBatteryInfo = batteryInfo;
            const defaultBatteryInfo = {
                site_id: 0,
                battery_uid: '',
                model_nm: '',
                module: '',
                id: null,
                serial_num: '',
                capacity: '',
                total_rack_cnt: 0,
                bt_model_serial1: '',
                bt_model_serial2: '',
                battery_power: null,
            };
            [0, 1, 2].map((index) => {
                if (!paddedBatteryInfo[index]) {
                    paddedBatteryInfo[index] = { ...defaultBatteryInfo };
                }
            });
            return paddedBatteryInfo;
        };
        return {
            padBatteryInfoWithDefaultData,
        };
    },
});
