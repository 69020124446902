import { AC_MODULE_CHART_LABEL } from '@hems/util/src/constant/mapper';
import { GRAPH_SERIES_TYPE, UNIT, WATT_HOUR_UNIT } from '@hems/util/src/constant';
import { attachUnit } from '@hems/util/src/helper/helper';
export const getACModuleChartConfig = (isDevOrAdmin) => {
    const commonACModuleChartConfig = [
        {
            id: 'ac_voltage',
            name: 'device.ac_voltage',
            altName: AC_MODULE_CHART_LABEL.AC_VOLTAGE,
            unit: UNIT.VOLT,
            type: GRAPH_SERIES_TYPE.SPLINE,
            yAxis: 'ac_voltage',
        },
        {
            id: 'dc_voltage',
            name: 'device.dc_voltage',
            altName: AC_MODULE_CHART_LABEL.DC_VOLTAGE,
            unit: UNIT.VOLT,
            type: GRAPH_SERIES_TYPE.SPLINE,
            yAxis: 'dc_voltage',
        },
        {
            id: 'dc_current',
            name: 'device.dc_current',
            altName: AC_MODULE_CHART_LABEL.DC_CURRENT,
            unit: UNIT.CURRENT,
            type: GRAPH_SERIES_TYPE.SPLINE,
            yAxis: 'dc_current',
        },
        {
            id: 'watt',
            name: 'device.ac_power_produced',
            altName: AC_MODULE_CHART_LABEL.AC_POWER_PRODUCED,
            unit: UNIT.WATT,
            type: GRAPH_SERIES_TYPE.SPLINE,
            yAxis: 'watt',
        },
        {
            id: 'energy_accum',
            name: 'device.energy_produced',
            altName: AC_MODULE_CHART_LABEL.ENERGY_PRODUCED,
            unit: WATT_HOUR_UNIT.WATT_HOUR,
            type: GRAPH_SERIES_TYPE.COLUMN,
            yAxis: 'energy_accum',
        },
    ];
    if (isDevOrAdmin) {
        commonACModuleChartConfig.push({
            id: 'temperature',
            name: 'device.temp',
            altName: 'Temperature',
            unit: UNIT.TEMPERATURE,
            type: GRAPH_SERIES_TYPE.SPLINE,
            yAxis: 'temperature',
        }, {
            id: 'ac_frequency',
            name: 'device.ac_frequency',
            altName: 'AC Frequency',
            unit: UNIT.FREQUENCY,
            type: GRAPH_SERIES_TYPE.SPLINE,
            yAxis: 'ac_frequency',
        });
    }
    return commonACModuleChartConfig;
};
export const getACModuleChartYAxis = (t, isDevOrAdmin) => {
    return [
        {
            id: 'ac_voltage',
            title: {
                text: attachUnit(t('device.ac_voltage'), UNIT.VOLT),
            },
            showEmpty: false,
        },
        {
            id: 'dc_voltage',
            title: {
                text: attachUnit(t('device.dc_voltage'), UNIT.VOLT),
            },
            showEmpty: false,
        },
        {
            id: 'dc_current',
            title: {
                text: attachUnit(t('device.dc_current'), UNIT.CURRENT),
            },
            opposite: true,
            showEmpty: false,
        },
        {
            id: 'watt',
            title: {
                text: attachUnit(t('device.ac_power_produced'), UNIT.WATT),
            },
            opposite: true,
            showEmpty: false,
        },
        {
            id: 'energy_accum',
            title: {
                text: attachUnit(t('device.energy_produced'), WATT_HOUR_UNIT.WATT_HOUR),
            },
            showEmpty: false,
        },
        {
            id: 'temperature',
            title: {
                text: attachUnit(t('device.temp'), UNIT.TEMPERATURE),
            },
            showEmpty: false,
            visible: isDevOrAdmin,
        },
        {
            id: 'ac_frequency',
            title: {
                text: attachUnit(t('device.ac_frequency'), UNIT.FREQUENCY),
            },
            showEmpty: false,
            visible: isDevOrAdmin,
        },
    ];
};
