import { Service } from '@hems/service/src/api/Service';
import { apiWrapper, getApiVersionHeader } from '@hems/service/src/util/helper';
import { StorageHelper } from '@hems/util';
import { DEVICE_CONNECTION_RESPONSE_CODE, LANGUAGE } from '@hems/util/src/constant';
export default class CommonService extends Service {
    constructor(axios) {
        super(axios);
    }
    getStorageKey(code) {
        return `${code.grpCd}${code.exclude?.join('_')}`.split('').reduce((a, b) => {
            a = (a << 5) - a + b.charCodeAt(0);
            return a & a;
        }, 0);
    }
    // 코드 목록 가져오기
    getCodesByGroupCode = async (codes) => {
        const notExistedCodes = [];
        const result = {};
        // Session Storage에 있으면 result에 추가, 없으면 notExistedCodes에 파라미터 추가
        codes.forEach((item) => {
            const hashCode = this.getStorageKey(item);
            if (StorageHelper.isExistCode(hashCode)) {
                result[item.grpCd] = StorageHelper.getCode(hashCode);
            }
            else {
                notExistedCodes.push(item);
            }
        });
        let resResult;
        if (notExistedCodes.length > 0) {
            // notExistedCodes에 포함된 것만 API 호출
            const { data } = await this.post(apiWrapper.managerApi('/managements/commons/codes'), { codes: notExistedCodes });
            resResult = data;
            for (const obj of notExistedCodes) {
                const hashCode = this.getStorageKey(obj);
                // notExistedCodes의 결과를 Session Storage에 저장
                if (resResult[obj.grpCd])
                    StorageHelper.setCode(hashCode, resResult[obj.grpCd]);
            }
        }
        return { ...result, ...resResult };
    };
    getGenType = async (siteId) => {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/profiles/${siteId}/types`));
        return data;
    };
    isConnection = async (deviceId) => {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/connections/${deviceId}/status-devices`), undefined, {
            headers: getApiVersionHeader(),
        });
        if (data.responseCd === DEVICE_CONNECTION_RESPONSE_CODE.ONLINE)
            return true;
        return false;
    };
    healthCheck = async () => {
        try {
            const { data } = await this.get('/health-check');
            return data;
        }
        catch (error) {
            return false;
        }
    };
    getGoogleAPIKey = async () => {
        const { data } = await this.get(apiWrapper.managerApi('/managements/commons/google/api-keys'));
        return data;
    };
    getNotice = async (langCd = LANGUAGE.EN) => {
        const { data } = await this.get(apiWrapper.managerApi('/managements/commons/notices'), { lang: langCd });
        return data;
    };
    getEventAlarmCodes = async () => {
        const { data } = await this.get(apiWrapper.managerApi('/devices/alarms/codes'));
        return data;
    };
    getGuideCategory = async () => {
        const { data } = await this.get(apiWrapper.managerApi('/managements/mobiles/pro-apps/supports/categories'));
        return data;
    };
    sendOwnerEmail = async (siteId, sendEmailOptions) => {
        const { data } = await this.post(apiWrapper.managerApi(`/managements/mails/${siteId}`), sendEmailOptions);
        return data;
    };
    getFcasSettingDefault = async (siteId) => {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/profiles/${siteId}/fcas-values`));
        return data;
    };
    getBaseGenType = async (productModelNm) => {
        const { data } = await this.get(apiWrapper.managerApi('/devices/profiles/gen-types'), { productModelNm });
        return data;
    };
    getPgpKeyFile = async () => {
        return await this.get(apiWrapper.managerApi('/managements/commons/s3/cyber-security/files'), {}, {
            headers: {
                Accept: '*/*',
            },
            responseType: 'blob',
        });
    };
}
export { CommonService };
