import { defineComponent, onMounted, reactive, ref, watch, computed } from 'vue';
import _ from 'lodash';
import Grid from 'tui-grid';
// const presetTheme = ['default', 'striped', 'clean'];
// const presetLanguage = ['en', 'ko'];
export default defineComponent({
    name: 'ToastGrid',
    props: {
        data: {
            type: [Array],
            default: () => [],
        },
        target: {
            type: String,
        },
        columns: {
            type: Array,
            required: true,
        },
        sortState: {
            type: Object,
        },
        options: {
            type: Object,
        },
        columnName: {
            type: Array,
            default: () => [],
        },
        allColumn: {
            type: Array,
            default: () => [],
        },
        checkAll: {
            type: Boolean,
        },
    },
    emits: ['instance'],
    setup(props, { emit, attrs }) {
        let instance = null;
        const state = reactive({
            show: false,
            column: props.columns,
            columnName: computed(() => props.columnName),
            target: computed(() => props.target),
            homeState: false,
            siteState: false,
            checkAll: computed(() => props.checkAll),
            allColumn: computed(() => props.allColumn),
        });
        const tuiGrid = ref(null);
        const listener = attrs.events;
        function createChart() {
            if (!tuiGrid.value)
                return;
            Grid.applyTheme('clean');
            instance = new Grid(_.merge({
                contextMenu: null,
                el: tuiGrid.value,
                columns: props.columns,
                data: props.data,
                rowHeight: 'auto',
                minRowHeight: 50,
                // useClientSort: false,
            }, props.options));
            // instance.setColumnValues()
            addEventListeners();
            emit('instance', instance);
        }
        function addEventListeners() {
            if (!listener)
                return;
            for (const _eventName of Object.keys(listener)) {
                const eventName = _eventName;
                if (typeof listener[eventName] === 'function') {
                    instance?.on(eventName, listener[eventName]);
                }
            }
        }
        watch(() => props.data, () => {
            instance?.resetData(_.cloneDeep(props.data) || [], {
                sortState: props.sortState,
            });
        });
        watch(() => [state.target, state.columnName, state.checkAll, state.allColumn], () => {
            if (state.target) {
                if (typeof state.target === 'string') {
                    if (state.columnName?.includes(state.target)) {
                        instance?.showColumn(state.target);
                    }
                    else {
                        instance?.hideColumn(state.target);
                    }
                }
            }
            if (state.checkAll === true) {
                state.columnName === state.allColumn;
                for (let i = 0; i < state.allColumn.length; i++) {
                    instance?.showColumn(state.allColumn[i]);
                }
            }
            else if (state.checkAll === false) {
                state.columnName === state.allColumn;
                for (let i = 0; i < state.allColumn.length; i++) {
                    instance?.hideColumn(state.allColumn[i]);
                }
            }
        });
        onMounted(() => {
            createChart();
        });
        return {
            state,
            tuiGrid,
        };
    },
});
