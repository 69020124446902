/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import _ from 'lodash';
import { NUMBER_UNIT } from '@hems/util/src/constant';
import { CAPACITY_UINT_TYPE, NUMBER_UNIT_WATT_HOUR_UNIT_MAP, NUMBER_UNIT_WATT_PEAK_UNIT_MAP, } from '@hems/util/src/constant/unit';
import { attachUnit } from '@hems/util/src/helper/helper';
import { floorTwoDecimal } from '@hems/util/src/helper/numberformatHelper';
export const getMultipleDashboardData = (label, data, unit) => {
    return data.map((value, index) => {
        const valueWithUnit = unit ? attachUnit(value, unit) : value;
        return `${label[index]}: ${valueWithUnit ?? ''}`;
    });
};
export const convertDeviceProfileData = (deviceInfo) => {
    const convertedData = { ...deviceInfo };
    for (const key in deviceInfo) {
        if (typeof convertedData[key] === 'number') {
            convertedData[key] = _.floor(Number(convertedData[key]), 2);
        }
    }
    return convertedData;
};
/** PCS, Battery 버전 변환 함수 (00.00) */
export const formatToTwoDecimalVersion = (value) => (Number(value) / NUMBER_UNIT.HUNDRED).toFixed(2);
/** BMS 버전 변환 함수 (00.000) */
export const formatToThreeDecimalVersion = (value) => (Number(value) / NUMBER_UNIT.THOUSAND).toFixed(3);
export const calculatePowerFactor = (activePower, reactivePower) => {
    if (!activePower && !reactivePower) {
        return null;
    }
    const apparentPower = Math.sqrt(activePower ** 2 + reactivePower ** 2);
    return floorTwoDecimal(activePower / apparentPower);
};
const getCapacityUnit = (unitType, numberUnitValue) => unitType === CAPACITY_UINT_TYPE.HOUR
    ? NUMBER_UNIT_WATT_HOUR_UNIT_MAP[numberUnitValue]
    : NUMBER_UNIT_WATT_PEAK_UNIT_MAP[numberUnitValue];
export const addUnitToCapacity = (capacity, unitType) => {
    if (capacity >= NUMBER_UNIT.MILLION) {
        return `${(capacity / NUMBER_UNIT.MILLION).toFixed(1)} ${getCapacityUnit(unitType, NUMBER_UNIT.MILLION)}`;
    }
    if (capacity >= NUMBER_UNIT.THOUSAND) {
        return `${(capacity / NUMBER_UNIT.THOUSAND).toFixed(1)} ${getCapacityUnit(unitType, NUMBER_UNIT.THOUSAND)}`;
    }
    return `${capacity.toFixed(1)} ${getCapacityUnit(unitType, NUMBER_UNIT.HUNDRED)}`;
};
