import { defineComponent } from 'vue';
import { useField } from 'vee-validate';
import { Helper } from '@hems/util';
import BasicCheckbox from './BasicCheckbox.vue';
import ErrorMessageLabel from '../../labels/ErrorMessageLabel.vue';
export default defineComponent({
    name: 'CheckboxGroup',
    components: {
        BasicCheckbox,
        ErrorMessageLabel,
    },
    inheritAttrs: false,
    props: {
        name: String,
        modelValue: {
            type: [String, Number, Boolean, null],
            required: true,
        },
        maxColumnNum: {
            type: Number,
            default: 4,
        },
        options: {
            type: Array,
            required: true,
        },
        direction: {
            type: String,
            default: 'row',
        },
        align: {
            type: String,
            default: 'left',
        },
        valueType: {
            type: String,
            default: 'string',
        },
    },
    setup(props) {
        const { value: inputValue, errorMessage } = useField(props.name || Helper.getUUID(), undefined, {
            initialValue: props.modelValue,
            type: props.valueType,
        });
        return {
            inputValue,
            errorMessage,
        };
    },
});
