import { defineComponent, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue';
import * as Highcharts from 'highcharts';
export default defineComponent({
    name: 'Graph',
    components: {},
    props: {
        options: {
            type: Object,
            required: true,
        },
    },
    emits: ['instance'],
    setup(props, { emit }) {
        const chart = ref(null);
        const state = reactive({
            instance: null,
        });
        function drawData(options) {
            if (!options.chart || !chart.value)
                return;
            options.chart.renderTo = chart.value;
            state.instance = Highcharts.chart(options);
            emit('instance', state.instance);
        }
        watch(() => props.options, (options) => {
            drawData(options);
        });
        onMounted(() => {
            drawData(props.options);
        });
        onBeforeUnmount(() => {
            state.instance?.destroy();
        });
        return { chart };
    },
});
