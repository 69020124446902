import { apiWrapper } from '../../util/helper';
import { Service } from '../Service';
export default class HomeApplianceService extends Service {
    constructor(axios) {
        super(axios);
    }
    async getApplianceList(siteId) {
        const { data } = await this.get(apiWrapper.managerApi(`/smart-things/${siteId}`));
        return data;
    }
    async getApplianceStatus(deviceId) {
        return await this.get(`/devices/${deviceId}/status`);
    }
    async getApplianceInfo(deviceId) {
        return await this.get(`/devices/${deviceId}`);
    }
    async getApplianceHealth(deviceId) {
        return await this.get(`/devices/${deviceId}/health`);
    }
    async getApplianceLocations() {
        return await this.get('/locations');
    }
    async getApplianceLocation(locationId) {
        return await this.get(`/locations/${locationId}`);
    }
    async getApplianceRooms(locationId) {
        return await this.get(`/locations/${locationId}/rooms`);
    }
    async getApplianceRoom(locationId, roomId) {
        return await this.get(`/locations/${locationId}/rooms/${roomId}`);
    }
    async controlAppliance(deviceId, params) {
        return await this.post(`/devices/${deviceId}/commands`, params);
    }
    async refreshSmartThingsToken(token) {
        const { data } = await this.post(apiWrapper.managerApi('/smart-things/tokens/refresh'), { token });
        return data;
    }
    async getApplianceChart(siteId, params) {
        const { data } = await this.get(apiWrapper.managerApi(`/smart-things/charts/${siteId}`), params);
        return data;
    }
    async getChartByAppliance(applianceDeivceId, params) {
        const { data } = await this.get(apiWrapper.managerApi(`/smart-things/charts/${applianceDeivceId}/powers`), params);
        return data;
    }
}
