import { defineComponent, reactive, watch, ref, nextTick, onMounted, onBeforeUpdate } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import _, { isNil } from 'lodash';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { Accordion, BasicInput, ToggleInput, Selector, RadioGroup, SettingTable, SettingTableRow, useMessageBox, ChangedMark, MultiSettingTab, } from '@hems/component';
import { isSettingField, settingTableRerender, getEngineerSettingACSystemConfig, getTargetList, } from '@hems/container/src/forms/device/settings/_shared/ACSystemConfig';
import SettingConfirmPopup from '@hems/container/src/forms/device/settings/_shared/SettingConfirmPopup.vue';
import { CommonService } from '@hems/service';
import { getDeviceTabListForAC, SETTING_TAB_CODE } from '@hems/util/src/constant';
import { isNull, codeNamesToSelectorOptions, addSelectorOptionAtFirst } from '@hems/util/src/helper/helper';
const initValue = {
    inverter_external_generator_mode: 0,
    inverter_permit_service_mode_select: 0,
    inverter_enter_service_randomized_delay: 0,
    ul1741_flag: 0,
    pcs_debug_mode: 0,
    hub_external_inverter_flag: 0,
    hub_external_inverter2_flag: 0,
    hub_generator_flag: 0,
    hub_generator_remote_on: 0,
    hub_external_ct_installed_point: 0,
    auto_fw_update_flag: 0,
    debug_mode_modbus: 0,
    site_configuration_disable_mode: 0,
};
export default defineComponent({
    name: 'EngineerSettingsACSystem',
    components: {
        Accordion,
        BasicInput,
        ToggleInput,
        Form,
        Selector,
        RadioGroup,
        SettingTable,
        SettingTableRow,
        ChangedMark,
        SettingConfirmPopup,
        MultiSettingTab,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        hideEdit: {
            type: Boolean,
            default: false,
        },
        isConnection: {
            type: Boolean,
            required: true,
        },
        changedValueSet: {
            type: Object,
            default: () => new Set(),
        },
    },
    emits: ['goToList', 'save', 'cancel', 'edit'],
    async setup(props, { emit }) {
        const messageBox = useMessageBox();
        const { t } = useI18n();
        const commonService = new CommonService(window.axiosInstance.axios);
        let copyData = _.cloneDeep(props.data);
        const copyChangedValueSet = _.cloneDeep(props.changedValueSet);
        const schema = ref(yup.object().shape({}));
        const store = useStore();
        const isInit = ref(true);
        const state = reactive({
            editable: props.editable,
            data: { ...initValue, ...props.data },
            changedValueSet: _.cloneDeep(props.changedValueSet),
            confirmPopup: { on: false, data: [] },
        });
        const settingTargetType = ref(SETTING_TAB_CODE.SITE);
        const selectorOptionsState = reactive({
            meterInfo: [],
            extCtInstalledPointInfo: [],
            debugInfo: [],
        });
        const fieldList = getTargetList(getEngineerSettingACSystemConfig(t, selectorOptionsState));
        const { engineeringDeviceOption } = getDeviceTabListForAC(store.state.device.acDeviceType, store.state.site.device_info);
        const deviceTarget = ref(engineeringDeviceOption[0]?.value ?? '');
        async function loadSelectorOptions() {
            const { DEBUGINFO_GEN3_CD } = await commonService.getCodesByGroupCode([{ grpCd: 'DEBUGINFO_GEN3_CD' }]);
            selectorOptionsState.debugInfo = [
                { text: t('common.select'), value: null },
                ...codeNamesToSelectorOptions(DEBUGINFO_GEN3_CD, t),
            ];
            selectorOptionsState.meterInfo = addSelectorOptionAtFirst([
                { text: 'Disable', value: '0' },
                { text: 'Meter', value: '1' },
                { text: 'CT', value: '2' },
            ], { text: t('common.select'), value: null });
            selectorOptionsState.extCtInstalledPointInfo = [
                { text: 'Non-Installed', value: '0' },
                { text: 'Grid', value: '1' },
                { text: 'Non-Backup Load', value: '2' },
            ];
        }
        function getParam() {
            const param = {
                inverter_max_output_power: state.data.inverter_max_output_power,
                inverter_grid_target_frequency: state.data.inverter_grid_target_frequency,
                inverter_external_generator_mode: state.data.inverter_external_generator_mode,
                inverter_permit_service_mode_select: state.data.inverter_permit_service_mode_select,
                inverter_enter_service_randomized_delay: state.data.inverter_enter_service_randomized_delay,
                inverter_import_power_limit: state.data.inverter_import_power_limit,
                inverter_export_power_limit: state.data.inverter_export_power_limit,
                battery_charge_limit: state.data.battery_charge_limit,
                battery_discharge_limit: state.data.battery_discharge_limit,
                battery_user_soc_min: state.data.battery_user_soc_min,
                battery_user_soc_max: state.data.battery_user_soc_max,
                // ul1741_flag 와의 dependency가 있어 화면에는 없으나 setting 내리도록 처리
                pcs_debug_mode: state.data.pcs_debug_mode,
                external_control_connection: state.data.ul1741_flag === 1 ? 0 : state.data.external_control_connection,
                ul1741_flag: state.data.ul1741_flag,
                battery_hysteresis_low: state.data.battery_hysteresis_low,
                battery_hysteresis_high: state.data.battery_hysteresis_high,
                hub_external_inverter_flag: state.data.hub_external_inverter_flag,
                hub_external_inverter_nameplate_power: state.data.hub_external_inverter_nameplate_power,
                hub_external_inverter2_flag: state.data.hub_external_inverter2_flag,
                hub_external_inverter2_nameplate_power: state.data.hub_external_inverter2_nameplate_power,
                hub_generator_flag: state.data.hub_generator_flag,
                hub_generator_remote_on: state.data.hub_generator_remote_on,
                hub_generator_nameplate_power: state.data.hub_generator_nameplate_power,
                hub_msp_current_limit: state.data.hub_msp_current_limit,
                hub_external_ct_installed_point: state.data.hub_external_ct_installed_point,
                auto_fw_update_flag: state.data.auto_fw_update_flag,
                log_level_process_mgr: state.data.log_level_process_mgr,
                log_level_system_log: state.data.log_level_system_log,
                log_level_fota: state.data.log_level_fota,
                log_level_power_control: state.data.log_level_power_control,
                log_level_ess_mgr: state.data.log_level_ess_mgr,
                log_level_cloud_mgr: state.data.log_level_cloud_mgr,
                log_level_meter_mgr: state.data.log_level_meter_mgr,
                log_level_gateway_mgr: state.data.log_level_gateway_mgr,
                log_level_data_accumulator: state.data.log_level_data_accumulator,
                log_level_db_mgr: state.data.log_level_db_mgr,
                log_level_web_engine: state.data.log_level_web_engine,
                log_level_mlpe_mgr: state.data.log_level_mlpe_mgr,
                log_level_sys_mgr: state.data.log_level_sys_mgr,
                log_level_energy_link: state.data.log_level_energy_link,
                log_level_diag_monitor: state.data.log_level_diag_monitor,
                log_level_csip_mgr: state.data.log_level_csip_mgr,
                log_level_energy_optimizer: state.data.log_level_energy_optimizer,
                log_level_ipc_bridge: state.data.log_level_ipc_bridge,
                log_level_energy_monitor: state.data.log_level_energy_monitor,
                log_level_energy_scheduler: state.data.log_level_energy_scheduler,
                log_level_power_scheduler: state.data.log_level_power_scheduler,
                debug_mode_mqtt: state.data.debug_mode_mqtt,
                debug_mode_modbus: state.data.debug_mode_modbus,
                site_configuration_disable_mode: state.data.site_configuration_disable_mode,
            };
            const cleanedParam = _.pickBy(param, (v) => v !== undefined);
            return cleanedParam;
        }
        function onSave() {
            const confirmItems = getParam();
            const filteredItems = getFilteredParams(confirmItems);
            const params = getEngineerSettingACSystemConfig(t, selectorOptionsState)
                .map((config) => {
                return {
                    ...config,
                    children: config.children
                        .map((item) => {
                        const itemValue = filteredItems[item.code];
                        const value = (() => {
                            if (item.type === 'number' || item.type === 'text') {
                                return itemValue;
                            }
                            if (!isNull(itemValue)) {
                                return item.options?.filter((option) => option.value == itemValue)[0]?.text;
                            }
                            return null;
                        })();
                        return {
                            ...item,
                            value,
                        };
                    })
                        .filter((item) => !isNull(item.value)),
                };
            })
                .filter((config) => config.children.length > 0);
            state.confirmPopup = { on: true, data: params };
        }
        // @TODO advancedSettingsUtil 에도 동일한 function이 있는데 type이 달라서 추후에 통합할 예정
        function getSchema(settingConfig) {
            const shape = settingConfig.reduce((acc, group) => {
                const groupShape = group.children.reduce((groupAcc, item) => {
                    if (['text', 'number', 'selector', 'toggle'].includes(item.type) && item.schema) {
                        if (isEngineeringSettingField(item.code)) {
                            return { ...groupAcc, [item.code]: item.schema() };
                        }
                    }
                    return groupAcc;
                }, {});
                return { ...acc, ...groupShape };
            }, {});
            return yup.object().shape(shape);
        }
        function onConfirm() {
            state.confirmPopup.on = false;
            emit('save', getFilteredParams(getParam()), (isOk) => {
                if (isOk)
                    state.editable = false;
            });
        }
        async function onEdit() {
            if (!props.isConnection) {
                messageBox.alert(t('message.modify_when_disconnected')).open();
                return;
            }
            copyData = _.cloneDeep(state.data);
            state.data = { ...state.data };
            state.editable = true;
            emit('edit', copyData);
            schema.value = getSchema(getEngineerSettingACSystemConfig(t, selectorOptionsState)).required();
            rerender();
        }
        function onCancel(handleReset) {
            state.data = copyData;
            state.editable = false;
            if (handleReset)
                handleReset();
            emit('cancel');
        }
        function goToList() {
            emit('goToList');
        }
        function onChange(valueKey, targetValue) {
            if (state.editable) {
                if (!isNull(targetValue)) {
                    // 변경 항목 key set에 저장
                    state.changedValueSet.add(valueKey);
                }
                else {
                    // 변경 항목 key set에서 제거
                    state.changedValueSet.delete(valueKey);
                }
            }
            else {
                // cancel 버튼 클릭 시
                state.changedValueSet = copyChangedValueSet;
            }
        }
        function isEngineeringSettingField(fieldName) {
            return isSettingField(fieldName, fieldList, settingTargetType.value, deviceTarget.value);
        }
        function getFilteredParams(confirmedItems) {
            const filteredItems = {};
            Object.keys(confirmedItems).forEach((code) => {
                if (isEngineeringSettingField(code)) {
                    filteredItems[code] = confirmedItems[code];
                }
            });
            return filteredItems;
        }
        const rerender = () => {
            isInit.value = false;
            nextTick(() => {
                isInit.value = true;
            });
        };
        onBeforeUpdate(() => {
            nextTick(() => {
                settingTableRerender('engineer-tb');
            });
        });
        onMounted(() => {
            settingTableRerender('engineer-tb');
        });
        watch(() => [settingTargetType.value, deviceTarget.value], () => {
            onCancel();
        });
        watch(() => state.data.hub_generator_flag, (generator) => {
            if (generator == 0) {
                // Generator 미사용이면 Remote On Impossible 처리
                state.data.hub_generator_remote_on = 0;
            }
        });
        await loadSelectorOptions();
        return {
            state,
            schema,
            selectorOptionsState,
            onSave,
            onEdit,
            onCancel,
            goToList,
            onChange,
            onConfirm,
            isEngineeringSettingField,
            engineeringDeviceOption,
            settingTargetType,
            deviceTarget,
            isInit,
            isNil,
        };
    },
});
