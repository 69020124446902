import { defineComponent, reactive } from 'vue';
import { useModelWrapper } from '@hems/util';
export default defineComponent({
    name: 'Tab',
    props: {
        modelValue: {
            type: String,
        },
        tabList: {
            type: Array,
            required: true,
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            tabname: useModelWrapper(props, emit),
        });
        function switchTab(tabname) {
            state.tabname = tabname;
        }
        return {
            state,
            switchTab,
        };
    },
});
