import { computed, defineComponent, watch, ref } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
import { PopupWrapper, Selector } from '@hems/component';
import { TermsService } from '@hems/service';
import { TermsHelper } from '@hems/util';
import { TERMS_TYPE, REQUIRED_TERMS_CATEGORY } from '@hems/util/src/constant';
import { isAU } from '@/config/ConstantPortal';
export default defineComponent({
    name: 'PrivacyPopup',
    components: {
        PopupWrapper,
        Selector,
    },
    emits: ['close'],
    async setup(_, { emit }) {
        const API_BASE_URL = process.env.VUE_APP_API_URL;
        const store = useStore();
        const appCtx = store.state.appCtx;
        const axiosInstance = axios.create({
            baseURL: API_BASE_URL,
        });
        const termsService = new TermsService(axiosInstance);
        const langCd = computed(() => appCtx.langCd);
        const selectedLocale = ref(appCtx.envLocale);
        const termsList = ref([]);
        const privacyPolicyTerms = ref();
        const getTermsList = async () => {
            try {
                const terms = await termsService.getTerms(TERMS_TYPE.USER);
                termsList.value = TermsHelper.getSortedTermsByEffectiveDate(terms);
            }
            catch (e) {
                console.error(e);
            }
        };
        const requiredTerms = () => {
            const filteredTerms = TermsHelper.getFilteredTerms(termsList.value, selectedLocale.value, langCd.value).requiredTerms;
            privacyPolicyTerms.value = filteredTerms[REQUIRED_TERMS_CATEGORY.TERMS_OF_PRIVACY];
        };
        const auCountryOptions = [
            { text: 'Australia', value: 'AU' },
            { text: 'New Zealand', value: 'NZ' },
        ];
        const closePopup = () => {
            emit('close');
        };
        watch(() => selectedLocale.value, () => {
            requiredTerms();
        });
        await getTermsList();
        requiredTerms();
        return {
            selectedLocale,
            termsList,
            privacyPolicyTerms,
            closePopup,
            isAU,
            auCountryOptions,
        };
    },
});
