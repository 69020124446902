import { defineComponent, reactive, watch } from 'vue';
import { OnClickOutside } from '@vueuse/components';
import Image2 from '../../image/Image2.vue';
import { BasicCheckbox, BasicInput } from '../../inputs';
export default defineComponent({
    name: 'ChecklistSelector',
    components: {
        Image2,
        OnClickOutside,
        BasicCheckbox,
        BasicInput,
    },
    props: {
        name: {
            type: String,
            default: 'column_code',
        },
        modelValue: {
            type: Array,
            default: () => [],
        },
        checkList: {
            type: Array,
            required: true,
        },
        columnHeader: {
            type: Array,
            default: () => [],
        },
        searchCondition: {
            type: Object,
            default: () => ({
                eventTypeCd: null,
                cntryCd: null,
                searchFor: null,
                filteredAlarmCodes: null,
            }),
        },
    },
    emits: ['update:modelValue', 'target', 'open', 'search', 'allhide'],
    setup(props, { emit }) {
        const state = reactive({
            showColumnChecklist: false,
            all: isAll(props.modelValue, props.checkList),
            value: [...props.modelValue],
            searchCondition: props.searchCondition,
            searchCode: null,
            codeList: props.checkList,
            columnHeader: props.columnHeader,
            checkList: props.checkList,
        });
        watch(() => props.modelValue, (value) => {
            if (state.value.join(',') !== value.join(','))
                handleChange(value);
        });
        watch(() => state.value, (value) => {
            if (props.modelValue.join(',') !== value.join(','))
                handleChange(value);
        });
        function isAll(values, checkList) {
            if (values.length === checkList.length) {
                return true;
            }
            if (!values.length) {
                return false;
            }
        }
        function handleChange(values) {
            state.all = isAll(values, props.checkList);
            state.value = values;
            emit('update:modelValue', values);
            emit('allhide', {
                allcheck: state.all,
                checklist: props.checkList.map((item) => item.name),
            });
        }
        const onClickColumnOption = () => {
            state.showColumnChecklist = !state.showColumnChecklist;
            if (state.showColumnChecklist) {
                emit('open');
            }
        };
        const onClickCheck = (e) => {
            emit('target', e.target.value);
        };
        const onClickOutside = () => {
            state.showColumnChecklist = false;
        };
        const onSearchFilter = () => {
            state.codeList = state.checkList; // codeList초기화
            state.searchCondition.searchFor = state.searchCondition.searchFor?.trim();
            if (state.searchCode != null && state.searchCode?.length !== 0) {
                const searchedData = state.codeList.filter((data) => {
                    return data.header.toLowerCase().includes(state.searchCode?.toLowerCase());
                });
                return (state.codeList = searchedData);
            }
            return state.codeList;
        };
        watch(() => state.searchCode, () => {
            onSearchFilter();
        });
        const onClickAllHide = () => {
            handleChange([]);
            return;
        };
        const onClickAllShow = () => {
            handleChange(props.checkList.map((item) => item.name) ?? []);
        };
        handleChange(state.value);
        return {
            onClickColumnOption,
            state,
            onClickOutside,
            onClickCheck,
            onSearchFilter,
            onClickAllHide,
            onClickAllShow,
        };
    },
});
