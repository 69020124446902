import { apiWrapper } from '../../util/helper';
import { Service } from '../Service';
export default class StormCoverService extends Service {
    constructor(axios) {
        super(axios);
    }
    async getStormInfo(siteId) {
        const { data } = await this.get(apiWrapper.managerApi(`/managements/users/storm-cover/alerts/${siteId}`));
        return data;
    }
    async sendSeenStormInfoFlag(siteId) {
        const { data } = await this.post(apiWrapper.managerApi(`/managements/users/storm-cover/alerts/${siteId}`));
        return data;
    }
}
