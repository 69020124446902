import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
export default defineComponent({
    name: 'EnergySubTab',
    setup() {
        const store = useStore();
        const route = useRoute();
        const deviceId = computed(() => route.query.deviceId);
        const genType = computed(() => store.state.device.gen_type);
        const tabname = computed(() => route.path.replace('/remote/monitoring/details/energy/', '').split('/')[0]);
        return {
            deviceId,
            genType,
            tabname,
        };
    },
});
