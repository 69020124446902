import { defineComponent } from 'vue';
import { Helper } from '@hems/util';
import PopupWrapper from './PopupWrapper.vue';
export default defineComponent({
    name: 'CommonPopup',
    components: {
        PopupWrapper,
    },
    props: {
        title: String,
        okText: String,
        cancelText: String,
    },
    emits: ['confirm', 'close'],
    setup(_, { emit }) {
        const uuid = Helper.getUUID();
        const close = () => {
            emit('close');
        };
        const confirm = () => {
            emit('confirm');
        };
        return {
            uuid,
            confirm,
            close,
        };
    },
});
