import { Helper } from '@hems/util';
import { apiWrapper } from '../../util/helper';
import { Service } from '../Service';
export default class UserInfoService extends Service {
    constructor(axios) {
        super(axios);
    }
    async getGeneralAccountInfo() {
        const { data } = await this.get(apiWrapper.managerApi('/managements/users/profiles'));
        return data;
    }
    async updateGeneralAccountInfo(params) {
        const { data } = await this.put(apiWrapper.managerApi('/managements/users/profiles'), Helper.convertSettingParam(params));
        return data;
    }
    async getInstallerAccountInfo(installerId) {
        const { data } = await this.get(apiWrapper.managerApi('/managements/users/installer-profiles'), { userId: installerId });
        return data;
    }
    async updateInstallerAccountInfo(params) {
        const _params = {
            ...params,
            instl_user_mpn: params.mpn_no,
            instl_user_email: params.email,
            instl_user_nm: params.user_nm,
        };
        delete _params.mpn_no;
        delete _params.email;
        delete _params.user_nm;
        const { data } = await this.put(apiWrapper.managerApi('/managements/users/installers/profiles'), Helper.keyToCamelCase(_params));
        return data;
    }
    async updateAdminAccountInfo(params) {
        const _params = {
            ...params,
            admin_user_mpn: params.mpn_no,
            admin_user_email: params.email,
            admin_user_nm: params.user_nm,
        };
        delete _params.mpn_no;
        delete _params.email;
        delete _params.user_nm;
        const { data } = await this.put(apiWrapper.managerApi('/managements/users/admins/profiles'), Helper.keyToCamelCase(_params));
        return data;
    }
    async updateGeneralAccountAlarmFlag(alarmFlag) {
        const { data } = await this.put(apiWrapper.managerApi('/managements/users/alarm-flags'), { alarmMailRecvFlag: alarmFlag });
        return data;
    }
    async getAccountCountryInfo(userId, accessToken) {
        const { data } = await this.get(apiWrapper.managerApi(`/managements/users/user-countries/${userId}/information`), undefined, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return data;
    }
    async updateAccountCountryInfo(params, accessToken) {
        const { data } = await this.put(apiWrapper.managerApi('/managements/users/user-countries'), params, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return data;
    }
}
