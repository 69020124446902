import { CustomRenderer } from '@hems/component';
import { ERROR_HISTORY_GRID_TYPE } from '@hems/util/src/constant/eventHistory';
import { ROLE_NAME } from '@hems/util/src/constant/role';
const getCommonErrorHistoryColumns = (t, isUS = false, isInstaller = false) => {
    return [
        {
            header: t('common.start_dt'),
            name: 'triggerDate',
            align: 'left',
            width: isInstaller ? 240 : 190,
            sortable: true,
            className: 'overflow-visible',
            resizable: true,
            renderer: {
                type: CustomRenderer.UTCTooltipRenderer,
                options: {
                    tooltipColumnName: 'triggerDateUtc',
                    position: ['bottom', 'right'],
                },
            },
        },
        {
            header: t('common.end_dt'),
            name: 'clearDate',
            align: 'left',
            width: isInstaller ? 240 : 190,
            sortable: true,
            className: 'overflow-visible',
            resizable: true,
            renderer: {
                type: CustomRenderer.UTCTooltipRenderer,
                options: {
                    tooltipColumnName: 'clearDateUtc',
                    position: ['bottom', 'left'],
                },
            },
        },
        {
            header: t('common.site_id'),
            name: 'siteId',
            align: 'left',
            sortable: true,
            width: isInstaller ? 145 : 108,
        },
        {
            header: t('device.serial_no'),
            name: 'deviceId',
            align: 'left',
            sortable: true,
            width: isInstaller ? 232 : 182,
            resizable: true,
        },
        {
            header: isUS ? t('device.err_code') : t('device.event_cd'),
            name: 'errorCode',
            align: 'left',
            sortable: true,
            width: isInstaller ? 161 : 131,
        },
    ];
};
const getCommonSiteErrorHistoryColumns = (t, isUS = false) => {
    return [
        {
            header: t('common.start_dt'),
            name: 'triggerDate',
            align: 'left',
            width: 190,
            sortable: true,
            className: 'overflow-visible',
            renderer: {
                type: CustomRenderer.UTCTooltipRenderer,
                options: {
                    tooltipColumnName: 'triggerDateUtc',
                    position: ['bottom', 'right'],
                },
            },
        },
        {
            header: t('common.end_dt'),
            name: 'clearDate',
            align: 'left',
            width: 190,
            sortable: true,
            className: 'overflow-visible',
            renderer: {
                type: CustomRenderer.UTCTooltipRenderer,
                options: {
                    tooltipColumnName: 'clearDateUtc',
                    position: ['bottom', 'left'],
                },
            },
        },
        {
            header: t('device.serial_no'),
            name: 'deviceId',
            align: 'left',
            sortable: true,
            width: 182,
            resizable: true,
        },
        {
            header: isUS ? t('device.err_code') : t('common.event_cd'),
            name: 'errorCode',
            align: 'left',
            sortable: true,
            width: 121,
        },
    ];
};
const getErrorLevelColumn = (t, isUS) => {
    return {
        header: isUS ? 'Error Level' : t('common.event_type'),
        name: 'errorLevel',
        align: 'left',
        width: 105,
        resizable: true,
    };
};
const getDescriptionColumn = (t) => {
    return {
        header: t('common.description'),
        name: 'description',
        renderer: {
            type: CustomRenderer.AlarmDetailRenderer,
        },
        align: 'left',
        resizable: true,
    };
};
const getColumnsForAdminSiteErrorHistory = (t, isUS = false) => {
    return [...getCommonSiteErrorHistoryColumns(t, isUS), getErrorLevelColumn(t, isUS), getDescriptionColumn(t)];
};
const getColumnsForInstallerSiteErrorHistory = (t, isUS = false) => {
    const commonSiteErrorHistoryColumns = getCommonSiteErrorHistoryColumns(t, isUS);
    const descriptionColumn = getDescriptionColumn(t);
    if (isUS) {
        return [...commonSiteErrorHistoryColumns, descriptionColumn];
    }
    return [...commonSiteErrorHistoryColumns, getErrorLevelColumn(t, isUS), descriptionColumn];
};
const getColumnsForAdminErrorHistory = (t, isUS) => {
    return [
        {
            header: t('common.country'),
            name: 'countryCode',
            align: 'left',
            sortable: true,
            width: 114,
        },
        ...getCommonErrorHistoryColumns(t, isUS),
        getErrorLevelColumn(t, isUS),
        getDescriptionColumn(t),
    ];
};
const getColumnsForInstallerErrorHistory = (t, isUS) => {
    const commonErrorHistoryColumns = getCommonErrorHistoryColumns(t, isUS, true);
    const descriptionColumn = getDescriptionColumn(t);
    if (isUS) {
        return [...commonErrorHistoryColumns, descriptionColumn];
    }
    return [...commonErrorHistoryColumns, getErrorLevelColumn(t, isUS), descriptionColumn];
};
// eslint-disable-next-line import/prefer-default-export
export const getColumns = (t, gridType = ERROR_HISTORY_GRID_TYPE.ALL, roleName = ROLE_NAME.INSTALLER, isUS = false) => {
    if (gridType === ERROR_HISTORY_GRID_TYPE.ALL) {
        if (roleName === ROLE_NAME.INSTALLER) {
            return getColumnsForInstallerErrorHistory(t, isUS);
        }
        return getColumnsForAdminErrorHistory(t, isUS);
    }
    if (roleName === ROLE_NAME.INSTALLER) {
        return getColumnsForInstallerSiteErrorHistory(t, isUS);
    }
    return getColumnsForAdminSiteErrorHistory(t, isUS);
};
