import { computed, defineComponent, watch } from 'vue';
import { useField } from 'vee-validate';
import ErrorMessageLabel from '@hems/component/src/labels/ErrorMessageLabel.vue';
export default defineComponent({
    name: 'ToggleInput',
    components: {
        ErrorMessageLabel,
    },
    props: {
        id: String,
        name: String,
        modelValue: {
            type: [String, Number, Boolean],
            default: false,
            required: true,
        },
        toggle: {
            type: Array,
            default: () => [
                [true, 'Enable'],
                [false, 'Disable'],
            ],
        },
        readonly: [String, Boolean],
        beforeChange: Function,
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const isReadonly = computed(() => props.readonly === '' || props.readonly);
        const { value: inputValue, errorMessage } = useField(props.name || 'toggleValue', undefined, {
            initialValue: props.modelValue,
            type: typeof props.toggle[0][0],
        });
        const isChecked = computed(() => inputValue.value === props.toggle[0][0]);
        watch(() => props.modelValue, () => {
            if (props.modelValue !== inputValue.value)
                inputValue.value = props.modelValue;
        });
        const onClick = (e) => {
            e.preventDefault();
            if (props.beforeChange) {
                const val = !isChecked.value ? props.toggle[0][0] : props.toggle[1][0];
                if (props.beforeChange(val, props.modelValue)) {
                    if (isReadonly.value)
                        return;
                    emit('update:modelValue', val);
                }
            }
            else {
                if (isReadonly.value)
                    return;
                emit('update:modelValue', !isChecked.value ? props.toggle[0][0] : props.toggle[1][0]);
            }
        };
        return {
            isChecked,
            onClick,
            isReadonly,
            errorMessage,
        };
    },
});
