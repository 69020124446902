import { Helper, Color } from '@hems/util';
class InstallationStepRenderer {
    el;
    text;
    constructor(props) {
        const { $ } = window;
        const el = $('<div class="installation-step-cell"></div>');
        this.text = $('<span class="installation-step-text"></span>');
        el.append(this.text);
        this.el = el.get(0);
        this.render(props);
    }
    getElement() {
        return this.el;
    }
    render(props) {
        const value = Helper.nullTo(props.value, '');
        const options = props.columnInfo.renderer.options;
        const maxStepColumnName = Helper.nullTo(options.maxStep, '') ?? '';
        const maxStep = Helper.nullTo(props.grid.getValue(props.rowKey, maxStepColumnName), value);
        const isCompleteColumnName = Helper.nullTo(options.isComplete, '') ?? '';
        const isComplete = Helper.nullTo(props.grid.getValue(props.rowKey, isCompleteColumnName), value);
        let displayedText = value;
        let textColor = Color.DEFAULT_COLOR.BLACK;
        let completeClass = '';
        if (Helper.isNull(value) || !options) {
            displayedText = '';
            return;
        }
        if (isComplete) {
            textColor = Color.DEFAULT_COLOR.PRIMARY_BLUE_LIGHT;
            completeClass = 'complete';
            displayedText = 'Complete';
        }
        else {
            textColor = Color.PRO_2_COLOR.TANGERINE;
            completeClass = 'incomplete';
            if (maxStep <= Number(value)) {
                displayedText = `Step ${Number(value)} is incomplete`;
            }
            else {
                displayedText = `Step ${Number(value) + 1} is incomplete`;
            }
        }
        this.text.addClass(completeClass);
        this.text.css({ color: textColor });
        this.text.text(displayedText);
    }
}
export default InstallationStepRenderer;
