import { defineComponent, ref, watch } from 'vue';
import { getACMeterData, getChartTemplateAndData, getXAxisCategories, updateChartData, } from '@hems/container/src/forms/device/statistics/_shared';
import { getACCombinerConfig, getBatteryConfig, getEnergyMinuteConfig, getInverterConfig, getMeterConfig, getPowerConfig, getPvConfig, } from '@hems/container/src/forms/device/statistics/_shared/filterConfig';
import { getDefaultMinuteKeys, getMinuteKeysByGenType, } from '@hems/container/src/forms/device/statistics/_shared/graphKeys';
import DeviceLineGraph from '@hems/container/src/highcharts/spline/DeviceLineGraph.vue';
import { useGenType, useACDeviceType, useRole, useSiteInfo } from '@hems/util';
import { AC_DEVICE_TYPE, GEN_TYPE, GRAPH_GROUP_CODE, GRAPH_Y_AXIS_UNIT } from '@hems/util/src/constant';
export default defineComponent({
    name: 'StatisticsMinuteContainer',
    components: {
        DeviceLineGraph,
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        isAcCoupled: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const xAxisCategories = ref([]);
        const power = ref([]);
        const energy = ref([]);
        const ACCombiner = ref([]);
        const pv = ref([]);
        const inverter = ref([]);
        const meter = ref([]);
        const battery = ref([]);
        const { genType, isACSystem } = useGenType();
        const { ACDeviceType, hasACCombiner, isCase1 } = useACDeviceType();
        const isShowACCombinerGraph = isACSystem && hasACCombiner;
        const isFoxH3 = genType.value === GEN_TYPE.FOX_ESS_H3;
        const { isAdminGroup, roleName } = useRole();
        const { ESSCount } = useSiteInfo();
        const getGraphKeys = () => {
            if (genType.value) {
                return getMinuteKeysByGenType({
                    genType: genType.value,
                    roleName: roleName.value,
                    ACDeviceType: ACDeviceType.value,
                    isACCoupled: props.isAcCoupled,
                    ESSCount,
                });
            }
            return getDefaultMinuteKeys();
        };
        const graphKeys = getGraphKeys();
        const getMinuteGraphConfig = () => {
            if (genType.value) {
                return [
                    getPowerConfig(graphKeys.power, genType.value),
                    getEnergyMinuteConfig(graphKeys.energy, genType.value),
                    getACCombinerConfig(graphKeys.ACCombiner),
                    getPvConfig(graphKeys.pv, genType.value),
                    getInverterConfig(graphKeys.inverter, genType.value),
                    getMeterConfig(graphKeys.meter, genType.value),
                    getBatteryConfig(graphKeys.battery, genType.value, roleName.value),
                ];
            }
            return [];
        };
        const { chartDataTemplate, chartData } = getChartTemplateAndData(getMinuteGraphConfig());
        const getFilterList = (filterGroup) => chartDataTemplate[filterGroup]?.filterList;
        const getFilterGroupName = (filterGroup) => chartDataTemplate[filterGroup]?.filterGroupName ?? '';
        watch(() => props.data, (data) => {
            xAxisCategories.value = getXAxisCategories(data);
            updateChartData(chartData, data, {
                power: graphKeys.power,
                energy: graphKeys.energy,
                ACCombiner: graphKeys.ACCombiner,
                pv: graphKeys.pv,
                inverter: graphKeys.inverter,
                meter: graphKeys.meter,
                battery: graphKeys.battery,
            });
            power.value = chartData[GRAPH_GROUP_CODE.POWER];
            energy.value = chartData[GRAPH_GROUP_CODE.ENERGY];
            ACCombiner.value = chartData[GRAPH_GROUP_CODE.AC_COMBINER];
            pv.value = chartData[GRAPH_GROUP_CODE.PV];
            inverter.value = chartData[GRAPH_GROUP_CODE.INVERTER];
            meter.value = isACSystem
                ? getACMeterData(chartData[GRAPH_GROUP_CODE.METER])
                : chartData[GRAPH_GROUP_CODE.METER];
            battery.value = chartData[GRAPH_GROUP_CODE.BATTERY];
        });
        return {
            power,
            energy,
            ACCombiner,
            pv,
            inverter,
            meter,
            battery,
            xAxisCategories,
            AC_DEVICE_TYPE,
            GEN_TYPE,
            isShowACCombinerGraph,
            isFoxH3,
            ACDeviceType,
            GRAPH_GROUP_CODE,
            GRAPH_Y_AXIS_UNIT,
            getFilterList,
            getFilterGroupName,
            isCase1,
            isACSystem,
            isAdminGroup,
        };
    },
});
