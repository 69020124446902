import { defineComponent } from 'vue';
import { Image2 } from '@hems/component';
export default defineComponent({
    name: 'EmptyListArea',
    components: {
        Image2,
    },
    props: {
        text: {
            type: String,
            default: '',
        },
        isBorder: {
            type: Boolean,
            default: true,
        },
    },
});
