import { defineComponent } from 'vue';
import { IconButton } from '@hems/component';
export default defineComponent({
    name: 'EnergyManagementNavigation',
    components: {
        IconButton,
    },
    props: {
        navigationTitle: {
            type: String,
            required: true,
        },
        isEditButton: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['moveToPrevPage', 'edit'],
});
