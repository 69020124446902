/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import { useStore } from 'vuex';
import { useRole } from '@hems/util/src/composable/role';
import { useSiteInfo } from '@hems/util/src/composable/site/siteInfo';
import { HUB_GENERATOR_FLAG } from '@hems/util/src/constant';
export const useGenerator = () => {
    const userStore = useStore();
    const { isUser } = useRole();
    const { hubInfo } = !isUser ? useSiteInfo() : { hubInfo: userStore.state.site.hubInfo };
    return {
        isGenerator: hubInfo?.hub_generator_flag === HUB_GENERATOR_FLAG.USED,
    };
};
