/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import { PROVIDE_INJECT_KEYS } from '@hems/util/src/constant/provideInjection';
export class Vue3GoogleOAuth2 {
    _accessToken;
    _email;
    _name;
    constructor() {
        // TODO: 타입지정 개선 확인 예정
        (function (doc, tagNm, id) {
            const fjs = doc.getElementsByTagName(tagNm)[0];
            if (doc.getElementById(id))
                return;
            const jsEl = doc.createElement(tagNm);
            jsEl.id = id;
            jsEl.src = 'https://accounts.google.com/gsi/client';
            fjs.parentNode?.insertBefore(jsEl, fjs);
        })(document, 'script', 'google-apisdk');
    }
    logout() {
        this._accessToken = undefined;
        this._email = undefined;
        this._name = undefined;
    }
    getAccessToken() {
        return this._accessToken;
    }
    getEmail() {
        return this._email;
    }
    getName() {
        return this._name;
    }
    isLogin() {
        return !!this._accessToken;
    }
    setAccessToken(accessToken) {
        this._accessToken = accessToken;
    }
    setEmail(email) {
        this._email = email;
    }
    setName(name) {
        this._name = name;
    }
}
const Vue3GoogleOAuth2Plugin = {
    install: (app, options) => {
        if (typeof options === 'object') {
            if (!options.clientId) {
                throw new Error('clientId is required');
            }
        }
        else {
            throw new TypeError('invalid option type. Object type accepted only');
        }
        // Install Vue plugin
        const instance = new Vue3GoogleOAuth2();
        app.config.globalProperties.$googleOAuth = instance;
        app.provide(PROVIDE_INJECT_KEYS.GOOGLE_OAUTH, instance);
    },
};
export default Vue3GoogleOAuth2Plugin;
