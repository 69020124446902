import { defineComponent } from 'vue';
import UnauthorizedPage from '@hems/component/src/redirectPages/UnauthorizedPage.vue';
import { useRole } from '@hems/util';
export default defineComponent({
    name: 'AuthTemplateContainer',
    components: {
        UnauthorizedPage,
    },
    props: {
        allowRole: {
            type: Array,
            required: true,
        },
    },
    setup() {
        const { roleName } = useRole();
        return {
            roleName,
        };
    },
});
