import { apiWrapper, getApiVersionHeader } from '../../util/helper';
import { Service } from '../Service';
export default class StatisticsService extends Service {
    constructor(axios) {
        super(axios);
    }
    async getDeviceStatistics(deviceId, params) {
        const { data } = await this.get(apiWrapper.managerApi(`/histories/graphs/${deviceId}`), params, {
            headers: getApiVersionHeader(),
        });
        return data;
    }
    async getSiteStatistics(siteId, params) {
        const { data } = await this.get(apiWrapper.managerApi(`/histories/graphs/${siteId}/ac-system`), params, {
            headers: getApiVersionHeader(),
        });
        return data;
    }
    async getFoxESSMonitoringData(deviceId) {
        const { data } = await this.get(apiWrapper.stateApi(`/devices/telemetries/realtime/${deviceId}`));
        return data;
    }
}
export { StatisticsService };
