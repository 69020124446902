import { Helper } from '@hems/util';
import { apiWrapper } from '../../util/helper';
import { Service } from '../Service';
export default class AuthService extends Service {
    constructor(axios) {
        super(axios);
    }
    async getAccessToken(params) {
        const { data } = await this.post(apiWrapper.authenticationApi('/login'), params);
        return data;
    }
    async getAccessTokenBySocialToken(issuer, issuer_token, username) {
        const { data } = await this.post(apiWrapper.authenticationApi('/login/social'), { username }, { headers: { issuer, issuer_token } });
        return data;
    }
    async findId(email) {
        const { data } = await this.get(apiWrapper.managerApi('/managements/users/id'), { email });
        return data;
    }
    async resetPassword(params) {
        const { email, user_id, langCd } = params;
        const { data } = await this.post(apiWrapper.managerApi(`/managements/users/reset/passwords?lang=${langCd}`), Helper.keyToCamelCase({ user_id, email }));
        return data;
    }
    async checkTermsVersion(params, accessToken) {
        const { data } = await this.get(apiWrapper.managerApi('/managements/users/validations/terms'), params, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return data;
    }
    async updateTermsVersion(params, accessToken) {
        const { data } = await this.put(apiWrapper.managerApi('/managements/users/terms'), undefined, {
            params,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return data;
    }
    async addRetryLoginLog(logMessage) {
        const { data } = await this.post('/tracking/login', logMessage);
        return data;
    }
    async getRealtimeApiAuthToken() {
        return await this.get(apiWrapper.daqApi('/v1/auth'));
    }
    exchangeEntraIdTokenToAccessToken = async (entraIdToken) => {
        const { data } = await this.post(apiWrapper.authenticationApi('/exchange-tokens'), {}, {
            headers: {
                Authorization: `Bearer ${entraIdToken}`,
            },
        });
        return data;
    };
}
