export function trimSerial(str) {
    if (str) {
        str = str.replace(/\n/g, '').replace(/\s/g, '');
    }
    else {
        str = '';
    }
    return str;
}
export function rgbToHex(red, green, blue) {
    const rgb = (red << 16) | (green << 8) | (blue << 0);
    return `#${(0x1000000 + rgb).toString(16).slice(1)}`;
}
export function hexToRgba(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    if (alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    return `rgb(${r}, ${g}, ${b})`;
}
export function checkUserAgent() {
    const userAgent = navigator.userAgent.toLowerCase();
    console.log('navigator.maxTouchPoints:', navigator.maxTouchPoints);
    const isAOS = userAgent.search('android') > -1;
    const isIOS = userAgent.search('iphone') > -1 || userAgent.search('ipad') > -1 || navigator.maxTouchPoints !== 0;
    if (isAOS) {
        console.log('userAgent === aos');
        return 'aos';
    }
    if (isIOS) {
        console.log('userAgent === ios');
        return 'ios';
    }
    console.log('userAgent === pc');
    return 'pc';
}
export function isPannelType(object) {
    if (object.qtype == 'pannel') {
        return true;
    }
    return false;
}
export function isArrayType(object) {
    if (!object._objects || object._objects.length === 0) {
        return false;
    }
    if (object.qtype == 'array') {
        return true;
    }
    return false;
}
export function isTextType(object) {
    if (object.qtype == 'i-text') {
        return true;
    }
    return false;
}
export function isStringTextType(object) {
    if (object.qtype == 'string-text') {
        return true;
    }
    return false;
}
export function isSelectObject(opt) {
    if (opt &&
        opt.target &&
        opt.target.type &&
        (opt.target.qtype == 'pannel' || opt.target.qtype == 'array' || opt.target.type == 'i-text')) {
        return true;
    }
    return false;
}
// 제거 요망
export function isTargetType(opt) {
    if (opt &&
        opt.target &&
        opt.target.type &&
        (opt.target.qtype == 'pannel' || opt.target.qtype == 'array' || opt.target.type == 'i-text')) {
        return true;
    }
    return false;
}
export function getSerialNumber(object) {
    if (object) {
        return object.get('qserial');
    }
    return '';
}
export function cutSerial(str, maxWidth) {
    if (str.length > maxWidth) {
        str = str.substring(0, maxWidth);
    }
    return str;
}
export function wrapSerial(str, maxWidth) {
    const newLineStr = '\n';
    let found = false;
    let res = '';
    while (str.length > maxWidth) {
        found = false;
        for (let i = maxWidth - 1; i >= 0; i--) {
            if (testWhite(str.charAt(i))) {
                res = res + [str.slice(0, i), newLineStr].join('');
                str = str.slice(i + 1);
                found = true;
                break;
            }
        }
        if (!found) {
            res += [str.slice(0, maxWidth), newLineStr].join('');
            str = str.slice(maxWidth);
        }
    }
    return res + str;
}
export function testWhite(c) {
    const white = new RegExp(/^\s$/);
    return white.test(c.charAt(0));
}
export function roundToTwo(num) {
    const float = parseFloat(num);
    return float.toFixed(2);
    // return +(Math.round(float + 'e+2') + 'e-2');
}
export function isNull(value) {
    if (value === null) {
        return true;
    }
    if (typeof value === 'string' && value === '') {
        return true;
    }
    if (typeof value === 'object' && value === '') {
        return true;
    }
    if (typeof value === 'undefined') {
        return true;
    }
    return false;
}
// mobile
export function checkMultiTouch(opt) {
    if (opt) {
        if (opt.e.touches && opt.e.touches.length > 1) {
            return true;
        }
        return false;
    }
    return false;
}
// mobile
export function getFingerDistance(e) {
    const diffX = e.touches[0].clientX - e.touches[1].clientX;
    const diffY = e.touches[0].clientY - e.touches[1].clientY;
    return Math.sqrt(diffX * diffX + diffY * diffY); // Pythagorean theorem
}
