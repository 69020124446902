import { defineComponent } from 'vue';
export default defineComponent({
    name: 'NavSubItem',
    props: {
        navItem: {
            type: Object,
            required: true,
        },
    },
    emits: ['click'],
    setup(props, { emit }) {
        const onClick = (e) => {
            emit('click', e, props.navItem.path);
        };
        return {
            onClick,
        };
    },
});
