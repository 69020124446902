import { computed } from 'vue';
import { useStore } from 'vuex';
export const useTheme = () => {
    const store = useStore();
    const themeClass = computed(() => {
        const theme = store.state.appCtx.theme;
        switch (theme) {
            case 'dark':
                return 'dark-theme';
            case 'light':
                return 'light-theme';
            default:
                return 'default-theme';
        }
    });
    return {
        themeClass,
    };
};
