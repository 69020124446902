import { computed, defineComponent, reactive, watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMessageBox } from '@hems/component';
import AddPricingItemPopup from '@hems/container/src/forms/device/settings/_shared/pricing/component/AddPricingItemPopup.vue';
import PricingTableRow from '@hems/container/src/forms/device/settings/_shared/pricing/component/PricingTableRow.vue';
export default defineComponent({
    name: 'PricingTable',
    components: {
        AddPricingItemPopup,
        PricingTableRow,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        modelValue: {
            type: Object,
            required: true,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        maxCount: {
            type: Number,
            default: 0,
        },
        minuteOptionUnit: {
            type: Number,
            default: 1,
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const state = reactive({
            data: props.modelValue ?? [],
        });
        const openPopup = ref(false);
        const allowed = computed(() => (props.modelValue?.length ?? 0) < props.maxCount);
        watch(() => props.modelValue, () => {
            state.data = props.modelValue;
        });
        const messageBox = useMessageBox();
        function togglePopup() {
            if (!allowed.value) {
                messageBox.alert(t('message.up_to_max', { max_count: props.maxCount })).open();
                return;
            }
            openPopup.value = !openPopup.value;
        }
        function closePopup() {
            openPopup.value = false;
        }
        function concatPricingItem(data) {
            const idx = state.data.findIndex((item) => item.time === data.time);
            let pricingItemList;
            if (idx >= 0) {
                pricingItemList = [...state.data];
                pricingItemList[idx] = data;
            }
            else {
                pricingItemList = state.data.concat(data).sort((a, b) => (a.time > b.time ? 1 : -1));
            }
            return pricingItemList;
        }
        function removePricingItemByIndex(index) {
            return state.data.filter((_, idx) => idx !== index);
        }
        function onAdd(data) {
            emit('update:modelValue', concatPricingItem(data));
            closePopup();
        }
        function onRemove(idx) {
            emit('update:modelValue', removePricingItemByIndex(idx));
        }
        return {
            state,
            openPopup,
            togglePopup,
            closePopup,
            onAdd,
            onRemove,
        };
    },
});
