import { defineComponent, ref, watch } from 'vue';
import Select from 'primevue/select';
import InputError from '@hems/component/src/error/InputError.vue';
import { SELECT_OPTION_LIST_EXPANDED_STATUS } from '@hems/util/src/constant';
import { defaultInputErrorInfo } from '@hems/util/src/helper/helper';
import { isGroupSelectOptionListType, isSelectOptionListType } from '@hems/util/src/helper/tsguardHelper';
export default defineComponent({
    name: 'SelectNew',
    components: {
        Select,
        InputError,
    },
    props: {
        modelValue: {
            type: [String, Number, Boolean, null],
            default: null,
        },
        placeholder: {
            type: String,
            default: '',
        },
        optionList: {
            type: Array,
            default: () => [],
        },
        errorInfo: {
            type: Object,
            default: () => defaultInputErrorInfo,
        },
        showDropdownIcon: {
            type: Boolean,
            default: true,
        },
        isGroupSelect: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        overlayClass: {
            type: String,
            default: '',
        },
        dropdownClass: {
            type: String,
            default: '',
        },
    },
    emits: ['change', 'update:modelValue'],
    setup(props, { emit }) {
        const getSelectedOption = () => {
            if (props.isGroupSelect && isGroupSelectOptionListType(props.optionList)) {
                return (props.optionList
                    .map((option) => option.items)
                    .flat()
                    .find((item) => item.value === props.modelValue) ?? null);
            }
            if (isSelectOptionListType(props.optionList)) {
                return props.optionList.find((option) => option.value === props.modelValue) ?? null;
            }
            return null;
        };
        const selectedOption = ref(getSelectedOption());
        const optionListExpandedStatus = ref(SELECT_OPTION_LIST_EXPANDED_STATUS.HIDE);
        const changeOptionListExpandedStatus = (expandedStatus) => {
            optionListExpandedStatus.value = expandedStatus;
        };
        const changeSelectedOption = () => {
            emit('update:modelValue', selectedOption.value?.value);
            emit('change', selectedOption.value);
        };
        watch(() => [props.optionList, props.modelValue], () => {
            selectedOption.value = getSelectedOption();
        });
        return {
            selectedOption,
            optionListExpandedStatus,
            SELECT_OPTION_LIST_EXPANDED_STATUS,
            changeOptionListExpandedStatus,
            changeSelectedOption,
        };
    },
});
