import { defineComponent, reactive } from 'vue';
import { Form } from 'vee-validate';
export default defineComponent({
    name: 'InstallationFormContainer',
    components: {
        Form,
    },
    props: {
        schema: {
            type: Object,
        },
        enableNext: {
            type: Boolean,
            default: true,
        },
        submitBtnText: {
            type: String,
            default: 'Save',
        },
    },
    setup(props, { emit }) {
        const state = reactive({});
        function onSave() {
            emit('save');
        }
        function onCancel() {
            emit('cancel');
        }
        return {
            state,
            onSave,
            onCancel,
        };
    },
});
