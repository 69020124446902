import { defineComponent } from 'vue';
import BasicSettingsACSystem from '@hems/container/src/forms/device/settings/_shared/basic/BasicSettingsACSystem.vue';
export default defineComponent({
    name: 'BasicSettingsSmartModuleACSystemContainer',
    components: {
        BasicSettingsACSystem,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        hideEdit: {
            type: Boolean,
            default: false,
        },
        isConnection: {
            type: Boolean,
            required: true,
        },
        productModelType: {
            type: String,
            required: true,
        },
        timeZone: {
            type: String,
            required: true,
        },
        changedValueSet: {
            type: Object,
            default: () => new Set(),
        },
    },
    emits: ['goToList', 'save', 'generate', 'cancel', 'edit', 'changeWebHMIPassword'],
    setup(props, { emit }) {
        function onSave(params, multiTabName, settingTarget, callback) {
            emit('save', params, multiTabName, settingTarget, callback);
        }
        function onGenerate(params) {
            emit('generate', params);
        }
        function onEdit(copyData) {
            emit('edit', copyData);
        }
        function onChangeWebHMIPassword(params) {
            emit('changeWebHMIPassword', params);
        }
        return {
            onSave,
            onGenerate,
            onEdit,
            onChangeWebHMIPassword,
        };
    },
});
