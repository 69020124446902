import { defineComponent } from 'vue';
import EngineerSettingsPvModuleFoxSharedContainer from '../../../_shared/engineer/EngineerSettingsPvModuleFoxSharedContainer.vue';
export default defineComponent({
    name: 'EngineerSettingsPvModuleFoxContainer',
    components: {
        EngineerSettingsPvModuleFoxSharedContainer,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        productModelNm: {
            type: String,
            required: true,
        },
        cntryCd: {
            type: String,
            required: true,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        hideEdit: {
            type: Boolean,
            default: false,
        },
        isConnection: Boolean,
        isHcore: {
            type: Boolean,
            default: false,
        },
        isQcore: {
            type: Boolean,
            default: false,
        },
        isAcCoupled: {
            type: Boolean,
            default: false,
        },
        isFcas: {
            type: Boolean,
            default: false,
        },
        siteId: {
            type: Number,
            default: null,
        },
    },
    emits: ['goToList', 'save', 'cancel', 'edit'],
    async setup(props, { emit }) {
        function onSave(params, callback) {
            emit('save', params, callback);
        }
        function onEdit(copyData) {
            emit('edit', copyData);
        }
        return {
            onSave,
            onEdit,
        };
    },
});
