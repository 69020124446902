class ClickableRenderer {
    el;
    constructor(props) {
        const { $ } = window;
        const el = $(`<a class="text">${props.value}</a>`);
        this.el = el.get(0);
    }
    getElement() {
        return this.el;
    }
}
export default ClickableRenderer;
