import { defineComponent, ref, computed, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import { ContentModal, ButtonNew, InputTitle, TextInput } from '@hems/component';
import { EnergyManagementService } from '@hems/service';
import { useToast } from '@hems/util';
import { BUTTON_NEW_TYPE } from '@hems/util/src/constant';
import { NAME_INPUT_DEBOUNCE_WAIT_TIME, VALIDATE_SCHEDULE_PRESET_NAME } from '@hems/util/src/constant/energyManagement';
import { defaultInputErrorInfo } from '@hems/util/src/helper/helper';
const PRESET_NAME_MAX_LENGTH = 25;
export default defineComponent({
    name: 'SaveAsPresetTypeModal',
    components: {
        ContentModal,
        InputTitle,
        TextInput,
        ButtonNew,
    },
    props: {
        scheduleName: {
            type: String,
            default: '',
        },
        siteId: {
            type: [Number, null],
            default: null,
        },
    },
    emits: ['save', 'cancel'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const energyManagementService = new EnergyManagementService(window.axiosInstance.axios);
        const { openToastMessage } = useToast();
        const presetName = ref(props.scheduleName);
        const isScheduleNameDuplicateChecked = ref(false);
        const presetNameErrorInfo = ref({ isError: false, errorMessage: '' });
        const isSaveButtonDisabled = computed(() => !isScheduleNameDuplicateChecked.value || !presetName.value || presetNameErrorInfo.value.isError);
        const checkIsDuplicate = async (targetPresetName) => {
            try {
                if (isNil(props.siteId)) {
                    return;
                }
                const validateResult = await energyManagementService.validatePresetName(props.siteId, {
                    value: targetPresetName,
                });
                if (presetName.value !== targetPresetName)
                    return;
                const isDuplicate = validateResult === VALIDATE_SCHEDULE_PRESET_NAME.DUPLICATE;
                if (!isDuplicate) {
                    presetNameErrorInfo.value = { ...defaultInputErrorInfo };
                    isScheduleNameDuplicateChecked.value = true;
                    return;
                }
                presetNameErrorInfo.value = {
                    isError: true,
                    errorMessage: t('message.name_already_exists'),
                };
            }
            catch (e) {
                console.error(e);
                openToastMessage(t('message.unable_connect_server'));
                return;
            }
        };
        const debouncedCheckIsDuplicate = debounce(checkIsDuplicate, NAME_INPUT_DEBOUNCE_WAIT_TIME);
        const checkIsValidate = async (presetName) => {
            isScheduleNameDuplicateChecked.value = false;
            if (presetName.length > PRESET_NAME_MAX_LENGTH) {
                presetNameErrorInfo.value = {
                    isError: true,
                    errorMessage: t('message.maximum_characters_length', { length: PRESET_NAME_MAX_LENGTH }),
                };
                return;
            }
            debouncedCheckIsDuplicate(presetName);
        };
        const onSave = () => {
            emit('save', presetName.value);
        };
        onBeforeMount(async () => {
            await checkIsDuplicate(props.scheduleName);
            isScheduleNameDuplicateChecked.value = true;
        });
        return {
            presetName,
            isSaveButtonDisabled,
            presetNameErrorInfo,
            PRESET_NAME_MAX_LENGTH,
            BUTTON_NEW_TYPE,
            checkIsValidate,
            onSave,
        };
    },
});
