export function getBasicSettingGen2Config(t) {
    return [
        {
            groupTitle: t('device.setting_info'),
            children: [
                {
                    title: t('device.pv_capacity_1'),
                    code: 'pv_max_pwr1',
                    type: 'number',
                },
                {
                    title: t('device.pv_capacity_2'),
                    code: 'pv_max_pwr2',
                    type: 'number',
                },
                {
                    title: t('control.feedin'),
                    code: 'feed_in_limit',
                    type: 'number',
                },
                {
                    title: t('control.3rd_con'),
                    code: 'pem_mode',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                },
                {
                    title: t('device.meter_type'),
                    code: 'smeterd0id',
                    type: 'radio',
                    options: [
                        { text: t('common.none'), value: 0 },
                        { text: t('code.smtrmodlcd.4'), value: 4 },
                        { text: t('code.smtrmodlcd.5'), value: 5 },
                    ],
                },
                {
                    title: t('control.inverter_limit'),
                    code: 'max_inverter_pw_cd',
                    type: 'number',
                },
                {
                    title: t('control.dynamic_optimizer'),
                    code: 'basicmode_cd',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: '0' },
                        { text: t('common.disable'), value: '1' },
                    ],
                },
                {
                    title: t('control.energy_backup'),
                    code: 'backup_soc',
                    type: 'number',
                },
            ],
        },
    ];
}
