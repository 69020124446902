export const GRAPH_TYPE = {
    CATEGORY: 'CATEGORY',
    INTEGRATED: 'INTEGRATED',
};
export const GRAPH_TERM_UNIT = {
    MINUTE: 'TH',
    HOUR: 'TD',
    MONTH: 'TM',
};
export const GRAPH_INTERVAL = {
    HOURLY: 'hourly',
    DAILY: 'daily',
    MONTHLY: 'monthly',
    YEARLY: 'yearly',
};
export const GRAPH_GROUP_CODE = {
    POWER: 'power',
    ENERGY: 'energy',
    INVERTER: 'inverter',
    METER: 'meter',
    GRID: 'grid',
    BATTERY: 'battery',
    PV: 'pv',
    AC_COMBINER: 'ACCombiner',
    ALL: 'all',
};
export const GRAPH_Y_AXIS_UNIT = {
    WATT: 'W',
    WATT_HOUR: 'Wh',
    KILOWATT_HOUR: 'kWh',
    VOLTAGE: 'V',
    AMPERE: 'A',
    HERTZ: 'Hz',
    CELSIUS: '℃',
    PERCENTAGE: '%',
    /** 유효 전력 단위 */
    VOLTAGE_AMPERE: 'VA',
    /** 무효 전력 단위 */
    VOLTAGE_AMPERE_REACTIVE: 'VAR',
    FACTOR: 'factor',
};
export const GRAPH_SERIES_TYPE = {
    SPLINE: 'spline',
    LINE: 'line',
    COLUMN: 'column',
    PIE: 'pie',
};
