import { PROVIDE_INJECT_KEYS } from '@hems/util/src/constant/provideInjection';
export class Vue3FacebookOAuth2 {
    _email;
    _name;
    constructor({ appId, version }) {
        window.fbAsyncInit = function () {
            FB.init({
                appId,
                cookie: true,
                xfbml: true,
                version: version ?? 'v12.0',
            });
            FB.AppEvents.logPageView();
        };
        // TODO: 타입지정 개선 확인 예정
        (function (d, s, id) {
            const fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            const js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            fjs.parentNode?.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
    }
    isLogin() {
        return new Promise((resolve) => {
            FB.getLoginStatus(async (res) => {
                if (res.status === 'connected') {
                    resolve(true);
                    return;
                }
                resolve(false);
            });
        });
    }
    login() {
        return new Promise((resolve) => {
            FB.getLoginStatus(async (res) => {
                if (res.status === 'connected') {
                    this._loadUserInfo().then(() => {
                        resolve(true);
                    });
                    return;
                }
                FB.login(() => {
                    this._loadUserInfo().then(() => {
                        resolve(true);
                    });
                }, { scope: 'public_profile,email' });
            });
        });
    }
    _loadUserInfo() {
        const self = this;
        return new Promise((resolve) => {
            FB.api('/me', { fields: 'email,name' }, (profile) => {
                const { email, name } = profile;
                self._email = email;
                self._name = name;
                resolve(true);
            });
        });
    }
    logout() {
        this._email = undefined;
        this._name = undefined;
        FB.logout();
    }
    getEmail() {
        return this._email;
    }
    getName() {
        return this._name;
    }
    getAccessToken() {
        return new Promise((resolve) => {
            FB.getLoginStatus(async (res) => {
                if (res.status === 'connected') {
                    resolve(res.authResponse.accessToken);
                }
            });
        });
    }
}
const Vue3FacebookOAuth2Plugin = {
    install: (app, options) => {
        const instance = new Vue3FacebookOAuth2(options);
        app.config.globalProperties.$facebookOAuth = instance;
        app.provide(PROVIDE_INJECT_KEYS.FACEBOOK_OAUTH, instance);
    },
};
export default Vue3FacebookOAuth2Plugin;
