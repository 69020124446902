import { defineComponent, reactive, watch, computed, onMounted } from 'vue';
import moment from 'moment-timezone';
import { Image2 } from '@hems/component';
import { WeatherService, UserReportService } from '@hems/service';
import { Helper, DateHelper, useLanguage } from '@hems/util';
import { ENV_LOCALE, ONE_MILE_TO_KILOMETER } from '@hems/util/src/constant';
import { now } from '@hems/util/src/helper/dateHelper';
import { getWeatherIcon } from './weatherIconConfig';
export default defineComponent({
    name: 'WeatherContainer',
    components: {
        Image2,
    },
    props: {
        baseDeviceInfo: {
            type: Object,
            required: true,
        },
        isWeather: {
            type: Boolean,
            default: true,
        },
    },
    setup(props) {
        const userReportService = new UserReportService(window.axiosInstance.axios);
        const weatherService = new WeatherService(window.axiosInstance.axios);
        const iconList = getWeatherIcon();
        const state = reactive({
            dateString: '',
            weatherInfo: null,
            temperature: '',
            humidity: '',
            windspeed: '',
            weatherIcon: '',
            serverTime: now(),
        });
        const { languageCode } = useLanguage();
        const baseDevice = computed(() => props.baseDeviceInfo);
        watch(() => languageCode.value, () => {
            getDateString(state.serverTime);
            getFormattedWeatherInfo();
        });
        const getDateString = (date) => {
            state.dateString = DateHelper.getDateFormatter(languageCode.value, {
                year: 'numeric',
                weekday: 'long',
                month: 'long',
                day: '2-digit',
            })
                .format(date)
                .toString();
        };
        const convertCelsiusToFahrenheit = (value) => {
            return (value - 32) * (5 / 9);
        };
        const convertKilometerToMile = (value) => {
            return value / ONE_MILE_TO_KILOMETER;
        };
        const getNumberFormatterWithUnit = (options = {}) => {
            return Helper.getNumberFormatter(languageCode.value, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                style: 'unit',
                ...options,
            });
        };
        const getFormattedWeatherInfo = () => {
            if (state.weatherInfo) {
                if (!isNaN(state.weatherInfo.apparentTemperature)) {
                    if (process.env.VUE_APP_LOCALE === ENV_LOCALE.US) {
                        state.temperature = getNumberFormatterWithUnit({ unit: 'fahrenheit' }).format(state.weatherInfo.apparentTemperature);
                    }
                    else {
                        state.temperature = getNumberFormatterWithUnit({ unit: 'celsius' }).format(convertCelsiusToFahrenheit(state.weatherInfo.apparentTemperature));
                    }
                }
                if (!isNaN(state.weatherInfo.humidity)) {
                    state.humidity = getNumberFormatterWithUnit({ unit: 'percent' }).format(state.weatherInfo.humidity * 100);
                }
                if (!isNaN(state.weatherInfo.windspeed)) {
                    if (process.env.VUE_APP_LOCALE === ENV_LOCALE.US) {
                        state.windspeed = getNumberFormatterWithUnit({ unit: 'mile-per-hour' }).format(convertKilometerToMile(state.weatherInfo.windspeed));
                    }
                    else {
                        state.windspeed = getNumberFormatterWithUnit({ unit: 'kilometer-per-hour' }).format(state.weatherInfo.windspeed);
                    }
                }
            }
        };
        const getServerTime = async () => {
            try {
                const serverTime = await userReportService.getServerTime(baseDevice.value?.timezone_id ?? '');
                state.serverTime = moment(serverTime, 'YYYYMMDDHHmmss').toDate();
            }
            catch (e) {
                console.error(e);
                state.serverTime = moment()
                    .tz(baseDevice.value?.timezone_id ?? '')
                    .toDate();
            }
            finally {
                getDateString(state.serverTime);
            }
        };
        const getWeatherData = async () => {
            try {
                const weatherInfo = await weatherService.getCurrentWeather(String(baseDevice.value?.site_id ?? ''));
                state.weatherInfo = weatherInfo;
                getFormattedWeatherInfo();
                if (weatherInfo.iconCode) {
                    state.weatherIcon = !Helper.isNull(iconList[weatherInfo.iconCode])
                        ? `weather_new/${iconList[weatherInfo.iconCode]}`
                        : '';
                }
            }
            catch (e) {
                console.error(e);
            }
        };
        onMounted(async () => {
            await getServerTime();
            if (props.isWeather) {
                await getWeatherData();
            }
        });
        return {
            state,
            baseDevice,
        };
    },
});
