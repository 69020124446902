import { defineComponent } from 'vue';
import { Helper } from '@hems/util';
import PopupWrapper from './PopupWrapper.vue';
export default defineComponent({
    name: 'TargetEssPopup',
    components: {
        PopupWrapper,
    },
    props: {
        okText: {
            type: String,
            default: 'Add Target',
        },
    },
    emits: ['confirm', 'close'],
    setup(props, { emit }) {
        const uuid = Helper.getUUID();
        const close = () => {
            emit('close');
        };
        const confirm = () => {
            emit('confirm');
        };
        return {
            uuid,
            confirm,
            close,
        };
    },
});
