import { defineComponent, reactive, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import _ from 'lodash';
import { LabelInput, Searchbox } from '@hems/component';
import { Helper } from '@hems/util';
export default defineComponent({
    name: 'CustomerSearchBox',
    components: {
        Searchbox,
        LabelInput,
    },
    props: {
        condition: {
            type: Object,
            default: () => ({
                searchFor: '',
            }),
        },
    },
    emits: ['search'],
    async setup(props, { emit }) {
        const { t } = useI18n();
        const state = reactive({
            searchCondition: { ...props.condition, searchFor: props.condition.searchFor ?? '' },
        });
        function onSearch() {
            state.searchCondition.searchFor = state.searchCondition.searchFor?.trim() ?? '';
            emit('search', _.pickBy(state.searchCondition, (v) => !Helper.isNull(v)));
        }
        return {
            state,
            onSearch,
            placeholder: computed(() => t('message.enter_search_word').concat(` (${[
                t('account.user_name'),
                t('account.id'),
                t('common.site_id'),
                t('device.serial_no'),
                t('common.phone_number'),
                t('account.email'),
            ].join(', ')})`)),
        };
    },
});
