import { defineComponent, reactive, ref, computed, watch } from 'vue';
class PromptPopup {
    constructor() {
        this.init();
    }
    init() {
        return this;
    }
    getInstance(resolve, reject) {
        return {
            on: true,
            resolve,
            reject,
        };
    }
    open() {
        const self = this;
        this.init();
        return new Promise((resolve, reject) => {
            _promptPopup.value.on = false;
            setTimeout(() => {
                _promptPopup.value = self.getInstance(resolve, reject);
            }, 250);
        });
    }
}
const _promptPopup = ref({});
const btnCloseEl = ref(null);
const close = () => {
    _promptPopup.value.on = false;
};
export const usePromptPopup = () => new PromptPopup();
export default defineComponent({
    name: 'PromptPopup',
    props: {
        title: String,
        showClose: {
            type: Boolean,
            default: true,
        },
        confirmButtonText: {
            type: String,
            default: 'Confirm',
        },
        closeButtonText: {
            type: String,
            default: 'Close',
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            promptPopup: computed(() => _promptPopup.value),
        });
        const onConfirm = () => {
            const { resolve } = state.promptPopup;
            if (resolve)
                resolve(true);
            close();
        };
        const onClose = () => {
            const { resolve } = state.promptPopup;
            if (resolve)
                resolve(false);
            close();
        };
        watch(() => _promptPopup.value.on, (on) => {
            if (on) {
                setTimeout(() => {
                    btnCloseEl.value?.focus();
                }, 250);
            }
        });
        return {
            state,
            onConfirm,
            onClose,
            btnCloseEl,
        };
    },
});
