import { defineComponent } from 'vue';
import PricingSettingsPvModuleGen3SmartModuleGen2 from '@hems/container/src/forms/device/settings/_shared/pricing/PricingSettingsPvModuleGen3SmartModuleGen2.vue';
export default defineComponent({
    name: 'PricingSettingsPvModuleGen3Container',
    components: {
        PricingSettingsPvModuleGen3SmartModuleGen2,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        editable: {
            type: Boolean,
            required: true,
        },
        hideEdit: {
            type: Boolean,
            default: false,
        },
        confirmMessage: {
            type: String,
            default: 'message.save_confirm',
        },
        isConnection: {
            type: Boolean,
            required: true,
        },
        productModelNm: {
            type: String,
            default: '',
        },
        emsVersion: {
            type: String,
            default: '',
        },
    },
    emits: ['save', 'goToList', 'cancel', 'edit'],
    setup(_props, { emit }) {
        async function onSave(param) {
            emit('save', param);
        }
        function onEdit(copyData) {
            emit('edit', copyData);
        }
        return {
            onSave,
            onEdit,
        };
    },
});
