import { computed, defineComponent, reactive } from 'vue';
import { batteryType } from '@hems/component/src/battery/batteryConfig';
import { Helper } from '@hems/util';
export default defineComponent({
    name: 'BatterySOC',
    props: {
        type: {
            type: String,
            default: 'wait',
        },
        name: String,
        soc: Number,
    },
    setup(props) {
        const state = reactive({
            soc: computed(() => Helper.formatNumber(props.soc || 0, 1)),
        });
        return {
            state,
            batteryType,
        };
    },
});
