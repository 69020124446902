import { defineComponent, computed } from 'vue';
import { Image2, InformationImage } from '@hems/component';
export default defineComponent({
    name: 'EnergyValueBox',
    components: {
        Image2,
        InformationImage,
    },
    props: {
        imagePath: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        valueType: {
            type: String,
            required: true,
        },
        data: {
            type: Object,
            default: null,
        },
        disconnect: {
            type: Boolean,
            default: false,
        },
        detailMessageInfo: {
            type: Object,
            default: () => ({ isInfo: false, message: '' }),
        },
    },
    emits: ['reloadHomeEnergyData'],
    setup(props) {
        const isHomeAppliance = computed(() => props.data?.status.isHomeAppliance ?? false);
        const numberText = computed(() => `${props.data?.num} ${props.data?.unit}`);
        return {
            isHomeAppliance,
            numberText,
        };
    },
});
