import axios from 'axios';
class AxiosCreator {
    isInit = false;
    _axios;
    _axios_request_interceptor_auth = -1;
    constructor(config = {}) {
        this._axios = axios.create(config);
        this._axios.interceptors.response.use((response) => response, (error) => {
            if (error.response.status === 401) {
                location.href = '/redirect/Unauthorized';
            }
            return Promise.reject(error);
        });
    }
    get axios() {
        if (!this.isInit)
            console.warn('The access token is not set.');
        return this._axios;
    }
    setBaseUrl(baseUrl) {
        this._axios.defaults.baseURL = baseUrl;
    }
    setAccessToken(accessToken) {
        if (!accessToken)
            return;
        if (this._axios_request_interceptor_auth >= 0)
            this.clearAccessToken();
        this._axios_request_interceptor_auth = this._axios.interceptors.request.use((config) => {
            config.headers.Authorization = `Bearer ${accessToken}`;
            return config;
        }, (error) => Promise.reject(error));
        this.isInit = true;
    }
    clearAccessToken() {
        this._axios.interceptors.request.eject(this._axios_request_interceptor_auth);
    }
}
const defaultConfig = {
// baseURL: process.env.BASE_URL,
// timeout: 60 * 1000, // Timeout
// withCredentials: true, // Check cross-site Access-Control
};
const AxiosPlugin = {
    install(app, config = {}) {
        const instance = new AxiosCreator({ ...defaultConfig, ...config });
        window.axiosInstance = instance;
        app.config.globalProperties.$axios = instance;
        app.provide('axios', instance);
    },
};
export default AxiosPlugin;
