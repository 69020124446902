import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
export default defineComponent({
    name: 'SinglePieChart',
    props: {
        initValue: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 0,
        },
        stop: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Object,
            default: () => ({
                fillColor: '#00adef',
                baseColor: '#e9ecef',
                fillWidth: 48,
                baseWidth: 36,
                fontSize: '80px',
                fontFace: 'Actual',
                fontColor: '#222222',
                textAlign: 'right',
                fontWeight: 'bold',
                lineCap: 'round',
            }),
        },
    },
    setup(props) {
        const chart = ref(null);
        const state = reactive({
            curAmount: props.initValue,
            curDegrees: props.initValue / 100,
            stop: computed(() => props.stop),
        });
        let halfWidth = 0;
        let halfHeight = 0;
        let ctx = null;
        const settings = reactive({
            fillAmountText: props.initValue,
            fillAmount: props.max === 0 ? 0 : (props.initValue / props.max) * 100,
            baseAmount: 60,
            ...props.options,
        });
        onMounted(() => {
            if (!chart.value)
                return;
            chart.value.width = chart.value.offsetWidth;
            chart.value.height = chart.value.offsetHeight;
            halfWidth = chart.value.width / 2;
            halfHeight = chart.value.height / 2;
            ctx = chart.value.getContext('2d');
            run();
        });
        let reqId = 0;
        let count = 0;
        function run() {
            count++;
            if (count % 60 === 0) {
                if ((props.max === 0 || (count / 60 > props.max && props.max > 0)) && state.stop) {
                    stop();
                    return;
                }
                update();
                draw();
            }
            reqId = requestAnimationFrame(run);
        }
        function stop() {
            if (reqId)
                cancelAnimationFrame(reqId);
        }
        function update() {
            state.curAmount += 1;
            state.curDegrees = (state.curAmount / settings.baseAmount) * 360;
        }
        function draw() {
            if (!chart.value || !ctx)
                return;
            ctx.clearRect(0, 0, chart.value.width, chart.value.height);
            drawArc(0, 360, settings.baseColor, settings.baseWidth, (settings.fillWidth - settings.baseWidth) / 2);
            drawArc(-90, (state.curDegrees % 360) - 90, settings.fillColor, settings.fillWidth);
            drawText(state.curAmount);
        }
        function drawArc(startDegrees, endDegrees, lineColor, fillWidth, lineGap = 0) {
            if (!chart.value || !ctx)
                return;
            ctx.lineWidth = fillWidth;
            ctx.strokeStyle = lineColor;
            ctx.lineCap = 'round';
            ctx.beginPath();
            ctx.arc(halfWidth, halfHeight, halfWidth - fillWidth / 2 - lineGap, degreesToRadians(startDegrees), degreesToRadians(endDegrees));
            ctx.stroke();
        }
        function drawText(text) {
            if (!chart.value || !ctx)
                return;
            ctx.fillStyle = settings.fontColor;
            ctx.font = `${settings.fontWeight} ${settings.fontSize} ${settings.fontFace}`;
            ctx.textAlign = settings.textAlign;
            ctx.fillText(`${text}`, halfWidth + 10, halfHeight + parseInt(settings.fontSize) / 3);
        }
        function degreesToRadians(degrees) {
            return (degrees * Math.PI) / 180;
        }
        return {
            chart,
        };
    },
});
