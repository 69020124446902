import { defineComponent, reactive, ref, watch } from 'vue';
import Image from '../image/Image.vue';
export default defineComponent({
    name: 'NavItem',
    components: {
        Image,
    },
    props: {
        src: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        description: String,
    },
    setup(props) {
        const state = reactive({
            hover: false,
            src: props.src,
        });
        const navItemCont = ref(null);
        watch(() => state.hover, (hover) => {
            state.src = hover ? props.src : `${props.src}_over`;
        });
        return {
            state,
            navItemCont,
        };
    },
});
