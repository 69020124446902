import { defineComponent, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useModelWrapper } from '@hems/util';
import { SUPPORT_GUIDE_TYPE } from '@hems/util/src/constant/supportGuide';
export default defineComponent({
    name: 'GuideTabList',
    props: {
        modelValue: {
            type: String,
            required: true,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const state = reactive({
            tabName: useModelWrapper(props, emit),
        });
        const guideTabItems = [
            { guideType: SUPPORT_GUIDE_TYPE.ALL, tabNameText: t('common.all') },
            {
                guideType: SUPPORT_GUIDE_TYPE.HARDWARE_GUIDE,
                tabNameText: t('common.hardware_guide'),
            },
            {
                guideType: SUPPORT_GUIDE_TYPE.SOFTWARE_GUIDE,
                tabNameText: t('common.software_guide'),
            },
            {
                guideType: SUPPORT_GUIDE_TYPE.ERROR_GUIDE,
                tabNameText: t('common.error_guide'),
            },
        ];
        const switchTab = (tabName) => {
            state.tabName = tabName;
        };
        return { state, SUPPORT_GUIDE_TYPE, guideTabItems, switchTab };
    },
});
