import { defineComponent } from 'vue';
import { Image2 } from '@hems/component';
import { ENERGY_FLOW_IMAGE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'EnergyFlowProductLayer',
    components: { Image2 },
    setup() {
        return {
            ENERGY_FLOW_IMAGE,
        };
    },
});
