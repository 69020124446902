import { defineComponent, ref, watch, computed, onMounted } from 'vue';
import { InputTitle, IconButton, List } from '@hems/component';
import EmptyListArea from '@hems/container/src/energyManagement/EmptyListArea.vue';
import { useEnergyManagement, useScheduleDate } from '@hems/util';
import { LIST_ITEM_MODE, LIST_ITEM_SIZE } from '@hems/util/src/constant';
import { MAX_COUNT_SCHEDULE } from '@hems/util/src/constant/energyManagement';
import { createScheduleEventByOperation } from '@hems/util/src/helper/energyManagement/energyManagementHelper';
import { isEmptyArray } from '@hems/util/src/helper/helper';
export default defineComponent({
    name: 'ScheduleList',
    components: {
        InputTitle,
        IconButton,
        List,
        EmptyListArea,
    },
    props: {
        isTou: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['onDetail', 'moveToScheduleDelete', 'addNewSchedule', 'onEdit'],
    setup(props, { emit }) {
        const { scheduleInfoList, updateWeeklySchedulerEventList, scheduleDateList } = useEnergyManagement();
        const { getOnStatus, getScheduleDateRangeText, hasAvailableSlot, getDateRangeDisabledDateList } = useScheduleDate();
        const convertScheduleToListItem = (list) => {
            return list.map((schedule) => ({
                id: schedule.name,
                onStatus: getOnStatus(schedule.startDate, schedule.endDate),
                activeStatus: false,
                mode: LIST_ITEM_MODE.DEFAULT,
                size: LIST_ITEM_SIZE.SMALL,
                mainContents: schedule.name,
                description: getScheduleDateRangeText(schedule.startDate, schedule.endDate),
                checkStatus: false,
                badgeStatus: null,
                iconImageInfo: schedule.isSaveRequired ? { src: 'icons/ic_in_progress', extension: 'svg' } : null,
            }));
        };
        const scheduleItemList = ref(convertScheduleToListItem(scheduleInfoList.value));
        const dateRangeDisabledDateList = computed(() => getDateRangeDisabledDateList(scheduleDateList.value));
        const canCreateSchedule = computed(() => {
            return scheduleItemList.value.length < MAX_COUNT_SCHEDULE && hasAvailableSlot(dateRangeDisabledDateList.value);
        });
        const onDetailSchedule = (scheduleName) => {
            scheduleItemList.value = scheduleItemList.value.map((schedule) => ({
                ...schedule,
                activeStatus: schedule.id === scheduleName,
            }));
            const currentSchedule = scheduleInfoList.value.find((schedule) => schedule.name === scheduleName) ?? null;
            emit('onDetail', scheduleName);
            if (currentSchedule) {
                updateWeeklySchedulerEventList(createScheduleEventByOperation(currentSchedule.operations));
            }
        };
        const onEditSchedule = (scheduleName) => {
            const targetSchedule = scheduleInfoList.value.find((schedule) => schedule.name === scheduleName);
            emit('onEdit', targetSchedule);
        };
        const updateWeeklySchedulerEventListByOnSchedule = () => {
            if (isEmptyArray(scheduleInfoList.value) || !props.isTou)
                return;
            const onSchedule = scheduleInfoList.value.find((schedule) => getOnStatus(schedule.startDate, schedule.endDate));
            if (onSchedule) {
                onDetailSchedule(onSchedule.name);
            }
            else {
                onDetailSchedule(scheduleInfoList.value[0].name);
            }
        };
        watch(() => scheduleInfoList.value, () => {
            scheduleItemList.value = convertScheduleToListItem(scheduleInfoList.value);
        });
        watch(() => props.isTou, () => {
            if (props.isTou) {
                updateWeeklySchedulerEventListByOnSchedule();
            }
            else {
                updateWeeklySchedulerEventList([]);
            }
        });
        onMounted(() => {
            updateWeeklySchedulerEventListByOnSchedule();
        });
        return {
            scheduleItemList,
            scheduleInfoList,
            canCreateSchedule,
            isEmptyArray,
            onDetailSchedule,
            onEditSchedule,
        };
    },
});
