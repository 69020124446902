import { computed, defineComponent, reactive } from 'vue';
import { useStore } from 'vuex';
import { Searchbox, HiddenSearchbox, Calendar, LabelInput } from '@hems/component';
import { Helper, DateHelper, useLocale } from '@hems/util';
import { DATE_UNIT, DAY_RANGE_31 } from '@hems/util/src/constant';
export default defineComponent({
    name: 'GroupSettingSearchBox',
    components: {
        VCalendarRange: Calendar.VCalendarRange,
        LabelInput,
        HiddenSearchbox,
        Searchbox,
    },
    props: {
        condition: {
            type: Object,
            default: () => ({
                date: {
                    start: DateHelper.sixDaysAgo(),
                    end: DateHelper.endDate(),
                },
                searchFor: null,
            }),
        },
    },
    emits: ['search'],
    setup(props, { emit }) {
        const store = useStore();
        const langCd = computed(() => store.state.appCtx.langCd);
        const state = reactive({
            searchCondition: getDefaultCondition(props.condition),
        });
        const { locale } = useLocale();
        function getDefaultCondition(condition) {
            return {
                ...condition,
                searchFor: condition.searchFor ?? '',
                deviceId: condition.deviceId ?? '',
            };
        }
        const validateRangeDate = (value) => {
            const dateCount = DateHelper.getDateDifference(value, DATE_UNIT.DAYS);
            if (dateCount > DAY_RANGE_31) {
                return false;
            }
            return true;
        };
        const onClickSearch = () => {
            state.searchCondition.searchFor = state.searchCondition.searchFor ? state.searchCondition.searchFor?.trim() : '';
            state.searchCondition.deviceId = state.searchCondition.deviceId ? state.searchCondition.deviceId?.trim() : '';
            emit('search', { ...state.searchCondition });
        };
        return {
            state,
            langCd,
            Helper,
            validateRangeDate,
            onClickSearch,
            locale,
            DateHelper,
        };
    },
});
