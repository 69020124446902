/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
export const GUIDE_TYPE = {
    GUIDE: 'guide',
    ERROR_CODE: 'errorcode',
};
export const SUPPORT_GUIDE_TYPE = {
    ALL: 'all',
    HARDWARE_GUIDE: 'hardware_guide',
    SOFTWARE_GUIDE: 'software_guide',
    ERROR_GUIDE: 'error_guide',
};
export const SUPPORT_GUIDE_CATEGORY_CODE = {
    QUICK_GUIDE: '1',
    INSTALLATION_MANUAL: '2',
    SPEC_SHEET: '3',
    USER_MANUAL: '4',
    INSTALLER_APP_GUIDE: '5',
    INSTALLER_WEB_GUIDE: '6',
};
export const SUPPORT_GUIDE_FILTER_TYPE = {
    SOLAR: 'Solar',
    BATTERY: 'Battery',
    MICRO_INVERTER: 'Microinverter',
    BOX: 'Box',
};
export const ERROR_DETAIL_POPUP_MODE = {
    DEVICE: 'device',
    GUIDE: 'guide',
};
