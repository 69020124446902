import { defineComponent, computed, watch, ref } from 'vue';
import { GraphChart, InformationImage } from '@hems/component';
import { getTodayProductionChartOptions } from '@hems/container/src/energyflow/value/energyIndendenceChartOptions';
import { createChartData, getFormattedNumberValue } from '@hems/container/src/energyflow/value/EnergyValueFunctions';
import { useLocale } from '@hems/util';
import { ENERGY_VALUE_IMAGE } from '@hems/util/src/constant';
import { getBrowserTimezone } from '@hems/util/src/helper/dateHelper';
export default defineComponent({
    name: 'TodayProduction',
    components: {
        GraphChart,
        InformationImage,
    },
    props: {
        reportData: {
            type: Object,
            required: true,
        },
        showChart: {
            type: Boolean,
            default: false,
        },
        isConnectThirdParty: {
            type: Boolean,
            default: false,
        },
        timezone: {
            type: String,
            default: getBrowserTimezone(),
        },
    },
    setup(props) {
        const { intlFormatLocale } = useLocale();
        const infoImageSize = { width: '16', height: '16' };
        const chartData = ref([]);
        const todayProduction = computed(() => getFormattedNumberValue(props.reportData?.gen_kwh.origin ?? 0, intlFormatLocale.value));
        const options = computed(() => getTodayProductionChartOptions({ locale: intlFormatLocale.value, data: chartData.value }));
        watch([() => props.showChart, () => props.reportData], () => {
            if (props.reportData) {
                chartData.value = createChartData(todayProduction.value.ori, props.reportData.gen_data, props.reportData.colec_time, props.timezone);
            }
        });
        return {
            todayProduction,
            options,
            ENERGY_VALUE_IMAGE,
            infoImageSize,
        };
    },
});
