import { defineComponent } from 'vue';
import { Image } from '@hems/component';
export default defineComponent({
    name: 'Header',
    components: {
        Image,
    },
    emits: ['home'],
    setup(props, { emit }) {
        const goHome = (e) => {
            e.preventDefault();
            emit('home');
        };
        return { goHome };
    },
});
