export class GoogleMapApi {
    apiKey;
    _lat;
    _lng;
    _address = '';
    _countryCode = '';
    _countryName = '';
    constructor(options) {
        const { apiKey } = options;
        this.apiKey = apiKey;
    }
    get lat() {
        return this._lat;
    }
    get lng() {
        return this._lng;
    }
    get address() {
        return this._address;
    }
    get countryCode() {
        return this._countryCode;
    }
    get countryName() {
        return this._countryName;
    }
    getLocatioinInfo(lat, lng, language = 'en') {
        return new Promise((resolve, reject) => {
            fetch(`https://maps.googleapis.com/maps/api/geocode/json?language=${language}&latlng=${lat},${lng}&key=${this.apiKey}`)
                .then((response) => {
                response
                    .json()
                    .then((addressInfo) => {
                    for (let i = 0; i < addressInfo.results.length; i++) {
                        const address_component = addressInfo.results[i].address_components.find((item) => item.types.includes('country'));
                        if (!address_component)
                            continue;
                        resolve({
                            lat,
                            lng,
                            address: addressInfo.results[i].formatted_address,
                            cntryCd: address_component?.short_name,
                            cntryNm: address_component?.long_name,
                        });
                        return;
                    }
                })
                    .catch((e) => {
                    console.error(e);
                    return reject();
                });
            })
                .catch((e) => {
                console.error(e);
                return reject();
            });
        });
    }
    getCurrentLocation() {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    resolve({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                }, (e) => {
                    reject(e);
                });
            }
        });
    }
    getTimezone(lat, lng) {
        return new Promise((resolve, reject) => {
            fetch(`https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${Math.floor(+new Date() / 1000)}&key=${this.apiKey}`)
                .then((response) => {
                response
                    .json()
                    .then((timezoneInfo) => {
                    resolve(timezoneInfo);
                })
                    .catch(() => reject());
            })
                .catch(() => reject());
        });
    }
    getStatePostalInfo(address) {
        return new Promise((resolve, reject) => {
            fetch(`https://maps.googleapis.com/maps/api/geocode/json?language=en&address=${encodeURIComponent(address)}&key=${this.apiKey}`)
                .then((response) => {
                response
                    .json()
                    .then((addressInfo) => {
                    const state = addressInfo.results[0].address_components.find((item) => item.types.includes('administrative_area_level_1'));
                    const postalCode = addressInfo.results[0].address_components.find((item) => item.types.includes('postal_code'));
                    resolve({
                        state: state?.short_name,
                        postalCode: postalCode?.short_name,
                    });
                })
                    .catch((e) => {
                    console.error(e);
                    return reject(e);
                });
            })
                .catch((e) => {
                console.error(e);
                return reject(e);
            });
        });
    }
}
