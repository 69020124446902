import { StatisticsService } from './StatisticsService';
import { apiWrapper } from '../../util/helper';
export default class StatisticsServiceAdmin extends StatisticsService {
    constructor(axios) {
        super(axios);
    }
    // @TODO: isMulti 사용 여부 확인 후 삭제
    async getDeviceCount(params, isMulti) {
        const { data } = await this.get(
        // isMulti
        //   ? apiWrapper.stateApi(`/devices/charts/data/multi-inverter`)
        //   : apiWrapper.stateApi(`/devices/charts/data`),
        apiWrapper.stateApi('/devices/charts/data/renewal'), params);
        return data;
    }
    async getLastWeekInstalledDeviceCount(params) {
        const { data } = await this.get(apiWrapper.managerApi('/managements/activities/instl-last-week'), params);
        return data;
    }
}
export { StatisticsServiceAdmin };
