/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import _ from 'lodash';
import { convertWattToKilowatt, getFormattedPVValue } from '@hems/container/src/energyflow/value/EnergyValueFunctions';
import { DEFAULT_LANGUAGE, ENERGY_POLICY, ENERGY_PRODUCT_TYPE, NETWORK_TYPE, NETWORK_TYPE_IMAGE, } from '@hems/util/src/constant';
import { isEnergyPolicyType } from '@hems/util/src/helper/tsguardHelper';
export const defaultEnergyFlow = {
    isPvToBattery: false,
    isPvToGrid: false,
    isPvToLoad: false,
    isBatteryToGrid: false,
    isBatteryToLoad: false,
    isGridToBattery: false,
    isGridToLoad: false,
    isGeneratorToLoad: false,
};
export const getEnergyFlowCases = (data) => {
    const { pv_pw, bt_pw, grid_pw, cons_pw } = data;
    if (isCase1(bt_pw, grid_pw)) {
        return getCase1Flow(pv_pw);
    }
    if (isCase2(bt_pw, grid_pw, pv_pw, cons_pw)) {
        return getCase2Flow(bt_pw, grid_pw, pv_pw, cons_pw);
    }
    if (isCase3_1(bt_pw, grid_pw, pv_pw, cons_pw)) {
        return getCase3_1Flow(bt_pw, grid_pw, pv_pw, cons_pw);
    }
    if (isCase3_2(bt_pw, grid_pw, pv_pw, cons_pw)) {
        return getCase3_2Flow(cons_pw);
    }
    if (isCase4_1(bt_pw, grid_pw, pv_pw, cons_pw)) {
        return getCase4_1Flow(bt_pw, grid_pw, pv_pw, cons_pw);
    }
    if (isCase4_2(bt_pw, grid_pw, cons_pw)) {
        return getCase4_2Flow(pv_pw, cons_pw);
    }
    return defaultEnergyFlow;
};
const isCase1 = (batteryPower, gridPower) => {
    return batteryPower > 0 && gridPower > 0;
};
const getCase1Flow = (pvPower) => {
    return pvPower
        ? {
            ...defaultEnergyFlow,
            isPvToLoad: true,
            isBatteryToLoad: true,
            isGridToLoad: true,
        }
        : {
            ...defaultEnergyFlow,
            isBatteryToLoad: true,
            isGridToLoad: true,
        };
};
const isCase2 = (batteryPower, gridPower, pvPower, loadPower) => {
    return (isCase2_1(batteryPower, gridPower, pvPower) ||
        isCase2_2(batteryPower, gridPower, pvPower) ||
        isCase2_3(batteryPower, gridPower, pvPower) ||
        isCase2_4(batteryPower, gridPower, pvPower, loadPower));
};
const getCase2Flow = (batteryPower, gridPower, pvPower, loadPower) => {
    if (isCase2_1(batteryPower, gridPower, pvPower)) {
        return getCase2_1Flow(loadPower);
    }
    if (isCase2_2(batteryPower, gridPower, pvPower)) {
        return getCase2_2Flow(loadPower);
    }
    if (isCase2_3(batteryPower, gridPower, pvPower)) {
        return getCase2_3Flow(loadPower);
    }
    if (isCase2_4(batteryPower, gridPower, pvPower, loadPower)) {
        return getCase2_4Flow();
    }
    return defaultEnergyFlow;
};
const isCase2_1 = (batteryPower, gridPower, pvPower) => {
    return batteryPower < 0 && gridPower < 0 && pvPower > 0;
};
const getCase2_1Flow = (loadPower) => {
    if (loadPower > 0) {
        return {
            ...defaultEnergyFlow,
            isPvToLoad: true,
            isPvToBattery: true,
            isPvToGrid: true,
        };
    }
    return {
        ...defaultEnergyFlow,
        isPvToBattery: true,
        sPvToGrid: true,
    };
};
const isCase2_2 = (batteryPower, gridPower, pvPower) => {
    return batteryPower === 0 && gridPower < 0 && pvPower > 0;
};
const getCase2_2Flow = (loadPower) => {
    if (loadPower > 0) {
        return {
            ...defaultEnergyFlow,
            isPvToLoad: true,
            isPvToGrid: true,
        };
    }
    return {
        ...defaultEnergyFlow,
        isPvToGrid: true,
    };
};
const isCase2_3 = (batteryPower, gridPower, pvPower) => {
    return batteryPower < 0 && gridPower === 0 && pvPower > 0;
};
const getCase2_3Flow = (loadPower) => {
    if (loadPower > 0) {
        return {
            ...defaultEnergyFlow,
            isPvToLoad: true,
            isPvToBattery: true,
        };
    }
    return {
        ...defaultEnergyFlow,
        isPvToBattery: true,
    };
};
const isCase2_4 = (batteryPower, gridPower, pvPower, loadPower) => {
    return batteryPower === 0 && gridPower === 0 && pvPower > 0 && loadPower > 0;
};
const getCase2_4Flow = () => {
    return {
        ...defaultEnergyFlow,
        isPvToLoad: true,
    };
};
const isCase3_1 = (batteryPower, gridPower, pvPower, loadPower) => {
    return (isCase3_1_1(batteryPower, gridPower, pvPower) ||
        isCase3_1_2(batteryPower, gridPower, pvPower) ||
        isCase3_1_3(batteryPower, gridPower, pvPower, loadPower));
};
const getCase3_1Flow = (batteryPower, gridPower, pvPower, loadPower) => {
    if (isCase3_1_1(batteryPower, gridPower, pvPower)) {
        return getCase3_1_1Flow(pvPower, loadPower);
    }
    if (isCase3_1_2(batteryPower, gridPower, pvPower)) {
        return getCase3_1_2Flow(loadPower);
    }
    if (isCase3_1_3(batteryPower, gridPower, pvPower, loadPower)) {
        return getCase3_1_3Flow(pvPower);
    }
    return defaultEnergyFlow;
};
const isCase3_1_1 = (batteryPower, gridPower, pvPower) => {
    return batteryPower > 0 && gridPower < 0 && pvPower > 0;
};
const getCase3_1_1Flow = (pvPower, loadPower) => {
    if (pvPower < loadPower) {
        return {
            ...defaultEnergyFlow,
            isPvToLoad: true,
            isBatteryToLoad: true,
            isBatteryToGrid: true,
        };
    }
    return {
        ...defaultEnergyFlow,
        isPvToLoad: true,
        isBatteryToGrid: true,
    };
};
const isCase3_1_2 = (batteryPower, gridPower, pvPower) => {
    return batteryPower > 0 && gridPower < 0 && pvPower === 0;
};
const getCase3_1_2Flow = (loadPower) => {
    if (loadPower > 0) {
        return {
            ...defaultEnergyFlow,
            isBatteryToLoad: true,
            isBatteryToGrid: true,
        };
    }
    return {
        ...defaultEnergyFlow,
        isBatteryToGrid: true,
    };
};
const isCase3_1_3 = (batteryPower, gridPower, pvPower, loadPower) => {
    return batteryPower > 0 && gridPower === 0 && pvPower <= loadPower;
};
const getCase3_1_3Flow = (pvPower) => {
    if (pvPower > 0) {
        return {
            ...defaultEnergyFlow,
            isPvToLoad: true,
            isBatteryToLoad: true,
        };
    }
    return {
        ...defaultEnergyFlow,
        isBatteryToLoad: true,
    };
};
const isCase3_2 = (batteryPower, gridPower, pvPower, loadPower) => {
    return batteryPower > 0 && gridPower < 0 && pvPower > loadPower;
};
const getCase3_2Flow = (loadPower) => {
    if (loadPower > 0) {
        return {
            ...defaultEnergyFlow,
            isPvToLoad: true,
            isPvToGrid: true,
            isBatteryToGrid: true,
        };
    }
    return {
        ...defaultEnergyFlow,
        isPvToGrid: true,
        isBatteryToGrid: true,
    };
};
const isCase4_1 = (batteryPower, gridPower, pvPower, loadPower) => {
    return isCase4_1_1(batteryPower, gridPower, pvPower, loadPower) || isCase4_1_2(batteryPower, gridPower, loadPower);
};
const getCase4_1Flow = (batteryPower, gridPower, pvPower, loadPower) => {
    if (isCase4_1_1(batteryPower, gridPower, pvPower, loadPower)) {
        return getCase4_1_1Flow(gridPower, loadPower);
    }
    if (isCase4_1_2(batteryPower, gridPower, loadPower)) {
        return getCase4_1_2Flow(pvPower);
    }
    return defaultEnergyFlow;
};
const isCase4_1_1 = (batteryPower, gridPower, pvPower, loadPower) => {
    return batteryPower < 0 && gridPower > 0 && pvPower > 0 && loadPower > 0;
};
const getCase4_1_1Flow = (gridPower, loadPower) => {
    if (gridPower < loadPower) {
        return {
            ...defaultEnergyFlow,
            isPvToBattery: true,
            isPvToLoad: true,
            isGridToLoad: true,
        };
    }
    return {
        ...defaultEnergyFlow,
        isPvToBattery: true,
        isGridToLoad: true,
    };
};
const isCase4_1_2 = (batteryPower, gridPower, loadPower) => {
    return batteryPower === 0 && gridPower > 0 && gridPower <= loadPower && loadPower > 0;
};
const getCase4_1_2Flow = (pvPower) => {
    if (pvPower > 0) {
        return {
            ...defaultEnergyFlow,
            isPvToLoad: true,
            isGridToLoad: true,
        };
    }
    return {
        ...defaultEnergyFlow,
        isGridToLoad: true,
    };
};
const isCase4_2 = (batteryPower, gridPower, loadPower) => {
    return batteryPower < 0 && gridPower > 0 && gridPower > loadPower;
};
const getCase4_2Flow = (pvPower, loadPower) => {
    if (pvPower > 0) {
        if (loadPower > 0) {
            return {
                ...defaultEnergyFlow,
                isPvToBattery: true,
                isGridToLoad: true,
                isGridToBattery: true,
            };
        }
        return {
            ...defaultEnergyFlow,
            isPvToBattery: true,
            isGridToBattery: true,
        };
    }
    if (loadPower > 0) {
        return {
            ...defaultEnergyFlow,
            isGridToLoad: true,
            isGridToBattery: true,
        };
    }
    return {
        ...defaultEnergyFlow,
        isGridToBattery: true,
    };
};
export const getEnergyFlowFormattedData = (data, t, locale = DEFAULT_LANGUAGE) => {
    if (_.isEmpty(data)) {
        return {
            [ENERGY_PRODUCT_TYPE.PV]: {
                pw: convertWattToKilowatt('W', 0.0, locale),
            },
            [ENERGY_PRODUCT_TYPE.LOAD]: {
                pw: convertWattToKilowatt('W', 0.0, locale),
            },
            [ENERGY_PRODUCT_TYPE.GRID]: { pw: convertWattToKilowatt('W', 0.0, locale), status: 'Grid' },
        };
    }
    return {
        [ENERGY_PRODUCT_TYPE.PV]: {
            pw: getFormattedPVValue(data.pv_pw, locale),
        },
        [ENERGY_PRODUCT_TYPE.LOAD]: {
            pw: convertWattToKilowatt('W', data.cons_pw, locale),
        },
        [ENERGY_PRODUCT_TYPE.GRID]: {
            pw: convertWattToKilowatt('W', data.grid_pw, locale),
            status: getGridStatus(data.grid_status ?? true, data.grid_pw, t),
        },
    };
};
const getGridStatus = (gridStatus, gridPower, t) => {
    if (!gridStatus)
        return 'Off-grid';
    if (gridPower > 0)
        return `${t('common.grid')}(${t('common.import')})`;
    if (gridPower < 0)
        return `${t('common.grid')}(${t('common.export')})`;
    return t('common.grid');
};
export const getOperationModeText = (hasESS) => {
    return {
        [ENERGY_POLICY.STAND_BY]: 'Standby',
        [ENERGY_POLICY.SELF_CONSUMPTION]: hasESS ? 'Self Consumption' : 'Auto',
        [ENERGY_POLICY.ZERO_EXPORT]: 'Zero Export',
        [ENERGY_POLICY.TIME_BASED]: 'Time Based Mode',
        [ENERGY_POLICY.EXTERNAL_GENERATION]: 'External Generation Mode',
        [ENERGY_POLICY.EOS]: 'EOS',
        [ENERGY_POLICY.GATEWAY]: 'Gateway',
        [ENERGY_POLICY.ENGINEER]: 'Engineer',
        [ENERGY_POLICY.SMART_SAVING]: 'Smart Saving',
        [ENERGY_POLICY.STORM_COVER]: 'Storm Cover',
        [ENERGY_POLICY.VPP]: 'VPP',
    };
};
export const convertOperationModeToNumberString = (code) => {
    if (code?.startsWith('G3')) {
        const extractedOperationModeString = Number(code.slice(-2)).toString();
        const codeString = isEnergyPolicyType(extractedOperationModeString)
            ? extractedOperationModeString
            : null;
        return codeString;
    }
    return isEnergyPolicyType(code) ? code : null;
};
export const getNetworkConnectionStatusText = (networkStatus, disconnect) => {
    if (networkStatus === NETWORK_TYPE.UNKNOWN)
        return '';
    if (disconnect)
        return 'Disconnected';
    return 'Connected';
};
export const getNetworkImagePath = (networkStatus) => {
    switch (networkStatus) {
        case NETWORK_TYPE.ETHERNET:
            return NETWORK_TYPE_IMAGE.ETHERNET_IMAGE_PATH;
        case NETWORK_TYPE.WIFI:
            return NETWORK_TYPE_IMAGE.WIFI_IMAGE_PATH;
        case NETWORK_TYPE.LTE:
            return NETWORK_TYPE_IMAGE.LTE_IMAGE_PATH;
        default:
            return '';
    }
};
