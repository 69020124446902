import { defineComponent, reactive, computed, watch } from 'vue';
import { useField } from 'vee-validate';
import ErrorMessageLabel from '@hems/component/src/labels/ErrorMessageLabel.vue';
import { Helper, useModelWrapper } from '@hems/util';
import BasicInput from './BasicInput.vue';
export default defineComponent({
    name: 'AutoResizeInput',
    components: {
        BasicInput,
        ErrorMessageLabel,
    },
    inheritAttrs: false,
    props: {
        label: String,
        placeholder: String,
        modelValue: [String, Number],
        readonly: Boolean,
        name: String,
        as: Function,
        type: String,
        validClass: {
            type: String,
            default: '',
        },
        className: {
            type: String,
            default: 'fm_ipt',
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const inputName = props.name ?? Helper.getUUID();
        const { value: inputValue, errorMessage } = useField(inputName, undefined, {
            initialValue: props.modelValue,
            type: props.type,
        });
        const state = reactive({
            value: useModelWrapper(props, emit),
            editable: computed(() => !props.readonly === undefined ? false : !!['', true, 'true'].includes(!props.readonly)),
        });
        watch(() => props.modelValue, () => {
            if (props.modelValue !== inputValue.value)
                inputValue.value = props.modelValue ?? null;
        });
        return {
            state,
            errorMessage,
        };
    },
});
