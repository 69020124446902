import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { ContentModal } from '@hems/component';
import { CONTENT_MODAL_SIZE_TYPE } from '@hems/util/src/constant';
import { TOU_OPERATION, TOU_OPERATION_MULTILINGUAL_CODE, TOU_OPERATION_MULTILINGUAL_DESCRIPTION_CODE, } from '@hems/util/src/constant/energyManagement';
export default defineComponent({
    name: 'OperationDescriptionModal',
    components: {
        ContentModal,
    },
    setup() {
        const { t } = useI18n();
        const touOperationValues = Object.values(TOU_OPERATION);
        const operationDescriptionList = touOperationValues.map((operation, index) => ({
            title: `${index + 1}. ${t(TOU_OPERATION_MULTILINGUAL_CODE[operation])}`,
            description: t(TOU_OPERATION_MULTILINGUAL_DESCRIPTION_CODE[operation]),
        }));
        return {
            CONTENT_MODAL_SIZE_TYPE,
            operationDescriptionList,
        };
    },
});
