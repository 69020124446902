import { defineComponent } from 'vue';
import InputError from '@hems/component/src/error/InputError.vue';
import Image2 from '@hems/component/src/image/Image2.vue';
import { INPUT_VALID_STATUS } from '@hems/util/src/constant';
import { defaultInputErrorInfo } from '@hems/util/src/helper/helper';
export default defineComponent({
    name: 'TextInput',
    components: {
        InputError,
        Image2,
    },
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        inputValidStatus: {
            type: String,
            default: INPUT_VALID_STATUS.DEFAULT,
        },
        errorInfo: {
            type: Object,
            default: () => defaultInputErrorInfo,
        },
        beforeImage: {
            type: Object,
            default: null,
        },
        afterImage: {
            type: Object,
            default: null,
        },
        showValidationImage: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['update:modelValue', 'blur'],
    setup(_props, { emit }) {
        const updateInputValue = (event) => {
            if (event.target instanceof HTMLInputElement) {
                emit('update:modelValue', event.target.value);
            }
        };
        return {
            INPUT_VALID_STATUS,
            updateInputValue,
        };
    },
});
