export const MOBILE_SITE_INFO_CATEGORY = {
    SITE_PROFILE: 'siteProfile',
    HUB: 'hub',
    AC_COMB: 'ACCombiner',
    SOLAR: 'solar',
    ESS: 'ess',
    GRID: 'grid',
    LOAD: 'load',
    INSTALL_HIST: 'installHistory',
};
