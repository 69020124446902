import { defineComponent, reactive } from 'vue';
import { useModelWrapper } from '@hems/util';
import BasicInput from './BasicInput.vue';
export default defineComponent({
    name: 'TruncateInput',
    components: {
        BasicInput,
    },
    inheritAttrs: false,
    props: {
        label: String,
        name: String,
        placeholder: String,
        modelValue: [String, Number],
        tooltipText: String,
        tooltipPosition: {
            type: Array,
        },
        readonly: Boolean,
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const state = reactive({
            value: useModelWrapper(props, emit),
            readonly: props.readonly,
        });
        return {
            state,
        };
    },
});
