import { computed, defineComponent } from 'vue';
import { useRoute, RouterLink } from 'vue-router';
export default defineComponent({
    name: 'RemoteControlSettingInfoTab',
    components: {
        RouterLink,
    },
    props: {
        genType: {
            type: String,
            required: true,
        },
    },
    setup() {
        const route = useRoute();
        const deviceId = computed(() => route.query.deviceId);
        const tabname = computed(() => route.path.split('/')[4]);
        return {
            deviceId,
            tabname,
        };
    },
});
