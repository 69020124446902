import { defineComponent, computed } from 'vue';
import { BADGE_STATUS_TYPE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'Badge',
    props: {
        status: {
            type: String,
            default: BADGE_STATUS_TYPE.NORMAL,
        },
    },
    setup(props) {
        const badgeColor = computed(() => {
            switch (props.status) {
                case BADGE_STATUS_TYPE.ERROR:
                    return 'red';
                case BADGE_STATUS_TYPE.NORMAL:
                default:
                    return 'teal';
            }
        });
        return { badgeColor };
    },
});
