import { defineComponent, computed } from 'vue';
import Image2 from '@hems/component/src/image/Image2.vue';
import { useEnergyManagement, useScheduleDate } from '@hems/util';
export default defineComponent({
    name: 'ScheduleStatusInfo',
    components: { Image2 },
    setup() {
        const { selectedScheduleInfo } = useEnergyManagement();
        const { getScheduleDateRangeText } = useScheduleDate();
        const scheduleDatePeriodInfo = computed(() => {
            if (!selectedScheduleInfo.value) {
                return '';
            }
            return getScheduleDateRangeText(selectedScheduleInfo.value.startDate, selectedScheduleInfo.value.endDate);
        });
        const isScheduleSaveRequired = computed(() => selectedScheduleInfo.value?.isSaveRequired);
        return { selectedScheduleInfo, scheduleDatePeriodInfo, isScheduleSaveRequired };
    },
});
