import { computed } from 'vue';
import { useStore } from 'vuex';
import { ROLE_NAME } from '@hems/util/src/constant/role';
export function useRole() {
    const store = useStore();
    const roleName = computed(() => store.state.user.roleNm);
    const roleCode = computed(() => store.state.user.roleCd);
    const isInstaller = roleName.value === ROLE_NAME.INSTALLER;
    const isUser = roleName.value === ROLE_NAME.USER;
    const isAdminGroup = !isInstaller && !isUser;
    const isAdmin = roleName.value === ROLE_NAME.ADMIN;
    const isDev = roleName.value === ROLE_NAME.DEV;
    const isService = roleName.value === ROLE_NAME.SERVICE;
    const isDevOrAdmin = isDev || isAdmin;
    const isDevOrAdminOrService = isDevOrAdmin || isService;
    return {
        roleName,
        roleCode,
        isInstaller,
        isUser,
        isAdminGroup,
        isAdmin,
        isDev,
        isService,
        isDevOrAdmin,
        isDevOrAdminOrService,
    };
}
