import { defineComponent } from 'vue';
import { AuthHelper } from '@hems/util';
export default defineComponent({
    name: 'UnauthorizedPage',
    setup() {
        AuthHelper.removeAccessToken();
        function goHome() {
            location.href = '/';
        }
        return {
            goHome,
        };
    },
});
