import { defineComponent } from 'vue';
import ToggleButtonGroup from '@hems/component/src/buttons/ToggleButtonGroup.vue';
import { DAYS_OF_WEEK } from '@hems/util/src/constant';
import { defaultInputErrorInfo } from '@hems/util/src/helper/helper';
export default defineComponent({
    name: 'DaySelectorButton',
    components: { ToggleButtonGroup },
    props: {
        modelValue: {
            type: Array,
            required: true,
        },
        errorInfo: {
            type: Object,
            default: () => defaultInputErrorInfo,
        },
        toggleButtonItemIsDisabledList: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['update:modelValue', 'change'],
    setup(props, { emit }) {
        const daysOfWeekToggleButtonItems = Object.values(DAYS_OF_WEEK).map((item) => ({
            text: item.toUpperCase().charAt(0),
            value: item,
        }));
        const onSelectItem = (selectedValue, isSelected) => {
            const updatedValues = props.modelValue;
            if (isSelected) {
                updatedValues.push(selectedValue);
            }
            else {
                // TODO filter로 바꾸기
                const selectedItemIndex = props.modelValue.findIndex((value) => value === selectedValue);
                if (selectedItemIndex < 0) {
                    return;
                }
                updatedValues.splice(selectedItemIndex, 1);
            }
            emit('update:modelValue', updatedValues);
            emit('change', selectedValue, isSelected);
        };
        return { daysOfWeekToggleButtonItems, onSelectItem };
    },
});
