import { defineComponent } from 'vue';
import BasicChip from './BasicChip.vue';
export default defineComponent({
    name: 'ErrorCodeChipsGroup',
    components: {
        BasicChip,
    },
    props: {
        displayedSiteErrorInfo: {
            type: Array,
            required: true,
        },
    },
});
