import { defineComponent, computed } from 'vue';
import Image2 from '@hems/component/src/image/Image2.vue';
import { BANNER_TYPE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'Banner',
    components: {
        Image2,
    },
    props: {
        contents: {
            type: String,
            required: true,
        },
        bannerType: {
            type: String,
            default: BANNER_TYPE.DEFAULT,
        },
    },
    setup(props) {
        const BannerIconMap = {
            [BANNER_TYPE.DEFAULT]: 'icons/ic_re_info',
            [BANNER_TYPE.SUCCESS]: 'icons/ic_check_circle_line_primary',
            [BANNER_TYPE.WARNING]: 'icons/ic_error',
            [BANNER_TYPE.NOTICE]: 'icons/ic_info',
        };
        const iconSrc = computed(() => BannerIconMap[props.bannerType]);
        return {
            iconSrc,
        };
    },
});
