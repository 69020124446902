import { defineComponent, reactive } from 'vue';
export default defineComponent({
    name: 'MultiLabel',
    props: {
        data: {
            type: Array,
        },
        options: {
            default: {
                paddingOption: 'sml',
            },
        },
    },
    setup(props) {
        const state = reactive({
            data: props.data,
            options: props.options,
            dataSize: props.data?.length,
        });
        return {
            state,
        };
    },
});
