import { AxiosErrorException } from '@hems/util/src/exception/exception';
import { DownloadService } from './DownloadService';
import { apiWrapper } from '../../util/helper';
export default class DownloadServiceUser extends DownloadService {
    constructor(axios) {
        super(axios);
    }
    async annualReportFileDownload(siteId, params) {
        return await this.get(apiWrapper.managerApi(`/histories/annual-reports/${siteId}/file`), params, {
            headers: {
                Accept: '*/*',
            },
            responseType: 'blob',
        });
    }
    async annualReportSendEmail(siteId, params, config) {
        const url = apiWrapper.managerApi(`/histories/annual-reports/${siteId}/mail`);
        try {
            const { status } = await this.axios.post(url, params, config);
            return { status };
        }
        catch (e) {
            throw new AxiosErrorException(url, params, e);
        }
    }
}
