import Cookies from 'js-cookie';
const RoleMap = Object.freeze({
    10: 'user',
    20: 'installer',
    30: 'operator',
    40: 'utility',
    50: 'cocompany',
    60: 'dev',
    70: 'service',
    90: 'admin',
    80: 'retailer',
});
export function getRoleNm(roleCd) {
    return RoleMap[roleCd];
}
export function parseJwt(jwt) {
    if (!jwt)
        return;
    try {
        const base64Url = jwt.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64)
            .split('')
            .map((c) => {
            return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
            .join(''));
        return JSON.parse(jsonPayload);
    }
    catch (error) {
        return;
    }
}
const TOKEN_KEY = 'access_token';
export const saveAccessToken = (token) => {
    Cookies.set(TOKEN_KEY, encodeURIComponent(token), {
        path: '/',
        sameSite: 'strict',
    });
};
export const saveAccessTokenForIframe = (token) => {
    return new Promise((resolve, reject) => {
        try {
            Cookies.set(TOKEN_KEY, encodeURIComponent(token), {
                path: '/',
                sameSite: 'none',
                secure: true,
            });
            resolve();
        }
        catch (error) {
            reject(error);
        }
    });
};
export const getAccessToken = () => Cookies.get(TOKEN_KEY);
export const removeAccessToken = () => {
    Cookies.remove(TOKEN_KEY, { path: '/', sameSite: 'strict' });
    // FIXME: 임시 기능. 기존 access token을 쿠키에 set할 때 window.location.hostname을 domain으로 설정했던 것들을 제거하기 위함.
    Cookies.remove(TOKEN_KEY, {
        domain: window.location.hostname,
        path: '/',
        sameSite: 'strict',
    });
};
export function isExpired(jwtObject) {
    const expiredDate = new Date(Number(jwtObject.exp) * 1000);
    const now = new Date();
    return now > expiredDate;
}
export function getRoleCdByRoleName(roleNm) {
    return Object.keys(RoleMap).find((roleCd) => RoleMap[roleCd] === roleNm);
}
