const codeStorage = window.sessionStorage;
const codeKey = 'qommand-code-list';
export function clearCode() {
    if (!codeStorage)
        return;
    codeStorage.removeItem(codeKey);
}
export function isExistCode(hashcode) {
    if (!codeStorage)
        return false;
    return !!JSON.parse(codeStorage.getItem(codeKey) ?? '{}')[hashcode];
}
export function getCode(hashcode) {
    if (!codeStorage)
        return [];
    return JSON.parse(codeStorage.getItem(codeKey) ?? '{}')[hashcode];
}
export function setCode(hashcode, data) {
    if (!codeStorage)
        return;
    const codeData = JSON.parse(codeStorage.getItem(codeKey) ?? '{}');
    codeData[hashcode] = data;
    codeStorage.setItem(codeKey, JSON.stringify(codeData));
}
class SessionStorageService {
    storagePrefix = 'scratchy__';
    get(key) {
        const item = window.sessionStorage.getItem(this.storagePrefix + key);
        if (!item || item === 'null') {
            return null;
        }
        try {
            return JSON.parse(item);
        }
        catch (e) {
            console.log(e);
        }
        return null;
    }
    set(key, value) {
        if (value === undefined) {
            value = null;
        }
        else {
            value = JSON.stringify(value);
        }
        try {
            window.sessionStorage.setItem(this.storagePrefix + key, value);
        }
        catch (e) {
            console.log(e);
        }
        return false;
    }
    remove(key) {
        window.sessionStorage.removeItem(this.storagePrefix + key);
    }
}
export default new SessionStorageService();
