class CustomImageRenderer {
    el;
    constructor(props) {
        const el = document.createElement('img');
        this.el = el;
        this.render(props);
    }
    getElement() {
        return this.el;
    }
    async render(props) {
        const options = props.columnInfo.renderer.options;
        this.el.src = options
            ? (await import(`@hems/component/resources/images/${options.baseUrl || ''}/${options.imageName || ''}.${options.extension || 'png'}`)).default
            : '';
        this.el.height = options?.height ?? 20;
        this.el.width = options?.width ?? 20;
    }
}
export default CustomImageRenderer;
