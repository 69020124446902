class OperationStatusRenderer {
    el;
    status;
    in;
    constructor(props) {
        this.status = $('<span class="state_prod"></span>');
        this.in = $('<span class="in"></span>');
        this.status.append(this.in);
        this.el = this.status.get(0);
        this.render(props);
    }
    getElement() {
        return this.el;
    }
    async render(props) {
        const value = props.grid.getValue(props.rowKey, 'oper_stus_cd');
        switch (value) {
            case '01':
            case '00':
                this.status.addClass('ty_1');
                this.in.text('run');
                break;
            case '11':
            case '10':
                this.status.addClass('ty_2');
                this.in.text('warn');
                break;
            case '21':
            case '20':
                this.status.addClass('ty_3');
                this.in.text('error');
                break;
            case '40':
                this.status.addClass('ty_4');
                this.in.text('incomplete');
                break;
            case '60':
                this.status.addClass('ty_5');
                this.in.text('offline');
                break;
        }
    }
}
export default OperationStatusRenderer;
