import { computed, defineComponent, ref } from 'vue';
import moment from 'moment';
import CalendarNew from '@hems/component/src/calendars/CalendarNew.vue';
import TextInput from '@hems/component/src/inputs/TextInput.vue';
export default defineComponent({
    name: 'CalendarInput',
    components: {
        CalendarNew,
        TextInput,
    },
    props: {
        modelValue: {
            type: [Date, null],
            required: true,
        },
        format: {
            type: String,
            default: 'DD MMM',
        },
        minDate: Date,
        maxDate: Date,
        disabledDates: {
            type: Array,
            required: false,
            default: () => [],
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const selectedDate = ref(props.modelValue);
        const isOpen = ref(false);
        const displayValue = computed(() => {
            if (props.modelValue) {
                return moment(props.modelValue).format(props.format);
            }
            return '';
        });
        const onOpen = () => {
            if (props.isDisabled) {
                return;
            }
            isOpen.value = true;
        };
        const onClose = () => (isOpen.value = false);
        const changeDate = (date) => {
            emit('update:modelValue', date);
        };
        return {
            displayValue,
            selectedDate,
            isOpen,
            onOpen,
            onClose,
            changeDate,
        };
    },
});
