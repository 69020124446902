import { defineComponent } from 'vue';
import { Flow } from '@hems/component';
import { defaultEnergyFlow } from '@hems/container/src/energyflow/flow/EnergyFlowFunctions';
export default defineComponent({
    name: 'EnergyFlow',
    components: {
        Flow,
    },
    props: {
        energyFlow: {
            type: Object,
            default: () => defaultEnergyFlow,
        },
    },
});
