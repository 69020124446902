import { isNull, isInteger } from './helper';
export const getTooltipFormattedNumber = (pointNumber) => {
    if (isInteger(pointNumber)) {
        return pointNumber.toFixed(0);
    }
    return pointNumber.toFixed(2).replace(/\.?0+$/, '');
};
export const graphTooltipFormatter = (tooltipObject) => {
    let tooltipText = String(tooltipObject.x);
    tooltipObject.points?.forEach((point) => {
        if (!isNull(point.y)) {
            tooltipText = `${tooltipText}<br /><span style="color:${point.color}">●</span> 
    ${point.series.name}: ${getTooltipFormattedNumber(point.y)}`;
        }
    });
    return tooltipText;
};
export const integratedGraphTooltipFormatter = (tooltipObject) => {
    const x = tooltipObject.point.x;
    const chart = tooltipObject.series.chart;
    let tooltipText = `<div style="line-height: 1rem">${tooltipObject.x}`;
    chart.series.forEach((series) => {
        if (series.visible) {
            const point = series.points.filter((point) => point.x === x)[0];
            if (!isNull(point?.y)) {
                tooltipText = `${tooltipText}<br><span style="color: ${point.color}">● </span>
        ${series.name}: ${getTooltipFormattedNumber(point.y)}`;
            }
        }
    });
    tooltipText = `${tooltipText}</div>`;
    return tooltipText;
};
