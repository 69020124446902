import { defineComponent, reactive, computed, ref, onBeforeUnmount, watch, onMounted } from 'vue';
import { useField } from 'vee-validate';
import ErrorMessageLabel from '@hems/component/src/labels/ErrorMessageLabel.vue';
import { Helper } from '@hems/util';
export default defineComponent({
    name: 'TwoDepthSelector',
    components: {
        ErrorMessageLabel,
    },
    props: {
        label: String,
        valueType: {
            type: String,
            default: 'string',
        },
        className: {
            type: String,
            default: 'selecter_2',
        },
        options: {
            type: Array,
            required: true,
        },
        modelValue: [String, Number],
        callback: Function,
        name: String,
        beforeChange: Function,
        afterChange: Function,
        disabled: Boolean,
    },
    setup(props, { emit }) {
        const selector = ref(null);
        const state = reactive({
            open: false,
            disabled: computed(() => props.disabled),
            focus: false,
            options: computed(() => props.options.map((opt) => {
                return {
                    label: opt.label,
                    open: opt.open,
                    options: opt.options.map((item) => {
                        const value = transformValue(item.value);
                        return { ...item, value };
                    }),
                };
            })),
        });
        const { value: inputValue, errorMessage } = useField(props.name || Helper.getUUID(), undefined, {
            initialValue: transformValue(props.modelValue),
            type: props.valueType,
        });
        function transformValue(value) {
            if (value === undefined || value === null)
                return null;
            if (props.valueType === 'boolean')
                return Boolean(value);
            if (props.valueType === 'number')
                return Number(value);
            return value;
        }
        const selected = computed(() => state.options
            .map((opt) => opt.options)
            .flat()
            .find((item) => item.value === inputValue.value));
        const selectedText = computed(() => (selected.value ? selected.value.text : state.options?.[0]?.options[0].text));
        function onClickSelect() {
            if (!state.disabled)
                state.open = !state.open;
        }
        function onClickOption(value) {
            if (props.beforeChange) {
                if (props.beforeChange(value.value, inputValue.value)) {
                    setValue(value);
                }
            }
            else {
                setValue(value);
            }
        }
        function onFocus(isFocus) {
            state.focus = isFocus;
        }
        function setValue(value) {
            inputValue.value = value.value;
            emit('update:modelValue', value.value, value.text);
            if (props.afterChange) {
                props.afterChange(value.value, value.text);
            }
        }
        watch(() => props.modelValue, () => {
            if (inputValue.value !== props.modelValue)
                inputValue.value = props.modelValue || '';
        });
        function onClickOtherLayer(e) {
            if (e.target.parentElement !== selector.value) {
                state.open = false;
            }
        }
        onMounted(() => {
            document.addEventListener('click', onClickOtherLayer);
        });
        onBeforeUnmount(() => {
            document.removeEventListener('click', onClickOtherLayer);
        });
        return {
            state,
            selected,
            selectedText,
            inputValue,
            selector,
            onClickSelect,
            onClickOption,
            onFocus,
            errorMessage,
        };
    },
});
