import { defineComponent, reactive } from 'vue';
import { useMessageNotification } from '@hems/component';
export default defineComponent({
    name: 'InformationImage',
    props: {
        src: {
            type: String,
            required: true,
        },
        extension: {
            type: String,
            default: 'png',
            validator: (value) => ['png', 'gif', 'svg'].indexOf(value) !== -1,
        },
        imageSize: {
            type: Object,
            default: () => ({ width: '16', height: '16' }),
        },
        title: {
            type: String,
            default: '',
        },
        message: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const messageNotification = useMessageNotification();
        const state = reactive({});
        const onShowInfo = async () => {
            await messageNotification
                .setMessageNotification('general', props.title, props.message)
                .setIsIcon(false)
                .setIsButton(false)
                .open();
        };
        return {
            state,
            onShowInfo,
        };
    },
});
