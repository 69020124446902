import { defineComponent } from 'vue';
export default defineComponent({
    name: 'GridInfo',
    props: {
        formattedData: {
            type: Object,
            required: true,
        },
        isConsumptionCt: {
            type: Boolean,
            default: true,
        },
    },
});
