import { defineComponent, reactive, defineAsyncComponent } from 'vue';
import { PopupWrapper } from '@hems/component';
export default defineComponent({
    name: 'CyberSecurityPopup',
    components: {
        PopupWrapper,
    },
    setup(props, { emit }) {
        const ReportingPolicy = defineAsyncComponent(() => import('./ReportingPolicy.vue'));
        const SecurityUpdatePolicy = defineAsyncComponent(() => import('./SecurityUpdatePolicy.vue'));
        const state = reactive({
            tabId: 'reportingPolicy',
        });
        function onClickTab(tabId) {
            state.tabId = tabId;
        }
        function closePopup() {
            emit('close');
        }
        return {
            state,
            ReportingPolicy,
            SecurityUpdatePolicy,
            onClickTab,
            closePopup,
        };
    },
});
