import { defineComponent, reactive, ref } from 'vue';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import BasicInput from '@hems/component/src/inputs/BasicInput.vue';
import CommonPopup from '@hems/component/src/popup/CommonPopup.vue';
export default defineComponent({
    name: 'ReplaceDevicePopup',
    components: {
        CommonPopup,
        Form,
        BasicInput,
    },
    props: {
        deviceId: {
            type: String,
            required: true,
            defaultValue: '',
        },
        productModelNm: {
            type: String,
            required: true,
        },
    },
    emits: ['checkdeviceId', 'confirm'],
    async setup(props, { emit }) {
        const replaceDeviceForm = ref(null);
        let isInit = false;
        const state = reactive({
            data: {
                deviceId: props.deviceId,
                productModelNm: props.productModelNm,
            },
            validClass: '',
        });
        const prevValues = {
            value: {
                deviceId: props.deviceId,
                productModelNm: props.productModelNm,
            },
            status: false,
        };
        const schema = yup.object().shape({
            deviceId: yup
                .string()
                .deviceId(prevValues, (isValid, result) => {
                if (isValid) {
                    state.data.productModelNm = result.product_model_nm;
                    state.validClass = 'ok';
                }
                else {
                    state.data.productModelNm = '';
                    state.validClass = 'error';
                }
            })
                .nullable()
                .required(),
        });
        async function onConfirm() {
            const { valid } = await replaceDeviceForm.value?.validate();
            if (valid) {
                const { deviceId, productModelNm } = state.data;
                emit('confirm', {
                    newDeviceId: deviceId,
                    productModelNm,
                });
            }
        }
        isInit = true;
        return {
            isInit,
            state,
            onConfirm,
            schema,
            replaceDeviceForm,
        };
    },
});
