import { computed, defineComponent, reactive, watch } from 'vue';
import { useField } from 'vee-validate';
import Image2 from '@hems/component/src/image/Image2.vue';
import ErrorMessageLabel from '@hems/component/src/labels/ErrorMessageLabel.vue';
import { Helper } from '@hems/util';
export default defineComponent({
    name: 'BasicInput',
    components: {
        ErrorMessageLabel,
        Image2,
    },
    inheritAttrs: false,
    props: {
        modelValue: [String, Number],
        validateMode: {
            type: String,
            default: 'input',
        },
        className: {
            type: String,
            default: 'fm_ipt',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        name: String,
        as: Function,
        unit: String,
        type: String,
        validClass: {
            type: String,
            default: '',
        },
    },
    emits: ['update:modelValue', 'change'],
    setup(props, { emit }) {
        const inputName = props.name ?? Helper.getUUID();
        const { value: inputValue, errorMessage } = useField(inputName, undefined, {
            initialValue: props.modelValue,
            type: props.type,
        });
        const state = reactive({
            isReadonly: computed(() => props.readonly === undefined ? false : !!['', true, 'true'].includes(props.readonly)),
            displayValue: computed(() => {
                const value = props.as ? props.as() : Helper.nullTo(props.modelValue, '');
                return `${value} ${!Helper.isNull(value) ? props.unit || '' : ''}`;
            }),
            showPassword: false,
        });
        watch(() => props.modelValue, () => {
            if (props.modelValue !== inputValue.value)
                inputValue.value = props.modelValue ?? null;
        });
        function setValue(value) {
            if (props.type === 'number') {
                if (Helper.isNull(value)) {
                    emit('update:modelValue', null);
                    return;
                }
                emit('update:modelValue', Number(value));
                return;
            }
            emit('update:modelValue', value);
        }
        function onBlur(e) {
            if (props.validateMode === 'blur') {
                setValue(e.target.value);
            }
        }
        function onChange(e) {
            if (props.validateMode === 'input') {
                setValue(e.target.value);
                emit('change');
            }
        }
        function displayPassword(value) {
            if (value === null)
                return value;
            return Array.from(Array(value.length), (_, index) => (index > 2 ? '*' : value.substring(index, 1))).join('');
        }
        return {
            state,
            inputValue,
            inputName,
            errorMessage,
            displayPassword,
            onBlur,
            onChange,
            Helper,
        };
    },
});
