import { defineComponent, watch, ref } from 'vue';
import { Calendar, Selector } from '@hems/component';
import { Helper, DateHelper, useLocale } from '@hems/util';
import { DATE_UNIT, DAY_RANGE_31, GRAPH_TERM_UNIT } from '@hems/util/src/constant';
const { VCalendarRange, VCalendar } = Calendar;
export default defineComponent({
    name: 'GraphSearchBox',
    components: {
        Selector,
        VCalendarRange,
        VCalendar,
    },
    props: {
        condition: {
            type: Object,
            required: true,
        },
        timezone: {
            type: String,
            default: null,
        },
    },
    emits: ['search', 'changeTermUnit'],
    setup(props, { emit }) {
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6, 0);
        const endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1);
        const searchCondition = ref(props.condition);
        const minDate = ref(getMinDate(props.condition.term_unit));
        const { locale } = useLocale();
        function getMinDate(termUnit) {
            if (termUnit === GRAPH_TERM_UNIT.MINUTE) {
                return new Date(now.getFullYear(), now.getMonth() - 3, now.getDate() + 1);
            }
            return new Date(now.getFullYear() - 1, now.getMonth(), now.getDate() + 1);
        }
        const validateRangeDate = (value) => {
            const dateCount = DateHelper.getDateDifference(value, DATE_UNIT.DAYS);
            if (dateCount > DAY_RANGE_31) {
                return false;
            }
            return true;
        };
        watch(() => searchCondition.value.term_unit, (term_unit) => {
            emit('changeTermUnit', term_unit);
            minDate.value = getMinDate(term_unit);
            if (term_unit === GRAPH_TERM_UNIT.MINUTE) {
                searchCondition.value.date = today;
                return;
            }
            searchCondition.value.date = {
                start: startDate,
                end: endDate,
            };
        });
        watch(() => searchCondition.value.date, () => {
            emit('search', { ...searchCondition.value });
        });
        return {
            searchCondition,
            minDate,
            Helper,
            GRAPH_TERM_UNIT,
            locale,
            DateHelper,
            validateRangeDate,
        };
    },
});
