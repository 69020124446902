import { defineComponent } from 'vue';
import BasicSettingsPvModuleFoxSharedContainer from '@hems/container/src/forms/device/settings/_shared/basic/BasicSettingsPvModuleFoxSharedContainer.vue';
export default defineComponent({
    name: 'BasicSettingFoxContainer',
    components: {
        BasicSettingsPvModuleFoxSharedContainer,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        hideEdit: {
            type: Boolean,
            default: false,
        },
        isConnection: {
            type: Boolean,
            required: true,
        },
        isHcore: {
            type: Boolean,
            default: false,
        },
        isAcCoupled: {
            type: Boolean,
            default: false,
        },
        isHybrid: {
            type: Boolean,
            default: false,
        },
        isGrp: {
            type: Boolean,
            default: false,
        },
        timeZone: {
            type: String,
            default: '',
        },
        emsVersion: {
            type: String,
            default: '',
        },
        changedValueSet: {
            type: Object,
            default: () => new Set(),
        },
    },
    emits: ['goToList', 'save', 'generate', 'cancel', 'edit', 'changeWebHMIPassword'],
    async setup(props, { emit }) {
        function onSave(params, changedValueSet, callback) {
            if (props.isGrp) {
                emit('save', params, changedValueSet);
            }
            else {
                emit('save', params, callback);
            }
        }
        function onGenerate(params) {
            emit('generate', params);
        }
        function onEdit(copyData) {
            emit('edit', copyData);
        }
        function onChangeWebHMIPassword(params) {
            emit('changeWebHMIPassword', params);
        }
        return {
            onSave,
            onGenerate,
            onEdit,
            onChangeWebHMIPassword,
        };
    },
});
