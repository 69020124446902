import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import AdvancedSettingsGEN3 from '@hems/container/src/forms/device/settings/_shared/advanced/AdvancedSettingsGEN3.vue';
import { advancedSettingGen3Config as configMOW } from '@hems/container/src/forms/device/settings/pvmodule/gen3/advanced/configMOW';
const initValue = {
    cls_export_limit: 5000,
    cls_import_limit: 18400,
};
export default defineComponent({
    name: 'AdvancedSettingsPvModuleGen3Container',
    components: {
        AdvancedSettingsGEN3,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        productModelNm: {
            type: String,
            required: true,
        },
        cntryCd: {
            type: String,
            required: true,
        },
        editable: {
            type: Boolean,
            required: true,
        },
        showEditableColumnsOnly: {
            type: Boolean,
            default: true,
        },
        isConnection: Boolean,
        roleNm: {
            type: String,
            required: true,
        },
        hideEdit: {
            type: Boolean,
            default: false,
        },
        isGrp: {
            type: Boolean,
            default: false,
        },
        changedValueSet: {
            type: Object,
            default: () => new Set(),
        },
        groupBasicSettingInfo: {
            type: Object,
            default: null,
        },
    },
    emits: ['goToList', 'save', 'cancel', 'edit'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const configData = { ...initValue, ...getFormattedData(props.data) };
        const config = configMOW(t);
        function getFormattedData(data) {
            const result = {};
            Object.entries(data).forEach(([key, value]) => {
                if (typeof value === 'number') {
                    result[key] = Number(value.toFixed(3));
                }
                else {
                    result[key] = value;
                }
            });
            return result;
        }
        function goToList() {
            emit('goToList');
        }
        function onSave(data, changedValueSet, callback) {
            if (props.isGrp) {
                emit('save', data, changedValueSet);
            }
            else {
                emit('save', data, callback);
            }
        }
        function onCancel() {
            emit('cancel');
        }
        function onEdit() {
            emit('edit');
        }
        return {
            config,
            configData,
            goToList,
            onSave,
            onCancel,
            onEdit,
        };
    },
});
