import { defineComponent, computed } from 'vue';
import { InformationImage } from '@hems/component';
import { ENERGY_VALUE_IMAGE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'ProductionInfo',
    components: {
        InformationImage,
    },
    props: {
        formattedData: {
            type: Object,
            required: true,
        },
        hasPv: {
            type: Boolean,
            default: true,
        },
        isConnectThirdParty: {
            type: Boolean,
            default: false,
        },
        isConsumptionCt: {
            type: Boolean,
            default: true,
        },
    },
    setup(props) {
        const productionValueText = computed(() => props.hasPv
            ? `${props.formattedData.pv?.pw.num} ${props.formattedData.pv?.pw.unit}`
            : `${props.formattedData.load?.pw.num} ${props.formattedData.load?.pw.unit}`);
        const infoImageSize = { width: '10', height: '10' };
        return {
            productionValueText,
            ENERGY_VALUE_IMAGE,
            infoImageSize,
        };
    },
});
