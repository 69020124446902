import { computed } from 'vue';
import { useStore } from 'vuex';
import { AC_DEVICE_TYPE } from '@hems/util/src/constant';
export const useACDeviceType = () => {
    const store = useStore();
    const ACDeviceType = computed(() => store.state.device.acDeviceType);
    const isCase1 = ACDeviceType.value === AC_DEVICE_TYPE.AC_COMBINER;
    const isCase3 = ACDeviceType.value === AC_DEVICE_TYPE.AC_COMBINER_MULTI_ESS_HUB;
    const isCase4 = ACDeviceType.value === AC_DEVICE_TYPE.AC_THIRD_PARTY_MULTI_ESS_HUB ||
        ACDeviceType.value === AC_DEVICE_TYPE.AC_THIRD_PARTY_ESS_HUB;
    const isCase5 = ACDeviceType.value === AC_DEVICE_TYPE.AC_THIRD_PARTY_ESS;
    const isCase6 = ACDeviceType.value === AC_DEVICE_TYPE.AC_COMBINER_MULTI_ESS;
    const isCase8 = ACDeviceType.value === AC_DEVICE_TYPE.AC_ESS || ACDeviceType.value === AC_DEVICE_TYPE.AC_MULTI_ESS;
    const isSolarConfig = isCase1;
    const isBackupConfig = isCase3 || isCase4;
    const isGridSupportConfig = isCase5 || isCase6;
    const isESSOnlyConfig = isCase8;
    const hasACCombiner = isCase1 || isCase3 || isCase6;
    /* Case 4-2, Case5, Case8-1 */
    const isStandAloneESS = ACDeviceType.value === AC_DEVICE_TYPE.AC_THIRD_PARTY_ESS_HUB ||
        isCase5 ||
        ACDeviceType.value === AC_DEVICE_TYPE.AC_ESS;
    const isThirdPartyCase = isCase4 || isCase5;
    return {
        ACDeviceType,
        isCase1,
        isCase3,
        isCase4,
        isCase5,
        isCase6,
        isCase8,
        isBackupConfig,
        isSolarConfig,
        isGridSupportConfig,
        isESSOnlyConfig,
        hasACCombiner,
        isStandAloneESS,
        isThirdPartyCase,
    };
};
