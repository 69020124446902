import { computed, defineComponent, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
    name: 'ErrorMessageLabel',
    props: {
        errorMessage: {
            type: [String, Object],
            default: '',
        },
    },
    setup(props) {
        const { t } = useI18n();
        const state = reactive({
            errorMessage: computed(() => {
                if (typeof props.errorMessage === 'string' || props.errorMessage === undefined) {
                    return String(props.errorMessage || '').trim();
                }
                return t(props.errorMessage?.key, props.errorMessage?.values || {});
            }),
        });
        return {
            state,
        };
    },
});
