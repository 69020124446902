import { defineComponent, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { PopupWrapper, useMessageBox, withLoading } from '@hems/component';
import { UserInfoService } from '@hems/service';
import { ROLE_NAME } from '@hems/util/src/constant/role';
import AccountInfo from './AccountInfo.vue';
export default defineComponent({
    name: 'InstallerAccountInfoPopup',
    components: {
        PopupWrapper,
        AccountInfo,
    },
    props: {
        roleNm: {
            type: String,
        },
    },
    emits: ['close', 'openPrivacyPopup', 'withdrawal'],
    async setup(props) {
        const { t } = useI18n();
        const store = useStore();
        const messageBox = useMessageBox();
        const userInfoService = new UserInfoService(window.axiosInstance.axios);
        const accountInfo = await getAccountInfo();
        const state = reactive({
            accountInfo,
        });
        const saveAccountInfo = withLoading(async (params) => {
            const { email, mpn_no, comn_nm, alarm_mail_recv_flag, password, user_nm } = params;
            if (props.roleNm === ROLE_NAME.INSTALLER) {
                await userInfoService.updateInstallerAccountInfo({
                    comn_nm,
                    email,
                    mpn_no,
                    user_nm,
                    alarm_mail_recv_flag,
                    password,
                });
            }
            else {
                await userInfoService.updateAdminAccountInfo({
                    comn_nm,
                    email,
                    mpn_no,
                    user_nm,
                    alarm_mail_recv_flag,
                    password,
                });
            }
            state.accountInfo = await getAccountInfo();
            messageBox.alert(t('message.save_success')).open();
        });
        async function getAccountInfo() {
            const installerId = store.state.user.userId;
            return await userInfoService.getInstallerAccountInfo(installerId);
        }
        return {
            state,
            saveAccountInfo,
        };
    },
});
