/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
export const alarmCodeMap = [
    { code: 'C128', genType: ['GEN2', 'GEN3', 'MLPE_GEN1', 'DC_OPTIMIZER_GEN2', 'AC_SYS'] },
    { code: 'G36P', genType: ['GEN2'] },
    { code: 'C001', genType: ['MLPE_GEN1', 'DC_OPTIMIZER_GEN2'] },
    { code: 'C002', genType: ['MLPE_GEN1', 'DC_OPTIMIZER_GEN2'] },
    { code: 'C003', genType: ['MLPE_GEN1', 'DC_OPTIMIZER_GEN2'] },
];
