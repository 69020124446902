import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import { Panel } from '@hems/component';
import { FORM_TYPE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'GroupConfigContainer',
    components: {
        Panel,
        Form,
    },
    props: {
        mode: {
            type: String,
            default: FORM_TYPE.READ,
        },
        title: {
            type: String,
            default: 'Setting Modification',
        },
        schema: {
            type: Object,
            default: null,
        },
        isGroupControl: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['next', 'goToList'],
    setup() {
        return {
            FORM_TYPE,
        };
    },
});
