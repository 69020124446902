import { defineComponent } from 'vue';
import EngineerSettingsPvModuleGen3SmartModuleGen2 from '@hems/container/src/forms/device/settings/_shared/engineer/EngineerSettingsPvModuleGen3SmartModuleGen2.vue';
export default defineComponent({
    name: 'EngineerSettingsPvModuleGen3Container',
    components: {
        EngineerSettingsPvModuleGen3SmartModuleGen2,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        hideEdit: {
            type: Boolean,
            default: false,
        },
        isConnection: {
            type: Boolean,
            required: true,
        },
        isHcore: {
            type: Boolean,
            default: false,
        },
        isQcore: {
            type: Boolean,
            default: false,
        },
        isAcCoupled: {
            type: Boolean,
            default: false,
        },
        isFcas: {
            type: Boolean,
            default: false,
        },
        siteId: {
            type: Number,
            default: null,
        },
    },
    emits: ['goToList', 'save', 'cancel', 'edit'],
    async setup(props, { emit }) {
        function onSave(params, callback) {
            emit('save', params, callback);
        }
        function onEdit(copyData) {
            emit('edit', copyData);
        }
        return {
            onSave,
            onEdit,
        };
    },
});
