import { defineComponent, reactive } from 'vue';
import { Accordion } from '@hems/component';
export default defineComponent({
    name: 'SettingConfirmPopup',
    components: {
        Accordion,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        data: {
            type: Object,
            default: () => [],
        },
    },
    emits: ['cancel', 'confirm'],
    setup(props, { emit }) {
        const state = reactive({});
        function onCancel() {
            emit('cancel');
        }
        function onConfirm() {
            emit('confirm');
        }
        return {
            state,
            onCancel,
            onConfirm,
            entries: Object.entries,
        };
    },
});
