/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRole } from '@hems/util';
import { GEN_TYPE } from '@hems/util/src/constant';
export function useGenType() {
    const adminInstallerStore = useStore();
    const userStore = useStore();
    const { isUser } = useRole();
    const genType = computed(() => isUser ? userStore.state.device.gen_type : adminInstallerStore.state.siteNew.genType);
    const isACSystem = genType.value === GEN_TYPE.AC_SYS;
    const isFoxESSType = genType.value === GEN_TYPE.FOX_ESS || genType.value === GEN_TYPE.FOX_ESS_H3;
    return {
        genType,
        isACSystem,
        isFoxESSType,
    };
}
