import { Service } from '@hems/service/src/api/Service';
import { apiWrapper } from '@hems/service/src/util/helper';
export default class SmartModuleService extends Service {
    constructor(axios) {
        super(axios);
        /** @FIXME API에 제거 요청. 수정해주면 이후 아래 로직 제거 필요 */
        this.setContentTypeHeaderInterceptor();
    }
    // 도면 파일 가져오기
    async getDrawingFileBySiteId(siteId) {
        return await this.get(apiWrapper.managerApi(`/devices/profiles/ac/drawing/${siteId}/sites`));
    }
    // 도면 파일 추가 및 업데이트
    async saveDrawingFileBySiteId(siteId, data) {
        const response = await this.post(apiWrapper.managerApi(`/devices/profiles/ac/drawing/${siteId}/sites`), data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    }
    async getModulesInfoBySiteId(siteId) {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/telemetries/ac/${siteId}/mi-profiles`));
        return data.result;
    }
    /** FIXME: interval, params 하나로 합치기 SmartModuleEnergyData */
    async getEnergyMonitoringInfoBySiteId(siteId, interval, params) {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/telemetries/ac/${siteId}/energies/${interval}`), params);
        return data.result;
    }
    async get15MinPvPowerInfoACBySiteId(siteId, params) {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/telemetries/ac/${siteId}/powers`), params);
        return data.result;
    }
    getMicroInverterActivities = async (siteId, deviceId, MicroInverterActivityParam) => {
        const { data } = await this.get(apiWrapper.managerApi(`/sites/${siteId}/devices/${deviceId}/micro-inverters/activities`), MicroInverterActivityParam);
        return data.body;
    };
}
export { SmartModuleService };
