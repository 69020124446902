import { computed, defineComponent } from 'vue';
import Image2 from '@hems/component/src/image/Image2.vue';
import { TOAST_MESSAGE_STATUS_TYPE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'ToastMessage',
    components: { Image2 },
    props: {
        message: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: TOAST_MESSAGE_STATUS_TYPE.SUCCESS,
        },
    },
    setup(props) {
        const statusClass = computed(() => (props.type === TOAST_MESSAGE_STATUS_TYPE.ERROR ? 'error' : ''));
        const iconSrc = computed(() => props.type === TOAST_MESSAGE_STATUS_TYPE.SUCCESS ? 'icons/ic_check' : 'icons/ic_error');
        return {
            iconSrc,
            statusClass,
        };
    },
});
