import { defineComponent, computed } from 'vue';
import Image2 from './Image.vue';
export default defineComponent({
    name: 'ImageDescription',
    components: {
        Image2,
    },
    props: {
        messageType: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        message: {
            type: String,
            default: '',
        },
        fontColor: {
            type: String,
            default: '#000000',
        },
    },
    setup(props) {
        const description = computed(() => props.message);
        function getBackgroundColor(messageType) {
            if (messageType === 'info') {
                return '#f6f6f6';
            }
            if (messageType === 'warn') {
                return '#fff4f5';
            }
            if (messageType === 'notice') {
                return '#fff9f3';
            }
            return '';
        }
        function getImageSrc(messageType) {
            if (messageType === 'info') {
                return 'svg/ic_re_info_default';
            }
            if (messageType === 'warn') {
                return 'svg/ic_error';
            }
            if (messageType === 'notice') {
                return 'svg/ic_re_info';
            }
            return '';
        }
        return {
            description,
            getBackgroundColor,
            getImageSrc,
        };
    },
});
