import omit from 'lodash/omit';
import { EnergyManagementService } from '@hems/service';
import { ENERGY_MANAGEMENT_SETTING_TYPE } from '@hems/util/src/constant/energyManagement';
export const initState = {
    siteId: null,
    productModelName: '',
    scheduleInfoList: [],
    presetInfoList: [],
    presetOperationMap: {},
    masterDeviceId: '',
    defaultOperation: null,
    currentEnergyManagementSettingType: ENERGY_MANAGEMENT_SETTING_TYPE.SCHEDULE,
    selectedOperationInfo: null,
    selectedScheduleInfo: null,
    weeklySchedulerEventList: [],
};
const prefix = 'energyManagement';
export const ENERGY_MANAGEMENT_ACTION = {
    SET_SITE_ID: `${prefix}/setSiteId`,
    SET_PRODUCT_MODEL_NAME: `${prefix}/setProductModelName`,
    SET_SCHEDULE_INFO_LIST_AND_DEFAULT_MODE: `${prefix}/setScheduleInfoListAndDefaultMode`,
    SET_SCHEDULE_NAME_AND_DATE: `${prefix}/setScheduleNameAndDate`,
    SET_SCHEDULE_OPERATION: `${prefix}/setScheduleOperation`,
    SET_PRESET_INFO_LIST_AND_OPERATION_MAP: `${prefix}/setPresetInfoListAndOperationMap`,
    SET_PRESET_OPERATION_MAP: `${prefix}/setPresetOperationMap`,
    SET_MASTER_DEVICE_ID: `${prefix}/setMasterDeviceId`,
    SET_DEFAULT_MODE: `${prefix}/setDefaultMode`,
    SET_CURRENT_ENERGY_MANAGEMENT_SETTING_TYPE: `${prefix}/setCurrentEnergyManagementSettingType`,
    SET_SELECTED_OPERATION_INFO: `${prefix}/setSelectedOperationInfo`,
    CLEAR: `${prefix}/clear`,
};
export const ENERGY_MANAGEMENT_MUTATION = {
    SET_SCHEDULE_INFO_LIST: `${prefix}/setScheduleInfoList`,
    ADD_SCHEDULE_OPERATION: `${prefix}/addScheduleOperation`,
    EDIT_SCHEDULE_OPERATION: `${prefix}/editScheduleOperation`,
    DELETE_SCHEDULE_OPERATION: `${prefix}/deleteScheduleOperation`,
    UPDATE_IS_SAVE_REQUIRED_OF_SCHEDULE: `${prefix}/updateIsSaveRequiredOfSchedule`,
    UPDATE_SCHEDULE_INFO: `${prefix}/updateScheduleInfo`,
    UPDATE_WEEKLY_SCHEDULER_EVENT_LIST: `${prefix}/updateWeeklySchedulerEventList`,
    SET_SELECTED_OPERATION_INFO: `${prefix}/setSelectedOperationInfo`,
    SET_SELECTED_SCHEDULE_INFO: `${prefix}/setSelectedScheduleInfo`,
    CLEAR_PRESET_SCHEDULE_INFO: `${prefix}/clearPresetScheduleInfo`,
};
export default {
    namespaced: true,
    state: { ...initState },
    mutations: {
        setSiteId: (state, payload) => {
            state.siteId = payload;
        },
        setProductModelName: (state, payload) => {
            state.productModelName = payload;
        },
        setScheduleInfoList: (state, payload) => {
            state.scheduleInfoList = payload;
        },
        setScheduleNameAndDate: (state, payload) => {
            const isExistSchedule = state.scheduleInfoList.find((schedule) => schedule.name === payload.name);
            if (isExistSchedule) {
                state.scheduleInfoList = state.scheduleInfoList.map((schedule) => {
                    if (schedule.name === payload.name) {
                        return { ...schedule, startDate: payload.startDate, endDate: payload.endDate };
                    }
                    return schedule;
                });
                return;
            }
            state.scheduleInfoList.push({ ...payload, operations: [] });
        },
        addScheduleOperation: (state, payload) => {
            state.scheduleInfoList = state.scheduleInfoList.map((schedule) => {
                if (schedule.name === payload.scheduleName) {
                    return { ...schedule, operations: [...schedule.operations, payload.operation] };
                }
                return schedule;
            });
        },
        editScheduleOperation: (state, payload) => {
            state.scheduleInfoList = state.scheduleInfoList.map((schedule) => {
                if (schedule.name === payload.scheduleName) {
                    return {
                        ...schedule,
                        operations: schedule.operations.map((item) => {
                            if (item.operationId === payload.operation.operationId) {
                                return payload.operation;
                            }
                            return item;
                        }),
                    };
                }
                return schedule;
            });
        },
        deleteScheduleOperation: (state, payload) => {
            state.scheduleInfoList = state.scheduleInfoList.map((schedule) => {
                if (schedule.name === payload.scheduleName) {
                    return {
                        ...schedule,
                        operations: schedule.operations.filter((operation) => !payload.operationIdList.includes(operation.operationId)),
                    };
                }
                return schedule;
            });
        },
        updateIsSaveRequiredOfSchedule: (state, payload) => {
            state.scheduleInfoList = state.scheduleInfoList.map((schedule) => {
                if (schedule.name === payload.scheduleName) {
                    return {
                        ...schedule,
                        isSaveRequired: payload.isSaveRequired,
                    };
                }
                return schedule;
            });
        },
        updateScheduleInfo: (state, payload) => {
            state.scheduleInfoList = state.scheduleInfoList.map((schedule) => {
                if (schedule.name === payload.beforeName) {
                    return {
                        ...payload.scheduleInfo,
                        isSaveRequired: true,
                    };
                }
                return schedule;
            });
        },
        setPresetInfoList: (state, payload) => {
            state.presetInfoList = payload;
        },
        setPresetOperationMap: (state, payload) => {
            state.presetOperationMap = payload;
        },
        setMasterDeviceId: (state, payload) => {
            state.masterDeviceId = payload;
        },
        setDefaultMode: (state, payload) => {
            state.defaultOperation = payload;
        },
        setCurrentEnergyManagementSettingType: (state, payload) => {
            state.currentEnergyManagementSettingType = payload;
        },
        setSelectedOperationInfo: (state, payload) => {
            state.selectedOperationInfo = payload;
        },
        setSelectedScheduleInfo: (state, payload) => {
            state.selectedScheduleInfo = payload;
        },
        updateWeeklySchedulerEventList: (state, payload) => {
            state.weeklySchedulerEventList = payload;
        },
        clearPresetScheduleInfo: (state) => {
            Object.assign(state, omit({ ...initState }, 'siteId', 'productModelName', 'masterDeviceId'));
        },
        clear: (state) => {
            Object.assign(state, { ...initState });
        },
    },
    actions: {
        setSiteId: ({ commit }, payload) => {
            commit('setSiteId', payload);
        },
        setProductModelName: ({ commit }, payload) => {
            commit('setProductModelName', payload);
        },
        setScheduleInfoListAndDefaultMode: async ({ commit }, siteId) => {
            const energyManagementService = new EnergyManagementService(window.axiosInstance.axios);
            try {
                const scheduleListResponse = await energyManagementService.getScheduleList(siteId);
                if (!scheduleListResponse.body)
                    return;
                commit('setScheduleInfoList', scheduleListResponse.body.schedules);
                commit('setDefaultMode', scheduleListResponse.body.defaultOperation);
                scheduleListResponse.body.schedules.forEach((schedule) => {
                    commit('updateIsSaveRequiredOfSchedule', { scheduleName: schedule.name, isSaveRequired: false });
                });
            }
            catch (e) {
                console.error(e);
            }
        },
        setScheduleNameAndDate: ({ commit }, payload) => {
            commit('setScheduleNameAndDate', payload);
        },
        setPresetInfoListAndOperationMap: async ({ commit }, siteId) => {
            const energyManagementService = new EnergyManagementService(window.axiosInstance.axios);
            try {
                const presetListResponse = await energyManagementService.getPresetList(siteId);
                commit('setPresetInfoList', presetListResponse.body);
                const presetOperationPromiseList = presetListResponse.body.map((preset) => () => energyManagementService.getPresetOperationList(siteId, preset.presetId));
                const presetOperationList = await Promise.all(presetOperationPromiseList.map((presetOperationPromise) => presetOperationPromise()));
                const presetOperationMap = {};
                presetOperationList.forEach((operation, index) => {
                    presetOperationMap[presetListResponse.body[index].presetId] = operation.body;
                });
                commit('setPresetOperationMap', presetOperationMap);
            }
            catch (e) {
                console.error(e);
            }
        },
        setPresetOperationMap: ({ commit }, payload) => {
            commit('setPresetOperationMap', payload);
        },
        setMasterDeviceId: ({ commit }, payload) => {
            commit('setMasterDeviceId', payload);
        },
        setDefaultMode: ({ commit }, payload) => {
            commit('setDefaultMode', payload);
        },
        setCurrentEnergyManagementSettingType: ({ commit }, payload) => {
            commit('setCurrentEnergyManagementSettingType', payload);
        },
        setSelectedOperationInfo: ({ commit }, payload) => {
            commit('setSelectedOperationInfo', payload);
        },
        clear: ({ commit }) => {
            commit('clear');
        },
    },
};
