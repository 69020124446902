import { computed, defineComponent } from 'vue';
import { DATE_UNIT } from '@hems/util/src/constant';
import { getDateDifference } from '@hems/util/src/helper/dateHelper';
export default defineComponent({
    name: 'DaysCounter',
    props: {
        startDate: {
            type: [Date, null],
            default: null,
            required: false,
        },
        endDate: {
            type: [Date, null],
            default: null,
            required: false,
        },
    },
    setup(props) {
        const daysDiff = computed(() => {
            if (!props.startDate || !props.endDate) {
                return 0;
            }
            const diff = getDateDifference({ start: props.startDate, end: props.endDate }, DATE_UNIT.DAYS);
            return diff < 0 ? 0 : diff + 1;
        });
        return {
            daysDiff,
        };
    },
});
