import { defineComponent, computed } from 'vue';
import { Image } from '@hems/component';
import { DateHelper } from '@hems/util';
export default defineComponent({
    name: 'ModulesInfo',
    components: {
        Image,
    },
    props: {
        data: {
            type: Object,
        },
        langCd: {
            type: String,
            required: true,
            default: 'en',
        },
        timezone: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const defaultWarrantyYear = '15';
        const src = computed(() => getWarrantyYear(props.data?.remain_year || defaultWarrantyYear));
        function getWarrantyYear(value) {
            return `mon/module_warranty_${value}`;
        }
        function convertToDate(value) {
            return DateHelper.getDateFormatter(props.langCd, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                timeZone: props.timezone,
            })
                .format(value * 1000)
                .toString();
        }
        return { convertToDate, src };
    },
});
