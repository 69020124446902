import { defineComponent } from 'vue';
import { defaultInputErrorInfo } from '@hems/util/src/helper/helper';
export default defineComponent({
    name: 'ToggleButton',
    props: {
        isSelected: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        errorInfo: {
            type: Object,
            default: () => defaultInputErrorInfo,
        },
    },
});
