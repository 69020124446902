import { defineComponent, nextTick, onMounted, reactive, ref } from 'vue';
import { useField } from 'vee-validate';
import { ErrorMessageLabel } from '@hems/component';
export default defineComponent({
    name: 'SignPadEditor',
    components: {
        ErrorMessageLabel,
    },
    props: {
        name: String,
        height: Number,
        width: Number,
    },
    emits: ['updateAgreeSignBinary'],
    setup(props, { emit }) {
        const state = reactive({
            height: props.height || 200,
            width: props.width || 650,
            sigImgUrl: null,
            isError: false,
        });
        const sigPad = ref(null);
        const sigPadOutput = ref(null);
        const { value: inputValue, errorMessage } = useField(props.name || 'output', undefined);
        onMounted(() => {
            if (!sigPad.value)
                return;
            $(sigPad.value).signaturePad({
                drawOnly: true,
                lineMargin: state.width * (19 / 20),
                lineTop: state.height * (8 / 10),
                validateFields: false,
                onDrawEnd() {
                    nextTick(() => {
                        if (!sigPadOutput.value) {
                            setValue('');
                            return;
                        }
                        setValue(sigPadOutput.value.value);
                    });
                },
            });
        });
        function toImage(agreeSgin) {
            const data = JSON.parse(agreeSgin || '[]');
            if (!sigPad.value)
                return;
            $(sigPad.value).signaturePad({ displayOnly: true }).regenerate(data);
            return $(sigPad.value).signaturePad().getSignatureImage();
        }
        function setValue(value) {
            inputValue.value = value;
            if (value === '') {
                emit('updateAgreeSignBinary', null);
                return;
            }
            emit('updateAgreeSignBinary', toImage(value)?.replace('data:image/png;base64,', ''));
        }
        function onClear() {
            if (!sigPad.value)
                return;
            setValue('');
        }
        return {
            state,
            sigPad,
            sigPadOutput,
            errorMessage,
            onClear,
        };
    },
});
