import { Service } from '@hems/service/src/api/Service';
import { apiWrapper } from '@hems/service/src/util/helper';
export default class NoticeService extends Service {
    constructor(axios) {
        super(axios);
    }
    async getNoticeInfo(noticeId) {
        const { data } = await this.get(apiWrapper.managerApi('/managements/notice'), { noticeId });
        return data;
    }
    async getNoticeList(params) {
        const { data } = await this.get(apiWrapper.managerApi('/managements/notice/list'), params);
        return data;
    }
    async createNotice(noticeInfo) {
        const { data } = await this.post(apiWrapper.managerApi('/managements/notice'), noticeInfo);
        return data;
    }
    async deleteNotice(noticeId) {
        const { data } = await this.delete(apiWrapper.managerApi('/managements/notice/delete'), undefined, { data: { noticeId } });
        return data;
    }
    async updateNotice(noticeInfo) {
        const { data } = await this.put(apiWrapper.managerApi('/managements/notice'), noticeInfo);
        return data;
    }
}
