/* eslint-disable */
import { computed } from 'vue';
export function useModelWrapper(props, emit, name = 'modelValue', validate = () => true) {
    return computed({
        get: () => props[name],
        set: (value) => {
            if (validate(value, props[name]))
                emit(`update:${name}`, value);
        },
    });
}
