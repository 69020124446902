import { defineComponent, ref } from 'vue';
import { SETTING_TAB_CODE, multiSettingTabName } from '@hems/util/src/constant/settings';
import Selector from '../selectors/Selector/Selector.vue';
export default defineComponent({
    name: 'MultiSettingTab',
    components: {
        Selector,
    },
    props: {
        settingTargetType: {
            type: String,
            required: true,
        },
        deviceTarget: {
            type: String,
            required: true,
        },
        selectorOptions: {
            type: Array,
            required: true,
        },
    },
    emits: ['update:settingTargetType', 'update:deviceTarget'],
    setup(props, { emit }) {
        const tabName = ref(props.settingTargetType);
        const selectedDevice = ref(props.deviceTarget);
        const switchTab = (tabname) => {
            tabName.value = tabname;
            emit('update:settingTargetType', tabName.value);
        };
        const onChange = () => {
            emit('update:deviceTarget', selectedDevice.value);
        };
        return {
            tabName,
            selectedDevice,
            multiSettingTabName,
            switchTab,
            onChange,
            SETTING_TAB_CODE,
        };
    },
});
