import { defineComponent } from 'vue';
import { MobileTemplate } from '@hems/component';
export default defineComponent({
    name: 'MobileTemplateContainer',
    components: { MobileTemplate },
    setup() {
        if (!window.appToWebCall)
            window.appToWebCall = {};
    },
});
