import { defineComponent, reactive, computed } from 'vue';
import BasicInput from '../../inputs/BasicInput.vue';
export default defineComponent({
    name: 'SimpleTable',
    components: {
        BasicInput,
    },
    props: {
        id: String,
        editable: {
            type: Boolean,
            default: false,
        },
        columns: {
            type: Array,
            required: true,
        },
        data: {
            type: Array,
            default: () => [],
        },
        sort: Function,
    },
    setup(props) {
        const state = reactive({
            data: props.data,
        });
        const contentAlign = computed(() => {
            return props.columns.reduce((prev, curr) => {
                return { ...prev, [curr.id]: curr.contentAlign ?? 'center' };
            }, {});
        });
        const orderedColumnIds = computed(() => {
            return props.columns.reduce((prev, curr) => {
                return prev.concat(curr.id);
            }, []);
        });
        return {
            state,
            orderedColumnIds,
            contentAlign,
        };
    },
});
