/** Mapper Demo Site ID List */
export const MAPPER_DEMO_SITE_ID_LIST = [100000960, 100000961];
export const MAPPER_MODULE_COLOR_CODE = {
    /** 0 : Not Connected */
    0: '#8B8B8B',
    /** 1 : Most Low */
    1: '#7386E6',
    2: '#73A2E6',
    3: '#73B6E6',
    4: '#73D3E6',
    5: '#73E6DA',
    6: '#73E6C0',
    7: '#73E686',
    8: '#9EE673',
    9: '#BFE673',
    /** 10 : Most High */
    10: '#E6E673',
};
export const MAPPER_POWER_DEFAULT_VALUE = {
    origin: 0,
    convertedEnergy: '0.0',
    unit: 'Wh',
};
export const MAPPER_POWER_UNIT_TYPE = {
    WATT: 'W',
    MILLI_WATT_HOUR: 'mWh',
    WATT_HOUR: 'Wh',
    KILO_WATT_HOUR: 'kWh',
    MEGA_WATT_HOUR: 'MWh',
};
export const MAPPER_POWER_PHASE_THRESHOLD = {
    TIMELINE: 12,
    DAY: 75,
};
const MAPPER_ERROR_MODULE_COLOR_CODE = '#FD2F40';
export const getMapperRoundedRectColor = (isError) => {
    return isError ? MAPPER_ERROR_MODULE_COLOR_CODE : '#000000';
};
const MAPPER_BACKGROUND_COLOR_DARK = '#0A0A0A';
const MAPPER_BACKGROUND_COLOR_LIGHT = '#F6F6F6';
export const getMapperBackgoundColor = (theme) => {
    if (theme === 'dark') {
        return MAPPER_BACKGROUND_COLOR_DARK;
    }
    return MAPPER_BACKGROUND_COLOR_LIGHT;
};
export const WEB_MAPPER_WIDTH = 1180;
export const WEB_MAPPER_HEIGHT = 700;
export const SCALE_FACTOR = 0.999;
export const DEFAULT_PINCH_ZOOM = 1;
export const MAX_ZOOM = 5;
export const MIN_ZOOM = 0.25;
export const DEFAULT_ZOOM_DELTA = 200; // 30;
export const TOUCH_LENGTH = 1;
export const MODULE_LANDSCAPE_WIDTH = 36;
export const MODULE_LANDSCAPE_HEIGHT = 24;
export const MODULE_PORTRAIT_WIDTH = 24;
export const MODULE_PORTRAIT_HEIGHT = 36;
export const MODULE_MARGIN = 2;
export const ARRAY_TEXT_PADDING = 30;
export const ARRAY_TEXT_HEIGHT = 16;
export const CONTROL_ICON_SIZE = 24;
export const ARRAY_SHAPE = {
    LANDSCAPE: 'landscape',
    PORTRAIT: 'portrait',
};
export const ARRAY_REVERSE_ANGLE = {
    /** array reverse 값: 180 */
    REVERSE_ANGLE: 180,
    /** landscape reverse 최소 값: 90 */
    LANDSCAPE_MIN: 90,
    /** landscape reverse 최대 값: 270 */
    LANDSCAPE_MAX: 270,
    /** portrait reverse 최소 값: 180 */
    PORTRAIT_MIN: 180,
    /** portrait reverse 최대 값: 360 */
    PORTRAIT_MAX: 360,
};
/**  하루 24시를 분단위로 변환 (24 * 60) */
export const DAY_HOUR_TO_MIN = 1440;
export const INITIAL_POWER_VALUE = '0.0';
export const INITIAL_POWER_UNIT = 'Wh';
export const MAPPER_POPUP_ICON_SIZE = {
    CHECK: 72,
    ERROR: 28,
};
export const arrayDefaultOptions = {
    subTargetCheck: true,
    perPixelTargetFind: true,
    centeredRotation: true,
    originX: 'center',
    originY: 'center',
    // scaleX: 0.5,
    // scaleY: 0.5,
    lockMovementX: false,
    lockMovementY: false,
    hasControls: true,
    borderColor: 'black',
};
export const moduleDefaultOptions = {
    type: 'group',
    originX: 'left',
    originY: 'top',
    borderColor: 'transparent',
    padding: 0,
    fill: '#00aa9e',
    subTargetCheck: false,
    lockMovementX: true,
    lockMovementY: true,
    hasControls: false,
};
export const textDefaultOptions = {
    type: 'textbox',
    originX: 'center',
    originY: 'top',
    // textAlign: 'center',
    fill: 'gray',
    fontSize: 18,
    fontFamily: 'Pretendard',
    fontWeight: 'normal',
    lineHeight: 1.2,
    charSpacing: 0.2,
    lockScalingX: false,
    lockScalingY: true,
    hasControls: true,
    left: 0,
    top: 10,
    width: 170,
    height: 70,
    splitByGrapheme: true,
    borderScaleFactor: 1.6,
    transparentCorners: false,
    cornerColor: '#ffffff',
    cornerStrokeColor: '#00c6c1',
    borderColor: '#00c6c1',
    cursorColor: '#00c6c1',
    editingBorderColor: '#00c6c1',
    // strokeWidth: 12,
    // borderDashArray: [3, 3],
};
const textBaseOptions = {
    originX: 'center',
    originY: 'top',
    textAlign: 'center',
    fontFamily: 'Pretendard',
    lockScalingX: true,
    lockScalingY: true,
    hasControls: false,
};
export function getSerialTextDefaultOptionsForEditor() {
    return {
        ...textBaseOptions,
        qtype: 'edit-serial-text',
        originX: 'right',
        originY: 'top',
        fill: '#fafafa',
        fontSize: 9,
        fontWeight: '600',
        visible: true,
    };
}
export function getPowerDataTextDefaultOptions(orientation) {
    const isLandscape = orientation === ARRAY_SHAPE.LANDSCAPE;
    return {
        ...textBaseOptions,
        originY: 'bottom',
        qtype: 'power-text',
        fill: '#fafafa',
        fontSize: 5,
        fontWeight: 600,
        lineHeight: 1,
        charSpacing: 20,
        top: isLandscape ? -1 : 1,
        visible: false,
    };
}
export function getUnitTextDefaultOptions(orientation) {
    const isLandscape = orientation === ARRAY_SHAPE.LANDSCAPE;
    return {
        ...textBaseOptions,
        qtype: 'unit-text',
        originY: 'bottom',
        fill: '#fafafa',
        fontSize: 4,
        fontWeight: 600,
        lineHeight: 1.3,
        top: isLandscape ? 4 : 6,
        visible: false,
    };
}
export function getSerialTextDefaultOptions(orientation) {
    const isLandscape = orientation === ARRAY_SHAPE.LANDSCAPE;
    return {
        ...textBaseOptions,
        qtype: 'serial-text',
        fill: '#fafafa',
        fontSize: 4,
        fontWeight: 400,
        lineHeight: 1,
        charSpacing: 20,
        top: isLandscape ? 6 : 12,
        visible: false,
    };
}
export function getSerialTextRectDefaultOptions(orientation) {
    const isLandscape = orientation === ARRAY_SHAPE.LANDSCAPE;
    const posDiff = 0.3;
    return {
        qtype: 'text-rect',
        originX: 'center',
        originY: 'top',
        width: isLandscape ? MODULE_LANDSCAPE_WIDTH : MODULE_PORTRAIT_WIDTH,
        height: MODULE_PORTRAIT_HEIGHT / 4.5,
        strokeWidth: 0,
        fill: '#000000',
        opacity: 0.4,
        left: posDiff,
        top: (isLandscape ? MODULE_LANDSCAPE_HEIGHT : MODULE_PORTRAIT_HEIGHT) / 2 - MODULE_PORTRAIT_HEIGHT / 4.5 + posDiff,
        visible: false,
    };
}
export const rectDefaultOptions = {
    type: 'rect',
    strokeWidth: 0.7,
    rx: 2,
    ry: 2,
    lockScalingX: true,
    lockScalingY: true,
    hasControls: false,
    visible: true,
};
export const commentDefaultOptions = {
    qtype: 'i-text',
    fontFamily: 'Pretendard',
    strokeWidth: 1,
    scaleX: 0.5,
    scaleY: 0.5,
    hasRotatingPoint: true,
    centerTransform: true,
};
export const stringTextDetaultOptions = {
    qtype: 'string-text',
    fontFamily: 'Pretendard',
    scaleX: 0.5,
    scaleY: 0.5,
    hasRotatingPoint: true,
    centerTransform: true,
    hasControls: false,
    borderColor: 'transparent',
};
export const controllerDetaultOptions = {
    type: 'circle',
    fill: '#ffffff',
    stroke: '#00c6c1',
    visible: true,
};
export const extraAttribute = [
    'qStringId',
    'qUid',
    'qGroupId',
    'mapperVersion',
    'qid',
    'qtype',
    'qvtype',
    'qname',
    'qshape',
    'qtilt',
    'qrotate',
    'qserial',
    'qcol',
    'qrow',
    'subTargetCheck',
    'borderColor',
    'padding',
    'textboxBorderColor',
    'lineHeight',
    'perPixelTargetFind',
    'qvisible',
    'visible',
    'hasControls',
    'text',
    'cornerColor',
    'cornerSize',
    'cornerStyle',
    'editingBorderColor',
    'arrayOrder',
];
export const mapperEditMenuList = [
    [
        { title: 'Array Build' },
        { icon: 'smartmodule/pro/ic_auto_array_default', label: 'Auto Array', value: 'auto_array' },
        { icon: 'smartmodule/pro/ic_add_module_default', label: 'Add Array', value: 'add_array' },
        { icon: 'smartmodule/pro/ic_add_text_default', label: 'Text', value: 'add_text' },
    ],
    [
        { title: 'Array Mapping' },
        { icon: 'smartmodule/pro/ic_scan_default', label: 'Scan', value: 'scan' },
        { icon: 'smartmodule/pro/ic_layout_mapping_default', label: 'List & Match', value: 'list_match' },
    ],
];
export const MAPPER_MENU = {
    AUTO_ARRAY: 'auto_array',
    ADD_ARRAY: 'add_array',
    ADD_TEXT: 'add_text',
    SCAN: 'scan',
    LIST_MATCH: 'list_match',
    EDIT_MODE: 'edit',
    LOGICAL_MODE: 'logical',
    PHYSICAL_MODE: 'physical',
};
export const MAPPER_BUTTON_TYPE = {
    ZOOM_AUTO: 'zoom_auto',
    ZOOM_IN: 'zoom_in',
    ZOOM_OUT: 'zoom_out',
    DELETE_ARRAY: 'delete_array',
};
export const MAPPER_STATUS = {
    LIST_MATCH: 'list_match',
    LIST_MATCH_SELECT: 'list_match_select',
    QR_SCAN: 'qr_scan',
    QR_SCAN_SELECT: 'qr_scan_select',
    ARRAY_EDIT: 'array_edit',
    SHOW_MODULE: 'plus',
    HIDE_MODULE: 'minus',
};
export const MAPPER_APP_TYPE = {
    HOME: 'home',
    PRO: 'pro',
};
export const MAPPER_PERIOD_DATA = {
    TIMELINE: 'timeline',
    DAY: 'day',
    MONTH: 'month',
    YEAR: 'year',
    LIFETIME: 'lifetime',
};
export const mapperEditorMenuList = [
    {
        title: 'Array Build',
        subList: [
            // @TODO 추후 Auto Array 기능 개발 시 주석 해제
            // { icon: 'smartmodule/pro/ic_auto_array_default', label: 'Auto Array', value: 'auto_array' },
            { icon: 'smartmodule/pro/ic_add_module_default', label: 'Add Array', value: 'add_array' },
            { icon: 'smartmodule/pro/ic_add_text_default', label: 'Text', value: 'add_text' },
        ],
    },
    {
        title: 'Array Mapping',
        subList: [
            { icon: 'smartmodule/pro/ic_scan_default', label: 'Scan', value: 'scan' },
            { icon: 'smartmodule/pro/ic_layout_mapping_default', label: 'List & Match', value: 'list_match' },
        ],
    },
];
export const mapperEditorMenuListForWeb = () => {
    mapperEditorMenuList[1].subList = [
        { icon: 'smartmodule/pro/ic_layout_mapping_default', label: 'List & Match', value: 'list_match' },
    ];
    return mapperEditorMenuList;
};
export const mapperLayoutMenuList = [
    {
        subList: [
            { label: 'Array Edit', value: 'edit' },
            { label: 'Logical Layout', value: MAPPER_MENU.LOGICAL_MODE },
            { label: 'Physical Layout', value: MAPPER_MENU.PHYSICAL_MODE },
        ],
    },
];
export const mapperLayoutMenuListForDemo = [
    {
        subList: [
            { label: 'Logical Layout', value: MAPPER_MENU.LOGICAL_MODE },
            { label: 'Physical Layout', value: MAPPER_MENU.PHYSICAL_MODE },
        ],
    },
];
/** AC Module 차트 관련 */
export const AC_MODULE_CHART_LABEL = {
    AC_VOLTAGE: 'AC Voltage',
    DC_VOLTAGE: 'DC Voltage',
    DC_CURRENT: 'DC Current',
    AC_POWER_PRODUCED: 'AC Power Produced',
    ENERGY_PRODUCED: 'Energy Produced',
};
export const AC_MODULE_KEY = {
    AC_VOLTAGE: 'ac_voltage',
    DC_VOLTAGE: 'dc_voltage',
    DC_CURRENT: 'dc_current',
    WATT: 'watt',
    ENERGY_ACCUM: 'energy_accum',
    TEMPERATURE: 'temperature',
    AC_FREQUENCY: 'ac_frequency',
};
export const ACModuleKeyArr = [
    AC_MODULE_KEY.AC_VOLTAGE,
    AC_MODULE_KEY.DC_VOLTAGE,
    AC_MODULE_KEY.DC_CURRENT,
    AC_MODULE_KEY.WATT,
    AC_MODULE_KEY.ENERGY_ACCUM,
    AC_MODULE_KEY.TEMPERATURE,
    AC_MODULE_KEY.AC_FREQUENCY,
];
export const ACModuleColorArr = [
    '#2caffe',
    '#544fc5',
    '#00e272',
    '#fe6a35',
    '#6b8abc',
    '#d568fb',
    '#2ee0ca',
    '#fa4b42',
    '#feb56a',
    '#91e8e1',
];
