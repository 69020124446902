export const initState = {
    userId: '',
    userNm: '',
    homeId: '',
    ownDeviceId: '',
    roleCd: '',
    roleNm: '',
};
export default {
    namespaced: true,
    state: { ...initState },
    getters: {},
    mutations: {
        clear(state) {
            state.userId = '';
            state.userNm = '';
            state.ownDeviceId = '';
            state.roleCd = '';
            state.roleNm = '';
        },
        setRole(state, payload) {
            state.roleCd = payload.roleCd;
            state.roleNm = payload.roleNm;
        },
        setUserId(state, payload) {
            state.userId = payload;
        },
    },
    actions: {
        clear({ commit }) {
            commit('clear');
        },
        setRole({ commit }, payload) {
            commit('setRole', payload);
        },
        setUserId({ commit }, payload) {
            commit('setUserId', payload);
        },
    },
};
