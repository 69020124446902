import RemoteUpdateService from './RemoteUpdateService';
import { apiWrapper } from '../../util/helper';
export default class RemoteUpdateServiceAdmin extends RemoteUpdateService {
    constructor(axios) {
        super(axios);
    }
    // 그룹 리스트
    async getRemoteUpdateGroupList(params) {
        const { data } = await this.get(apiWrapper.controlApi('/groups/firmware-updates'), params);
        return data;
    }
    // 그룹 상세 정보
    async getRemoteUpdateDetails(groupId) {
        const { data } = await this.get(apiWrapper.controlApi(`/groups/firmware-updates/${groupId}/information`));
        return data;
    }
    // 대상 ESS
    async getTargetEssList(params) {
        const { data } = await this.post(apiWrapper.managerApi('/groups/firmware-updates/devices'), {
            ...params,
            grpId: params.grpId ? `${params.grpId}` : undefined,
        });
        return data;
    }
    // 그룹명 중복 체크
    async checkGroupName(groupNm) {
        const { data } = await this.get(apiWrapper.controlApi(`/groups/firmware-updates/${groupNm}/duplications`));
        return data;
    }
    async getStableGroupByProductModelNm(productModelNm) {
        const { data } = await this.get(apiWrapper.controlApi('/groups/firmware-updates/stable/summaries'), { productModelNm });
        return data;
    }
    // Stable 그룹 정보
    async getStableGroupInfoByProductModelNm(productModelNm) {
        const { data } = await this.get(apiWrapper.controlApi('/groups/firmware-updates/stable'), { productModelNm });
        return data;
    }
    // Stable 그룹 요약 정보
    async getStableGroupSummaryByProductModelNm(productModelNm) {
        const { data } = await this.get(apiWrapper.controlApi('/groups/firmware-updates/stable/summaries'), { productModelNm });
        return data;
    }
    // Statble 취소
    async revertStable(groupId) {
        const { data } = await this.put(apiWrapper.controlApi(`/groups/firmware-updates/stable/${groupId}/revert`));
        return data;
    }
    // 업데이트 진행 Status 확인 -- TODO: 사용하지 않음 -> 삭제
    async getRemoteUpdateProcessStatus(groupId, ctrlId) {
        return await this.get(apiWrapper.adminAPI(`/upt/grp/process/${groupId}`), { grpId: groupId, ctrlId });
    }
    // 업데이트 presigned URL 정보 가져오기
    async getRemoteUpdatePreSignedUrl(productModelNm, fileName) {
        const { data } = await this.get(apiWrapper.managerApi('/managements/commons/s3/pre-signed'), { productModelNm, fileName });
        return data;
    }
    async putRemoteUpdateFile(fileURL, file) {
        if (fileURL == null || file == null)
            return;
        return await fetch(fileURL, {
            method: 'PUT',
            body: file,
        });
    }
    async saveRemoteUpdate(groupNm, params) {
        const { data } = await this.post(apiWrapper.controlApi(`/groups/firmware-updates/${groupNm}`), params);
        return data;
    }
    async getProcessResult(groupId, ctrlId) {
        const { data } = await this.get(apiWrapper.controlApi(`/groups/firmware-updates/${groupId}/progress`), { ctrlId });
        return data;
    }
}
