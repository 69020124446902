import { defineComponent, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import PopupWrapper from '@hems/component/src/popup/PopupWrapper.vue';
import { Helper, DateHelper } from '@hems/util';
export default defineComponent({
    name: 'ProcessResultPopup',
    components: { PopupWrapper },
    props: {
        processResult: {
            type: Array,
            required: true,
        },
    },
    emits: ['close'],
    setup(props, { emit }) {
        const uuid = Helper.getUUID();
        const store = useStore();
        const state = reactive({
            langCd: computed(() => store.state.appCtx.langCd),
            data: computed(() => transformData(props.processResult)),
        });
        function transformData(data) {
            const processResultItems = data.map((item) => {
                const retData = [];
                const procDate = item.response_dt == null ? '' : DateHelper.getLocalDate(item.response_dt, { isTime: true });
                retData.push({
                    response_dt_utc: procDate,
                });
                return retData;
            });
            return processResultItems;
        }
        const closePopup = () => {
            emit('close');
        };
        return {
            state,
            uuid,
            closePopup,
        };
    },
});
