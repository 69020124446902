import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ModuleGrid',
    props: {
        siteId: Number,
        deviceId: String,
        data: Array,
    },
    setup() {
        return {};
    },
});
