import { defineComponent } from 'vue';
import { isDisplayedSiteErrorInfoType } from '@hems/util/src/helper/tsguardHelper';
import ErrorCodeChipsGroup from '../chips/ErrorCodeChipsGroup.vue';
export default defineComponent({
    name: 'DashboardTable',
    components: {
        ErrorCodeChipsGroup,
    },
    props: {
        tableDataList: {
            type: Array,
            required: true,
        },
        caption: {
            type: String,
            required: true,
        },
        showCaption: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const getIndentClassName = ({ isIndent, indentDepth = 1 }) => {
            if (isIndent) {
                return `indent${indentDepth}`;
            }
        };
        return {
            getIndentClassName,
            isDisplayedSiteErrorInfoType,
        };
    },
});
