import moment from 'moment-timezone';
import { DAYS_OF_WEEK, DEFAULT_LANGUAGE, ENV_LOCALE, GRAPH_INTERVAL, GRAPH_TERM_UNIT, LANGUAGE, NORMAL_DATE_FORMAT, ONE_DAY_VALUE_IN_MILLISECONDS, UTC_TIME_ZONE, } from '@hems/util/src/constant';
import { getEnvLocale, getLangCd, padLeft, isNull } from '@hems/util/src/helper/helper';
/** 기간 조회 - Search Box의 기간 설정 용도 */
export function getDiffDate(start, end) {
    if (!end) {
        const now = new Date();
        end = String(now.getFullYear()) + String(now.getMonth() + 1) + String(now.getDate());
    }
    const year1 = Number(start.substring(0, 4));
    const month1 = Number(start.substring(4, 2)) - 1; // 1월=0,12월=11
    const day1 = Number(start.substring(6, 2));
    const hour1 = Number(start.substring(8, 2));
    const min1 = Number(start.substring(10, 2));
    const year2 = Number(end.substring(0, 4));
    const month2 = Number(end.substring(4, 2)) - 1;
    const day2 = Number(end.substring(6, 2));
    const hour2 = Number(end.substring(8, 2));
    const min2 = Number(end.substring(10, 2));
    const startDate = new Date(year1, month1, day1, hour1, min1);
    const endDate = new Date(year2, month2, day2, hour2, min2);
    const day = ONE_DAY_VALUE_IN_MILLISECONDS;
    const value = (endDate.getTime() - startDate.getTime()) / day;
    return parseInt(String(value), 10);
}
/** 일 기준 기간 조회 - 유효성 체크 용도 */
export function getRangeDate(value) {
    const startValue = moment(value.start).valueOf();
    const endValue = moment(value.end).valueOf();
    return (endValue - startValue) / ONE_DAY_VALUE_IN_MILLISECONDS;
}
/** 월 기준 기간 조회 - 유효성 체크 용도 */
export function getRangeMonth(value) {
    const startDate = moment(value.start);
    const endDate = moment(value.end);
    const startValue = startDate.year() * 12 + startDate.month();
    const endValue = endDate.year() * 12 + endDate.month();
    return endValue - startValue;
}
/** 날짜 스트링 조회 - 'YYYYMMDD' | 'YYYYMMDDHH' */
export function getDateToString(date, type) {
    return (date.getFullYear() +
        padLeft(date.getMonth() + 1, 2) +
        padLeft(date.getDate(), 2) +
        (type === GRAPH_TERM_UNIT.HOUR ? padLeft(date.getHours(), 2) : ''));
}
/** 날짜 포맷 조회 - 그리드(테이블) 용도 */
export function getGridDateFormat(termUnit, langCd) {
    if (termUnit === GRAPH_TERM_UNIT.MINUTE) {
        switch (langCd) {
            case LANGUAGE.KO:
                return 'YYYY/MM/DD';
            default:
                return 'DD/MM/YYYY';
        }
    }
    else if (termUnit === GRAPH_TERM_UNIT.HOUR) {
        switch (langCd) {
            case LANGUAGE.KO:
                return 'YYYY/MM/DD HH:mm:ss';
            default:
                return 'DD/MM/YYYY HH:mm:ss';
        }
    }
}
/** timestamp 값 -> 날짜 스트링 변환(타임존 적용) */
export function getTDUnitDateFormat(timestamp, langCd, timezone) {
    return moment.utc(timestamp).tz(timezone, true).format(getGridDateFormat(GRAPH_TERM_UNIT.HOUR, langCd));
}
/** 날짜 포맷 조회 - 캘린더 용도 */
export function getCalendarDateFormat(termUnit, langCd) {
    if (termUnit === GRAPH_TERM_UNIT.MONTH) {
        switch (langCd) {
            case LANGUAGE.KO:
                return 'YYYY/MM';
            default:
                return 'MM/YYYY';
        }
    }
    else if (termUnit === GRAPH_TERM_UNIT.MINUTE) {
        switch (langCd) {
            case LANGUAGE.KO:
                return 'YYYY/MM/DD';
            default:
                return 'DD/MM/YYYY';
        }
    }
    else if (termUnit === GRAPH_TERM_UNIT.HOUR) {
        switch (langCd) {
            case LANGUAGE.KO:
                return 'YYYY/MM/DD HH:mm';
            default:
                return 'DD/MM/YYYY HH:mm';
        }
    }
}
/** 날짜 포맷 변환 - Intl 내장 객체 적용 */
export function getDateFormatter(langCd, options = {}) {
    return new Intl.DateTimeFormat(langCd, options);
}
/** 오늘 기준 시작 Date 조회 - moment 적용 */
export function getHourlyStartValue() {
    return moment().startOf('day').toDate();
}
/** 오늘 기준 끝 Date 조회 - moment 적용 */
export function getHourlyEndValue(date) {
    return moment(date).endOf('day').toDate();
}
/** 현재 Date 조회 */
export function now() {
    return new Date();
}
/** 현재 Date 조회 - 년, 월, 일까지 적용 */
export function today() {
    return new Date(now().getFullYear(), now().getMonth(), now().getDate());
}
/** 현재 Date 포맷 - moment 적용 */
export function formatToday(format) {
    return moment().format(format);
}
/** 어제 Date 조회 - 년, 월, 일까지 적용 */
export const getYesterday = () => {
    const now = today();
    return new Date(now.setDate(now.getDate() - 1));
};
/** 2일 전 Date 조회 - 년, 월, 일까지 적용 */
export function twoDaysAgo() {
    return new Date(now().getFullYear(), now().getMonth(), now().getDate() - 2);
}
/** 1달 후 Date 조회 - 년, 월, 일까지 적용 */
export function oneMonthAfter(date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, date.getDate());
}
/** 날짜 조회 시 시작 Date 조회 (오늘 기준 6일 전) */
export function sixDaysAgo() {
    return new Date(now().getFullYear(), now().getMonth(), now().getDate() - 6);
}
/** 날짜 조회 시 끝 Date 조회 (오늘 기준 끝 시간) */
export function endDate() {
    return new Date(now().getFullYear(), now().getMonth(), now().getDate(), 23, 59, 59, 999);
}
/** 날짜 포맷 변환 - moment 적용 (기본값: 'YYYYMMDD') */
export function formatDate(date, format = NORMAL_DATE_FORMAT) {
    return moment(date).format(format);
}
/** 날짜 포맷 변환(UTC) - moment 적용 (기본값: 'YYYYMMDD') */
export const formatDateUTC = (date, format = NORMAL_DATE_FORMAT) => moment(date).utc().format(format);
/** 현재 날짜 포맷 변환 - 정규식, Intl 내장 객체 적용 */
export function getSimpleDateFormat(date, type) {
    let now = new Date();
    if (date) {
        const year = Number(date.substring(0, 4));
        const month = Number(date.substring(4, 6)) > 0 ? Number(date.substring(4, 6)) : 1;
        const day = Number(date.substring(6, 8)) > 0 ? Number(date.substring(6, 8)) : 1;
        now = new Date(year, month - 1, day);
    }
    let options = { year: 'numeric', month: 'short', day: 'numeric' };
    let replaceValue = '$2 $1';
    let additionalValue = ' ';
    if (type === 'month') {
        options = { year: 'numeric', month: 'short' };
        replaceValue = '$1 $2';
    }
    else if (type === 'year') {
        options = { year: 'numeric' };
    }
    else if (type === 'lifetime') {
        options = { year: 'numeric' };
        const today = new Date();
        additionalValue = ` - ${today.getUTCFullYear()}`;
    }
    const re = /(\w+)\s(\w+)/;
    return now.toLocaleDateString('en-US', options).replace(re, replaceValue) + additionalValue;
}
/** Interval에 따른 날짜 포맷 변환 */
export function formatDateByInterval(date, interval) {
    switch (interval) {
        case GRAPH_INTERVAL.HOURLY:
            return moment(date).format('YYYYMMDDHH');
        case GRAPH_INTERVAL.DAILY:
            return moment(date).format('YYYYMMDD');
        case GRAPH_INTERVAL.MONTHLY:
            return moment(date).format('YYYYMM');
        case GRAPH_INTERVAL.YEARLY:
            return moment(date).format('YYYY');
    }
}
/** Interval에 따른 timestamp 조회 */
export function getTime(date, interval) {
    if (typeof date == 'number')
        return 0;
    switch (interval) {
        case GRAPH_INTERVAL.HOURLY:
            return new Date(Number(date.substring(0, 4)), Number(date.substring(4, 6)) - 1, Number(date.substring(6, 8)), Number(date.substring(8, 10))).getTime();
        case GRAPH_INTERVAL.DAILY:
            return new Date(Number(date.substring(0, 4)), Number(date.substring(4, 6)) - 1, Number(date.substring(6, 8))).getTime();
        case GRAPH_INTERVAL.MONTHLY:
            return new Date(Number(date.substring(0, 4)), Number(date.substring(4, 6)) - 1).getTime();
        case GRAPH_INTERVAL.YEARLY:
            return moment(date, 'YYYY').toDate().getTime();
    }
}
/** 날짜의 '일' 반환 */
export function getNumberOfDaysByMonth(value) {
    return new Date(Number(value.substring(0, 4)), Number(value.substring(4, 6)), 0).getDate();
}
/** 끝 날짜가 시작 날짜 이후인지 체크함 */
export function isValidDateRange(start, end) {
    const startDate = start;
    const endDate = end;
    if (!startDate || !endDate)
        return true;
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
    return startDate <= endDate;
}
/** 리전에 따른 Date 포맷 조회 */
// FIXME: complexity 줄이기
// eslint-disable-next-line complexity
export function getLocalDateFormat(dateFormatConfig) {
    const { locale, isTime, isDay, isMonthly } = dateFormatConfig;
    const isSecond = dateFormatConfig.isSecond ?? true;
    if (isMonthly) {
        return 'MMM, YYYY';
    }
    switch (locale) {
        case ENV_LOCALE.EU:
        case ENV_LOCALE.AU:
        case ENV_LOCALE.NZ:
            if (isDay) {
                if (isTime && isSecond) {
                    return 'dddd, DD MMM, YYYY (HH:mm:ss)';
                }
                if (isTime) {
                    return 'dddd, DD MMM, YYYY (HH:mm)';
                }
                return 'dddd, DD MMM, YYYY';
            }
            if (isTime && isSecond) {
                return 'DD MMM, YYYY (HH:mm:ss)';
            }
            if (isTime) {
                return 'DD MMM, YYYY (HH:mm)';
            }
            return 'DD MMM, YYYY';
        case ENV_LOCALE.US:
            if (isDay) {
                if (isTime && isSecond) {
                    return 'dddd, MMM DD, YYYY (HH:mm:ss)';
                }
                if (isTime) {
                    return 'dddd, MMM DD, YYYY (HH:mm)';
                }
                return 'dddd, MMM DD, YYYY';
            }
            if (isTime && isSecond) {
                return 'MMM DD, YYYY (HH:mm:ss)';
            }
            if (isTime) {
                return 'MMM DD, YYYY (HH:mm)';
            }
            return 'MMM DD, YYYY';
        default:
            return 'DD MMM, YYYY';
    }
}
/** 리전에 따른 Date 포맷(타임존 포함) 조회 */
// FIXME: complexity 줄이기
// eslint-disable-next-line complexity
export function getDateFormatWithTimeZone(dateFormatConfig) {
    const { locale, isTime, isDay } = dateFormatConfig;
    const isSecond = dateFormatConfig.isSecond ?? true;
    switch (locale) {
        case ENV_LOCALE.EU:
        case ENV_LOCALE.AU:
        case ENV_LOCALE.NZ:
            if (isDay) {
                if (isTime && isSecond) {
                    return 'dddd, DD MMM, YYYY (HH:mm:ss z, UTCZ)';
                }
                if (isTime) {
                    return 'dddd, DD MMM, YYYY (HH:mm z, UTCZ)';
                }
                return 'dddd, DD MMM, YYYY (z, UTCZ)';
            }
            if (isTime && isSecond) {
                return 'DD MMM, YYYY (HH:mm:ss z, UTCZ)';
            }
            if (isTime) {
                return 'DD MMM, YYYY (HH:mm z, UTCZ)';
            }
            return 'DD MMM, YYYY (z, UTCZ)';
        case ENV_LOCALE.US:
            if (isDay) {
                if (isTime && isSecond) {
                    return 'dddd, MMM DD, YYYY (HH:mm:ss z, UTCZ)';
                }
                if (isTime) {
                    return 'dddd, MMM DD, YYYY (HH:mm z, UTCZ)';
                }
                return 'dddd, MMM DD, YYYY (z, UTCZ)';
            }
            if (isTime && isSecond) {
                return 'MMM DD, YYYY (HH:mm:ss z, UTCZ)';
            }
            if (isTime) {
                return 'MMM DD, YYYY (HH:mm z, UTCZ)';
            }
            return 'MMM DD, YYYY (z, UTCZ)';
        default:
            return 'DD MMM, YYYY (HH:mm:ss z, UTCZ)';
    }
}
/** 날짜 스트링 조회  'YYYY-MM-DD' */
export const getFormattedDate = (date) => moment(date).format('YYYY-MM-DD');
/** 날짜+시간 스트링 조회 'YYYY-MM-DD HH:mm:ss' */
export const getFormattedDateTime = (date) => moment(date).format('YYYY-MM-DD HH:mm:ss');
/** 리전에 따른 기본 타임존 스트링 조회 */
export function getDefaultTimeZoneByLocale(envLocale) {
    switch (envLocale) {
        case ENV_LOCALE.EU:
            return 'Europe/Berlin';
        case ENV_LOCALE.AU:
        case ENV_LOCALE.NZ:
            return 'Australia/Sydney';
        case ENV_LOCALE.US:
            return 'America/Los_Angeles';
    }
}
/**
 * locale 날짜(date)값에 대해 GMT(UTC+0) String 타입 포맷(YYYY-MM-DD HH:mm:ss)으로 변환
 * @param envLocale
 * @param date
 * @returns
 */
export const convertDefaultToGMTDateTime = (envLocale, date) => {
    const defaultDateTime = moment.tz(date, getDefaultTimeZoneByLocale(envLocale));
    const gmtDateTime = defaultDateTime.clone().tz('GMT').toDate();
    return getFormattedDateTime(gmtDateTime);
};
/**
 * timestamp 값에 대해 locale 날짜 String 타입 포맷(YYYY-MM-DD)으로 변환
 * @param envLocale
 * @param timestamp
 * @returns
 */
export function getTimestampToLocaleDate(envLocale, timestamp) {
    return new Date(moment.unix(Number(timestamp)).tz(getDefaultTimeZoneByLocale(envLocale)).format('YYYY-MM-DD'));
}
/**
 * unix timestamp 값에 대한 타임존(timezone)의 String 타입 날짜 포맷(dateFormat)으로 변환
 * @param timestamp
 * @param timezone
 * @param dateFormat
 * @returns
 */
export function getTimestampToTimeZoneDateFormat(timestamp, timezone, dateFormat) {
    try {
        return moment(timestamp).tz(timezone).format(dateFormat);
    }
    catch (error) {
        console.log(error);
        return '';
    }
}
export function getTimeZoneOffsetByLocale(envLocale, withTime = false, date) {
    const timeZoneId = getDefaultTimeZoneByLocale(envLocale);
    const offsetMinutes = date ? moment.tz(date, timeZoneId).utcOffset() : moment.tz(timeZoneId).utcOffset();
    const hours = Math.floor(Math.abs(offsetMinutes) / 60);
    const minutes = Math.abs(offsetMinutes) % 60;
    let offsetString = offsetMinutes < 0 ? `-${padLeft(hours, 2)}` : `+${padLeft(hours, 2)}`;
    if (withTime) {
        offsetString = `${offsetString}:${padLeft(minutes, 2)}`;
    }
    return offsetString;
}
/** momentTimeZone의 short term 조회 */
export function getShortFormTimeZone(momentTimeZone) {
    return moment.tz(momentTimeZone).format('z');
}
/** momentTimeZone의 long term 조회 */
export function getLongFormTimeZone(momentTimeZone) {
    const abbrs = {
        AEDT: 'Australian Eastern Daylight Time',
    };
    moment.fn.zoneName = function () {
        const abbr = this.zoneAbbr();
        return abbrs[abbr] || abbr;
    };
    return moment.tz(momentTimeZone).format('zz');
}
/** UTC 기준 날짜 포맷 변환 */
export function getUtcDate(date, locale, formatConfig) {
    return moment.utc(date).format(getDateFormatWithTimeZone({ locale, ...formatConfig }));
}
/** Date with Timezone 날짜 포맷 변환 */
export function getTimezoneDate(date, formatConfig) {
    const { needUtcConvert, timezone = UTC_TIME_ZONE } = formatConfig;
    const locale = getEnvLocale();
    const lang = getLangCd() ?? DEFAULT_LANGUAGE;
    const dateFormat = getDateFormatWithTimeZone({ locale, ...formatConfig });
    moment.locale(lang);
    if (needUtcConvert) {
        moment(date).utc().tz(timezone).format(dateFormat);
    }
    return moment.tz(date, timezone).format(dateFormat);
}
/** Date의 시작 시간 조회  */
export const getStartOfDate = (date) => moment(date).startOf('date').toDate();
/** Date의 끝 시간 조회 */
export const getEndOfDate = (date) => moment(date).endOf('date').toDate();
/** 오늘 시작, 끝 Date 조회 (포맷 변환 포함) */
export const getTodayStartEndDateTimes = (timeZone, format) => {
    const startDate = moment(getStartOfDate(new Date())).tz(timeZone).format(format);
    const endDate = moment(getEndOfDate(new Date())).tz(timeZone).format(format);
    return [startDate, endDate];
};
/** local Date String */
export function getLocalDate(date, formatConfig) {
    const { isTime, isDay, isSecond, isMonthly, needUtcConvert, needTimezoneConvert, timezone } = formatConfig;
    const locale = getEnvLocale();
    const lang = getLangCd() ?? DEFAULT_LANGUAGE;
    const dateFormat = getLocalDateFormat({ locale, isTime, isDay, isSecond, isMonthly });
    moment.locale(lang);
    if (needUtcConvert) {
        if (needTimezoneConvert && !isNull(timezone)) {
            return moment.utc(date).tz(timezone, true).format(dateFormat);
        }
        return moment.utc(date).format(dateFormat);
    }
    if (needTimezoneConvert && !isNull(timezone)) {
        return moment.tz(date, timezone).format(dateFormat);
    }
    return moment(date).format(dateFormat);
}
/** Graph Date 파라미터 */
export function getGraphDateParameter(date, termUnit) {
    if (termUnit === GRAPH_TERM_UNIT.MINUTE) {
        return moment(date).format('YYYYMMDD');
    }
    return moment(date).format('YYYYMMDDHH');
}
/** Graph Date String */
export function getConvertedGraphDateString(date, termUnit) {
    const locale = getEnvLocale();
    const lang = getLangCd() ?? DEFAULT_LANGUAGE;
    const dateFormat = termUnit === GRAPH_TERM_UNIT.MINUTE
        ? 'HH:mm'
        : getLocalDateFormat({ locale, isTime: true, isDay: false, isSecond: false });
    moment.locale(lang);
    return moment(date, 'YYYYMMDDHHmm').format(dateFormat);
}
/** 두 날짜의 차이 조회 (일, 월, 년) */
export const getDateDifference = (date, dateUnit) => {
    return moment(date.end).diff(moment(date.start), dateUnit);
};
/** 시간 설정 옵션으로 변경된 날짜 조회 */
export const getDateWithAdjustedTime = (date, adjustedOptions) => {
    return moment(date).set(adjustedOptions).toDate();
};
/** 타임존에 해당하는 현재 시간 조회 */
export const getCurrentHourByTimezone = (timezone) => moment().tz(timezone).hour();
/** 브라우저 타임존 조회 */
export const getBrowserTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
/** 오늘 기준 날짜 빼기 */
export const subtractDate = (amount, unit) => moment().subtract(amount, unit).toDate();
/** 시작 시간 기본 옵션 */
export const defaultStartDateTimeOptions = { hour: 0, minute: 0, second: 0, millisecond: 0 };
/** 끝 시간 기본 옵션 */
export const defaultEndDateTimeOptions = { hour: 23, minute: 59, second: 59, millisecond: 999 };
/** 기본 기간 옵션 (3일) */
export const defaultPeriod = {
    start: getDateWithAdjustedTime(twoDaysAgo(), defaultStartDateTimeOptions),
    end: getDateWithAdjustedTime(now(), defaultEndDateTimeOptions),
};
/** 31일 기간 옵션 */
export const thirtyOneDaysPeriod = {
    start: getDateWithAdjustedTime(subtractDate(30, 'days'), defaultStartDateTimeOptions),
    end: getDateWithAdjustedTime(now(), defaultEndDateTimeOptions),
};
/** 6달 기간 옵션 */
export const sixMonthsPeriod = {
    start: getDateWithAdjustedTime(subtractDate(6, 'months'), defaultStartDateTimeOptions),
    end: getDateWithAdjustedTime(now(), defaultEndDateTimeOptions),
};
/** timezone offset을 제외한 날짜 부분만 추출 */
export const extractDateWithoutOffset = (dateString) => {
    return dateString.replace(/([+-]\d{2}:\d{2}|Z)$/, '');
};
/** 기간에 포함된 날짜인지 체크 */
export const isDateInRange = (targetDate, period) => {
    return moment(targetDate).isBetween(period.start, period.end, undefined, '[]');
};
/** 이번 주의 시작일 조회 */
export const startOfWeek = moment().startOf('week').toDate();
/** 특정 Date + N days 조회 */
export const getAddedDate = (date, days) => moment(date).add(days, 'day').toDate();
const weekdayList = Object.values(DAYS_OF_WEEK).filter((day) => day !== DAYS_OF_WEEK.SATURDAY && day !== DAYS_OF_WEEK.SUNDAY);
const weekendList = Object.values(DAYS_OF_WEEK).filter((day) => day === DAYS_OF_WEEK.SATURDAY || day === DAYS_OF_WEEK.SUNDAY);
const everyDayList = Object.values(DAYS_OF_WEEK);
/** 주중 요일 배열인지 여부 */
export const isWeekdayList = (daysOfWeek) => weekdayList.every((day) => daysOfWeek.includes(day)) && !weekendList.some((day) => daysOfWeek.includes(day));
/** 주말 요일 배열인지 여부 */
export const isWeekendList = (daysOfWeek) => weekendList.every((day) => daysOfWeek.includes(day)) && !weekdayList.some((day) => daysOfWeek.includes(day));
/** 모든 요일 배열인지 여부 */
export const isEverydayList = (daysOfWeek) => everyDayList.every((day) => daysOfWeek.includes(day));
/** 날짜 - Date 타입 객체 */
export const getDateByDateString = (dateString) => moment(dateString).toDate();
