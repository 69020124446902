import { defineComponent, computed } from 'vue';
import Image2 from '@hems/component/src/image/Image2.vue';
export default defineComponent({
    name: 'IconButton',
    components: { Image2 },
    props: {
        src: {
            type: String,
            required: true,
        },
        disableSrc: {
            type: String,
            required: false,
            default: '',
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const iconSrc = computed(() => {
            if (!props.disableSrc) {
                return props.src;
            }
            return props.isDisabled ? props.disableSrc : props.src;
        });
        return { iconSrc };
    },
});
