import { computed, defineComponent, reactive, watch } from 'vue';
import moment from 'moment';
import { CalendarInput, DaysCounter } from '@hems/component/src/calendars';
import InputTitle from '@hems/component/src/title/InputTitle.vue';
import { TOU_SCHEDULE_DATE_FORMAT } from '@hems/util/src/constant';
import { getAddedDate } from '@hems/util/src/helper/dateHelper';
export default defineComponent({
    name: 'ScheduleRangeSelector',
    components: {
        InputTitle,
        CalendarInput,
        DaysCounter,
    },
    props: {
        modelValue: {
            type: Object,
            required: true,
        },
        existingScheduleRangeList: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const selectedDate = reactive({
            startDate: props.modelValue.start,
            endDate: props.modelValue.end,
        });
        const today = moment();
        const firstDayOfThisYear = today.startOf('year').toDate();
        const lastDayOfNextYear = today.add(1, 'year').endOf('year').toDate();
        const fromDateOfStartDate = computed(() => selectedDate.startDate || firstDayOfThisYear);
        const fromDateOfEndDate = computed(() => selectedDate.endDate || selectedDate.startDate);
        const checkLeapYear = (year) => year % 4 === 0;
        const getDisableLeapDayRange = (leapYear) => {
            const leapDay = moment(`${leapYear}-02-29`);
            return { start: leapDay.startOf('day').toDate(), end: leapDay.endOf('day').toDate() };
        };
        const sortedScheduleRangeList = computed(() => [...props.existingScheduleRangeList].sort((a, b) => (moment(a.start).isBefore(moment(b.start)) ? -1 : 1)));
        const existingScheduleRangeListOfTwoYear = computed(() => {
            return [
                ...sortedScheduleRangeList.value,
                ...sortedScheduleRangeList.value.map((range) => ({
                    start: moment(range.start).add(1, 'y').toDate(),
                    end: moment(range.end).add(1, 'y').toDate(),
                })),
            ];
        });
        const leapDay = computed(() => {
            const currentYear = today.year() - 1;
            const nextYear = currentYear + 1;
            let leapYear = null;
            if (checkLeapYear(currentYear)) {
                leapYear = currentYear;
            }
            else if (checkLeapYear(nextYear)) {
                leapYear = nextYear;
            }
            if (!leapYear)
                return [];
            return [getDisableLeapDayRange(leapYear)];
        });
        const disabledDates = computed(() => {
            const disabledDateRangeList = existingScheduleRangeListOfTwoYear.value;
            return [...disabledDateRangeList, ...leapDay.value];
        });
        const minDateOfStartCalendarInput = computed(() => {
            let minDate = null;
            let firstDate = firstDayOfThisYear;
            existingScheduleRangeListOfTwoYear.value.forEach((scheduleRange) => {
                const endDate = scheduleRange.end;
                if (moment(scheduleRange.start).format(TOU_SCHEDULE_DATE_FORMAT) ===
                    moment(firstDate).format(TOU_SCHEDULE_DATE_FORMAT)) {
                    minDate = getAddedDate(endDate, 1);
                    firstDate = getAddedDate(endDate, 1);
                }
            });
            return minDate || firstDayOfThisYear;
        });
        const maxDateOfEndCalendarInput = computed(() => {
            let maxDate = null;
            const maxDateOneYearFromStart = moment(selectedDate.startDate).add(1, 'year').subtract(1, 'day').toDate();
            existingScheduleRangeListOfTwoYear.value.forEach((scheduleRange) => {
                const startDate = scheduleRange.start;
                if (!maxDate && moment(selectedDate.startDate).isSameOrBefore(moment(startDate))) {
                    maxDate = moment(startDate).subtract(1, 'day').toDate();
                }
            });
            return maxDate || maxDateOneYearFromStart;
        });
        const changeStartDate = (date) => {
            emit('update:modelValue', { start: date, end: null });
        };
        const changeEndDate = (date) => {
            emit('update:modelValue', { start: selectedDate.startDate, end: date });
        };
        watch(() => props.modelValue.start, () => {
            selectedDate.startDate = props.modelValue.start;
        });
        watch(() => props.modelValue.end, () => {
            selectedDate.endDate = props.modelValue.end;
        });
        return {
            selectedDate,
            disabledDates,
            fromDateOfStartDate,
            fromDateOfEndDate,
            firstDayOfThisYear,
            lastDayOfNextYear,
            minDateOfStartCalendarInput,
            maxDateOfEndCalendarInput,
            leapDay,
            changeStartDate,
            changeEndDate,
        };
    },
});
