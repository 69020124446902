import { defineComponent, reactive, computed, watch } from 'vue';
import Multiselect from '@vueform/multiselect';
export default defineComponent({
    name: 'MultiSelector',
    components: {
        Multiselect,
    },
    props: {
        label: String,
        valueType: {
            type: String,
            default: 'string',
        },
        placeholder: String,
        options: {
            type: Array,
            required: false,
        },
        inputValue: {
            type: [],
            required: false,
        },
        modelValue: Object,
        productModelNms: {
            type: Object,
            required: false,
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            placeholder: computed(() => props.placeholder ?? 'Select an option'),
            productModelNms: computed(() => props.productModelNms),
            inputValue: props.productModelNms,
        });
        watch(() => props.modelValue, () => {
            if (state.inputValue !== props.modelValue)
                state.inputValue = props.modelValue || [];
        });
        watch(() => state.inputValue, () => {
            emit('update:modelValue', state.inputValue);
        });
        return {
            state,
            selOptions: computed(() => props.options),
        };
    },
});
