import { defineComponent, computed, onBeforeUnmount, onMounted, watch, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import EnergyFlow from '@hems/container/src/energyflow/flow/EnergyFlow.vue';
import { defaultEnergyFlow, getEnergyFlowCases, getEnergyFlowFormattedData, } from '@hems/container/src/energyflow/flow/EnergyFlowFunctions';
import EnergyFlowHomeLayer from '@hems/container/src/energyflow/flow/EnergyFlowHomeLayer.vue';
import EnergyFlowPipeLayer from '@hems/container/src/energyflow/flow/EnergyFlowPipeLayer.vue';
import EnergyFlowProductLayer from '@hems/container/src/energyflow/flow/EnergyFlowProductLayer.vue';
import GridInfo from '@hems/container/src/energyflow/flow/GridInfo.vue';
import NetworkStatus from '@hems/container/src/energyflow/flow/NetworkStatus.vue';
import OperationModeInfo from '@hems/container/src/energyflow/flow/OperationModeInfo.vue';
import ProductionInfo from '@hems/container/src/energyflow/flow/ProductionInfo.vue';
import { useGenType, useLocale } from '@hems/util';
import { GEN_TYPE, NETWORK_TYPE, PROVIDE_INJECT_KEYS } from '@hems/util/src/constant';
export default defineComponent({
    name: 'EnergyFlowContainer',
    components: {
        EnergyFlowHomeLayer,
        EnergyFlowPipeLayer,
        EnergyFlow,
        EnergyFlowProductLayer,
        NetworkStatus,
        ProductionInfo,
        OperationModeInfo,
        GridInfo,
    },
    props: {
        realtimeData: {
            type: Object,
            required: true,
        },
        isRealtimeConnected: {
            type: Boolean,
            required: false,
            default: false,
        },
        disconnect: {
            type: Boolean,
            required: false,
            default: true,
        },
        locale: {
            type: String,
            required: false,
            default: 'en',
        },
        isConnectThirdParty: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { t } = useI18n();
        const popup = inject(PROVIDE_INJECT_KEYS.CONTROL_POPUP);
        const { intlFormatLocale } = useLocale();
        const { genType } = useGenType();
        const energyFlow = ref(defaultEnergyFlow);
        const realtimeMonitoringData = computed(() => props.realtimeData);
        const formattedData = ref(getEnergyFlowFormattedData(props.realtimeData, t, intlFormatLocale.value));
        const isOnGrid = ref(true);
        const showFlow = (data) => {
            isOnGrid.value = genType.value === GEN_TYPE.GEN2 ? !data.grid_fault : data.grid_status ?? true;
            energyFlow.value = getEnergyFlowCases(data);
        };
        const clearEnergyFlow = () => {
            energyFlow.value = defaultEnergyFlow;
        };
        watch(() => realtimeMonitoringData.value, () => {
            showFlow(realtimeMonitoringData.value);
        });
        watch(() => props.disconnect, () => {
            if (props.disconnect) {
                clearEnergyFlow();
            }
        });
        watch(() => [intlFormatLocale.value, realtimeMonitoringData.value], () => {
            formattedData.value = getEnergyFlowFormattedData(realtimeMonitoringData.value, t, intlFormatLocale.value);
        });
        watch(() => props.isRealtimeConnected, clearEnergyFlow);
        onMounted(() => {
            showFlow(realtimeMonitoringData.value);
        });
        onBeforeUnmount(() => {
            clearEnergyFlow();
        });
        return {
            popup,
            genType,
            GEN_TYPE,
            isOnGrid,
            energyFlow,
            realtimeMonitoringData,
            formattedData,
            NETWORK_TYPE,
        };
    },
});
