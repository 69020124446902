import { defineComponent, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { PopupWrapper, Selector } from '@hems/component';
import { Helper } from '@hems/util';
import GeneralJoinType from '@/components/joinTypeSelection/GeneralJoinType.vue';
import InstallerJoinType from '@/components/joinTypeSelection/InstallerJoinType.vue';
import { isAU } from '@/config/ConstantPortal';
export default defineComponent({
    name: 'SelectJoinTypePopup',
    components: {
        PopupWrapper,
        GeneralJoinType,
        InstallerJoinType,
        Selector,
    },
    emits: ['close', 'joinGeneral', 'joinInstaller'],
    setup(_, { emit }) {
        const { t } = useI18n();
        const state = reactive({
            selectedLocale: null,
            defaultLocale: Helper.getEnvLocale(),
        });
        function joinGeneral() {
            const locale = (state.selectedLocale ?? state.defaultLocale);
            emit('joinGeneral', locale);
        }
        function joinInstaller() {
            const locale = (state.selectedLocale ?? state.defaultLocale);
            emit('joinInstaller', locale);
        }
        const auCountryOptions = [
            { text: t('message.select_your_country'), value: null },
            { text: 'Australia', value: 'AU' },
            { text: 'New Zealand', value: 'NZ' },
        ];
        return {
            state,
            isAU,
            joinGeneral,
            joinInstaller,
            auCountryOptions,
        };
    },
});
