import { defineComponent, ref, watch, computed } from 'vue';
import Image2 from '../image/Image2.vue';
class MessageNotification {
    _type = 'error';
    _imageName = 'ic_re_error_glass_img';
    _imageExtension = 'svg';
    _imageSize = 52;
    _title = '';
    _message = '';
    _isIcon = true;
    _isButton = true;
    _width = 400;
    _height = 268;
    _function = () => {
        return this;
    };
    constructor() {
        this.init();
    }
    init(type = 'error') {
        this._type = type;
        this._imageName = type === 'error' || type === 'confirm' ? 'ic_re_error_glass_img' : 'ic_re_check_glass_img';
        this._imageExtension = 'svg';
        this._imageSize = type === 'error' || type === 'confirm' ? 52 : 102;
        this._title = '';
        this._message = '';
        return this;
    }
    getInstance(resolve, reject) {
        return {
            on: true,
            type: this._type,
            imageName: this._imageName,
            imageExtension: this._imageExtension,
            imageSize: this._imageSize,
            title: this._title,
            message: this._message,
            isIcon: this._isIcon,
            isButton: this._isButton,
            width: this._width,
            height: this._height,
            function: this._function,
            resolve,
            reject,
        };
    }
    setMessageNotification(type, title, message) {
        this.init(type);
        this._title = title;
        this._message = message;
        return this.chainSetMessage();
    }
    chainSetMessage() {
        const self = this;
        return {
            open() {
                return new Promise((resolve, reject) => {
                    _messageNotification.value.on = false;
                    setTimeout(() => {
                        _messageNotification.value = self.getInstance(resolve, reject);
                    }, 250);
                });
            },
            setIsIcon(isIcon = true) {
                self._isIcon = isIcon;
                return this;
            },
            setIcon(iconName, iconExt, iconSize) {
                self._imageName = iconName;
                self._imageExtension = iconExt;
                self._imageSize = iconSize;
                return this;
            },
            setIsButton(isButton = true) {
                self._isButton = isButton;
                return this;
            },
            setSize(width, height) {
                self._width = width;
                self._height = height;
                return this;
            },
            setFunction(func) {
                self._function = func;
                return this;
            },
        };
    }
}
const _messageNotification = ref({});
const btnCancelEl = ref(null);
const close = () => {
    _messageNotification.value.on = false;
};
export const useMessageNotification = () => new MessageNotification();
export default defineComponent({
    name: 'MessageNotification',
    components: {
        Image2,
    },
    setup() {
        const messageNotification = computed(() => _messageNotification.value);
        const onOk = () => {
            const { resolve } = messageNotification.value;
            if (resolve)
                resolve(true);
            close();
        };
        const onCancel = () => {
            const { resolve } = messageNotification.value;
            if (resolve)
                resolve(false);
            close();
        };
        const onProcess = () => {
            onOk();
            messageNotification.value.function();
        };
        watch(() => _messageNotification.value.on, (on) => {
            if (on) {
                setTimeout(() => {
                    btnCancelEl.value?.focus();
                }, 250);
            }
        });
        return {
            messageNotification,
            onOk,
            onCancel,
            onProcess,
            btnCancelEl,
        };
    },
});
