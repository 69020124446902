import { useMessageBox as createMessageBox } from '@hems/component';
import { i18n } from '..';
export const useMessageBox = () => {
    const messageBox = createMessageBox();
    const { t } = i18n.global;
    const alertMessageBox = (message) => {
        return messageBox.alert(message).open();
    };
    const saveFailMessageBox = () => {
        return alertMessageBox(t('message.save_fail'));
    };
    const saveSuccessMessageBox = () => {
        return alertMessageBox(t('message.save_success'));
    };
    const confirmMessageBox = async (message) => {
        const isOK = await messageBox.confirm(message).open();
        return isOK;
    };
    const removeMessageBox = async (message) => {
        const isOK = await messageBox.remove(message).open();
        return isOK;
    };
    return {
        alertMessageBox,
        saveFailMessageBox,
        saveSuccessMessageBox,
        confirmMessageBox,
        removeMessageBox,
    };
};
