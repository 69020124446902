import { defineComponent } from 'vue';
import Image2 from '@hems/component/src/image/Image2.vue';
export default defineComponent({
    name: 'InputTitle',
    components: {
        Image2,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        showInfo: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['clickInfoIcon'],
});
