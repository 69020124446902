export const apiWrapper = {
    api(url) {
        return `/api/v2${url}`;
    },
    userAPI(url) {
        return `/api/v2/user${url}`;
    },
    deviceAPI(url) {
        return `/api/v2/device${url}`;
    },
    nmiAPI(url) {
        return `/api/v2/device/nmi${url}`;
    },
    monitoringAPI(url) {
        return `/api/v2/monitoring${url}`;
    },
    adminAPI(url) {
        return `/api/v2/monitoring/admin${url}`;
    },
    installerAPI(url) {
        return `/api/v2/monitoring/installer${url}`;
    },
    controlAPI(url) {
        return `/api/v2/control${url}`;
    },
    smartModuleAPI(url) {
        return `/api/v2/mlpe${url}`;
    },
    weatherAPI(url) {
        return `/weather/api/v1/sites${url}`;
    },
    notificationAPI(url) {
        return `/api/v2/notification${url}`;
    },
    daqApi(url) {
        return `${url}`;
    },
    managerApi(url) {
        return `/manager/apis/v2${url}`;
    },
    stateApi(url) {
        return `/state/apis/v2${url}`;
    },
    controlApi(url) {
        return `/control/apis/v2${url}`;
    },
    authenticationApi(url) {
        return `/authentication/apis/v2${url}`;
    },
    notificationApi(url) {
        return `/notification/apis/v2${url}`;
    },
    alarmApi(url) {
        return `/alarm/apis/v1${url}`;
    },
    managerApiV1(url) {
        return `/manager/apis/v1${url}`;
    },
};
export const getApiVersionHeader = (isNew = false) => {
    return {
        'X-API-VERSION': isNew ? '300' : '200',
        'X-API-CHANNEL': 'WEB',
    };
};
