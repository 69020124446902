import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Checkbox',
    props: {
        isChecked: {
            type: Boolean,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['updateCheckedStatus'],
    setup(_props, { emit }) {
        const onClickCheckbox = (event) => {
            if (event.target instanceof HTMLInputElement) {
                emit('updateCheckedStatus', event.target.checked);
            }
        };
        return {
            onClickCheckbox,
        };
    },
});
