import { defineComponent, computed } from 'vue';
import { EnergyIndependenceBar, InformationImage } from '@hems/component';
import { getFormattedNumberValue } from '@hems/container/src/energyflow/value/EnergyValueFunctions';
import { useLocale } from '@hems/util';
import { ENERGY_VALUE_IMAGE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'EnergyIndependence',
    components: {
        EnergyIndependenceBar,
        InformationImage,
    },
    props: {
        energyIndependenceData: {
            type: Object,
            default: null,
        },
    },
    setup(props) {
        const { intlFormatLocale } = useLocale();
        const infoImageSize = { width: '16', height: '16' };
        const todayIndependencePercent = computed(() => getFormattedNumberValue(props.energyIndependenceData?.today?.percent ?? 0, intlFormatLocale.value, 0));
        return {
            todayIndependencePercent,
            ENERGY_VALUE_IMAGE,
            infoImageSize,
        };
    },
});
