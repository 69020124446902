import { computed, defineComponent, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMessageBox } from '@hems/component';
import VCalendarWrapper from '@hems/component/src/calendars/VCalendarWrapper.vue';
import { useModelWrapper, DateHelper } from '@hems/util';
export default defineComponent({
    name: 'PricingCalendarRange',
    components: {
        VCalendarWrapper,
    },
    props: {
        modelValue: {
            type: Object,
            required: true,
        },
        format: {
            type: String,
            default: 'DD MMM, YYYY',
        },
        language: {
            type: String,
            default: 'en',
        },
        editable: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: String,
            default: 'date',
        },
        minDate: Date,
        maxDate: Date,
        validate: {
            type: Function,
            default: () => false,
        },
        isPermanent: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['permanent'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const messageBox = useMessageBox();
        const state = reactive({
            open: {
                start: false,
                end: false,
            },
            value: useModelWrapper(props, emit),
            displayStartValue: getDisplayValue(props.modelValue?.start, false),
            displayEndValue: getDisplayValue(props.modelValue?.end, props.isPermanent),
            isPermanent: props.isPermanent,
        });
        const dateMode = computed(() => (props.mode ? props.mode : 'datetime'));
        const editable = computed(() => props.editable);
        function getDisplayValue(value, isPermanent) {
            if (isPermanent) {
                return t('common.permanent');
            }
            if (value) {
                return DateHelper.getLocalDate(value, { isTime: false });
            }
            return '';
        }
        const applyPermanent = (isPermanent) => {
            state.isPermanent = isPermanent;
            state.displayEndValue = getDisplayValue(null, state.isPermanent);
            emit('permanent', state.isPermanent);
        };
        const checkValidFromToDate = () => {
            if (!DateHelper.isValidDateRange(state.value.start, state.value.end)) {
                messageBox.alert(t('message.start_before_end')).open();
                return false;
            }
            return true;
        };
        const onOpenStart = () => {
            if (editable.value)
                state.open.start = true;
        };
        const onCloseStart = () => {
            state.open.start = false;
        };
        const onOpenEnd = () => {
            if (editable.value)
                state.open.end = true;
        };
        const onCloseEnd = () => {
            state.open.end = false;
        };
        watch(() => state.value.start, (value) => {
            state.displayStartValue = getDisplayValue(value, false);
            checkValidFromToDate();
        });
        watch(() => state.value.end, (value) => {
            if (value) {
                state.isPermanent = false;
                state.displayEndValue = getDisplayValue(value, state.isPermanent);
                emit('permanent', state.isPermanent);
            }
            checkValidFromToDate();
        });
        return {
            state,
            dateMode,
            onOpenStart,
            onCloseStart,
            onOpenEnd,
            onCloseEnd,
            applyPermanent,
        };
    },
});
