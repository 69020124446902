import { computed, defineComponent, onBeforeUnmount, ref, watch } from 'vue';
import { useModal } from 'vue-final-modal';
import { useI18n } from 'vue-i18n';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { ContentModal, InputTitle, SelectNew, DaySelectorButton, ButtonNew } from '@hems/component';
import OperationDescriptionModal from '@hems/container/src/energyManagement/modal/OperationDescriptionModal.vue';
import { useEnergyManagement, useOperationOptionList } from '@hems/util';
import { TOU_OPERATION, SCHEDULER_INTERACTIVE_STATUS, SCHEDULE_EVENT_TYPE, START_HOUR, START_TIME_END_HOUR, END_TIME_END_HOUR, START_MINUTE, END_MINUTE, MINUTE_LIST_STEP, MAX_COUNT_OPERATION_PER_DAY, MAX_COUNT_OPERATION_PER_WEEK, } from '@hems/util/src/constant/energyManagement';
import { convertWeeklyScheduleEventToDayCountMap, createScheduleEventByOperation, getOperationTimeFromStartAndEndTime, getTimeStringWithOperationTime, initialOperationTime, isDuplicatedScheduleEvent, } from '@hems/util/src/helper/energyManagement/energyManagementHelper';
import { getNumberLeftPadStringArrayInRange, isEmptyArray, padLeft } from '@hems/util/src/helper/helper';
export default defineComponent({
    name: 'OperationAddEditModal',
    components: {
        ContentModal,
        InputTitle,
        SelectNew,
        DaySelectorButton,
        ButtonNew,
    },
    props: {
        currentOperation: {
            type: Object,
            default: null,
        },
        currentOperationList: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['detail', 'add', 'edit', 'close', 'inputEdited'],
    setup(props, { emit }) {
        const teleportTo = '.operation-setting-container';
        const { t } = useI18n();
        const copyCurrentOperation = cloneDeep(props.currentOperation);
        const operationOptionList = useOperationOptionList().operationOptionList;
        const { totalOperationCount, weeklySchedulerEventList, updateWeeklySchedulerEventList } = useEnergyManagement();
        const operationId = copyCurrentOperation ? copyCurrentOperation.operationId : crypto.randomUUID();
        const isNew = computed(() => !copyCurrentOperation);
        const title = computed(() => (isNew.value ? t('message.add_operation') : t('message.edit_operation')));
        const isEveryTimeValue = computed(() => Object.values(operationTime.value).every((time) => !!time));
        const inputErrorInfo = computed(() => ({ isError: isDuplicated.value, errorMessage: '' }));
        const isEveryInputNotEmpty = computed(() => isEveryTimeValue.value && !isEmptyArray(operationDaysOfWeek.value) && !!operation.value);
        const isSavable = computed(() => isInputEdited.value &&
            isEveryInputNotEmpty.value &&
            !isDuplicated.value &&
            totalOperationCount.value <= MAX_COUNT_OPERATION_PER_WEEK);
        const isEqualStartTime = computed(() => isEqual(copyCurrentOperation?.startTime, getTimeStringWithOperationTime(operationTime.value).startTime));
        const isEqualEndTime = computed(() => isEqual(copyCurrentOperation?.endTime, getTimeStringWithOperationTime(operationTime.value).endTime));
        const isEqualDaysOfWeek = computed(() => isEqual(copyCurrentOperation?.daysOfWeek, operationDaysOfWeek.value));
        const operationInfo = computed(() => ({
            ...getTimeStringWithOperationTime(operationTime.value),
            operationId,
            daysOfWeek: [...operationDaysOfWeek.value],
            operation: operation.value ?? TOU_OPERATION.CHARGE_FROM_SOLAR,
        }));
        const operationCountByDayMap = computed(() => convertWeeklyScheduleEventToDayCountMap(operationInfo.value.operationId, weeklySchedulerEventList.value));
        const daysOfWeekDisabledList = computed(() => Object.entries(operationCountByDayMap.value).map(([key, value]) => ({
            key,
            isDisabled: value >= MAX_COUNT_OPERATION_PER_DAY,
        })));
        const operationTime = ref(copyCurrentOperation
            ? getOperationTimeFromStartAndEndTime(copyCurrentOperation?.startTime, copyCurrentOperation?.endTime)
            : initialOperationTime);
        const operationDaysOfWeek = ref(copyCurrentOperation ? [...copyCurrentOperation.daysOfWeek] : []);
        const operation = ref(copyCurrentOperation?.operation ?? null);
        const isDuplicated = ref(false);
        const isInputEdited = ref(false);
        const isDisableEndMinute = ref(false);
        const startTimeHourSelectOptionList = getNumberLeftPadStringArrayInRange(START_HOUR, START_TIME_END_HOUR).map((hour) => ({
            text: hour,
            value: hour,
        }));
        const startTimeMinuteSelectOptionList = getNumberLeftPadStringArrayInRange(START_MINUTE, END_MINUTE, MINUTE_LIST_STEP).map((minute) => ({
            text: minute,
            value: minute,
        }));
        const isEndTimeHourEqualEndHour = computed(() => operationTime.value.endHour === padLeft(END_TIME_END_HOUR, 2));
        const endTimeStartMinute = computed(() => {
            if (isEndTimeHourEqualEndHour.value ||
                !operationTime.value.endHour ||
                operationTime.value.startHour < operationTime.value.endHour) {
                return START_MINUTE;
            }
            return Number(operationTime.value.startMinute) + MINUTE_LIST_STEP;
        });
        const endTimeHourSelectOptionList = computed(() => getNumberLeftPadStringArrayInRange(Number(operationTime.value.startHour), END_TIME_END_HOUR).map((hour) => ({
            text: hour,
            value: hour,
        })));
        const endTimeMinuteSelectOptionList = computed(() => getNumberLeftPadStringArrayInRange(endTimeStartMinute.value, END_MINUTE, MINUTE_LIST_STEP).map((hour) => ({
            text: hour,
            value: hour,
        })));
        const resetInputValues = () => {
            operationTime.value.startHour = '';
            operationTime.value.endHour = '';
            operationTime.value.startMinute = '';
            operationTime.value.endMinute = '';
        };
        const onSaveOperation = () => {
            if (isNew.value) {
                emit('add', operationInfo.value);
            }
            else {
                emit('edit', operationInfo.value);
            }
        };
        const onDetailOperation = () => {
            const currentScheduleEventList = createScheduleEventByOperation(props.currentOperationList);
            const isEdited = !(isEqualStartTime.value && isEqualEndTime.value && isEqualDaysOfWeek.value);
            if (isEdited && isEveryTimeValue.value && !isEmptyArray(operationDaysOfWeek.value)) {
                const scheduleEventListWithDifferentId = currentScheduleEventList.filter((scheduleEvent) => scheduleEvent.id !== operationInfo.value.operationId);
                let temporaryScheduleEventList = createScheduleEventByOperation([operationInfo.value]).map((scheduleEvent) => ({
                    ...scheduleEvent,
                    eventType: SCHEDULE_EVENT_TYPE.TEMPORARY,
                }));
                isDuplicated.value = temporaryScheduleEventList.some((scheduleEvent) => isDuplicatedScheduleEvent(scheduleEventListWithDifferentId, scheduleEvent));
                if (isDuplicated.value) {
                    temporaryScheduleEventList = temporaryScheduleEventList.map((scheduleEvent) => ({
                        ...scheduleEvent,
                        eventType: SCHEDULE_EVENT_TYPE.DUPLICATED,
                    }));
                }
                emit('detail', [...scheduleEventListWithDifferentId, ...temporaryScheduleEventList]);
            }
            else {
                isDuplicated.value = false;
                emit('detail', currentScheduleEventList);
            }
        };
        const onChangeEndHour = () => {
            if (isEndTimeHourEqualEndHour.value) {
                operationTime.value.endMinute = padLeft(START_MINUTE, 2);
                isDisableEndMinute.value = true;
            }
            else {
                isDisableEndMinute.value = false;
            }
        };
        const onChangeStartTime = () => {
            operationTime.value.endHour = '';
            operationTime.value.endMinute = '';
        };
        const operationDescriptionModal = useModal({
            component: OperationDescriptionModal,
        });
        const openOperationDescriptionModal = () => operationDescriptionModal.open();
        const onCloseWithoutSave = () => {
            const savedEventList = createScheduleEventByOperation(props.currentOperationList);
            updateWeeklySchedulerEventList(savedEventList);
        };
        watch(() => operationInfo.value, () => {
            isInputEdited.value = !isEqual(copyCurrentOperation, operationInfo.value);
            emit('inputEdited', isInputEdited.value);
        }, { deep: true });
        onBeforeUnmount(() => {
            resetInputValues();
        });
        return {
            teleportTo,
            title,
            startTimeHourSelectOptionList,
            endTimeHourSelectOptionList,
            startTimeMinuteSelectOptionList,
            endTimeMinuteSelectOptionList,
            operationDaysOfWeek,
            operationOptionList,
            operationTime,
            operation,
            isSavable,
            inputErrorInfo,
            isDisableEndMinute,
            daysOfWeekDisabledList,
            SCHEDULER_INTERACTIVE_STATUS,
            onSaveOperation,
            openOperationDescriptionModal,
            onDetailOperation,
            onChangeEndHour,
            onChangeStartTime,
            onCloseWithoutSave,
        };
    },
});
