import { computed, defineComponent } from 'vue';
import _ from 'lodash';
import { Accordion, Image2 } from '@hems/component';
import { useRole } from '@hems/util';
import { SUPPORT_GUIDE_CATEGORY_CODE, SUPPORT_GUIDE_TYPE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'GuideWrapper',
    components: {
        Accordion,
        Image2,
    },
    props: {
        subtitle: {
            type: String,
            required: true,
        },
        hardwareData: {
            type: Array,
            default: () => [],
        },
        softwareData: {
            type: Array,
            default: () => [],
        },
        role: {
            type: String,
            required: true,
        },
    },
    emits: ['edit', 'create', 'delete'],
    async setup(props, { emit }) {
        const { isAdmin, isDev } = useRole();
        const isAllowedEdit = computed(() => isAdmin || isDev);
        // 카테고리 분류
        const quickGuideList = computed(() => props.hardwareData?.filter((guide) => guide.categoryCode === SUPPORT_GUIDE_CATEGORY_CODE.QUICK_GUIDE));
        const quickGuideSubTitleList = computed(() => _.uniq(quickGuideList.value?.map((guide) => guide.filterName)).sort());
        const installationManualList = computed(() => props.hardwareData?.filter((guide) => guide.categoryCode === SUPPORT_GUIDE_CATEGORY_CODE.INSTALLATION_MANUAL));
        const installationManualSubTitleList = computed(() => _.uniq(installationManualList.value?.map((guide) => guide.filterName)).sort());
        const specSheetList = computed(() => props.hardwareData?.filter((guide) => guide.categoryCode === SUPPORT_GUIDE_CATEGORY_CODE.SPEC_SHEET));
        const specSheetSubTitleList = computed(() => _.uniq(specSheetList.value?.map((guide) => guide.filterName)).sort());
        const userManualList = computed(() => props.hardwareData?.filter((guide) => guide.categoryCode === SUPPORT_GUIDE_CATEGORY_CODE.USER_MANUAL));
        const userManualSubTitleList = computed(() => _.uniq(userManualList.value?.map((guide) => guide.filterName)).sort());
        const installerAppGuideList = computed(() => props.softwareData?.filter((guide) => guide.categoryCode === SUPPORT_GUIDE_CATEGORY_CODE.INSTALLER_APP_GUIDE));
        const installerAppGuideSubTitleList = computed(() => _.uniq(installerAppGuideList.value?.map((guide) => guide.filterName)).sort());
        const installerWebGuideList = computed(() => props.softwareData?.filter((guide) => guide.categoryCode === SUPPORT_GUIDE_CATEGORY_CODE.INSTALLER_WEB_GUIDE));
        const installerWebGuideSubTitleList = computed(() => _.uniq(installerWebGuideList.value?.map((guide) => guide.filterName)).sort());
        const onCreate = () => {
            emit('create');
        };
        const onEdit = (id) => {
            emit('edit', id);
        };
        const onDelete = (id) => {
            emit('delete', id);
        };
        return {
            quickGuideList,
            quickGuideSubTitleList,
            installationManualList,
            installationManualSubTitleList,
            specSheetList,
            specSheetSubTitleList,
            userManualList,
            userManualSubTitleList,
            installerAppGuideList,
            installerAppGuideSubTitleList,
            installerWebGuideList,
            installerWebGuideSubTitleList,
            SUPPORT_GUIDE_TYPE,
            isAllowedEdit,
            onCreate,
            onEdit,
            onDelete,
        };
    },
});
