import { computed, defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Calendar, Selector, useMessageBox, Button, Panel } from '@hems/component';
import { CommonService } from '@hems/service';
import { useLocale, useRole } from '@hems/util';
import { DATE_UNIT, DAY_RANGE_31 } from '@hems/util/src/constant';
import { ERROR_LEVEL_SELECTOR_OPTIONS, ERROR_RESOLVED_FILTER } from '@hems/util/src/constant/eventHistory';
import { getLocalDateFormat, now, getDateDifference, defaultPeriod, sixMonthsPeriod, thirtyOneDaysPeriod, } from '@hems/util/src/helper/dateHelper';
import { codeNamesToSelectorOptions } from '@hems/util/src/helper/helper';
const { VCalendarRange } = Calendar;
export default defineComponent({
    name: 'EventBySiteSearchBox',
    components: {
        Panel,
        Selector,
        VCalendarRange,
        Button,
    },
    props: {
        condition: {
            type: Object,
            required: true,
        },
    },
    emits: ['search'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const messageBox = useMessageBox();
        const searchCondition = ref({
            ...props.condition,
            date: props.condition.date ?? defaultPeriod,
        });
        const { locale, isUS } = useLocale();
        const { isAdminGroup } = useRole();
        const maxDate = now();
        const errorLevelLabel = computed(() => (isUS ? t('common.error_level') : t('common.event_type')));
        const showErrorLevel = computed(() => !isUS || isAdminGroup);
        const errorResolvedFilterOptions = [
            { text: t('common.current'), value: ERROR_RESOLVED_FILTER.CURRENT },
            { text: t('common.resolved'), value: ERROR_RESOLVED_FILTER.RESOLVED },
        ];
        const commService = new CommonService(window.axiosInstance.axios);
        const errorLevelCode = ref([]);
        const errorLevelOptions = computed(() => {
            const firstItem = { text: t('common.all'), value: '' };
            if (isUS) {
                return [firstItem, ...codeNamesToSelectorOptions(ERROR_LEVEL_SELECTOR_OPTIONS, t)];
            }
            return [firstItem, ...codeNamesToSelectorOptions(errorLevelCode.value, t)];
        });
        onMounted(() => {
            getCodeGroups();
        });
        const getCodeGroups = async () => {
            const { ALARM_TYPE_CD } = await commService.getCodesByGroupCode([{ grpCd: 'ALARM_TYPE_CD' }]);
            errorLevelCode.value = ALARM_TYPE_CD;
        };
        const validateRangeDate = (value) => {
            const dateDifference = getDateDifference(value, DATE_UNIT.DAYS);
            if (dateDifference > DAY_RANGE_31) {
                messageBox.alert(t('message.period_not_exceed')).open();
                return false;
            }
            return true;
        };
        const onSearch = () => {
            if (isUS) {
                searchCondition.value.date =
                    searchCondition.value.errorResolved === ERROR_RESOLVED_FILTER.CURRENT ? sixMonthsPeriod : thirtyOneDaysPeriod;
            }
            emit('search', { ...searchCondition.value });
        };
        return {
            searchCondition,
            errorLevelOptions,
            locale,
            maxDate,
            errorLevelLabel,
            showErrorLevel,
            isUS,
            errorResolvedFilterOptions,
            validateRangeDate,
            onSearch,
            getLocalDateFormat,
        };
    },
});
