import { Service } from '@hems/service/src/api/Service';
import { apiWrapper } from '@hems/service/src/util/helper';
export default class FleetManagementService extends Service {
    constructor(axios) {
        super(axios);
    }
    getFleetManagementDetails = async (parentId, installerId) => {
        const { data } = await this.get(apiWrapper.managerApi('/managements/users/cocompanies/installers/accounts'), { parentId, installerId });
        return data;
    };
    getFleetManagementList = async (fleetManagementListParam) => {
        const { data } = await this.get(apiWrapper.managerApi('/managements/users/cocompanies/installers'), fleetManagementListParam);
        return data;
    };
    createFleetManagement = async (fleetManagementCreateRequestBody) => {
        const { status } = await this.post(apiWrapper.managerApi('/managements/users/cocompanies/installers'), fleetManagementCreateRequestBody);
        return status;
    };
    validateUserIdAndEmail = async (userId, email) => {
        const { data } = await this.get(apiWrapper.managerApi('/managements/users/validate'), { userId, email });
        return data;
    };
    deleteFleetManagementAccount = async (parentId, installerId) => {
        const { data } = await this.delete(apiWrapper.managerApi('/managements/users/cocompanies/installers'), { parentId, installerId });
        return data;
    };
    validateDuplicateAccount = async (userId) => {
        const { data } = await this.get(apiWrapper.managerApi('/managements/users/validate/duplicate'), { userId });
        return data;
    };
}
