class DeviceRouteRenderer {
    el;
    button;
    constructor(props) {
        const { $ } = window;
        const el = $('<div class="pd_small tac"></div>');
        const type = props.columnInfo.renderer.options?.type;
        const isAssignedUID = props.grid.getValue(props.rowKey, 'is_assigned_uid');
        const isEmsSync = props.grid.getValue(props.rowKey, 'is_ems_sync');
        const isEnable = isAssignedUID === 'Y' && isEmsSync === 'N' ? true : false ?? false;
        this.button = $('<button class="btn_i" type="button"><span class="ir ico_arrow">상세보기</span></button>');
        switch (type) {
            case 'delete':
                if (isEnable)
                    this.button.addClass('btn_c_2');
                else
                    this.button.addClass('btn_c_1');
                break;
            case 'replacement':
                if (isEnable)
                    this.button.addClass('btn_c_3');
                else
                    this.button.addClass('btn_c_1');
                break;
            default:
                this.button.addClass('btn_c_1');
                break;
        }
        el.append(this.button);
        this.el = el.get(0);
    }
    getElement() {
        return this.el;
    }
}
export default DeviceRouteRenderer;
