import { defineComponent, reactive, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { Searchbox, HiddenSearchbox, Selector, LabelInput } from '@hems/component';
import { CommonService } from '@hems/service';
import { useModelWrapper } from '@hems/util';
export default defineComponent({
    name: 'ControlGroupSearchBox',
    components: {
        Searchbox,
        HiddenSearchbox,
        Selector,
        LabelInput,
    },
    props: {
        modelValue: {
            type: Object,
            required: true,
        },
    },
    emits: ['search'],
    async setup(props, { emit }) {
        const { t } = useI18n();
        const commService = new CommonService(window.axiosInstance.axios);
        const state = reactive({
            hide: true,
            condition: useModelWrapper(props, emit),
        });
        const { EMS_OPER_MODE_CD, ERM_MODE_1_CD, ERM_MODE_2_CD, ERM_MODE_3_CD, ERA_MODE_CD } = await commService.getCodesByGroupCode([
            { grpCd: 'EMS_OPER_MODE_CD' },
            { grpCd: 'ERM_MODE_1_CD' },
            { grpCd: 'ERM_MODE_2_CD' },
            { grpCd: 'ERM_MODE_3_CD' },
            { grpCd: 'ERA_MODE_CD' },
        ]);
        function convertOptions(codeNames) {
            const selectAll = { text: t('common.all'), value: '' };
            const result = codeNames.map((item) => ({
                value: item.code,
                text: t(`${item.name}`, { defaultValue: item.name }),
            }));
            return [selectAll].concat(result);
        }
        function onSearch() {
            emit('search');
        }
        return {
            state,
            onSearch,
            operationModeList: computed(() => convertOptions(EMS_OPER_MODE_CD || [])),
            ermMode1List: computed(() => convertOptions(ERM_MODE_1_CD || [])),
            ermMode2List: computed(() => convertOptions(ERM_MODE_2_CD || [])),
            ermMode3List: computed(() => convertOptions(ERM_MODE_3_CD || [])),
            eraModeList: computed(() => convertOptions(ERA_MODE_CD || [])),
            placeholder: computed(() => t('message.enter_search_word').concat(` (${[t('device.grp_name'), t('control.target_soc'), t('control.target_pw')].join(', ')})`)),
        };
    },
});
