class RevertStableRenderer {
    el;
    button;
    constructor(props) {
        const value = props.grid.getValue(props.rowKey, 'stable_ver_flag');
        const productModelNm = props.grid.getValue(props.rowKey, 'product_model_nm');
        const $el = $('<div style="display: flex; justify-content: center; align-items: center"><div>');
        const $value = $(`<span style="display: inline-block">${value}</span>`);
        this.button = $('<button title="change to N" style="margin-left: 1rem" type="button" class="btn_etc"><span class="ir ico_del">Delete</span></button>');
        $el.append($value);
        if (value === 'Y') {
            this.button.on('click', () => props.columnInfo.renderer.options?.click(productModelNm));
            $el.append(this.button);
        }
        this.el = $el.get(0);
        // this.render(props);
    }
    getElement() {
        return this.el;
    }
}
export default RevertStableRenderer;
