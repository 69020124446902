import { apiWrapper } from '../../util/helper';
import { Service } from '../Service';
export default class WeatherService extends Service {
    constructor(axios) {
        super(axios);
    }
    // Retrieve Current Weather data
    async getCurrentWeather(siteId) {
        return await this.get(apiWrapper.weatherAPI(`/qcells-${siteId}/currentweatherdata`));
    }
}
