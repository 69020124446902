import { Helper } from '@hems/util';
import { ENV_LOCALE } from '@hems/util/src/constant';
import { getKwhData } from '@hems/util/src/helper/helper';
import { apiWrapper } from '../../util/helper';
import { Service } from '../Service';
export default class UserReportService extends Service {
    constructor(axios) {
        super(axios);
    }
    async getServerTime(timezoneId) {
        const { data } = await this.get(apiWrapper.managerApi('/managements/commons/now'), {
            timezoneId,
        });
        return data;
    }
    async getReportsByInterval(interval, params) {
        const isUS = Helper.getEnvLocale() === ENV_LOCALE.US;
        const { from, to, type, site_id } = params;
        const reportParams = isUS ? { from, to, type, site_id, interval } : { ...params, interval };
        const { data } = await this.get(apiWrapper.managerApi('/histories/user-reports'), reportParams);
        return {
            ...data,
            result: getAccumulatedData(data.result),
        };
    }
    async getOverview(params) {
        const isUS = Helper.getEnvLocale() === ENV_LOCALE.US;
        const overviewParams = isUS ? { site_id: params.site_id } : params;
        const { data } = await this.get(apiWrapper.managerApi('/histories/user-reports/overviews'), overviewParams);
        return data;
    }
}
function getAccumulatedData(data) {
    const { gen_data = [], con_data = [], core_data = [], btc_data = [], btd_data = [], feedin_data = [], demand_data = [], } = data;
    const defaultValue = { origin: 0, convertedEnergy: '0.0', unit: 'kWh' };
    return {
        ...data,
        gen_kwh: gen_data ? getKwhData(gen_data) : defaultValue,
        con_kwh: con_data ? getKwhData(con_data) : defaultValue,
        core_kwh: core_data ? getKwhData(core_data) : defaultValue,
        btc_kwh: btc_data ? getKwhData(btc_data) : defaultValue,
        btd_kwh: btd_data ? getKwhData(btd_data) : defaultValue,
        feedin_kwh: feedin_data ? getKwhData(feedin_data) : defaultValue,
        demand_kwh: demand_data ? getKwhData(demand_data) : defaultValue,
    };
}
export { UserReportService };
