/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import _ from 'lodash';
import { checkDateRange } from '@hems/container/src/energyflow/value/energyIndendenceChartOptions';
import { Helper } from '@hems/util';
import { DEFAULT_LANGUAGE, ENERGY_PRODUCT_TYPE, LANGUAGE, ENERGY_VALUE_IMAGE } from '@hems/util/src/constant';
import { getBrowserTimezone, getCurrentHourByTimezone } from '@hems/util/src/helper/dateHelper';
export const DEFAULT_REALTIME_MONITORING_DATA = {
    grid_fault: false,
    cons_pw: 0,
    load_main_pw: 0,
    load_sub_pw: 0,
    bt_pw: 0,
    grid_pw: 0,
    pv_pw: 0,
    grid_status: true,
    time: '',
    ems_opmode: '',
    pv_fault: false,
    storage_fault: false,
};
export const getFormattedNumberValue = (numberValue, locale = DEFAULT_LANGUAGE, fractionDigits = 2) => {
    const formatter = Helper.getNumberFormatter(locale, {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    });
    return { ori: numberValue, num: formatter.format(numberValue) };
};
export const getDetail = (locale = DEFAULT_LANGUAGE, valueType = null, data, disconnect = false, t) => {
    switch (valueType) {
        case ENERGY_PRODUCT_TYPE.BATTERY: {
            if (_.isEmpty(data)) {
                return { text: t('device.battery'), highlight: false };
            }
            if (disconnect) {
                return { text: t('device.battery'), highlight: false };
            }
            if (Helper.isNull(data.bt_soc)) {
                return { text: t('device.battery'), highlight: false };
            }
            return { text: `${t('device.battery')} ${getFormattedNumberValue(data.bt_soc, locale).num}%`, highlight: true };
        }
        case ENERGY_PRODUCT_TYPE.PV:
            return { text: t('common.solar'), highlight: false };
        case ENERGY_PRODUCT_TYPE.LOAD:
            return { text: t('device.load'), highlight: false };
        default:
            return { text: '', highlight: false };
    }
};
export const getHomeEnergyDetail = () => {
    return { text: '', highlight: false };
};
export const getStatus = (valueType = null, data, disconnect = false, t) => {
    if (disconnect) {
        return getDisconnectStatus(t);
    }
    if (_.isEmpty(data)) {
        return getStandbyStatus(t);
    }
    switch (valueType) {
        case ENERGY_PRODUCT_TYPE.BATTERY:
            return getBatteryStatus(data, t);
        case ENERGY_PRODUCT_TYPE.PV:
            return getPVStatus(data, t);
        case ENERGY_PRODUCT_TYPE.LOAD:
            return getLoadStatus(data, t);
        default:
            return { text: '', highlight: false, icon: '' };
    }
};
export const getHomeEnergyStatus = (t, data) => {
    if (data && _.isEmpty(data)) {
        return { text: '', highlight: false, icon: '', isHomeAppliance: data.isHomeAppliance };
    }
    return { text: t('common.current_energy_use'), highlight: false, icon: '', isHomeAppliance: data?.isHomeAppliance };
};
export const convertWattToKilowatt = (unit, targetNumber = 0.0, language = LANGUAGE.EN) => {
    if (Math.abs(targetNumber) >= 1000) {
        return {
            ori: targetNumber,
            num: getFormattedNumberValue(Math.abs(targetNumber / 1000), language).num,
            unit: `k${unit}`,
        };
    }
    return { ori: targetNumber, num: getFormattedNumberValue(Math.abs(targetNumber), language).num, unit };
};
export const transformNegativeNumber = (num) => {
    if (num >= 0 || num <= -100)
        return num;
    return 0;
};
export const getFormattedPVValue = (pvPower, locale = DEFAULT_LANGUAGE) => {
    const transformedNumber = transformNegativeNumber(pvPower);
    const formattedPVValue = convertWattToKilowatt('W', transformedNumber, locale);
    if (transformedNumber < 0) {
        formattedPVValue.num = `-${formattedPVValue.num}`;
    }
    return formattedPVValue;
};
const getBatteryStatus = (data, t) => {
    if (data.bt_pw > 0) {
        return {
            text: t('device.discharging'),
            highlight: true,
            icon: ENERGY_VALUE_IMAGE.BATTERY_DISCHARGING_STATUS_IMAGE_PATH,
        };
    }
    if (data.bt_pw < 0) {
        return { text: t('device.charging'), highlight: true, icon: ENERGY_VALUE_IMAGE.BATTERY_CHARGING_STATUS_IMAGE_PATH };
    }
    if (data.bt_soc === 100) {
        return {
            text: t('common.fully_charged'),
            highlight: true,
            icon: ENERGY_VALUE_IMAGE.BATTERY_FULL_CHARGED_STATUS_IMAGE_PATH,
        };
    }
    return getStandbyStatus(t);
};
const getPVStatus = (data, t) => {
    if (data.pv_pw > 0) {
        return { text: t('common.producing'), highlight: true, icon: ENERGY_VALUE_IMAGE.PRODUCING_STATUS_IMAGE_PATH };
    }
    return getStandbyStatus(t);
};
const getLoadStatus = (data, t) => {
    if (data.cons_pw > 0) {
        return { text: t('common.consuming'), highlight: true, icon: ENERGY_VALUE_IMAGE.CONSUMING_STATUS_IMAGE_PATH };
    }
    return getStandbyStatus(t);
};
const getDisconnectStatus = (t) => {
    return {
        text: t('common.disconnected'),
        highlight: false,
        icon: ENERGY_VALUE_IMAGE.DISCONNECTED_STATUS_IMAGE_PATH,
    };
};
const getStandbyStatus = (t) => {
    return { text: t('device.standby'), highlight: false, icon: ENERGY_VALUE_IMAGE.STANDBY_STATUS_IMAGE_PATH };
};
export const createChartData = (totalEnergyData, energyDataList, collectedTimeDataList, timezone) => {
    const currentHourByTimezone = getCurrentHourByTimezone(timezone ?? getBrowserTimezone());
    const accumulatedDataList = energyDataList.reduce((prevAccumulatedList, data, index) => {
        if (currentHourByTimezone < index) {
            prevAccumulatedList.push(null);
            return prevAccumulatedList;
        }
        if (data !== null) {
            prevAccumulatedList.push(data + (prevAccumulatedList[index - 1] || 0));
            return prevAccumulatedList;
        }
        prevAccumulatedList.push(prevAccumulatedList[index - 1] ?? null);
        return prevAccumulatedList;
    }, []);
    const chartData = collectedTimeDataList.reduce((filteredList, time, index) => {
        if (checkDateRange(time)) {
            filteredList.push({
                x: time,
                y: totalEnergyData > 0 && accumulatedDataList[index] !== null ? accumulatedDataList[index] : null,
            });
        }
        return filteredList;
    }, []);
    return chartData;
};
