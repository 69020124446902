import { defineComponent } from 'vue';
export default defineComponent({
    name: 'InputError',
    props: {
        errorInfo: {
            type: Object,
            required: true,
        },
    },
});
