import { apiWrapper } from '../../util/helper';
import { Service } from '../Service';
export default class WebHmiManagementService extends Service {
    constructor(axios) {
        super(axios);
    }
    async getWebHmiPassword(params) {
        const { data } = await this.get(apiWrapper.managerApi('/managements/security/ems/web/hmi/passwords'), params);
        return data;
    }
}
export { WebHmiManagementService };
