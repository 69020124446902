import { isNull } from '@hems/util/src/helper/helper';
const getDescriptionValue = (description) => {
    if (typeof description === 'string') {
        return description;
    }
    return '';
};
class AlarmDetailRenderer {
    el;
    desc;
    button;
    constructor(props) {
        const { $ } = window;
        const el = $('<div class="error-description-container"></div>');
        this.desc = $('<div class="error-description-text"></div>');
        this.button = $('<div class="error-description-icon-wrapper"><div class="error-description-icon" /></div>');
        el.append(this.desc).append(this.button);
        this.el = el.get(0);
        this.render(props);
    }
    getElement() {
        return this.el;
    }
    async render(props) {
        const description = getDescriptionValue(props.value);
        if (isNull(description)) {
            this.button.hide();
            return;
        }
        this.desc.text(description);
    }
}
export default AlarmDetailRenderer;
