import { defineComponent, reactive, computed } from 'vue';
export default defineComponent({
    name: 'MultipleTableReadOnly',
    props: {
        id: String,
        editable: {
            type: Boolean,
            default: false,
        },
        columns: {
            type: Array,
            required: true,
        },
        data: {
            type: Array,
            default: () => [],
        },
        sort: Function,
    },
    setup(props, { emit }) {
        const state = reactive({
            data: props.data,
        });
        const contentAlign = computed(() => {
            return props.columns.reduce((prev, curr) => {
                return { ...prev, [curr.id]: curr.contentAlign ?? 'center' };
            }, {});
        });
        return {
            state,
            contentAlign,
        };
    },
});
