import { defineComponent, ref } from 'vue';
import { PageTemplate, GuideTabList } from '@hems/component';
import { SUPPORT_GUIDE_TYPE } from '@hems/util/src/constant';
import { ROLE_NAME } from '@hems/util/src/constant/role';
// FIXME: 절대 경로 지정 시 path가 node_modules 내로 연결되는 문제 해결 후 수정
import GuideContainer from './GuideContainer.vue';
import SupportSearchBox from '../searchbox/SupportSearchBox.vue';
export default defineComponent({
    name: 'GuideList',
    components: {
        SupportSearchBox,
        PageTemplate,
        GuideTabList,
        GuideContainer,
    },
    setup() {
        const tabName = ref(SUPPORT_GUIDE_TYPE.ALL);
        const searchCondition = ref({
            sortColumn: null,
            searchFor: null,
        });
        const onSearch = (condition) => {
            searchCondition.value = condition;
        };
        return {
            tabName,
            searchCondition,
            ROLE_NAME,
            onSearch,
        };
    },
});
