import { defineComponent, reactive } from 'vue';
import { Button } from '@hems/component';
import { Helper, useModelWrapper } from '@hems/util';
import PopupWrapper from './PopupWrapper.vue';
export default defineComponent({
    name: 'ConfirmMessagePopup',
    components: { Button, PopupWrapper },
    emits: ['confirm', 'close'],
    setup(props, { emit }) {
        const uuid = Helper.getUUID();
        const state = reactive({
            onPopup: useModelWrapper(props, emit),
        });
        const close = () => {
            emit('close');
        };
        const addTarget = () => {
            emit('confirm');
        };
        return {
            state,
            uuid,
            addTarget,
            close,
        };
    },
});
