import { defineComponent, ref } from 'vue';
export default defineComponent({
    name: 'TermsGroup',
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
        termsTitleKey: {
            type: String,
            required: true,
        },
        agreementTitleKey: {
            type: String,
            required: true,
        },
        termsContents: {
            type: String,
            required: true,
        },
        isTermsTitle: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const isAgreement = ref(props.modelValue);
        const onChangeAgreement = () => {
            emit('update:modelValue', isAgreement.value);
        };
        return {
            isAgreement,
            onChangeAgreement,
        };
    },
});
