import { auto } from '@popperjs/core';
import { CustomRenderer } from '@hems/component';
export function getColumns(t) {
    return [
        {
            header: t('common.site_id'),
            name: 'site_id',
            align: 'center',
            sortable: true,
            width: 180,
        },
        {
            header: t('device.serial_no'),
            name: 'device_id',
            align: 'center',
            sortable: true,
            width: 180,
        },
        {
            header: t('device.battery_model'),
            name: 'product_model_nm',
            align: 'center',
            resizable: true,
            whiteSpace: 'normal',
        },
        {
            header: t('device.device_type'),
            name: 'device_type_cd',
            align: 'center',
            width: 130,
        },
        {
            header: t('device.battery_capacity'),
            name: 'capacity',
            align: 'center',
            width: 135,
        },
        {
            header: t('account.id'),
            name: 'id',
            align: 'center',
            width: 120,
        },
        {
            header: t('device.install_type'),
            name: 'change_type_cd',
            align: 'center',
            width: 250,
        },
        {
            header: t('control.device_id'),
            name: 'prn_device_id',
            align: 'center',
            className: 'clickable',
            sortable: true,
            width: 180,
        },
        {
            header: t('device.module'),
            name: 'module',
            align: 'left',
            width: auto,
            resizable: true,
        },
        {
            header: t('common.create_dt'),
            name: 'create_dt_str',
            align: 'center',
            width: 165,
            className: 'overflow-visible',
            renderer: {
                type: CustomRenderer.UTCTooltipRenderer,
                options: {
                    tooltipColumnName: 'create_dt_utc_str',
                    position: ['bottom', 'left'],
                },
            },
        },
    ];
}
