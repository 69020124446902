export const STORM_COVER_BUTTON = {
    ON: 1,
    OFF: 0,
};
export const STORM_MESSAGE = {
    TITLE: 'Storm Cover',
    PROVIDER: 'Severe weather information provided by National Weather Service.',
    DETAIL: 'When Storm Cover is enabled and severe weather alert is issued, this mode will automatically prioritize charging the battery to maximum capacity to provide backup protection during potential grid outages.',
    ENABLE: 'Enable Storm Cover',
    DISABLE: 'Disable Storm Cover',
    CHARGING_FROM_GRID: 'Charging from Grid may have tax implications or be restricted by local regulations. Check with your tax advisor and installer.',
    OVERRIDE: 'When Storm Cover is activated, Storm Cover overrides your usual operation mode settings.',
};
