export const REQUIRED_TERMS_CATEGORY = {
    TERMS_OF_SERVICE: 'Terms of Service',
    TERMS_OF_REGION: 'Terms of Region',
    TERMS_OF_PRIVACY: 'Privacy Policy',
};
export const OPTIONAL_TERMS_CATEGORY = {
    TERMS_OF_INDIVIDUAL: 'Terms of Individual',
    TERMS_OF_DIRECT_MARKETING: 'Terms of DirectMarketing',
};
export const TERMS_CATEGORY = {
    ...REQUIRED_TERMS_CATEGORY,
    ...OPTIONAL_TERMS_CATEGORY,
};
export const TERMS_TYPE = {
    USER: 'USER',
    INSTALLER: 'INSTALLER',
    SITE: 'SITE',
};
