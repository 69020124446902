import { computed, defineComponent, reactive, watch } from 'vue';
export default defineComponent({
    name: 'BasicRadio',
    inheritAttrs: false,
    props: {
        name: {
            type: String,
            required: true,
        },
        value: {
            type: [String, Number, Boolean],
            required: true,
        },
        modelValue: {
            type: [String, Number, Boolean],
            required: true,
        },
        readonly: {
            type: [String, Boolean],
            default: false,
        },
        valueType: {
            type: String,
            default: 'string',
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const state = reactive({
            isReadonly: computed(() => !!(props.readonly === '' || props.readonly)),
            value: transformValue(props.modelValue),
        });
        watch(() => props.modelValue, () => {
            const value = transformValue(props.modelValue);
            if (state.value !== value)
                state.value = value;
        });
        function transformValue(value) {
            if (value === undefined || value === null)
                return null;
            if (props.valueType === 'boolean')
                return Boolean(value);
            if (props.valueType === 'number')
                return Number(value);
            return value;
        }
        function onClick(e) {
            if (state.isReadonly) {
                e.preventDefault();
            }
        }
        function onChange(e) {
            const target = e.target;
            emit('update:modelValue', transformValue(target.value));
        }
        return {
            state,
            onClick,
            onChange,
            transformValue,
        };
    },
});
