import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { EventDataGrid } from '@hems/container/src/dataGrid';
import EventBySiteSearchBox from '@hems/container/src/searchbox/EventBySiteSearchBox.vue';
import { useLocale, useSiteId } from '@hems/util';
import { ERROR_HISTORY_GRID_TYPE, ERROR_RESOLVED_FILTER } from '@hems/util/src/constant/eventHistory';
import { sixMonthsPeriod, defaultPeriod } from '@hems/util/src/helper/dateHelper';
export default defineComponent({
    name: 'SiteEventContainer',
    components: {
        EventBySiteSearchBox,
        EventDataGrid,
    },
    setup() {
        const store = useStore();
        const { isUS } = useLocale();
        const deviceId = computed(() => store.state.device.device_info.device_id);
        const { siteId } = useSiteId();
        const searchCondition = ref({
            date: isUS ? sixMonthsPeriod : defaultPeriod,
            errorLevel: null,
            search: isUS ? String(siteId) : deviceId.value,
            errorResolved: ERROR_RESOLVED_FILTER.CURRENT,
        });
        const onSearch = (condition) => {
            if (isUS) {
                if (condition.search !== String(siteId)) {
                    condition.search = String(siteId);
                }
            }
            else if (condition.search !== deviceId.value) {
                condition.search = deviceId.value;
            }
            searchCondition.value = condition;
        };
        return {
            searchCondition,
            ERROR_HISTORY_GRID_TYPE,
            onSearch,
        };
    },
});
