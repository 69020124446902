import { computed, defineComponent, reactive } from 'vue';
import { Form } from 'vee-validate';
export default defineComponent({
    name: 'GroupInfoForm',
    components: {
        Form,
    },
    props: {
        mode: {
            type: String,
            default: 'read',
        },
        title: {
            type: String,
            default: 'Group Information',
        },
        schema: {
            type: Object,
        },
    },
    emits: ['next', 'goToList'],
    setup(props, { emit }) {
        const state = reactive({
            mode: computed(() => props.mode),
        });
        function onSave() {
            emit('next');
        }
        function goToList() {
            emit('goToList');
        }
        return {
            state,
            onSave,
            goToList,
        };
    },
});
