// https://github.com/vueform/slider
import { defineComponent, reactive, computed } from 'vue';
import Slider from '@vueform/slider';
export default defineComponent({
    name: 'BasicSlider',
    components: {
        Slider,
    },
    inheritAttrs: false,
    props: {
        modelValue: {
            type: [Number, Array],
            default: 0,
        },
        tooltipEnable: {
            type: Boolean,
            default: true,
        },
    },
    setup(props) {
        const state = reactive({
            value: computed(() => props.modelValue ?? 0),
            tooltipEnable: props.tooltipEnable ? 'tooltipEnable' : 'tooltipDisable',
        });
        return {
            state,
        };
    },
});
