import { defineComponent, reactive } from 'vue';
import { useModelWrapper } from '@hems/util';
export default defineComponent({
    name: 'GroupDetailsTabContainer',
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        tabs: {
            type: Array,
            required: true,
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            tabname: useModelWrapper(props, emit),
        });
        const switchTab = (tabname) => {
            state.tabname = tabname;
        };
        return {
            state,
            switchTab,
        };
    },
});
