import { defineComponent, watch } from 'vue';
import { useField } from 'vee-validate';
import { ErrorMessageLabel, Image2 } from '@hems/component';
import { Helper } from '@hems/util';
import BasicRadio from './BasicRadio.vue';
export default defineComponent({
    name: 'RadioImageGroup',
    components: {
        BasicRadio,
        ErrorMessageLabel,
        Image2,
    },
    inheritAttrs: false,
    props: {
        name: {
            type: String,
            default: '',
        },
        modelValue: {
            type: [String, Number, Boolean],
            required: true,
        },
        maxColumnNum: {
            type: Number,
            default: 4,
        },
        orientationOptions: {
            type: Array,
            required: true,
        },
        direction: {
            type: String,
            default: 'row',
        },
        align: {
            type: String,
            default: 'left',
        },
        valueType: {
            type: String,
            default: 'string',
        },
        notModify: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const inputName = props.name || Helper.getUUID();
        const { value: inputValue, errorMessage } = useField(inputName, undefined, {
            initialValue: props.modelValue,
            type: props.valueType,
        });
        watch(() => inputValue.value, () => {
            emit('update:modelValue', inputValue.value);
        });
        watch(() => props.modelValue, () => {
            if (inputValue.value !== props.modelValue)
                inputValue.value = props.modelValue || '';
        });
        return {
            errorMessage,
            inputValue,
            inputName,
        };
    },
});
