import { computed, defineComponent, onMounted, reactive, watch } from 'vue';
import { Helper } from '@hems/util';
export default defineComponent({
    name: 'IncreasingNumberLabel',
    props: {
        targetNumber: {
            type: Number,
            default: 0,
        },
        seconds: {
            type: Number,
            default: 2,
        },
        decimal: {
            type: Number,
            default: 0,
        },
        locale: {
            type: String,
            default: 'en',
        },
    },
    setup(props) {
        const state = reactive({
            number: 0,
            formatNumber: computed(() => Helper.getNumberFormatter(props.locale, {
                minimumFractionDigits: props.decimal,
                maximumFractionDigits: props.decimal,
            }).format(state.number)),
        });
        const loop = (repeat, delay, func) => {
            if (repeat <= 0)
                return;
            setTimeout(() => {
                func();
                loop(repeat - 1, delay, func);
            }, delay);
        };
        const run = () => {
            const isNegative = props.targetNumber < 0;
            const absNum = Math.abs(props.targetNumber);
            const _increaseUnit = Math.ceil(absNum / 100);
            const increaseUnit = _increaseUnit > 10000
                ? Math.ceil(_increaseUnit / 1000) * 1000
                : _increaseUnit > 1000
                    ? Math.ceil(_increaseUnit / 100) * 100
                    : _increaseUnit > 100
                        ? Math.ceil(_increaseUnit / 10) * 10
                        : _increaseUnit;
            const repeatNum = Math.ceil(absNum / increaseUnit);
            const delay = (props.seconds * 1000) / repeatNum;
            loop(repeatNum, delay, () => {
                if (Math.abs(state.number) + increaseUnit > absNum) {
                    state.number = props.targetNumber;
                    return;
                }
                if (isNegative) {
                    state.number -= increaseUnit;
                    return;
                }
                state.number += increaseUnit;
            });
        };
        watch(() => props.targetNumber, run);
        onMounted(() => {
            run();
        });
        return {
            state,
        };
    },
});
