import { computed, defineComponent, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { LabelInput, Selector } from '@hems/component';
import { CommonService } from '@hems/service';
import { Helper, useModelWrapper } from '@hems/util';
export default defineComponent({
    name: 'TargetEssSearchBox',
    components: {
        LabelInput,
        Selector,
    },
    props: {
        modelValue: {
            type: Object,
            required: true,
        },
    },
    emits: ['search'],
    async setup(props, { emit }) {
        const { t } = useI18n();
        const commService = new CommonService(window.axiosInstance.axios);
        const state = reactive({
            searchCondition: useModelWrapper(props, emit),
            emsModeList: [],
        });
        const onSearch = async () => {
            emit('search');
        };
        function convertOptions(codeNames) {
            const selectAll = { text: 'All', value: '' };
            const result = codeNames.map((item) => ({
                value: item.code,
                text: t(`${item.name}`, { defaultValue: item.name }),
            }));
            return [selectAll].concat(result);
        }
        const { EMS_RUN_MODE_CD } = await commService.getCodesByGroupCode([{ grpCd: 'EMS_RUN_MODE_CD' }]);
        return {
            state,
            Helper,
            onSearch,
            options: computed(() => convertOptions(EMS_RUN_MODE_CD || [])),
        };
    },
});
