class ConnectionStatusRenderer {
    el;
    connection;
    blindValue;
    constructor(props) {
        this.connection = $('<span class="state_connect"></span>');
        this.blindValue = $('<span class="blind"></span>');
        this.connection.append(this.blindValue);
        this.el = this.connection.get(0);
        this.render(props);
    }
    getElement() {
        return this.el;
    }
    async render(props) {
        const value = props.grid.getValue(props.rowKey, 'is_connect');
        if (value) {
            this.connection.addClass('ty_1');
            this.blindValue.text('connect');
        }
        else {
            this.connection.addClass('ty_2');
            this.blindValue.text('disconnect');
        }
    }
}
export default ConnectionStatusRenderer;
