export class ErrorException extends Error {
    _message = '';
    constructor(name, message) {
        if (Array.isArray(message))
            super(message.join(','));
        else
            super(message);
        this._message = message;
        this.name = name;
    }
    getMessageJoin(divider) {
        return typeof this._message === 'string' ? this._message : this._message?.join(divider) ?? '';
    }
}
export class UnknownErrorException extends ErrorException {
    constructor() {
        super('UnknownErrorException', 'Unknown error occurred.');
    }
}
export class UnauthorizedException extends ErrorException {
    constructor(message) {
        super('UnauthorizedException', message || 'Unauthorized');
    }
}
export class ForbiddenException extends ErrorException {
    constructor(message) {
        super('ForbiddenException', message || 'Forbidden');
    }
}
export class NoArgumentsException extends ErrorException {
    constructor(message) {
        super('NoArgumentsException', message || 'NoArguments');
    }
}
export class AccessTokenParseException extends ErrorException {
    constructor(message) {
        super('AccessTokenParseException', message || 'NoArguments');
    }
}
export class UnsupportedGenType extends ErrorException {
    constructor(message) {
        super('UnsupportedGenType', `Unspported Gen Type: ${message}`);
    }
}
export class UnsupportedSettingsMessageType extends ErrorException {
    constructor(message) {
        super('UnsupportedSettingsMessageType', message || 'NoArguments');
    }
}
export class SocialLoginException extends ErrorException {
    constructor(message) {
        super('SocialLoginException', message || 'This account is not registered.');
    }
}
export class AxiosErrorException extends ErrorException {
    _url;
    _params;
    _status;
    _statusText;
    _originError;
    _data;
    constructor(url, params, e, message) {
        super('AxiosErrorException', message || e.response.statusText);
        this._url = url;
        this._params = params;
        this._status = e.response.status;
        this._statusText = e.response.statusText;
        this._originError = e;
        this._data = e.response.data;
    }
    get url() {
        return this._url;
    }
    get params() {
        return this._params;
    }
    get status() {
        return this._status;
    }
    get statusText() {
        return this._statusText;
    }
    get originError() {
        return this._originError;
    }
    get data() {
        return this._data;
    }
    afterError(fnMap) {
        if (fnMap[this._status]) {
            fnMap[this._status]();
            return true;
        }
        return false;
    }
}
export class UnregisteredProductModel extends ErrorException {
    constructor() {
        super('NonExistProductModel', 'Unregistered Product model.');
    }
}
export const STATUS_CODE = {
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NO_CONTENT: 204,
    WARNING: 235,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    DUPLICATED: 409,
    INTERNAL_SERVER_ERROR: 500,
    NOT_IMPLEMENTED: 501,
    BAD_GATEWAY: 502,
    SERVICE_UNAVAILABLE: 503,
    GATEWAY_TIMEOUT: 504,
};
