import { defineComponent } from 'vue';
import { BUTTON_NEW_SIZE, BUTTON_NEW_TYPE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'ButtonNew',
    props: {
        size: {
            type: String,
            default: BUTTON_NEW_SIZE.SMALL,
        },
        type: {
            type: String,
            default: BUTTON_NEW_TYPE.SOLID,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },
});
