export const CONTROL_CODE = {
    CONTROL_COMMAND: 'controlCommand',
    REBOOT: 'reboot',
    CHANGE_OPERATION_MODE: 'changeOperationMode',
    POWER_CONTROL_POINT: 'powerControlPoint',
    GET_SYSTEM_STATUS: 'getSystemStatus',
    CONNECT_REVERSE_SSH: 'connectReverseSsh',
    RELEASE_BMS_PROTECTION: 'releaseBmsProtection',
    RELEASE_PCS_PROTECTION: 'releasePcsProtection',
    RELEASE_EMS_PROTECTION: 'releaseEmsProtection',
    SET_OPTIMIZER_PERSONAL_COMMAND: 'setOptimizerPersonalCommand',
    RUN_OPTIMIZER_RESYNC: 'runOptimizerResync',
    RUN_ACCUMULATED_POWER_CLEAR: 'runAccumulatedPowerClear',
    SET_MODULE_INFO: 'setModuleInfo',
    SET_VC_INFO: 'setVcInfo',
    SET_DO_CONTROL_INFO: 'setDoControlInfo',
    RELEASE_HUB_PROTECTION: 'releaseHubProtection',
    REALTIME_MONITORING: 'realtimeMonitoring',
};
export const OPERATION_MODE_CODE = {
    NOP: '0',
    AUTO: '1',
    MANUAL: '2',
    RESTORE: '99',
};
export const ENGINEERING_MODE_CODE = {
    OFF: 0,
    ON: 1,
};
export const POWER_CONTROL_POINT_CODE = {
    BATTERY: '0',
    INVERTER: '1',
};
export const POWER_CONTROL_RANGE = {
    INVERTER: { MIN: -32768, MAX: 32767 },
    BATTERY: { MIN: -32768, MAX: 32767 },
    PV: { MIN: 0, MAX: 32767 },
};
export const MODULE_INFO_MODEL_CODE = {
    T: 0,
    S: 1,
};
export const ModuleInfoModelTextMap = {
    [MODULE_INFO_MODEL_CODE.T]: '2T',
    [MODULE_INFO_MODEL_CODE.S]: '2S',
};
export const VCTRL_GAIN_USAGE_CODE = {
    NOT_USE: 0,
    USE: 1,
};
export const VctrlGainUsageTextMap = {
    [VCTRL_GAIN_USAGE_CODE.NOT_USE]: 'Not Use',
    [VCTRL_GAIN_USAGE_CODE.USE]: 'Use',
};
export const REMOTE_CONTROL_FOX_COMMAND = [
    {
        code: CONTROL_CODE.REBOOT,
        name: 'code.remotecontroltypecd.0',
    },
    {
        code: CONTROL_CODE.CHANGE_OPERATION_MODE,
        name: 'code.remotecontroltypecd.1',
    },
    {
        code: CONTROL_CODE.GET_SYSTEM_STATUS,
        name: 'code.remotecontroltypecd.2',
    },
    {
        code: CONTROL_CODE.CONNECT_REVERSE_SSH,
        name: 'code.remotecontroltypecd.3',
    },
];
export const REMOTE_CONTROL_AC_SITE_COMMAND = [
    {
        code: CONTROL_CODE.REBOOT,
        name: 'code.remotecontroltypecd.0',
    },
    {
        code: CONTROL_CODE.CHANGE_OPERATION_MODE,
        name: 'code.remotecontroltypecd.1',
    },
    {
        code: CONTROL_CODE.GET_SYSTEM_STATUS,
        name: 'code.remotecontroltypecd.2',
    },
];
export const REMOTE_CONTROL_AC_DEVICE_COMMAND = REMOTE_CONTROL_FOX_COMMAND.concat([
    {
        code: CONTROL_CODE.RELEASE_BMS_PROTECTION,
        name: 'code.remotecontroltypecd.4',
    },
    {
        code: CONTROL_CODE.RELEASE_PCS_PROTECTION,
        name: 'code.remotecontroltypecd.5',
    },
    {
        code: CONTROL_CODE.RELEASE_EMS_PROTECTION,
        name: 'code.remotecontroltypecd.7',
    },
    {
        code: CONTROL_CODE.RELEASE_HUB_PROTECTION,
        name: 'code.remotecontroltypecd.14',
    },
    {
        code: CONTROL_CODE.REALTIME_MONITORING,
        name: 'code.remotecontroltypecd.15',
    },
]);
export const REMOTE_CONTROL_GEN3_COMMAND = REMOTE_CONTROL_AC_DEVICE_COMMAND.concat([
    {
        code: CONTROL_CODE.RELEASE_EMS_PROTECTION,
        name: 'code.remotecontroltypecd.7',
    },
    {
        code: CONTROL_CODE.SET_OPTIMIZER_PERSONAL_COMMAND,
        name: 'code.remotecontroltypecd.8',
    },
    {
        code: CONTROL_CODE.RUN_OPTIMIZER_RESYNC,
        name: 'code.remotecontroltypecd.9',
    },
    {
        code: CONTROL_CODE.RUN_ACCUMULATED_POWER_CLEAR,
        name: 'code.remotecontroltypecd.10',
    },
    {
        code: CONTROL_CODE.SET_MODULE_INFO,
        name: 'code.remotecontroltypecd.11',
    },
    {
        code: CONTROL_CODE.SET_VC_INFO,
        name: 'code.remotecontroltypecd.12',
    },
    {
        code: CONTROL_CODE.SET_DO_CONTROL_INFO,
        name: 'code.remotecontroltypecd.13',
    },
]);
