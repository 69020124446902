import { defineComponent } from 'vue';
import { Tooltip } from '@hems/component';
export default defineComponent({
    name: 'StableInfoItem',
    components: { Tooltip },
    props: {
        title: {
            type: String,
            required: true,
        },
        caption: {
            type: String,
            default: '',
        },
        stableData: {
            type: Object,
            default: null,
        },
        mode: {
            type: String,
            required: true,
        },
        titleWidth: {
            type: String,
            default: '100%',
        },
    },
});
