import { computed, defineComponent, reactive, ref, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMessageBox } from '@hems/component';
import { useModelWrapper } from '@hems/util';
export default defineComponent({
    name: 'ImageUploader',
    props: {
        name: String,
        editable: {
            type: Boolean,
            default: true,
        },
        modelValue: File,
        accept: Array,
        blobImg: Blob,
    },
    setup(props, { emit }) {
        const messageBox = useMessageBox();
        const { t } = useI18n();
        const state = reactive({
            file: useModelWrapper(props, emit),
            editable: computed(() => props.editable),
            imageUrl: props.modelValue ? URL.createObjectURL(props.modelValue) : null,
            savedImageUrl: props.blobImg || null,
        });
        const imageInput = ref(null);
        onMounted(() => {
            createImageObjectURL(props.blobImg);
        });
        watch(() => props.blobImg, () => {
            createImageObjectURL(props.blobImg);
        });
        function createImageObjectURL(image) {
            if (!image) {
                state.imageUrl = null;
                return;
            }
            state.imageUrl = URL.createObjectURL(image);
        }
        function onChangeImages() {
            if (!imageInput.value)
                return;
            const file = imageInput.value.files?.[0];
            if (!file)
                return;
            if (props.accept && !props.accept.includes(file?.type)) {
                clear();
                messageBox.alert(t('message.not_allowed_file')).open();
                return;
            }
            state.file = file;
            state.imageUrl = URL.createObjectURL(file);
        }
        function onClickImageUpload() {
            imageInput.value?.click();
        }
        function clear() {
            state.file = null;
            state.imageUrl = null;
            if (!imageInput.value)
                return;
            imageInput.value.value = '';
            imageInput.value.files = null;
        }
        return {
            state,
            imageInput,
            onClickImageUpload,
            onChangeImages,
            clear,
        };
    },
});
