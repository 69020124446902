import _ from 'lodash';
import { ENV_LOCALE, LANGUAGE, REQUIRED_TERMS_CATEGORY, TERMS_CATEGORY } from '@hems/util/src/constant';
export const getSortedTermsByEffectiveDate = (terms) => {
    const now = new Date();
    const effectiveTerms = terms
        .filter((item) => item.effect_dt <= now.getTime())
        .map((item) => {
        return {
            ...item,
            effectiveDate: item.effect_dt,
        };
    });
    return _.sortBy(effectiveTerms, 'effectiveDate').reverse();
};
export const getFilteredTerms = (terms, locale, langCd) => {
    const englishTermsRegionList = [ENV_LOCALE.AU, ENV_LOCALE.NZ, ENV_LOCALE.US];
    const lang = langCd === LANGUAGE.KO || englishTermsRegionList.includes(locale) ? LANGUAGE.EN : langCd;
    const localeFilteredTerms = localeFilter(terms, locale);
    const serviceTerms = languageFilter(categoryNameFilter(localeFilteredTerms, TERMS_CATEGORY.TERMS_OF_SERVICE), lang);
    const regionTerms = languageFilter(categoryNameFilter(localeFilteredTerms, TERMS_CATEGORY.TERMS_OF_REGION), lang);
    const privacyTerms = languageFilter(categoryNameFilter(localeFilteredTerms, TERMS_CATEGORY.TERMS_OF_PRIVACY), lang);
    const individualTerms = categoryNameFilter(localeFilteredTerms, TERMS_CATEGORY.TERMS_OF_INDIVIDUAL);
    const directMarketingTerms = categoryNameFilter(localeFilteredTerms, TERMS_CATEGORY.TERMS_OF_DIRECT_MARKETING);
    const defaultContents = {
        terms_contents: '',
    };
    const requiredTerms = {
        [TERMS_CATEGORY.TERMS_OF_SERVICE]: serviceTerms[0] ?? defaultContents,
        [TERMS_CATEGORY.TERMS_OF_REGION]: regionTerms[0] ?? defaultContents,
        [TERMS_CATEGORY.TERMS_OF_PRIVACY]: privacyTerms[0] ?? defaultContents,
    };
    const optionalTerms = {
        [TERMS_CATEGORY.TERMS_OF_INDIVIDUAL]: individualTerms[0] ?? defaultContents,
        [TERMS_CATEGORY.TERMS_OF_DIRECT_MARKETING]: directMarketingTerms[0] ?? defaultContents,
    };
    const allTerms = {
        ...requiredTerms,
        ...optionalTerms,
    };
    return { requiredTerms, optionalTerms, allTerms };
};
const categoryNameFilter = (termsList, categoryName) => {
    return termsList.filter((item) => item.terms_nm === categoryName);
};
const localeFilter = (termsList, locale) => {
    return termsList.filter((item) => item.cntry_cd === locale);
};
const languageFilter = (termsList, lang) => {
    return termsList.filter((item) => item.lang === lang);
};
export const getTermsTitleKey = (termsCategory, locale) => {
    switch (termsCategory) {
        case TERMS_CATEGORY.TERMS_OF_SERVICE:
            if (locale === ENV_LOCALE.US)
                return REQUIRED_TERMS_CATEGORY.TERMS_OF_SERVICE;
            return 'common.terms_of_use';
        case TERMS_CATEGORY.TERMS_OF_REGION:
            if (locale === ENV_LOCALE.AU || locale === ENV_LOCALE.US)
                return 'common.terms_region_au';
            if (locale === ENV_LOCALE.NZ)
                return 'common.terms_region_nz';
            return 'common.terms_region_eu';
        case TERMS_CATEGORY.TERMS_OF_PRIVACY:
            return 'common.terms_privacy_policy';
        case TERMS_CATEGORY.TERMS_OF_INDIVIDUAL:
            return 'Individual Usage Profiles (Optional)';
        case TERMS_CATEGORY.TERMS_OF_DIRECT_MARKETING:
            return 'Direct Marketing (Optional)';
    }
};
export const getAgreementTitleKey = (termsCategory, locale) => {
    switch (termsCategory) {
        case TERMS_CATEGORY.TERMS_OF_SERVICE:
            if (locale === ENV_LOCALE.US)
                return 'I agree to the Terms of Service';
            return 'message.agree_terms_service';
        case TERMS_CATEGORY.TERMS_OF_REGION:
            if (locale === ENV_LOCALE.AU || locale === ENV_LOCALE.US)
                return 'message.agree_region_au';
            if (locale === ENV_LOCALE.NZ)
                return 'message.agree_region_nz';
            return 'message.agree_region_eu';
        case TERMS_CATEGORY.TERMS_OF_PRIVACY:
            return 'message.agree_terms_privacy';
        case TERMS_CATEGORY.TERMS_OF_INDIVIDUAL:
            return 'Individual Usage Profiles (Optional)';
        case TERMS_CATEGORY.TERMS_OF_DIRECT_MARKETING:
            return 'Direct Marketing (Optional)';
    }
};
