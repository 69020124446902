import { Helper } from '@hems/util';
class TooltipRenderer {
    el;
    tooltipContainer;
    text;
    tooltipText;
    constructor(props) {
        const el = $('<div class="tooltip-grid"></div>');
        this.tooltipContainer = $('<div class="tui-grid-tooltip"></div>');
        this.text = $('<span class="tui-grid-value"></span>');
        this.tooltipText = $('<span class="tui-grid-tooltip-text"></span>');
        this.tooltipContainer.append(this.tooltipText);
        el.append(this.text).append(this.tooltipContainer);
        this.el = el.get(0);
        this.render(props);
    }
    getElement() {
        return this.el;
    }
    render(props) {
        const value = Helper.nullTo(props.value, '');
        const options = props.columnInfo.renderer.options;
        if (!options) {
            this.setTooltip(value, value);
            return;
        }
        const tooltipColumnName = Helper.nullTo(options.tooltipColumnName, '');
        let tooltip_text = Helper.nullTo(props.grid.getValue(props.rowKey, tooltipColumnName), value);
        tooltip_text = tooltip_text ? options.format?.(tooltip_text) || tooltip_text : '';
        this.setTooltip(value, tooltip_text, options.position);
    }
    mounted(parent) {
        $(parent).closest('.tui-grid-cell').css({ overflow: 'visible' });
    }
    setTooltip(value, tooltipText, pos = ['bottom']) {
        this.text.text(value);
        if (tooltipText) {
            this.tooltipText.text(tooltipText);
            this.tooltipContainer.addClass(pos);
        }
        else
            this.tooltipContainer.remove();
    }
}
export default TooltipRenderer;
