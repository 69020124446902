/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { TOU_OPERATION, TOU_OPERATION_MULTILINGUAL_CODE } from '@hems/util/src/constant/energyManagement';
export const useOperationOptionList = () => {
    const { t } = useI18n();
    const operationOptionList = computed(() => Object.values(TOU_OPERATION).map((operation) => ({
        text: t(TOU_OPERATION_MULTILINGUAL_CODE[operation]),
        value: operation,
    })));
    return {
        operationOptionList,
    };
};
