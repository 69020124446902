class ImageRenderer {
    el;
    constructor(props) {
        const el = document.createElement('img');
        this.el = el;
        this.render(props);
    }
    getElement() {
        return this.el;
    }
    async render(props) {
        const options = props.columnInfo.renderer.options;
        this.el.src = options
            ? (await import(`@hems/component/resources/vendor/image/${options.baseUrl || ''}/${options.prefix || ''}${props.value}${options.postfix || ''}.${options.extension || 'png'}`)).default
            : '';
    }
}
export default ImageRenderer;
