import { WEB_MAPPER_HEIGHT, WEB_MAPPER_WIDTH } from '@hems/util/src/constant/mapper';
export function checkUserAgent() {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.search('android') > -1) {
        return 'aos';
    }
    if (userAgent.search('iphone') > -1 || userAgent.search('ipad') > -1) {
        return 'ios';
    }
    return 'pc';
}
export function isModuleType(object) {
    return object.qtype === 'module';
}
export function isArrayType(object) {
    return Array.isArray(object._objects) && object._objects.length > 0 && object.qtype === 'array';
}
export function isTextType(object) {
    return object.qtype === 'i-text';
}
export function isStringTextType(object) {
    return object.qtype === 'string-text';
}
export function isRectType(object) {
    return object.type === 'rect';
}
export function isArraySelectionType(object) {
    return object.qtype === 'array-selection';
}
export function isInverterType(object) {
    return object.qtype === 'inverter';
}
export function isPannelType(object) {
    return object.qtype === 'pannel';
}
export function isLogicalTextType(object) {
    return object.qtype === 'logical_text';
}
export function isTextBoxType(object) {
    return object.type === 'textbox';
}
export function isSelectObject(opt) {
    if (opt &&
        opt.target &&
        opt.target.type &&
        (opt.target.qtype == 'module' ||
            opt.target.qtype == 'array' ||
            opt.target.type == 'i-text' ||
            opt.target.type == 'textbox' ||
            opt.target.qtype == 'inverter')) {
        return true;
    }
    return false;
}
export function getSerialNumber(object) {
    if (object) {
        return object.get('qserial');
    }
    return '';
}
export function getInverterNumber(object) {
    if (object) {
        return object.get('inverterSerial');
    }
    return '';
}
// mobile
export function getFingerDistance(e) {
    const diffX = e.touches[0].clientX - e.touches[1].clientX;
    const diffY = e.touches[0].clientY - e.touches[1].clientY;
    return Math.sqrt(diffX * diffX + diffY * diffY); // Pythagorean theorem
}
export function getCanvasSize() {
    const canvasWidth = checkUserAgent() === 'pc' ? WEB_MAPPER_WIDTH : window.innerWidth;
    const canvasHeight = checkUserAgent() === 'pc' ? WEB_MAPPER_HEIGHT : window.innerHeight;
    return [canvasWidth, canvasHeight];
}
