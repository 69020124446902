import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import * as Highcharts from 'highcharts';
import * as Highstock from 'highcharts/highstock';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
export default defineComponent({
    name: 'GraphChart',
    components: {},
    props: {
        options: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            default: 'General',
        },
    },
    setup(props) {
        const chart = ref(null);
        const chartInstance = ref(null);
        NoDataToDisplay(Highstock);
        function drawData(options) {
            if (!options.chart || !chart.value)
                return;
            options.chart.renderTo = chart.value;
            options.chart.backgroundColor = 'transparent';
            chartInstance.value =
                props.type === 'Appliance' || props.type === 'TodayProduction' || props.type === 'TodayConsumption'
                    ? Highcharts.chart(options)
                    : Highstock.stockChart(options);
        }
        watch(() => props.options, (options) => {
            drawData(options);
        });
        onMounted(() => {
            drawData(props.options);
        });
        onBeforeUnmount(() => {
            chartInstance.value?.destroy();
        });
        return { chart };
    },
});
