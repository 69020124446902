import { apiWrapper } from '../../util/helper';
import { Service } from '../Service';
export default class NotificationService extends Service {
    constructor(axios) {
        super(axios);
    }
    async getUnRecognitionNotification() {
        const { data } = await this.get(apiWrapper.notificationApi('/history/un-recognition'));
        return data;
    }
    async getNotificationList(params) {
        const { data } = await this.get(apiWrapper.notificationApi('/history'), params);
        return data;
    }
    async updateNotificationRead(notificationId) {
        const { data } = await this.post(apiWrapper.notificationApi(`/history/read/${notificationId}`));
        return data;
    }
    async updateNotificationRecognition(params) {
        const { data } = await this.post(apiWrapper.notificationApi('/history/recognition'), params);
        return data;
    }
    async getNotificationModals() {
        const { data } = await this.get(apiWrapper.notificationApi('/modals'));
        return data;
    }
}
export { NotificationService };
