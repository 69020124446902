class RouteRenderer {
    el;
    button;
    constructor(props) {
        const { $ } = window;
        const el = $('<div class="pd_small tac"></div>');
        if (props.columnInfo.renderer.options?.type === 'info' || props.columnInfo.renderer.options?.type === 'delete') {
            this.button = $('<button class="btn_i btn_c_2" type="button"><span class="ir ico_arrow">상세보기</span></button>');
        }
        else {
            this.button = $('<button class="btn_i btn_c_3" type="button"><span class="ir ico_arrow">상세보기</span></button>');
        }
        el.append(this.button);
        this.el = el.get(0);
    }
    getElement() {
        return this.el;
    }
}
export default RouteRenderer;
