import { defineComponent, reactive } from 'vue';
import { batteryType } from '@hems/component/src/battery/batteryConfig';
import { useModelWrapper } from '@hems/util';
export default defineComponent({
    name: 'Battery',
    inheritAttrs: false,
    props: {
        modelValue: [Array, Boolean],
        name: String,
        type: {
            type: String,
            required: true,
            default: 'wait',
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            value: useModelWrapper(props, emit),
        });
        return {
            state,
            batteryType,
        };
    },
});
