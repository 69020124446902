import { defineComponent, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';
import IncreasingNumberLabel from '@hems/component/src/labels/IncreasingNumberLabel.vue';
import { Helper } from '@hems/util';
export default defineComponent({
    name: 'ValueBox',
    components: {
        IncreasingNumberLabel,
    },
    props: {
        value: {
            type: [Number, String, null],
            required: false,
        },
        unit: {
            type: String,
            required: false,
        },
        text: {
            type: String,
            required: false,
        },
        showBtn: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            required: false,
        },
        btnPath: {
            type: String,
            required: false,
        },
        locale: {
            type: String,
            required: false,
            default: 'en',
        },
        decimal: {
            type: Number,
            required: false,
            default: 2,
        },
        width: {
            type: Number,
            required: false,
        },
        height: {
            type: Number,
            required: false,
        },
    },
    setup(props) {
        const state = reactive({
            isNumber: computed(() => typeof props.value === 'number'),
            locale: computed(() => props.locale),
        });
        const router = useRouter();
        function onClick() {
            router.push({
                name: props.btnPath,
            });
        }
        return {
            state,
            Helper,
            onClick,
        };
    },
});
