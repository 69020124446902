import moment from 'moment';
import { DAY_MONTH_NAME_FORMAT, TOU_SCHEDULE_DATE_FORMAT } from '@hems/util/src/constant/date';
import { formatDate, getEndOfDate, getStartOfDate, isDateInRange, today } from '@hems/util/src/helper/dateHelper';
export const useScheduleDate = () => {
    const dateOfToday = today();
    const momentToday = moment(dateOfToday);
    const currentYear = dateOfToday.getFullYear();
    const firstDayOfThisYear = momentToday.startOf('year').toDate();
    const lastDayOfThisYear = momentToday.endOf('year').toDate();
    const firstDayOfNextYear = momentToday.add(1, 'year').startOf('year').toDate();
    const lastDayOfNextYear = momentToday.add(1, 'year').endOf('year').toDate();
    const getScheduleFormatDateString = (date) => formatDate(new Date(date), DAY_MONTH_NAME_FORMAT);
    const isStartDateLaterThanEndDate = (start, end) => {
        const startDateNumber = Number(start.replace('-', ''));
        const endDateNumber = Number(end.replace('-', ''));
        return startDateNumber > endDateNumber;
    };
    const splitScheduleDateRange = ({ start, end, }) => {
        const startMMDD = moment(start).format(TOU_SCHEDULE_DATE_FORMAT);
        const endMMDD = moment(end).format(TOU_SCHEDULE_DATE_FORMAT);
        if (isStartDateLaterThanEndDate(startMMDD, endMMDD)) {
            return [
                { start, end: lastDayOfThisYear },
                { start: firstDayOfThisYear, end: moment(end).subtract(1, 'year').toDate() },
            ];
        }
        return [{ start, end }];
    };
    /** MM-DD -> Full Date */
    const convertScheduleDateRange = (start, end, setTime = false) => {
        const startDate = setTime ? getStartOfDate(new Date(start)) : new Date(start);
        const endDate = setTime ? getEndOfDate(new Date(end)) : new Date(end);
        startDate.setFullYear(currentYear);
        endDate.setFullYear(currentYear);
        if (isStartDateLaterThanEndDate(start, end)) {
            endDate.setFullYear(currentYear + 1);
        }
        return { start: startDate, end: endDate };
    };
    const getDateRangeDisabledDateList = (dateRangeList) => {
        const result = [];
        dateRangeList.forEach((range) => {
            result.push(...splitScheduleDateRange(range));
        });
        return result;
    };
    const getOnStatus = (start, end) => {
        return isDateInRange(dateOfToday, convertScheduleDateRange(start, end, true));
    };
    const getScheduleDateRangeText = (start, end) => `${getScheduleFormatDateString(start)} ~ ${getScheduleFormatDateString(end)}`;
    const sortCalendarRange = (rangeList) => rangeList.sort((a, b) => (moment(a.start).isBefore(moment(b.start)) ? -1 : 1));
    const hasAvailableSlot = (existingSchedules) => {
        const sortedExistingSchedules = sortCalendarRange(existingSchedules);
        let lastEndDate = firstDayOfThisYear;
        // eslint-disable-next-line no-restricted-syntax
        for (const { start, end } of sortedExistingSchedules) {
            const isCurrentStartAfterPreviousEnd = moment(start).isAfter(moment(lastEndDate).add(1, 'day'), 'day');
            // 현재 일정의 시작일이 이전 일정의 종료일 이후라면 빈 구간이 존재
            if (isCurrentStartAfterPreviousEnd) {
                return true;
            }
            if (moment(end).isAfter(moment(lastEndDate), 'day')) {
                lastEndDate = end;
            }
        }
        // 마지막 일정 이후에 빈 구간이 있는지 확인
        if (moment(lastEndDate).isBefore(moment(lastDayOfThisYear), 'day')) {
            return true;
        }
        return false;
    };
    return {
        dateOfToday,
        currentYear,
        firstDayOfThisYear,
        lastDayOfThisYear,
        firstDayOfNextYear,
        lastDayOfNextYear,
        hasAvailableSlot,
        splitScheduleDateRange,
        getOnStatus,
        getScheduleFormatDateString,
        getScheduleDateRangeText,
        convertScheduleDateRange,
        isStartDateLaterThanEndDate,
        getDateRangeDisabledDateList,
    };
};
