import { defineComponent, computed } from 'vue';
import { INSTALLATION_STATUS } from '@hems/util/src/constant';
import Image2 from '../../image/Image2.vue';
import BaseCard from '../BaseCard.vue';
export default defineComponent({
    name: 'ProcessCard',
    components: {
        BaseCard,
        Image2,
    },
    props: {
        currentMainStep: {
            type: Number,
            default: 1,
        },
        stepNumber: {
            type: Number,
            default: 1,
        },
        mainStep: {
            type: Object,
            default: null,
        },
        subSteps: {
            type: Array,
            default: () => [],
        },
        maxMainStepNumber: {
            type: Number,
            default: 3,
        },
        replacedStep: {
            type: Number,
            default: null,
        },
    },
    setup(props) {
        const isComplete = computed(() => props.mainStep?.status === INSTALLATION_STATUS.COMPLETE);
        const isInProgress = computed(() => props.stepNumber === props.currentMainStep);
        return {
            isComplete,
            isInProgress,
        };
    },
});
