import { Service } from '@hems/service/src/api/Service';
import { apiWrapper } from '@hems/service/src/util/helper';
export default class ErrorCodeService extends Service {
    constructor(axios) {
        super(axios);
    }
    async getAllErrorCodes(language) {
        const { data } = await this.get(apiWrapper.alarmApi('/error-codes'), {
            language,
        });
        return data.data;
    }
    async getErrorCodes(language, errorCode) {
        const { data } = await this.get(apiWrapper.alarmApi(`/error-codes/${errorCode}`), { language });
        return data.data;
    }
    async getErrorHistory(params) {
        const { data } = await this.get(apiWrapper.alarmApi('/error-history'), params);
        return data;
    }
}
export { ErrorCodeService };
