export const RoleWebMap = {
    admin: '/admin',
    utility: '/admin',
    operator: '/admin',
    cocompany: '/admin',
    dev: '/admin',
    installer: '/installer',
    user: '/user',
    service: '/admin',
    retailer: '/admin',
};
export const isAU = (['local', 'development'].includes(process.env.VUE_APP_ENV ?? '') && process.env.VUE_APP_LOCALE === 'AU') ||
    (process.env.VUE_APP_ENV === 'production' && process.env.VUE_APP_LOCALE === 'AU');
/** Application Store Type */
export const APP_STORE_TYPE = {
    GOOGLE: 'google',
    APPLE: 'apple',
};
export const JOIN_TYPE = {
    GENERAL: 'general',
    INSTALLER: 'installer',
};
export const AUTH_CREDENTIAL_INFO = {
    ID: 'id',
    PW: 'pw',
};
export const MOBILE_APP_TYPE = {
    GO: 'go',
    PRO: 'pro',
    HOME: 'home',
};
